import styled from '@emotion/styled';
import defaultProfile from 'assets/images/default_profile.webp';
import { SyntheticEvent, useEffect, useMemo } from 'react';
import { useSetRecoilState } from 'recoil';
import { isImageLoadingAtom } from 'recoil/avatar';

type AvatarSize = 96 | 80 | 32 | 24 | 16 | number;

type Props = {
  /** 이미지의 주소 값 */
  imgUrl?: string;
  /** Avatar 크기 (기본값: 24) */
  size?: AvatarSize;
  /** 외곽 형태 (기본값: circular) */
  edge?: 'square' | 'circular';
};

type AvatarStyle = {
  /** Avatar 크기 (기본값: 24) */
  size?: AvatarSize;
  /** 외곽 형태 (기본값: circular) */
  edge?: 'square' | 'circular';
  /** Avatar 패딩 */
  paddingSize?: number;
};

const Avatar = ({ imgUrl: beforeImgUrl, size = 24, edge = 'circular' }: Props) => {
  const seIsImageLoading = useSetRecoilState(isImageLoadingAtom);

  const imgSrc = useMemo(() => {
    /** 임시 이미지 업로드 때는 퀄리티 제외 */
    if (beforeImgUrl?.includes('blob')) return beforeImgUrl;
    return beforeImgUrl ? `${beforeImgUrl.slice(0, beforeImgUrl.length - 4)}q=100` : defaultProfile;
  }, [beforeImgUrl]);

  useEffect(() => {
    seIsImageLoading(!!beforeImgUrl);
  }, [seIsImageLoading, beforeImgUrl]);

  useEffect(() => {
    if (imgSrc) {
      const img = new Image();
      img.src = imgSrc;
      img.onload = () => {
        seIsImageLoading(false);
      };
    }
  }, [imgSrc, seIsImageLoading]);

  const paddingSize = useMemo(() => {
    switch (size) {
      case 24:
        return 1;
      case 16:
        return 0.67;
      default:
        return 0;
    }
  }, [size]);

  const replaceDefault = (e: SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.src = defaultProfile;
    seIsImageLoading(false);
  };

  return (
    <Container className="avatar" size={size} paddingSize={paddingSize}>
      <AvatarImg edge={edge} src={imgSrc} alt="avatar image" onError={replaceDefault} />
    </Container>
  );
};

const Container = styled.div<AvatarStyle>`
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  width: ${props => props.size + 'px'};
  height: ${props => props.size + 'px'};
  padding: ${props => props.paddingSize + 'px'};
`;

const AvatarImg = styled.img<AvatarStyle>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: ${({ edge }) => (edge === 'circular' ? '50%' : '16px')};
`;

export default Avatar;
