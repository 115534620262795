import { yupResolver } from '@hookform/resolvers/yup';
import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import Form from 'components/Form';
import dayjs from 'dayjs';
import { BoardNoticeParams } from 'hooks/service/mutations/usePostBoardNotice';
import usePostFileUploadAttachmentPresignedUrl from 'hooks/service/mutations/usePostFileUploadAttachmentPresignedUrl';
import useToast from 'hooks/useToast';
import { ReactNode, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import LeaveDialog from 'sharedComponents/LeaveDialog';
import MainLayout from 'sharedComponents/MainLayout';
import filters from 'utils/filters';
import { maximumLength } from 'utils/validate';
import { object } from 'yup';

import { NOTICE_FORM_TEXT } from '../constants';
import { NoticeFormType } from '../types';

type Props = {
  title: string;
  children: ReactNode;
  mutateAction: (values: BoardNoticeParams, onError: () => void) => void;
  defaultValues?: NoticeFormType;
};

const yupSchema = object().shape({
  title: maximumLength(255),
});

const NoticeForm = ({ title, children, defaultValues, mutateAction }: Props) => {
  const [isPastDateDialogOpen, setIsPastDialogOpen] = useState(false);
  const { setToast } = useToast();
  const methods = useForm<NoticeFormType>({
    mode: 'onChange',
    resolver: yupResolver(yupSchema),
    defaultValues: defaultValues || {
      title: '',
      contents: '',
      popup_on: true,
      is_top_fixed: false,
      is_used_period: true,
      is_unlimited_period: false,
      isTargetActive: true,
      isTargetExpired: false,
      start_on: filters.date(),
      period: 'limited',
      imgUrls: [],
      files: [],
    },
  });

  const { getAttachmentIds } = usePostFileUploadAttachmentPresignedUrl('boards');
  const setFormError = () => {
    methods.setError('root', { type: 'manual' });
  };

  const submitForm = useCallback(
    async (formValues: NoticeFormType) => {
      const { isTargetActive, isTargetExpired, period, start_on, end_on, files, imgUrls } = formValues;
      const isPeriodLimited = period === 'limited';
      const isPeriodUnlimited = period === 'unlimited';

      // mutation 전 유효성 검사
      if (!isTargetActive && !isTargetExpired) {
        setToast({ type: 'error', message: NOTICE_FORM_TEXT.noTargetError, bottom: 84 });
        setFormError();
        return;
      }
      if (isPeriodLimited && !end_on) {
        setToast({ type: 'error', message: NOTICE_FORM_TEXT.noLimitedPeriodError, bottom: 84 });
        setFormError();
        return;
      }
      const isPastEndonThanToday = dayjs(end_on).isBefore(dayjs(), 'day');
      if (isPastEndonThanToday) {
        setIsPastDialogOpen(true);
        setFormError();
        return;
      }

      const savedImages = imgUrls.map(img => img.id).filter(id => id) as number[]; // 수정 시 기존 이미지 id
      const uploadedImages = await getAttachmentIds(files, 92); // 새로 업로드한 이미지 id
      if (uploadedImages === false) {
        setFormError();
        return; // 이미지 업로드 실패시 중단
      }

      // mutation 전 가공
      const params: BoardNoticeParams = {
        ...formValues,
        attachment_id: [...savedImages, ...uploadedImages],
        target_member: isTargetActive && isTargetExpired ? 'both' : isTargetActive ? 'active' : 'expired',
        is_unlimited_period: isPeriodUnlimited,
        is_used_period: isPeriodLimited,
        start_on: isPeriodLimited ? filters.dateDash(start_on) : undefined,
        end_on: isPeriodLimited ? filters.dateDash(end_on) : undefined,
      };

      mutateAction(params, setFormError);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mutateAction],
  );

  return (
    <MainLayout header={{ title }}>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(submitForm)}>{children}</Form>
        <LeaveDialog />
      </FormProvider>
      {isPastDateDialogOpen && (
        <Dialog
          onClose={() => setIsPastDialogOpen(false)}
          isError
          positiveAction={{ text: NOTICE_FORM_TEXT.ok, onClick: () => setIsPastDialogOpen(false) }}>
          <DialogDescription>{NOTICE_FORM_TEXT.endOnError[0]}</DialogDescription>
          <DialogDescription>{NOTICE_FORM_TEXT.endOnError[1]}</DialogDescription>
        </Dialog>
      )}
    </MainLayout>
  );
};

export default NoticeForm;
