import useGetAllBookingList from 'hooks/service/queries/useGetAllBookingList';
import useGetLectureDetail from 'hooks/service/queries/useGetLectureDetail';
import useCheckPermissionBookingDetail from 'hooks/useCheckPermissionBookingDetail';
import MainLayout from 'sharedComponents/MainLayout';

import BookingForm from './BookingForm';

type Props = {
  currentLectureId: number;
};

const Main = ({ currentLectureId }: Props) => {
  const { data: currentLecture } = useGetLectureDetail(currentLectureId);
  const { data: bookings } = useGetAllBookingList(currentLectureId);
  const bookingType = currentLecture.type === 'G' ? 'group' : 'private';
  const { canCancelBooking } = useCheckPermissionBookingDetail(currentLecture.staff.id, bookingType);

  return (
    <MainLayout header={{ title: '회원 변경' }}>
      <BookingForm
        bookingType={bookingType}
        currentLecture={currentLecture}
        bookings={bookings}
        canCancelBooking={canCancelBooking}
      />
    </MainLayout>
  );
};

export default Main;
