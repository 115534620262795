import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import { DrawerProps } from 'designedComponents/Drawers';
import OneLinedTitle from 'designedComponents/Drawers/DrawerTitle/OneLinedTitle';
import DoubleButtonDrawer from 'designedComponents/Drawers/DrawerWithButton/DoubleButtonDrawer';
import useQueryString from 'hooks/useQueryString';

type Props = Pick<DrawerProps, 'isOpen' | 'onClose'> & {
  target?: string;
  onSubmit: () => void;
};

const MobileConfirmDrawer = ({ target, isOpen, onClose, onSubmit }: Props) => {
  const { getSearchParams } = useQueryString();

  const isFromMyInfo = getSearchParams('type') === 'my-info';

  return (
    <DoubleButtonDrawer
      type={3}
      isOpen={isOpen}
      onClose={onClose}
      header={<OneLinedTitle title="휴대폰 번호 변경" />}
      leftButton={{ text: '취소', onClick: onClose }}
      rightButton={{ text: '계속', onClick: onSubmit }}>
      <DrawerContent>
        {isFromMyInfo ? (
          <>
            <Typography size={15}>
              번호 변경 시, 시설과의 연동이 해제될 수 있습니다.
              <br />
              계속 하시겠습니까?
            </Typography>

            <Typography size={15} textColor="gray2">
              *연동 해제를 원하지 않는 경우
              <br />
              <b>{'[내 계정정보 > 휴대폰 번호]'}</b> 에서 동일한 번호로 변경해 주세요.
            </Typography>
          </>
        ) : (
          <Typography size={15}>
            번호 변경 시, 변경된 번호로 {target ?? '관리자앱'}과 연동됩니다.
            <br />
            계속 하시겠습니까?
          </Typography>
        )}
      </DrawerContent>
    </DoubleButtonDrawer>
  );
};

export default MobileConfirmDrawer;

const DrawerContent = styled.section`
  ${theme.flex('column', '', '', 20)};

  .typography b {
    font-weight: 500;
  }
`;
