import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { PERMISSION } from 'constants/permission';
import Drawer from 'designedComponents/Drawers';
import CheckRadioFilterContents from 'designedComponents/Drawers/CheckRadioDrawer/CheckRadioFilterContents';
import OneLinedTitle from 'designedComponents/Drawers/DrawerTitle/OneLinedTitle';
import useGetPermissionDoHavePermission from 'hooks/service/queries/useGetPermissionDoHavePermission';
import usePermission from 'hooks/usePermission';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { CONTENT_TEXT } from '../constants';
import ContentTitle from './ContentTitle';

type Props = {
  roles: Array<{
    id: string;
    label: string;
    value: string;
  }>;
};

const RoleSelect = ({ roles }: Props) => {
  const navigate = useNavigate();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { hasPermission } = usePermission();
  const { checkPermission } = useGetPermissionDoHavePermission();

  const isRolePermission = hasPermission(PERMISSION.role.edit);

  const { control, setValue, watch } = useFormContext();

  const currentRole = roles.filter(role => role.id === watch('role'))[0].label;

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  return (
    <Container>
      <ContentTitle
        title={CONTENT_TEXT.roleSelect.title}
        sub={CONTENT_TEXT.roleSelect.sub}
        showRoleButton={isRolePermission}
        clickRoleManagement={() => {
          checkPermission(PERMISSION.role.edit.id, {
            onSuccess: () => {
              navigate('role-management');
            },
          });
        }}
      />
      <Button
        className="role-drawer-button"
        variant="outlined"
        size="medium48"
        fontSize={15}
        borderRadius={10}
        textColor="gray1"
        rightIcon={<Icon name="arrowBottom" fillColor={theme.color.gray2} />}
        padding={{ left: 16, right: 12 }}
        fullWidth
        onClick={() => {
          checkPermission(PERMISSION.role.edit.id, {
            onSuccess: () => {
              setIsDrawerOpen(true);
            },
          });
        }}>
        {currentRole}
      </Button>

      <Drawer header={<OneLinedTitle title="역할 선택" />} isOpen={isDrawerOpen} onClose={closeDrawer}>
        <CheckRadioFilterContents
          name="role"
          data={roles}
          control={control}
          onClick={({ target: { value } }) => {
            setValue('role', value);
            closeDrawer();
          }}
        />
      </Drawer>
    </Container>
  );
};

export default RoleSelect;

const Container = styled.section`
  padding: 8px 20px 32px;

  .role-drawer-button {
    ${theme.flex('row', 'center', 'space-between')};
  }
`;
