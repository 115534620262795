import { MessageFilterWrapper } from 'pages/MoreDetails/Message/Mobile/List/components/MobileFilter';
import { MOBILE_FILTER_DEFAULT } from 'pages/MoreDetails/Message/Mobile/List/constants';
import { appPushSelectedFilterAtom } from 'recoil/message';
import Filter from 'sharedComponents/Filters';

import { APP_PUSH_FILTER_TAB_LABELS, APP_PUSH_FILTERS } from '../constants';
import AppPushDrawerFilterOptions from './AppPushDrawerFilterOptions';
import AppPushWholeFilterOptions from './AppPushWholeFilterOptions';

type Props = {
  canView: boolean;
};

const AppPushFilter = ({ canView }: Props) => {
  if (!canView) return null;
  return (
    <MessageFilterWrapper>
      <Filter
        filterAtom={appPushSelectedFilterAtom}
        filters={APP_PUSH_FILTERS}
        defaultFilter={MOBILE_FILTER_DEFAULT}
        defaultTabLabels={APP_PUSH_FILTER_TAB_LABELS}
        renderDrawerOptions={AppPushDrawerFilterOptions}
        renderWholeFilterPopupOptions={AppPushWholeFilterOptions}
      />
    </MessageFilterWrapper>
  );
};

export default AppPushFilter;
