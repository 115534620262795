import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import { PRODUCT_TYPE_TEXT } from 'constants/text';
import usePatchUserTicketUpdate from 'hooks/service/mutations/usePatchUserTicketUpdate';
import useToast from 'hooks/useToast';
import { TicketEditFormType, UserTicketProps } from 'pages/TicketDetail/types';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import MainLayout from 'sharedComponents/MainLayout';
import TicketCard from 'sharedComponents/TicketCard';
import { CustomError } from 'types/errorTypes';
import filters from 'utils/filters';
import formatSmallUserTicketCard from 'utils/formatSmallUserTicketCard';

import TicketEditForm from './TicketEditForm';

type Props = UserTicketProps & {
  currentStaffId: number;
};

const TicketEdit = ({ userTicket, currentStaffId }: Props) => {
  const { setToast } = useToast();
  const navigate = useNavigate();
  const { mutate: updateTicketMutate } = usePatchUserTicketUpdate();

  const {
    id,
    studio_id,
    user_id,
    max_coupon,
    remaining_cancel,
    remaining_coupon,
    availability_start_at,
    expire_at,
    is_shared,
    staffs,
  } = userTicket;

  const methods = useForm<TicketEditFormType>({
    defaultValues: {
      count: {
        max_coupon,
        remaining_cancel,
        remaining_coupon,
      },
      period: {
        availability_start_at: filters.dateDash(availability_start_at),
        expire_at: filters.dateDash(expire_at),
      },
      originAvailablePeriod: 90,
      detail: {
        staffs,
        tempStaffs: staffs,
        is_shared,
        user_id,
        studio_id,
        staff_id: currentStaffId,
      },
    },
  });

  const submit = async (values: TicketEditFormType) => {
    const {
      detail: { staffs: staffsState, tempStaffs, ...rest },
      count,
      period,
    } = values;

    const params = {
      ...rest,
      ...count,
      ...period,
      staffs: staffsState.map(({ id }) => id),
      user_ticket_id: id,
    };

    updateTicketMutate(
      { user_tickets: [params] },
      {
        onSuccess: () => {
          setToast({
            type: 'success',
            message: `${PRODUCT_TYPE_TEXT[userTicket.ticket.available_class_type]} 정보가 수정되었습니다.`,
          });
          navigate(-1);
        },
        onError: (error: CustomError) => {
          const noPermission = error.response?.data?.code === 35001;
          if (noPermission) {
            return false;
          }
        },
      },
    );
  };

  return (
    <MainLayout header={{ title: `${PRODUCT_TYPE_TEXT[userTicket.ticket.available_class_type]} 수정` }}>
      <FormProvider {...methods}>
        <Container>
          <TicketCard ticket={formatSmallUserTicketCard(userTicket)} />
          <Divider />
          <TicketEditForm userTicket={userTicket} onSubmit={submit} />
        </Container>
      </FormProvider>
    </MainLayout>
  );
};

export default TicketEdit;

const Container = styled.div`
  ${theme.flex('column', '', '')};
  min-height: 100%;

  form {
    flex: 1;
  }

  .ticket-card {
    padding-left: 20px;
    padding-right: 20px;
  }

  .staff-link-button {
    border-bottom: none;
  }
`;
