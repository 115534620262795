import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Accordion from 'components/Accordion';
import Button from 'components/Button';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import dayjs from 'dayjs';
import RangeTimePicker from 'designedComponents/Pickers/TimePicker/RangeTimePicker';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import filters from 'utils/filters';

import { CALENDAR_SETTING_TEXT } from '../../constants';
import { CalendarSettingsFormType } from '../../types';
import SettingInfoTooltip from '../SettingInfoTooltip';

const TimeRange = () => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const { control, setValue } = useFormContext<CalendarSettingsFormType>();

  const currentTimeRange = useWatch({ control, name: 'timeRange' });

  /**
   * 끝 시간은 시작 시간과 같을 순 없으므로, 30분 뒤로 설정
   * 끝 시간이 00:00 일때는 그대로 적용
   */
  const changeTime = ([start, end]: [Date, Date], endValue?: Date) => {
    let endTime: Date;

    if (start === end && !!endValue && filters.time(endValue).slice(0, 2) === '00') {
      endTime = filters.time(endValue) === '00:30' ? dayjs(endValue).hour(0).minute(0).toDate() : endValue;
      setValue('timeRange', [start, endTime]);
      return;
    }
    endTime = dayjs(start).isBefore(end) ? end : dayjs(start).add(30, 'minute').toDate();
    setValue('timeRange', [start, endTime]);
  };

  return (
    <Container
      isOpen={isAccordionOpen}
      header={
        <div className="custom-title">
          <div className="title-tooltip-wrapper">
            <Typography className="title-left" size={15} weight={500}>
              {CALENDAR_SETTING_TEXT.timeRange.title}
            </Typography>

            <SettingInfoTooltip
              isOpen={isTooltipOpen}
              setIsOpen={setIsTooltipOpen}
              message={CALENDAR_SETTING_TEXT.timeRange.tooltipMessage}
            />
          </div>
          <Button fontSize={15} fontWeight={600} onClick={() => setIsAccordionOpen(!isAccordionOpen)}>
            {filters.timePeriod(currentTimeRange[0], currentTimeRange[1])}
          </Button>
        </div>
      }
      showArrow={false}>
      <Divider thin />
      <RangeTimePicker timeRange={currentTimeRange} onChange={changeTime} stepMinute={30} />
    </Container>
  );
};

export default TimeRange;

const Container = styled(Accordion)`
  border-radius: 12px;
  background-color: white;
  box-shadow: 1px 3px 10px 0px rgba(145, 145, 145, 0.12);

  .header-wrapper {
    padding: 0 16px;

    .custom-title {
      ${theme.flex('row', 'center', 'space-between')};
      width: 100%;

      .title-tooltip-wrapper {
        ${theme.flex('row', 'center', 'flex-start', 2)};

        .title-left {
          color: ${theme.color.gray2} !important; // RoundedAccordion color props의 한계로 css에서 조정
        }
      }
    }
  }

  .mbsc-datepicker-tab-time {
    padding-top: 16px !important; // picker 커스텀 간격 조정
  }

  .from-text {
    margin-bottom: 8px; // picker 커스텀 간격 조정("부터" 텍스트)
  }

  .custom-title .typography {
    color: ${({ isOpen }) => (isOpen ? theme.color.primary : theme.color.gray1)};
  }

  .tooltip-typography-wrapper .typography {
    color: ${theme.color.white};
  }

  .tooltip-contents-wrapper {
    width: 198px !important;
  }

  .range-time-picker {
    background-color: transparent !important;
  }
`;
