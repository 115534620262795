import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import useGetStudiomateNoticeDetail from 'hooks/service/queries/useGetStudiomateNoticeDetail';
import filters from 'utils/filters';

type Props = {
  id: number;
};

const NoticeDetail = ({ id }: Props) => {
  const { data: notice } = useGetStudiomateNoticeDetail(id);
  const contents = notice?.context.replace(/_500x0\.png/g, '_0x0.png');

  return (
    <Container>
      <Title size={17} weight={700}>
        {notice.title}
      </Title>
      <Typography opacity={0.88}>{filters.dateTimeWithWeekday(notice.created_at)}</Typography>
      <Contents dangerouslySetInnerHTML={{ __html: contents }} />
    </Container>
  );
};

export default NoticeDetail;

const Container = styled.main`
  padding: 16px 20px;
`;

const Title = styled(Typography)`
  margin-bottom: 8px;
`;
const Contents = styled.div`
  margin: 40px 0;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 22px;
  color: ${theme.color.gray1};
`;
