import Avatar from 'components/Avatar';
import CheckBox from 'components/CheckBox';
import Typography from 'components/Typography';

import { ReportStaff } from '../type';

type Props = {
  staff: ReportStaff;
  isSelected: boolean;
  isCurrentUser: boolean;
  onChange: (staff: ReportStaff, checked: boolean) => void;
};

const ReportStaffItem = ({ staff, isSelected, isCurrentUser, onChange }: Props) => {
  return (
    <li>
      <CheckBox
        id={`${staff.id}`}
        label={
          <div className="staff-label-wrapper">
            <Avatar size={24} imgUrl={staff.imgUrl} />
            <div className="staff-label">
              <Typography size={17} weight={500}>
                {staff.name}
              </Typography>
              {isCurrentUser && (
                <Typography textColor="gray3" weight={600}>
                  나
                </Typography>
              )}
            </div>
          </div>
        }
        gap={16}
        checked={isSelected}
        onChange={e => onChange(staff, e.target.checked)}
      />
    </li>
  );
};

export default ReportStaffItem;
