import { yupResolver } from '@hookform/resolvers/yup';
import { PERMISSION } from 'constants/permission';
import useGetMemberDetail from 'hooks/service/queries/useGetMemberDetail';
import useGetStudioInfo from 'hooks/service/queries/useGetStudioInfo';
import useInfinityUserTicketProduct from 'hooks/service/queries/useInfinityUserTicketProduct';
import usePermission from 'hooks/usePermission';
import { useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { studioIdAtom } from 'recoil/common';
import getImageUrl from 'utils/getImageUrl';
import { memberNameRequired, mobile } from 'utils/validate';
import { object, string } from 'yup';

import MemberFormMainLayout from '../components/MemberFormMainLayout';
import { MEMBER_FORM_TEXT } from '../constants';
import { MemberFormType } from '../types';
import MemberUpdateForm from './MemberUpdateForm';

type Props = {
  currentMemberId: number;
};

const yupSchema = object().shape({
  mobile: string().when('$canViewMemberMobileNumber', {
    is: true,
    then: () => mobile,
    otherwise: () => string().optional(),
  }),
  profile: object({ name: memberNameRequired }),
});

const FormMain = ({ currentMemberId }: Props) => {
  const currentStudioId = useRecoilValue(studioIdAtom);
  const {
    data: {
      mobile,
      address,
      user_grade,
      vaccination_yn,
      avatar,
      profile: { name, registered_at, gender, birthday, reward_point, paid_point },
    },
  } = useGetMemberDetail(currentMemberId);
  const { data: currentUserTickets } = useInfinityUserTicketProduct({
    user_id: currentMemberId,
    product_type: 'all',
    status: 'active',
  });
  const {
    data: {
      policy: { is_use_user_grade, is_auto_write_unpaid },
    },
  } = useGetStudioInfo(currentStudioId);
  const { hasPermission } = usePermission();
  const canViewMemberMobileNumber = hasPermission(PERMISSION.member.viewMobile);

  const userTickets = currentUserTickets.map(userTicket => ({
    ...userTicket,
    isAutoWriteUnpaid: !!is_auto_write_unpaid,
    newPayment: { ...userTicket.payments.last_payment, paymentId: userTicket.payments.last_payment.id },
  }));

  const methods = useForm<MemberFormType>({
    mode: 'onChange',
    resolver: yupResolver(yupSchema),
    shouldFocusError: false,
    context: { canViewMemberMobileNumber },
    defaultValues: {
      image: !avatar ? '' : getImageUrl(avatar.image),
      beforeName: name,
      mobile: !canViewMemberMobileNumber ? '***********' : mobile || '',
      address: address
        ? {
            address: address.address ?? '',
            detail_address: address.detail_address ?? '',
          }
        : undefined,
      userGradeId: !user_grade ? MEMBER_FORM_TEXT.userGrade.notSelected.value : String(user_grade.id),
      vaccination_yn: vaccination_yn === 'Y',
      profile: {
        name,
        registered_at,
        gender: gender || 'F',
        birthday: birthday ?? undefined,
      },
      userTickets,
      initialUserTickets: userTickets,
      tickets: [],
      updateUserTickets: [],
      search: '',
      tempSearchKeyword: '',
      selectedTicket: null,
      selectedCreatedTicket: undefined,
      currentMemberPoint: reward_point + paid_point,
    },
  });

  return (
    <MemberFormMainLayout pageMode="update" {...methods}>
      <MemberUpdateForm
        currentMemberId={currentMemberId}
        canViewMemberMobileNumber={canViewMemberMobileNumber}
        isUserGrade={!!is_use_user_grade}
      />
    </MemberFormMainLayout>
  );
};

export default FormMain;
