export const BOOKING_PRIVATE_FORM_HEADER_TITLE_TEXT = {
  create: '프라이빗 수업 예약',
  update: '프라이빗 수업 수정',
  repeat: '반복 설정',
  custom: '맞춤 설정',
  selectMember: '수강회원 선택',
};

export const BOOKING_PRIVATE_FORM_TEXT = {
  lectureSize: {
    placeholder: '수업인원',
    drawer: {
      title: '수업인원 선택',
    },
  },
  title: {
    description: ['수업명을 입력하지 않으면', '회원명으로 수업이 등록됩니다.'],
  },
  selectMember: {
    dialog: {
      positiveText: '확인',
      description: `날짜 선택 후, \n수강회원 선택이 가능합니다.`,
    },
    search: {
      placeholder: '회원명, 휴대폰 번호 검색',
    },
    footerButton: '선택 완료',
  },
  dialog: {
    beforeLeave: {
      positiveText: '나가기',
      negativeText: '취소',
      description: {
        selectMember: `변경사항이 저장되지 않았습니다. \n페이지를 나가시겠습니까?`,
        default: '예약정보가 저장되지 않았습니다. \n페이지를 나가시겠습니까?',
      } as { [key: string]: string },
    },
  },
};

export const LECTURE_SIZE_CHECK_RADIO_DATA = [
  { id: 'alone', label: '개인 (1:1)', value: '1' },
  { id: 'duet', label: '듀엣 (2:1)', value: '2' },
  { id: 'triple', label: '트리플 (3:1)', value: '3' },
];

export const VALID_ERROR_TYPE_TEXT = {
  total: {
    daily: '일일 예약가능 횟수: ',
    weekly: '최대 예약가능 횟수: 주 ',
    monthly: '최대 예약가능 횟수: 월 ',
  },
  count: {
    daily: '',
    weekly: '주 ',
    monthly: '월 ',
  },
};
