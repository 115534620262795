import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Avatar from 'components/Avatar';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { MemberResponse } from 'hooks/service/queries/useInfinityMember';
import { MemberCardProps } from 'types/memberTypes';
import filters from 'utils/filters';
import getImageUrl from 'utils/getImageUrl';

import MemberGrade from './MemberGrade';

type Props = {
  member: MemberResponse | MemberCardProps;
  showRegisteredAt?: boolean;
};

const MemberInfo = ({ member, showRegisteredAt = true }: Props) => {
  const {
    avatar,
    name,
    vaccination_yn,
    mobile,
    account_id,
    profile: { registered_at },
  } = member;

  const user_grade = 'user_grade' in member ? member.user_grade : member.userGrade;
  const attendance_count = 'attendance_count' in member ? member.attendance_count : null;

  const isShowRegisteredAt = showRegisteredAt && attendance_count === null;
  const isShowAttendanceCount = attendance_count !== null;

  return (
    <MemberInfoWrapper>
      <Avatar imgUrl={avatar ? getImageUrl(avatar.image) : undefined} size={40} edge="circular" />
      <div className="detail">
        <Row>
          <TypoWithIcon>
            <Typography span size={15} weight={700} ellipsis={1}>
              {name}
            </Typography>
            {vaccination_yn === 'Y' && <Icon name="vaccinateBold" size={16} fillColor={theme.color.secondary1} />}
          </TypoWithIcon>
          <MemberGrade name={user_grade?.name} color={user_grade?.representative_color} />
        </Row>
        <Row>
          <TypoWithIcon>
            <Typography span textColor="gray2">
              {filters.mobile(mobile || '') || '-'}
            </Typography>
            {!account_id && <Icon name={'connectOff'} size={16} fillColor={theme.color.gray3} />}
          </TypoWithIcon>
          {isShowRegisteredAt && (
            <Typography size={13} textColor="gray3">
              {filters.date(registered_at)} 등록
            </Typography>
          )}
          {isShowAttendanceCount && (
            <Typography size={13} textColor="primary" weight={600}>
              {attendance_count}회 출석
            </Typography>
          )}
        </Row>
      </div>
    </MemberInfoWrapper>
  );
};

export default MemberInfo;

const MemberInfoWrapper = styled.div`
  ${theme.flex('', '', '', 10)};
  .detail {
    flex: 1;
  }
  flex: 1;
`;

const Row = styled.div`
  ${theme.flex('', 'center', 'space-between', 20)};

  flex: 1 0 auto;
  .label {
    max-width: 88px;
    min-width: 32px;
    flex: 0 0 auto;

    .typography {
      ${theme.textEllipsis(1)}
    }
  }
`;

const TypoWithIcon = styled.div`
  ${theme.flex('', 'center', 'initial', 1)};
`;
