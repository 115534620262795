import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import TimePicker from 'components/TimePicker';
import Typography from 'components/Typography';
import dayjs from 'dayjs';
import DoubleButtonDrawer from 'designedComponents/Drawers/DrawerWithButton/DoubleButtonDrawer';
import { useCallback, useEffect, useState } from 'react';
import { FilterOptionsType } from 'sharedComponents/Filters/types';
import filters from 'utils/filters';

type Props = {
  initialTime?: (string | undefined)[];
  onSave: (option: FilterOptionsType, key: string) => void;
  startDateDisabled?: boolean;
  endDateDisabled?: boolean;
  defaultStartButtonText?: string;
  defaultEndButtonText?: string;
  prevSelectedOption?: {
    type?: string;
    start?: string;
    end?: string;
  };
};

const CheckTimePicker = ({
  defaultStartButtonText = '시작시간',
  defaultEndButtonText = '종료시간',
  initialTime,
  startDateDisabled,
  endDateDisabled,
  onSave: changeOptions,
  prevSelectedOption,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tempTime, setTempTime] = useState(initialTime);
  const [activeTimePicker, setActiveTimePicker] = useState<string | null>(null);

  const startValue = !prevSelectedOption?.type ? defaultStartButtonText : filters.time(tempTime?.[0]);
  const endValue = !prevSelectedOption?.type
    ? defaultEndButtonText
    : tempTime?.[1]
      ? dayjs(tempTime[1]).format('HH:mm')
      : defaultEndButtonText;

  const changeDate = useCallback(
    (e: { value: string }) => {
      setTempTime(prevTempTime => {
        const newTempTime = prevTempTime ? [...prevTempTime] : [];
        if (activeTimePicker === 'start') {
          const startTime = e.value;
          const endTime = tempTime?.[1];
          if (endTime && dayjs(endTime).isBefore(startTime)) {
            // 시작 시간을 설정하고, 끝 시간을 시작 시간보다 5분 뒤로 설정
            newTempTime[0] = e.value;
            newTempTime[1] = dayjs(e.value).add(5, 'm').toString();
          } else {
            newTempTime[0] = e.value;
          }
        } else if (activeTimePicker === 'end') {
          newTempTime[1] = e.value;
        }
        return newTempTime;
      });
    },
    [activeTimePicker, tempTime],
  );

  const closeTimePicker = () => {
    setTempTime(initialTime || []);
    setIsOpen(false);
  };

  const saveTime = () => {
    changeOptions(
      {
        value: { type: prevSelectedOption?.type, start: tempTime?.[0], end: tempTime?.[1] },
        label: '',
      },
      'classTime',
    );
    setIsOpen(false);
  };

  useEffect(() => {
    setTempTime(initialTime);
  }, [initialTime]);

  return (
    <>
      <Container className="period-picker">
        <TimeButton
          fullWidth
          size="medium48"
          variant="outlined"
          padding={{ left: 16, right: 12 }}
          disabled={startDateDisabled}
          textColor="gray1"
          fontSize={15}
          onClick={() => {
            setIsOpen(true);
            setActiveTimePicker('start');
          }}>
          {startValue}
        </TimeButton>
        <Typography textColor="gray3" size={15} weight={500}>
          ~
        </Typography>
        <TimeButton
          fullWidth
          size="medium48"
          variant="outlined"
          padding={{ left: 16, right: 12 }}
          disabled={endDateDisabled}
          textColor={endDateDisabled ? 'gray3' : 'gray1'}
          fontSize={15}
          onClick={() => {
            setIsOpen(true);
            setActiveTimePicker('end');
          }}>
          {endValue}
        </TimeButton>
      </Container>
      <StyledDoubleButtonDrawer
        hideHandle
        type={3}
        isOpen={isOpen}
        onClose={closeTimePicker}
        leftButton={{ text: '취소', onClick: () => setIsOpen(false) }}
        rightButton={{ text: '완료', onClick: saveTime }}>
        <TimePicker
          value={tempTime?.[activeTimePicker === 'start' ? 0 : 1]}
          onChange={changeDate}
          stepMinute={5}
          minTime={activeTimePicker === 'end' && tempTime?.[0] ? dayjs(tempTime[0]).add(5, 'm').toDate() : undefined}
        />
      </StyledDoubleButtonDrawer>
    </>
  );
};

export default CheckTimePicker;

const Container = styled.div`
  ${theme.flex('', 'center', 'space-between', 8)};
`;

const TimeButton = styled(Button)`
  justify-content: flex-start;

  :disabled {
    background-color: ${theme.color.gray6};
    border: 1px solid ${theme.color.gray6};

    span {
      color: ${theme.color.gray3};
    }
  }
`;

const StyledDoubleButtonDrawer = styled(DoubleButtonDrawer)`
  padding-top: 32px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: calc(32px + 76px);

  div > .mbsc-datepicker-tab-wrapper .mbsc-datepicker-tab {
    padding: 0px;
  }
`;
