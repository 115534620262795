import { useQuery } from '@tanstack/react-query';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';
import { PeriodDateType } from './useGetAllScheduleLecture';

type Props = {
  start_date: PeriodDateType;
  end_date: PeriodDateType;
  enabled?: boolean;
};

const useGetHolidayTarget = ({ enabled, ...params }: Props) => {
  const { queryFn } = useQueryFnWithCommonParams<HolidayTargetResponse, HolidayTargetParams>({
    url: '/api/holiday/target',
    params,
  });

  return useQuery({
    queryKey: ['holiday/target', params],
    queryFn,
    select: data => data.data,
    enabled,
  });
};

export default useGetHolidayTarget;

export type HolidayTargetResponse = string[];

type HolidayTargetParams = Props;
