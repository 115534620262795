import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography, { TypographyProps } from 'components/Typography';
import { SettingByRuleTypeProps } from 'pages/Booking/types';
import filters from 'utils/filters';

/**
 * 프라이빗 수업 1개 수정 폼, 그룹 수업 폼의
 * 운영정보 설정에서 사용되는 스타일
 */
export const RuleTypeContainer = styled.div`
  &.booking.R,
  &.cancel.R {
    .others-setting-text {
      display: none;
    }
  }

  &.update:has(+ hr) {
    .accordion:last-of-type {
      border-bottom: none;
    }
  }

  // 1개 수정에서는 DateTimePicker 사용
  &.update .mbsc-datepicker-tab-date {
    border-right: 1px solid ${theme.color.gray8};
  }
`;

export const RuleTypeAccordionHeader = styled.div`
  ${theme.flex('row', 'center', 'flex-start', 6)}
`;

const StyledRuleTypeTypography = styled(Typography)`
  background-color: ${theme.color.gray6};
  border-radius: 6px;
  height: 40px;
  line-height: 40px;
  margin: 16px 20px 8px;
  padding: 0 16px;
`;

export const RuleTypeTypography = ({ children }: TypographyProps) => {
  return (
    <StyledRuleTypeTypography size={14} weight={500} textColor="gray3">
      {children}
    </StyledRuleTypeTypography>
  );
};

export const StyledRuleTypeTimeTypography = styled.div`
  ${theme.flex('row', 'center', 'center', 2)};
`;

export const StyledEndAtTypography = styled(StyledRuleTypeTimeTypography)`
  height: 56px;
  line-height: 56px;
  align-items: center;
`;

export const PrimaryColorTypography = ({
  children,
  className,
  isOpen,
  ...props
}: TypographyProps & Pick<SettingByRuleTypeProps, 'isOpen'>) => {
  return (
    <Typography
      className={`primary-color-typography ${className ?? ''}`}
      span
      size={15}
      weight={isOpen ? 600 : 500}
      textColor={isOpen ? 'primary' : 'gray1'}
      {...props}>
      {children}
    </Typography>
  );
};

const StyledUpdateDateWrapper = styled.span`
  display: inline-flex;
  .primary-color-typography {
    display: inline-block;

    &.time {
      margin-left: 6px;
    }
  }
`;

export const UpdateDateTypography = ({ isOpen, startAt, endAt }: { isOpen?: boolean; startAt?: string; endAt?: string }) => {
  return (
    <StyledUpdateDateWrapper>
      {startAt && (
        <>
          <PrimaryColorTypography isOpen={!!isOpen}>{filters.date(startAt)}</PrimaryColorTypography>
          <PrimaryColorTypography className="time" isOpen={!!isOpen}>
            {filters.time(startAt)}
          </PrimaryColorTypography>
        </>
      )}
      {startAt && endAt && (
        <Typography span size={15} weight={500}>
          &nbsp;~&nbsp;
        </Typography>
      )}
      {endAt && (
        <>
          <PrimaryColorTypography isOpen={!!isOpen}>{filters.date(endAt)}</PrimaryColorTypography>
          <PrimaryColorTypography className="time" isOpen={!!isOpen}>
            {filters.time(endAt)}
          </PrimaryColorTypography>
        </>
      )}
    </StyledUpdateDateWrapper>
  );
};
