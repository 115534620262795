import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import CheckBox from 'components/CheckBox';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import dayjs from 'dayjs';
import _ from 'lodash';
import { FieldType } from 'pages/MoreDetails/Staff/Form/types';
import { useState } from 'react';
import { ScheduleFormType } from 'sharedComponents/ProfileField/types';
import staffUtils from 'utils/staffSchedules';
import { v4 as uuidv4 } from 'uuid';

import { ALL_DAYS, WORK_TIME_TITLE } from '../constants';
import RestTimeAccordion from './RestTimeAccordion';
import SubHeader from './SubHeader';
import WorkTimeAccordion from './WorkTimeAccordion';

type Props = {
  index: number;
  /**
   * 해당 요일의 근무시간 데이터
   */
  workTimeField: ScheduleFormType;
  /**
   * 근무시간 전체 배열, 일괄 적용에 사용
   */
  workTimeFieldsOrigin: ScheduleFormType[];
  /**
   * 해당 요일의 휴식시간 데이터
   */
  restTimeFields: ScheduleFormType[];
  updateTime: (index: number, value: ScheduleFormType, type: FieldType) => void;
  removeRestTime: (index: number) => void;
  appendRestTime: (value: ScheduleFormType) => void;
  replaceFields: (newFields: ScheduleFormType[], type: FieldType) => void;
  /**
   * 휴식시간 전체 배열, remove 및 update에 사용되는 fields index 확인용
   */
  restTimeFieldsOrigin: ScheduleFormType[];
};

const WorkTimeCard = ({
  index,
  workTimeField,
  workTimeFieldsOrigin,
  restTimeFields,
  updateTime,
  removeRestTime,
  appendRestTime,
  replaceFields,
  restTimeFieldsOrigin,
}: Props) => {
  const [isClosedChecked, setIsClosedChecked] = useState<boolean>(!!workTimeField?.isClosed);

  const appendRestTimeAction = (day?: ScheduleFormType['day_of_week']) => {
    if (day !== 0 && !day) return;

    // 시간만으로는 dayjs 계산이 불가능해서, 오늘 날짜 기준으로 객체를 가지고와 계산 진행
    const end_time = dayjs(`${dayjs().format('YYYY-MM-DD')} ${workTimeField?.start_time}`)
      .add(30, 'minute')
      .format('HH:mm:ss');

    appendRestTime({
      id: uuidv4(),
      date: '',
      type: 'restTime',
      day_of_week: day,
      start_time: workTimeField?.start_time,
      end_time,
      isClosed: false,
      created_at: '',
      updated_at: '',
    });
  };

  // 휴무일은 일괄 적용에 포함 X
  const batchApplyFields = () => {
    const newWorkTimeFields = workTimeFieldsOrigin.map(fieldOrigin => {
      if (fieldOrigin.isClosed) return fieldOrigin;
      return {
        ...workTimeField,
        day_of_week: fieldOrigin.day_of_week,
      };
    });

    const newRestTimeFields = workTimeFieldsOrigin.map(fieldOrigin => {
      if (fieldOrigin.isClosed) return [];
      return restTimeFields.map(field => {
        return {
          ...field,
          day_of_week: fieldOrigin.day_of_week,
        };
      });
    });

    replaceFields(newWorkTimeFields, 'work');
    replaceFields(newRestTimeFields.flat(), 'rest');
  };

  return (
    <Container isClosedChecked={isClosedChecked}>
      <section className="card-header">
        <Typography size={15} weight={600} textColor={isClosedChecked ? 'gray4' : 'gray1'}>
          {ALL_DAYS[index].label}
        </Typography>

        <CheckBox
          id={`closed-checkbox-${index}`}
          label={WORK_TIME_TITLE.closed}
          gap={8}
          checked={isClosedChecked}
          onChange={e => {
            setIsClosedChecked(!isClosedChecked);
            updateTime(index, { ...workTimeField, isClosed: e.target.checked }, 'work');
          }}
        />
      </section>

      {!isClosedChecked && (
        <>
          <section className="work-time-info">
            <SubHeader
              title={WORK_TIME_TITLE.work}
              diffText={staffUtils.workTimeDiff(workTimeField?.start_time, workTimeField?.end_time)}
            />
            <WorkTimeAccordion field={workTimeField} index={index} updateTime={updateTime} />
          </section>

          <Divider thin />

          <section className="rest-time-info">
            <SubHeader
              title={WORK_TIME_TITLE.rest}
              diffText={staffUtils.restTimeDiff(restTimeFields, workTimeField)}
              onClick={() => {
                // 요일만 파악하면 되므로 workTime 데이터 사용
                const day = workTimeField?.day_of_week;
                appendRestTimeAction(day);
              }}
            />
            <ul className="rest-time-list">
              {restTimeFields.map((restTimeField, index) => {
                const lastIndex = index === restTimeFields.length - 1;
                const restTimeIndex = _.findIndex(restTimeFieldsOrigin, { id: restTimeField.id });
                return (
                  <li key={`${restTimeField.day_of_week}-${restTimeField.id}`}>
                    {index > 0 && <Divider thin />}

                    <div className="rest-time-accordion-wrapper">
                      <RestTimeAccordion
                        field={restTimeField}
                        index={restTimeIndex}
                        lastIndex={lastIndex}
                        updateTime={updateTime}
                        removeRestTime={removeRestTime}
                        appendRestTimeAction={appendRestTimeAction}
                      />
                    </div>
                  </li>
                );
              })}
            </ul>
          </section>

          <div className="batch-apply-button">
            <Button
              variant="outlined"
              size="small"
              padding={{ left: 12, right: 12 }}
              fontSize={13}
              fontWeight={500}
              textColor="gray2"
              onClick={batchApplyFields}>
              모든 요일 일괄 적용
            </Button>
          </div>
        </>
      )}
    </Container>
  );
};

export default WorkTimeCard;

const Container = styled.section<{ isClosedChecked: boolean }>`
  padding: 18px 0;
  border-radius: 16px;
  background-color: ${theme.color.white};
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08);

  &:not(:last-of-type) {
    margin-bottom: 12px;
  }

  .card-header {
    ${theme.flex('row', 'center', 'space-between')};
    padding: 0 20px;

    .checkbox > .typography {
      font-size: 1.4rem;
      color: ${({ isClosedChecked }) => theme.color[isClosedChecked ? 'gray4' : 'gray2']};
    }
  }

  .work-time-info,
  .rest-time-info {
    margin-top: 19px;

    > div {
      ${theme.flex('row', 'center', 'space-between')};

      &:first-of-type {
        margin-bottom: 9px;
        padding: 0 20px;
      }

      .accordion {
        .header-box,
        .rest-time-info {
          width: 100%;
        }

        .rest-time-info {
          ${theme.flex('row', 'center', 'space-between')};
        }

        .header-wrapper {
          margin-bottom: 16px;
        }

        .header-wrapper.accordion__large {
          padding: 0;
          height: 22px;
        }
      }
    }

    .accordion {
      width: 100%;
    }

    .add-rest-time {
      margin-top: 16px;
    }
  }

  .rest-time-info {
    .header-wrapper {
      margin-bottom: 16px;
      padding: 0;
      height: inherit;
    }

    li:not(:first-of-type) > hr {
      margin: 0 20px 15px;
    }

    .rest-time-accordion-wrapper {
      position: relative;

      .header-box {
        justify-content: space-between;
        padding: 0 20px;
      }

      .icon-wrapper {
        ${theme.flex('row', 'center', '', 8)};
      }
    }
  }

  .batch-apply-button {
    ${theme.flex('', '', 'flex-end')};
    margin-top: 2px;
    padding: 0 20px;
  }

  .rest-time-hr {
    margin: 0 20px;
  }
`;
