import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import TabPanel from 'components/Tabs/TabPanel';
import { CurrentMemberIdProps, HistoryFilterFormType } from 'pages/MemberDetail/types';
import { FormProvider, useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { memberDetailHistoryFilterAtom } from 'recoil/MemberDetail';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import BookingHistory from './BookingHistory';
import CountTabSkeleton from './CountTabSkeleton';
import Filters from './Filters';
import HistoryCountTab from './HistoryCountTab';
import HistorySkeleton from './HistorySkeleton';

const History = ({ currentMemberId }: CurrentMemberIdProps) => {
  const historyFilter = useRecoilValue(memberDetailHistoryFilterAtom);

  const methods = useForm<HistoryFilterFormType>({
    defaultValues: historyFilter,
  });

  return (
    <FormProvider {...methods}>
      <Container>
        <ApiBoundary fallback={<CountTabSkeleton />}>
          <HistoryCountTab currentMemberId={currentMemberId} />
        </ApiBoundary>
        <Filters currentMemberId={currentMemberId} />
        <TabPanel>
          <ApiBoundary fallback={<HistorySkeleton />}>
            <BookingHistory currentMemberId={currentMemberId} />
          </ApiBoundary>
        </TabPanel>
      </Container>
    </FormProvider>
  );
};

export default History;

const Container = styled.div`
  ${theme.flex('column', '', '')};
  flex: 1;
  position: relative;

  .tab-panel {
    padding: 24px 20px 54px;
    background-color: ${theme.color.gray6};

    .more-button {
      margin-top: 24px;
    }
  }
`;
