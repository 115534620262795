import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import { IDateCalendarPickerProps } from 'components/DateCalendarPicker';
import Typography from 'components/Typography';
import DateCalendarPickerDrawer from 'designedComponents/Pickers/DateCalendarPicker/DateCalendarPickerDrawer';
import PickerButtonOutlined from 'designedComponents/Pickers/PickerButton/PickerButtonOutlined';
import useToast from 'hooks/useToast';
import { useEffect, useState } from 'react';
import filters from 'utils/filters';

type Props = IDateCalendarPickerProps & {
  initialDate?: string[];
  onSave: (period: string[]) => boolean;
  startDateDisabled?: boolean;
  endDateDisabled?: boolean;
  defaultStartButtonText?: string;
  defaultEndButtonText?: string;
  onChangeDate?: (value: string[]) => void;
};

const PeriodPicker = ({
  defaultStartButtonText = '시작일',
  defaultEndButtonText = '종료일',
  initialDate,
  startDateDisabled,
  endDateDisabled,
  onChangeDate,
  onSave,
  ...props
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tempDate, setTempDate] = useState(initialDate || []);

  useEffect(() => {
    if (!isOpen) {
      const startTarget = document.querySelector('label.mbsc-segmented-item.mbsc-range-start') as HTMLElement;
      startTarget?.click();
    }
  }, [isOpen]);

  const changeDate = (e: { value: string[] }) => {
    setTempDate(e.value);
    onChangeDate?.(e.value);
  };

  const closeCalendar = () => {
    setTempDate(initialDate || []);
    setIsOpen(false);
  };

  const { setToast } = useToast();

  /**
   * 기간 선택 후 완료 버튼 클릭 시
   * true: drawer 닫히지 않음
   * false: drawer 닫힘
   */
  const saveDate = () => {
    const isIncompleteSelection = tempDate.length < 2 || tempDate.some(date => !date);
    if (isIncompleteSelection) {
      setToast({ message: '날짜를 선택해 주세요.', bottom: 92 });
      return;
    }

    const isOpenDatePeriod = onSave(tempDate);
    if (!isOpenDatePeriod) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (!initialDate?.length) {
      setTempDate([]);
    } else {
      setTempDate(initialDate);
    }
  }, [initialDate]);

  return (
    <>
      <Container className="period-picker">
        <PickerButtonOutlined disabled={startDateDisabled} placeholder={defaultStartButtonText} onClick={() => setIsOpen(true)}>
          {tempDate[0] && filters.date(tempDate[0])}
        </PickerButtonOutlined>

        <Typography span size={15} weight={500} textColor={startDateDisabled && endDateDisabled ? 'gray4' : 'gray2'}>
          ~
        </Typography>
        <PickerButtonOutlined disabled={endDateDisabled} placeholder={defaultEndButtonText} onClick={() => setIsOpen(true)}>
          {tempDate[1] && filters.date(tempDate[1])}
        </PickerButtonOutlined>
      </Container>

      <DateCalendarPickerDrawer
        {...props}
        isOpen={isOpen}
        onClose={closeCalendar}
        zIndex={6010}
        select="range"
        value={tempDate}
        onSave={saveDate}
        onChange={changeDate}
      />
    </>
  );
};

export default PeriodPicker;

const Container = styled.div`
  ${theme.flex('', 'center', 'space-between', 8)};
`;
