import useGetEtcScheduleEtcScheduleTime from 'hooks/service/queries/useGetEtcScheduleEtcScheduleTime';
import useCheckPermissionEtcDetail from 'hooks/useCheckPermissionEtcDetail';

import DetailMain from '../components/DetailMain';

type Props = {
  currentEtcScheduleId: number;
};

const IndividualDetail = ({ currentEtcScheduleId }: Props) => {
  const { data } = useGetEtcScheduleEtcScheduleTime(currentEtcScheduleId);
  const { canViewEtcSchedule, canCreateEtcSchedule, canUpdateEtcSchedule, canDeleteEtcSchedule } = useCheckPermissionEtcDetail(
    data.register,
  );

  return (
    <DetailMain
      currentEtcScheduleId={currentEtcScheduleId}
      data={data}
      canViewEtcSchedule={canViewEtcSchedule}
      canCreateEtcSchedule={canCreateEtcSchedule}
      canUpdateEtcSchedule={canUpdateEtcSchedule}
      canDeleteEtcSchedule={canDeleteEtcSchedule}
    />
  );
};

export default IndividualDetail;
