import { PERMISSION } from 'constants/permission';
import { DropdownType } from 'pages/Booking/types';
import { object, string } from 'yup';

export const ETC_FORM_VALIDATE = object().shape({
  title: string().max(200, '제목은 200자 이내로 입력해 주세요.'),
});

export const ETC_FORM_DEFAULT_HEADER_TITLE_TEXT = {
  create: '기타 일정 등록',
  copy: '기타 일정 등록',
  update: '기타 일정 수정',
  updateAll: '기타 일정 수정',
  repeat: '반복설정',
  custom: '맞춤 설정',
} as { [key: string]: string };

export const ETC_FORM_TEXT = {
  title: {
    placeholder: '제목',
  },
  isAllDay: {
    label: '종일',
  },
  isOverlap: {
    label: '수업중복 허용',
  },
  scheduleShare: {
    title: '일정 공유',
  },
  isPrivate: {
    title: '공개 설정',
  },
  footerButton: {
    create: '등록 완료',
    update: '수정 완료',
    updateAll: '수정 완료',
    copy: '등록 완료',
  },
  successMessage: {
    create: '일정이 등록되었습니다.',
    update: '일정이 수정되었습니다.',
  },
};

export const ETC_PRIVATE_DATA = [
  { id: 'open', label: '전체 공개', value: 'open' },
  { id: 'close', label: '비공개', value: 'close' },
];

/** 기타 일정 생성/수정/삭제 권한 체크 */
export const ETC_SCHEDULE_PERMISSION = {
  mine: {
    create: PERMISSION.etcSchedule.mine.create,
    update: PERMISSION.etcSchedule.mine.edit,
    delete: PERMISSION.etcSchedule.mine.delete,
  },
  others: {
    view: PERMISSION.etcSchedule.others.view,
    create: PERMISSION.etcSchedule.others.create,
    update: PERMISSION.etcSchedule.others.edit,
    delete: PERMISSION.etcSchedule.others.delete,
  },
  all: {
    view: [PERMISSION.etcSchedule.others.view],
    create: [PERMISSION.etcSchedule.mine.create, PERMISSION.etcSchedule.others.create],
    update: [PERMISSION.etcSchedule.mine.edit, PERMISSION.etcSchedule.others.edit],
    delete: [PERMISSION.etcSchedule.mine.delete, PERMISSION.etcSchedule.others.delete],
  },
};

export const ETC_DETAIL_DROPDOWN_DATA: Array<DropdownType> = [
  { value: 'update', label: '수정하기', textColor: 'gray2', icon: { name: 'writing2', color: 'gray2' } },
  { value: 'copy', label: '복사하기', textColor: 'gray2', icon: { name: 'copy', color: 'gray2' } },
  { value: 'delete', label: '삭제하기', textColor: 'secondary3', icon: { name: 'delete', color: 'secondary3' } },
];

export const ETC_DETAIL_UPDATE_DRAWER_LIST = [
  { label: '이 일정만 수정', value: 'update' },
  { label: '이후 일정 모두 수정', value: 'update-after-all' },
  { label: '모든 일정 수정', value: 'update-all' },
];

export const ETC_DETAIL_DELETE_DRAWER_LIST = [
  { label: '이 일정만 삭제', value: 'delete' },
  { label: '이후 일정 모두 삭제', value: 'deleteAfterDate' },
  { label: '모든 일정 삭제', value: 'deleteAll' },
];

export const ETC_DETAIL_DELETE_TEXT = {
  dialog: {
    negativeText: '취소',
    positiveText: '삭제',
    description: '일정을 삭제하시겠습니까?',
  },
  successMessage: '일정이 삭제되었습니다.',
  processingTitle: '일정 삭제중',
};

export const ETC_COLOR_DATA = [
  { id: 'magenta', label: '마젠타', value: 'C63361' },
  { id: 'purple', label: '퍼플', value: '822CA4' },
  { id: 'violet', label: '바이올렛', value: '5837AA' },
  { id: 'sapphire', label: '사파이어', value: '3C48A5' },
  { id: 'easternBlue', label: '이스턴 블루', value: '0F9AB9' },
  { id: 'horizonBlue', label: '호리즌 블루', value: '5A8D9B' },
  { id: 'prussianBlue', label: '프러시안 블루', value: '3D7398' },
  { id: 'oliveGreen', label: '올리브 그린', value: '5C9E51' },
  { id: 'ashGreen', label: '애쉬 그린', value: '778163' },
  { id: 'gray', label: '그레이', value: '757575' },
  { id: 'redOrange', label: '레드 오렌지', value: 'E25D33' },
  { id: 'burntUmber', label: '번트 엄버', value: 'B7643E' },
  { id: 'woodBrown', label: '우드 브라운', value: '99826B' },
  { id: 'sandBrown', label: '샌드 브라운', value: 'B58149' },
];
