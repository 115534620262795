import styled from '@emotion/styled';
import { Datepicker, localeKo, MbscDatepickerOptions } from '@mobiscroll/react';
import { theme } from 'assets/styles';
import { CommonWheelStyles } from 'sharedComponents/mobiscroll/CommonWheelStyles';

const DateTimePicker = ({ ...props }: MbscDatepickerOptions) => {
  return (
    <Container className="date-time-picker">
      <Datepicker
        theme="material"
        touchUi={true}
        locale={localeKo}
        display="inline"
        controls={['date', 'time']}
        rows={3}
        timeFormat="HH:mm"
        itemHeight={32}
        minWheelWidth={32}
        {...props}
      />
    </Container>
  );
};

export default DateTimePicker;

const Container = styled(CommonWheelStyles)`
  /** 특정 숫자 말줄임 표시되지 않고 전부 표시 되도록 */
  .mbsc-scroller-wheel-item {
    min-width: fit-content;
  }

  /** 날짜 wrapper */
  .mbsc-datepicker-tab-date {
    flex: 1.44;
    border-right: 1px solid ${theme.color.gray6};

    .mbsc-scroller-wheel-group {
      padding: 0 16px;

      .mbsc-scroller-wheel-wrapper {
        margin: 0 6px;

        /** date: 연도(year) */
        &.mbsc-datetime-year-wheel {
          flex: 1.6;
        }

        /** date: 월(month),일(day) */
        &.mbsc-datetime-month-wheel,
        &.mbsc-datetime-day-wheel {
          flex: 1;
        }
      }
    }
  }

  /** 시간 wrapper */
  .mbsc-datepicker-tab-time {
    flex: 1;

    .mbsc-scroller-wheel-group {
      padding: 0 22px;

      .mbsc-scroller-wheel-wrapper {
        flex: 1;
        margin: 0 8px;
      }
    }

    /** 시간과 분 사이 콜론(:) */
    .mbsc-scroller-wheel-line::before {
      content: ':';
      color: #aaabaf;
      font-size: 1.9rem;
      font-weight: 500;
      line-height: 26px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, calc(-50% - 1px));
    }
  }
`;
