export const MESSAGE_COUNSEL_TEXT = {
  start_on: '상담일',
  channel: '상담유형',
  funnel: '유입경로',
  staff: '담당스태프',
};

export const MESSAGE_COUNSEL_SORT_DATA = [
  { id: 'nameAsc', label: '이름 오름차순', value: 'name,asc' },
  { id: 'nameDesc', label: '이름 내림차순', value: 'name,desc' },
  { id: 'dateDesc', label: '상담일 최신순', value: 'start_on,desc' },
  { id: 'dateAsc', label: '상담일 과거순', value: 'start_on,asc' },
];
