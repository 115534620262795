import styled from '@emotion/styled';
import SearchBar from 'components/SearchBar';
import { BOOKING_PRIVATE_FORM_TEXT } from 'pages/Booking/Private/constants';
import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { BookingSelectMemberFormType } from '../types';

const Search = () => {
  const { setValue, control } = useFormContext<BookingSelectMemberFormType>();
  const search = useWatch({ control, name: 'search' });
  const [tempSearch, setTempSearch] = useState('');

  useEffect(() => {
    if (search === undefined) {
      setTempSearch('');
    }
  }, [search]);

  return (
    <Container>
      <SearchBar
        id="booking-select-member-search-bar"
        placeholder={BOOKING_PRIVATE_FORM_TEXT.selectMember.search.placeholder}
        value={tempSearch}
        onClear={() => setTempSearch('')}
        onCancel={() => {
          setTempSearch('');
          setValue('search', undefined);
        }}
        onChange={e => setTempSearch(e.target.value)}
        onSubmitForm={() => setValue('search', tempSearch)}
      />
    </Container>
  );
};

export default Search;

const Container = styled.div`
  width: 100%;
  flex: 0 0 auto;
  padding: 0 20px 16px;
`;
