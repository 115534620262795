import styled from '@emotion/styled';
import { theme } from 'assets/styles';

export const PaymentRow = styled.div`
  border-bottom: 1px solid ${theme.color.gray8};

  &:has(+ hr) {
    border-bottom: none;
  }

  .underlined-text-field {
    border-bottom: none;

    input {
      padding-right: 0;
    }

    .suffix {
      text-align: center;
      min-width: 13px;
      margin-left: 2px;
      margin-right: 20px;
    }
  }
`;

export const PaymentCheckboxRow = styled(PaymentRow)`
  padding: 0;
  padding-left: 20px;
  ${theme.flex('', 'center', 'space-between', 12)};

  .left,
  .apply-all {
    flex: 0 0 auto;
  }
  .right {
    flex: 1;

    input:disabled {
      & + div {
        background-color: transparent;
      }
    }
  }
`;
