import { currentCounselingAtom } from 'recoil/counseling';
import { allMemberSelectedFiltersAtom, assignedMemberSelectedFiltersAtom, counselSelectedFiltersAtom } from 'recoil/Member';
import {
  currentMemberAtom,
  currentMemberUserTicketsAtom,
  paymentSelectedFilterAtom,
  pointFiltersAtom,
} from 'recoil/MemberDetail';
import {
  appPushSelectedFilterAtom,
  messageTargetCounselSelectedFilter,
  messageTargetMemberSelectedFilter,
  mobileSelectedFilterAtom,
} from 'recoil/message';
import {
  bookingParamsAtom,
  checkinParamsAtom,
  currentPermissionMoreDetailScheduleAtom,
  deletedLectureParamsAtom,
  isEveryPermissionMoreDetailScheduleAtom,
  staffListForScheduleFilter,
} from 'recoil/moreDetail';
import {
  reportLectureParamAtom,
  reportLecturePeriodAtom,
  reportPointParamAtom,
  reportPointPeriodAtom,
  reportSaleParamAtom,
  reportSalePeriodAtom,
  reportUnpaidParamAtom,
} from 'recoil/report/atom';
import { selectedFiltersAtom, selectedTempSchedulesAtom, tempApplyStaffsAtom } from 'recoil/schedule';
import { searchKeywordsAtom } from 'recoil/searchKeywords';
import { selectedPaymentAtom, selectedUserTicketHistoryAtom } from 'recoil/TicketDetail';

// 시설 변경, 로그아웃 시 초기화 할 recoil 상태
export const resetRecoilState = [
  searchKeywordsAtom,
  currentCounselingAtom, // counseling
  allMemberSelectedFiltersAtom, // Member
  assignedMemberSelectedFiltersAtom,
  counselSelectedFiltersAtom,
  currentMemberAtom, // MemberDetail
  currentMemberUserTicketsAtom,
  paymentSelectedFilterAtom,
  pointFiltersAtom,
  mobileSelectedFilterAtom, // message
  appPushSelectedFilterAtom,
  messageTargetMemberSelectedFilter,
  messageTargetCounselSelectedFilter,
  bookingParamsAtom, // moreDetail
  checkinParamsAtom,
  deletedLectureParamsAtom,
  staffListForScheduleFilter,
  isEveryPermissionMoreDetailScheduleAtom,
  currentPermissionMoreDetailScheduleAtom,
  reportSalePeriodAtom, // report
  reportLecturePeriodAtom,
  reportPointPeriodAtom,
  reportSaleParamAtom,
  reportLectureParamAtom,
  reportUnpaidParamAtom,
  reportPointParamAtom,
  selectedFiltersAtom, // schedule
  tempApplyStaffsAtom,
  selectedTempSchedulesAtom,
  selectedUserTicketHistoryAtom, // TicketDetail
  selectedPaymentAtom,
];
