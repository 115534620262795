import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import CheckBox from 'components/CheckBox';
import Divider from 'components/Divider';
import OneLinedTitle from 'designedComponents/Drawers/DrawerTitle/OneLinedTitle';
import SingleButtonDrawer from 'designedComponents/Drawers/DrawerWithButton/SingleButtonDrawer';
import { useEffect, useState } from 'react';
import { FilterOptionsType } from 'sharedComponents/Filters/types';

import { ReportCommonParamType } from '../type';

type TicketOption = { id?: number | string; title?: string };

type Props = {
  selectedOption: FilterOptionsType['value'];
  isOpen: boolean;
  multiSelectedOptions: FilterOptionsType[];
  onClose: () => void;
  onSave: (option: FilterOptionsType, key: string) => void;
};

const ReportMultiTicketOptionDrawer = ({
  selectedOption,
  isOpen,
  multiSelectedOptions,
  onClose,
  onSave: changeOptions,
}: Props) => {
  const [options, setOptions] = useState<TicketOption[]>([]);
  const [tempSelectedOption, setTempSelectedOption] = useState<TicketOption[]>([]);

  const checkedState = (() => {
    if (tempSelectedOption.length === 0) {
      return options.length > 0 ? 'unchecked' : 'unchecked';
    }
    if (tempSelectedOption.some(t => t.title === 'all')) return 'checked';
    return tempSelectedOption.length === options.length ? 'checked' : 'someChecked';
  })();

  const changeMultiSelectedOptions = () => {
    changeOptions({ value: tempSelectedOption, label: '' }, 'tickets');
    onClose();
  };

  // 전체선택
  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      setTempSelectedOption([...options, { title: 'all' }]);
    } else {
      setTempSelectedOption([]);
    }
  };

  // 개별 선택
  const handleTicketSelect = (ticket: TicketOption, checked: boolean) => {
    setTempSelectedOption(prev => {
      const filteredOptions = prev.filter(t => t.title !== 'all');
      if (checked) {
        const newSelectedOptions = [...filteredOptions, ticket];
        return newSelectedOptions.length === options.length ? [...newSelectedOptions, { title: 'all' }] : newSelectedOptions;
      } else {
        // 선택 해제 시 해당 티켓만 제거
        return filteredOptions.filter(t => t.id !== ticket.id);
      }
    });
  };

  // 수강권 체크박스 초기설정
  useEffect(() => {
    if (isOpen) {
      const prevSelectedTickets = (selectedOption as ReportCommonParamType['tickets']) || [];
      const ticketInfos = multiSelectedOptions.map(multiSelectedOption => multiSelectedOption.value as TicketOption);
      setOptions(ticketInfos);
      // 이전 선택이 없고 staff 목록이 있으면 전체 선택
      if (prevSelectedTickets.length === 0 && ticketInfos.length > 0) {
        setTempSelectedOption([...ticketInfos, { title: 'all' }]);
      } else {
        setTempSelectedOption([...prevSelectedTickets]);
      }
    }
  }, [isOpen, multiSelectedOptions, selectedOption]);

  return (
    <StyledSingleButtonDrawer
      isOpen={isOpen}
      onClose={onClose}
      header={<OneLinedTitle title="수강권 선택" />}
      button={{
        text: '선택 완료',
        onClick: changeMultiSelectedOptions,
        disabled: tempSelectedOption.length === 0,
      }}>
      <Container checkedState={checkedState}>
        <CheckBox
          id="all-ticket"
          className="header-checkbox"
          label="전체"
          labelFontsize={17}
          labelFontWeight={500}
          gap={16}
          value="all"
          checked={checkedState === 'checked'}
          iconName={checkedState === 'someChecked' ? 'lineBold' : 'selectCheckBold'}
          checkColor={checkedState !== 'unchecked' ? 'white' : 'gray5'}
          onChange={e => handleSelectAll(e.target.checked)}
        />
        <Divider thin thinColor="gray6" />
        <StyledUl>
          {options.map(ticket => (
            <li key={ticket.id}>
              <CheckBox
                id={`${ticket.id}`}
                label={ticket.title}
                gap={16}
                labelFontsize={17}
                labelFontWeight={500}
                checked={tempSelectedOption.some(t => t.id === ticket.id)}
                onChange={e => handleTicketSelect(ticket, e.target.checked)}
              />
            </li>
          ))}
        </StyledUl>
      </Container>
    </StyledSingleButtonDrawer>
  );
};

export default ReportMultiTicketOptionDrawer;

const Container = styled.div<{ checkedState: 'checked' | 'unchecked' | 'someChecked' }>`
  padding: 0 20px;

  hr {
    margin-top: 20px;
  }

  .header-checkbox {
    .input-span-style {
      border-color: ${({ checkedState }) => (checkedState === 'unchecked' ? theme.color.gray5 : theme.color.primary)};
      background-color: ${({ checkedState }) => (checkedState === 'unchecked' ? theme.color.white : theme.color.primary)};
    }
  }
`;

const StyledSingleButtonDrawer = styled(SingleButtonDrawer)`
  padding-left: 0;
  padding-right: 0;
`;

const StyledUl = styled.ul`
  ${theme.flex('column', '', '', 8)};
  padding-top: 12px;
  padding-left: 20px;

  li {
    padding: 8px 0;
  }

  .staff-label-wrapper {
    ${theme.flex('', 'center', '', 8)}
  }
`;
