import { isArray } from 'lodash';

import { MemberUpdateUserTicket } from '../types';

// 수정 가능한 기간, 횟수, 다중예약 항목
const fieldsToCompare = [
  'availability_start_at',
  'expire_at',
  'is_shared',
  'max_coupon',
  'remaining_cancel',
  'remaining_coupon',
] as const;

// 수정 가능한 결제 항목
const newPaymentFieldsToCompare = [
  'amount',
  'card_amount',
  'cash_amount',
  'installment_period',
  'point_amount',
  'reward_point',
  'settlement_at',
  'unpaid_amount',
  'wiretransfer_amount',
] as const;

const isEqualStaffIds = (updateStaffs: MemberUpdateUserTicket['staffs'], currentStaffs: MemberUpdateUserTicket['staffs']) => {
  if (!isArray(updateStaffs) || !isArray(currentStaffs)) return false;
  if (updateStaffs.length !== currentStaffs.length) return false;

  const updateIds = new Set(updateStaffs.map(staff => staff.id));
  const currentIds = new Set(currentStaffs.map(staff => staff.id));

  if (updateIds.size !== currentIds.size) return false;
  return Array.from(updateIds).every(id => currentIds.has(id));
};

/** 회원 수정 시 보유 수강권 수정 여부 */
const isEqualUserTickets = (updateUserTickets: MemberUpdateUserTicket[], currentUserTickets: MemberUpdateUserTicket[]) => {
  if (updateUserTickets.length !== currentUserTickets.length) {
    return false;
  }

  return updateUserTickets.every((updateTicket, index) => {
    const currentTicket = currentUserTickets[index];

    // 결제 외 항목 비교
    const basicFieldsMatch = fieldsToCompare.every(field => {
      return updateTicket[field] === currentTicket[field];
    });

    // 결제 항목 비교
    const newPaymentFieldsMatch = newPaymentFieldsToCompare.every(field => {
      return updateTicket.newPayment[field] === currentTicket.newPayment[field];
    });

    // staffs 배열 비교
    const staffsMatch = isEqualStaffIds(updateTicket.staffs, currentTicket.staffs);

    return basicFieldsMatch && newPaymentFieldsMatch && staffsMatch;
  });
};

export default isEqualUserTickets;
