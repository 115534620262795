import { useIsMutating, useQueryClient } from '@tanstack/react-query';
import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import Typography from 'components/Typography';
import usePatchBookingGroupCancel from 'hooks/service/mutations/usePatchBookingGroupCancel';
import usePatchBookingPrivateCancel from 'hooks/service/mutations/usePatchBookingPrivateCancel';
import { LectureBookingListResponse } from 'hooks/service/queries/useInfinityBookingList';
import useErrorDialog from 'hooks/useErrorDialog';
import useToast from 'hooks/useToast';
import { BOOKING_DETAIL_CANCEL_DIALOG_TEXT } from 'pages/Booking/constants';
import { BookingType } from 'pages/Booking/types';
import { useNavigate } from 'react-router-dom';

type Props = {
  onClose: () => void;
  bookingType: BookingType;
  currentMemberBooking: LectureBookingListResponse;
  currentBookingTraineeCount: number;
};

const CancelBookingDialog = ({ bookingType, currentMemberBooking, currentBookingTraineeCount, onClose }: Props) => {
  const type = bookingType === 'private' && currentBookingTraineeCount === 1 ? 'delete' : 'cancel';

  const queryClient = useQueryClient();
  const isMutating = useIsMutating() > 0;
  const navigate = useNavigate();
  const { setToast } = useToast();
  const errorDialog = useErrorDialog();
  const { mutate: cancelPrivateBookingMutate } = usePatchBookingPrivateCancel(currentMemberBooking.id);
  const { mutate: cancelGroupBookingMutate } = usePatchBookingGroupCancel(currentMemberBooking.id);
  const mutateFunc = bookingType === 'private' ? cancelPrivateBookingMutate : cancelGroupBookingMutate;

  const refresh = () => {
    if (type === 'delete') {
      navigate('/schedule', { replace: true });
    } else {
      queryClient.invalidateQueries({
        queryKey: ['lecture', 'detail', currentMemberBooking.lecture_id],
      });
    }
  };

  const clickPositiveAction = () => {
    mutateFunc(undefined, {
      onSettled: onClose,
      onSuccess: () => {
        setToast({
          type: 'success',
          message: BOOKING_DETAIL_CANCEL_DIALOG_TEXT[type].successMessage,
          bottom: type === 'delete' ? 76 : 40,
        });
        refresh();
      },
      onError: error => {
        if (error.response?.data?.code === 10207 || error.response?.data?.code === 10208) {
          const message = `${error.response?.data?.message}\n페이지를 새로고침 해주세요.`;
          errorDialog.open(message, refresh, '새로고침');
          return false;
        }
      },
    });
  };

  return (
    <Dialog
      onClose={onClose}
      positiveAction={{
        text: BOOKING_DETAIL_CANCEL_DIALOG_TEXT[type].positiveText,
        disabled: isMutating,
        onClick: clickPositiveAction,
      }}
      negativeAction={{
        text: BOOKING_DETAIL_CANCEL_DIALOG_TEXT[type].negativeText,
        disabled: isMutating,
      }}>
      <DialogDescription tag="pre">
        <Typography size={15} weight={600} span>
          [{currentMemberBooking.member.name}]
        </Typography>
        {BOOKING_DETAIL_CANCEL_DIALOG_TEXT[type].description}
      </DialogDescription>
    </Dialog>
  );
};

export default CancelBookingDialog;
