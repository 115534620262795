import { queryClient } from 'api/queryClient';
import { AxiosError } from 'axios';
import {
  BookingErrorDialogType,
  BookingFormType,
  NewBookingListType,
  NewLectureDetailType,
} from 'pages/Booking/BookingMember/types';
import { REFRESH_KEYS } from 'pages/Booking/constants';
import { formatBookingParams, formatSelectedUserTicket, getErrorMembersName } from 'pages/Booking/utils/bookings';
import { useCallback, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import usePostBooking, { BookingError, BookingParams } from './service/mutations/usePostBooking';
import { BookingCountResponse } from './service/queries/useGetBookingCount';
import useToast from './useToast';

const useBooking = (methods: UseFormReturn<BookingFormType>) => {
  const {
    setValue,
    getValues,
    reset,
    formState: { defaultValues },
  } = methods;
  const [error, setError] = useState<BookingErrorDialogType>(null);
  const [bookingCountValidation, setBookingCountValidation] = useState<BookingCountResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const { setToast } = useToast();
  const { mutate: bookingMutate } = usePostBooking();

  const resetFormAfterError = useCallback(() => {
    reset({ ...defaultValues, search: getValues('search') }, { keepDirtyValues: true });
  }, [defaultValues, getValues, reset]);

  // 에러 발생 후 페이지 새로고침 하면서 폼 리셋
  const refresh = useCallback(async () => {
    const queryKey = ['lecture', 'detail', getValues('currentLecture.id')];
    try {
      setIsLoading(true);
      setError(null);
      await queryClient.invalidateQueries({ queryKey });
      await queryClient.invalidateQueries({ queryKey: ['booking/list', getValues('currentLecture.id')] });
      const newLectureDetailData = queryClient.getQueryData<NewLectureDetailType>(queryKey);
      const newBookingListData = queryClient.getQueryData<NewBookingListType>(['booking/list', getValues('currentLecture.id')]);

      if (!newLectureDetailData || !newBookingListData) return;

      const {
        data: { data: newLectureDetail },
      } = newLectureDetailData;
      const newBookingList = newBookingListData.data;
      const newSelectedUserTickets = formatSelectedUserTicket(newBookingList);

      reset({
        ...defaultValues,
        currentLecture: newLectureDetail,
        selectedUserTickets: newSelectedUserTickets,
        currentBookingUserTickets: newSelectedUserTickets,
        search: undefined,
        overlapTickets: [],
      });
      queryClient.invalidateQueries({ queryKey: ['userTicket/lecture'] });
    } finally {
      setIsLoading(false);
    }
  }, [defaultValues, getValues, reset]);

  const bookingMember = useCallback(
    (params: BookingParams) => {
      setIsLoading(true);
      bookingMutate(params, {
        onSettled: () => setIsLoading(false),
        onSuccess: () => {
          navigate(-1);
          setToast({ type: 'success', message: '예약 인원이 변경되었습니다.' });
        },
        onError: (error: AxiosError<BookingError>) => {
          if (!error.response?.data) return;
          const errorData = error.response.data;
          if (!!errorData.code && REFRESH_KEYS.includes(errorData.code)) {
            setError({
              message: errorData?.message || '',
              isRefresh: true,
              onClick: refresh,
            });
            return false;
          }
          const memberNameError =
            errorData.booked ||
            errorData.cancelled ||
            errorData.waited ||
            errorData.waiting_cancelled ||
            errorData.not_changeable;
          if (memberNameError) {
            setError({
              message: memberNameError[0].message,
              bold: getErrorMembersName(memberNameError),
              isRefresh: true,
              onClick: refresh,
            });
            return false;
          }
          if (errorData.limit) {
            setBookingCountValidation(errorData.limit);
            return false;
          }
          if (errorData.overlap) {
            resetFormAfterError();
            setError({
              message: '해당 시간대에 다른 예약이 있습니다.',
              onClick: () => setError(null),
            });
            const overlapIds = errorData.overlap.map(({ user_ticket_id }) => user_ticket_id);
            setValue('overlapTickets', overlapIds);
            return false;
          }
          if (error.response.status === 400) {
            resetFormAfterError();
          }
        },
      });
    },
    [bookingMutate, navigate, refresh, setToast, setValue, resetFormAfterError],
  );

  const clickPassLimit = useCallback(() => {
    setBookingCountValidation(null);
    const params = {
      ...formatBookingParams(getValues()),
      is_pass_limit: true,
    };
    bookingMember(params);
  }, [bookingMember, getValues]);

  const closeBookingCountValidation = useCallback(() => {
    resetFormAfterError();
    setBookingCountValidation(null);
  }, [resetFormAfterError]);

  const closeError = useCallback(() => {
    setError(null);
  }, []);

  return {
    bookingMember,
    clickPassLimit,
    closeBookingCountValidation,
    closeError,
    bookingCountValidation,
    error,
    isPending: isLoading,
  };
};

export default useBooking;
