import styled from '@emotion/styled';
import { useQueryClient } from '@tanstack/react-query';
import useInfinityStaffNoticeCommon from 'hooks/service/queries/useInfinityStaffNoticeCommon';
import useQueryString from 'hooks/useQueryString';
import { PUSH_TEXT } from 'pages/Notification/constants';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';
import NodataTypography from 'sharedComponents/NodataTypography';
import PullToRefresh from 'sharedComponents/PullToRefresh';

import PushListItem from './PushListItem';
import { getPushFilter } from './utils';

const PushList = () => {
  const { getSearchParams } = useQueryString();
  const filter = getPushFilter(getSearchParams('filter') ?? '');

  const {
    data: pushItems,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isRefetching,
    refetch,
  } = useInfinityStaffNoticeCommon(filter);

  const queryClient = useQueryClient();
  const refreshNotifications = () => {
    // 알림쪽은 탭 알림표시 및 하단 네비바의 알림표시도 갱신되어야 함
    queryClient.invalidateQueries({ queryKey: ['staff/notice'] });
    refetch();
  };

  return (
    <PullToRefreshWithStyle isRefetching={isRefetching} onRefresh={refreshNotifications}>
      <InfiniteScroll loadMore={fetchNextPage} isLoading={isFetchingNextPage} hasMore={hasNextPage}>
        {!pushItems?.length ? <NodataTypography>{PUSH_TEXT.noData}</NodataTypography> : null}
        {pushItems.map(pushItem => (
          <PushListItem key={pushItem.id} pushItem={pushItem} />
        ))}
      </InfiniteScroll>
    </PullToRefreshWithStyle>
  );
};

export default PushList;

const PullToRefreshWithStyle = styled(PullToRefresh)`
  .spinner {
    margin-bottom: 20px; // 알림 리스트는 여백이 없어서 스피너 아래에 추가
  }
`;
