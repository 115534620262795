import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import useInfinityStaff, { StaffParams } from 'hooks/service/queries/useInfinityStaff';
import useScrollRestoration from 'hooks/useScrollRestoration';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { listHeaderTotalAtom, staffListPageAtom } from 'recoil/staff';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';
import ListContentHeader from 'sharedComponents/ListContentHeader';
import MoreDetailStaffNoList from 'sharedComponents/MoreDetailStaffNoList';
import PullToRefresh from 'sharedComponents/PullToRefresh';

import { NAME_SORT_FILTERS } from '../constants';
import { StaffFieldFormType, StaffTypes } from '../types';
import StaffCard from './StaffCard';

const StaffCardListLayout = () => {
  useScrollRestoration();

  const setListHeaderTotal = useSetRecoilState(listHeaderTotalAtom);
  const { search } = useRecoilValue(staffListPageAtom);
  const [pageData, setPageData] = useRecoilState(staffListPageAtom);

  const { getValues } = useFormContext<StaffFieldFormType>();

  const params: Omit<StaffParams, 'paginate_type'> = {
    search,
    sort_type: getValues('sort'),
    sort_target: 'name',
  };
  const { data, isRefetching, isFetchingNextPage, hasNextPage, fetchNextPage, refetch } = useInfinityStaff(params);

  useEffect(() => {
    setListHeaderTotal(data.total);
  }, [setListHeaderTotal, data]);

  const changeSort = (e: { target: { value: string } }) => {
    setPageData({ ...pageData, sort: e.target.value as 'asc' | 'desc' });
  };

  if (!data.staffs.length) return <MoreDetailStaffNoList sort={pageData.sort} />;

  return (
    <PullToRefresh onRefresh={refetch} isRefetching={isRefetching}>
      <ListContentHeader sortData={NAME_SORT_FILTERS} total={data.total} onChange={changeSort} sort={pageData.sort} />
      <Container>
        <InfiniteScroll isLoading={isFetchingNextPage} hasMore={hasNextPage} loadMore={fetchNextPage}>
          <section className="staff-list">
            {data.staffs.map((staff: StaffTypes) => {
              return <StaffCard key={staff.id} {...staff} />;
            })}
          </section>
        </InfiniteScroll>
      </Container>
    </PullToRefresh>
  );
};

export default StaffCardListLayout;

const Container = styled.section`
  padding: 0 20px 100px;
  min-height: calc(100% - 64px);

  .staff-list {
    ${theme.flex('row', '', 'space-between', 12)};
    flex-wrap: wrap;
  }
`;
