import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Form from 'components/Form';
import Loading from 'components/Loading';
import Typography from 'components/Typography';
import { LectureDetailResponse } from 'hooks/service/queries/useGetLectureDetail';
import { LectureBookingListResponse } from 'hooks/service/queries/useInfinityBookingList';
import useBooking from 'hooks/useBooking';
import useGetMinHeight from 'hooks/useGetMinHeight';
import LimitValidationDialog from 'pages/Booking/SelectMember/LimitValidationDialog';
import Search from 'pages/Booking/SelectMember/Search';
import { BookingType } from 'pages/Booking/types';
import { formatBookingParams, formatSelectedUserTicket } from 'pages/Booking/utils/bookings';
import { FormProvider, useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { isKeyboardShowAtom } from 'recoil/keyboard';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import LeaveDialog from 'sharedComponents/LeaveDialog';

import BookableList from './BookableList';
import BookingErrorDialog from './BookingErrorDialog';
import SelectedUserTicketList from './SelectedUserTicketList';
import SubmitButton from './SubmitButton';
import { BookingFormType } from './types';

type Props = {
  bookingType: BookingType;
  canCancelBooking: boolean;
  currentLecture: LectureDetailResponse;
  bookings: LectureBookingListResponse[];
};

const BookingForm = ({ bookingType, canCancelBooking, currentLecture, bookings }: Props) => {
  const isKeyboardShow = useRecoilValue(isKeyboardShowAtom);
  const fullHeight = useGetMinHeight();
  const selectedUserTickets = formatSelectedUserTicket(bookings);

  const methods = useForm<BookingFormType>({
    defaultValues: {
      canCancelBooking,
      currentLecture,
      selectedUserTickets,
      currentBookingUserTickets: selectedUserTickets,
      overlapTickets: [],
    },
  });

  const { handleSubmit } = methods;
  const { isPending, bookingCountValidation, bookingMember, error, closeBookingCountValidation, clickPassLimit, closeError } =
    useBooking(methods);

  const submit = (values: BookingFormType) => {
    const params = formatBookingParams(values);
    bookingMember(params);
  };

  return (
    <FormProvider {...methods}>
      <Container>
        <Search />
        <StyledBookingForm onSubmit={handleSubmit(submit)} isKeyboardShow={isKeyboardShow} minHeight={fullHeight}>
          <SelectedUserTicketList />
          <StyledBookingListTitleTypography size={13} weight={500} textColor="gray2">
            회원목록
          </StyledBookingListTitleTypography>
          <ApiBoundary>
            <BookableList />
          </ApiBoundary>
          <SubmitButton bookingType={bookingType} />
        </StyledBookingForm>
      </Container>
      <LeaveDialog />

      {bookingCountValidation && (
        <LimitValidationDialog
          isPending={isPending}
          bookingCountValidation={bookingCountValidation}
          onClickBooking={clickPassLimit}
          onClose={closeBookingCountValidation}
        />
      )}
      {error && <BookingErrorDialog onClose={closeError} error={error} />}

      {isPending && (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      )}
    </FormProvider>
  );
};

export default BookingForm;

const LoadingWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Container = styled.div`
  ${theme.flex('column', '', '')};
  height: 100%;
`;

export const StyledBookingForm = styled(Form)<{ isKeyboardShow: boolean; minHeight: number }>`
  flex: 1;
  overflow-y: auto;
  padding-bottom: ${({ isKeyboardShow }) => (isKeyboardShow ? '0px' : '76px')};
  ${theme.flex('column', '', '')};

  hr {
    flex: 0 0 auto;
  }

  .infinite-scroll {
    // 전체 - 스크롤 부분 뺀 나머지 영역 (헤더, 푸터 버튼 등)
    ${({ minHeight }) => minHeight && `min-height: ${minHeight - 56 - 56 - 254 - 31 - 76}px;`}
  }
`;

export const StyledBookingListTitleTypography = styled(Typography)`
  width: 100%;
  flex: 0 0 auto;
  padding: 11px 20px 4px;
`;
