import { queryClient } from 'api/queryClient';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import useGetPermissionDoHavePermission from 'hooks/service/queries/useGetPermissionDoHavePermission';
import usePermission from 'hooks/usePermission';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { MEMBER_PERMISSION } from '../constants';
import { CurrentMemberIdProps, MemberDropDownValueType } from '../types';
import DeleteMemberDialog from './DeleteMemberDialog';
import MemberDropDown from './MemberDropDown';

const MoreDetail = ({ currentMemberId }: CurrentMemberIdProps) => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const navigate = useNavigate();
  const { checkPermission } = useGetPermissionDoHavePermission();
  const { hasPermission } = usePermission();
  const canUpdateMember = hasPermission(MEMBER_PERMISSION.memberEdit);
  const canDeleteMember = hasPermission(MEMBER_PERMISSION.memberDelete);

  const clickDropDownContents = (value: MemberDropDownValueType['value']) => {
    switch (value) {
      case 'update':
        checkPermission(MEMBER_PERMISSION.memberEdit.id, {
          onSuccess: () => {
            queryClient.removeQueries({ queryKey: ['staff/me'] });
            navigate(`/member/form/update/${currentMemberId}`);
          },
        });
        break;
      case 'delete':
        checkPermission(MEMBER_PERMISSION.memberDelete.id, { onSuccess: () => setIsOpenDialog(true) });
        break;
    }
    setIsOpenDropDown(false);
  };

  if (!canUpdateMember && !canDeleteMember) {
    return null;
  }

  return (
    <>
      <MemberDropDown
        canUpdateMember={canUpdateMember}
        canDeleteMember={canDeleteMember}
        isOpen={isOpenDropDown}
        setIsOpen={setIsOpenDropDown}
        onClick={clickDropDownContents}>
        <IconButton onClick={() => setIsOpenDropDown(!isOpenDropDown)}>
          <Icon name="headerMore" />
        </IconButton>
      </MemberDropDown>

      {isOpenDialog && <DeleteMemberDialog currentMemberId={currentMemberId} onClose={() => setIsOpenDialog(false)} />}
    </>
  );
};

export default MoreDetail;
