import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import useInfinityScheduleManagementDeletedLecture, {
  ScheduleManagementDeletedLecture,
  ScheduleManagementDeletedLectureParams,
} from 'hooks/service/queries/useInfinityScheduleManagementDeletedLecture';
import useScrollRestoration from 'hooks/useScrollRestoration';
import useSearchKeywords from 'hooks/useSearchKeywords';
import { useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { staffIdAtom } from 'recoil/common';
import {
  deletedLectureParamsAtom,
  isEveryPermissionMoreDetailScheduleAtom,
  staffListForScheduleFilter,
} from 'recoil/moreDetail/atoms';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';
import PullToRefresh from 'sharedComponents/PullToRefresh';
import filters from 'utils/filters';
import getImageUrl from 'utils/getImageUrl';

import { SCHEDULE_NODATA_TEXT } from '../../constant';
import { ScheduleStaff } from '../../type';
import { getScheduleGroupByStartOn, getStaffIds } from '../../util';
import NoData from '../NoData';
import ScheduleCard from '../ScheduleCard';
import ScheduleSorting from '../ScheduleSorting';
import ScheduleTotal from '../ScheduleTotal';

type Props = {
  currentTab: 'booking' | 'checkin' | 'deletedLecture';
};

const DeletedLectureScheduleList = ({ currentTab }: Props) => {
  useScrollRestoration();
  const currentStaffId = useRecoilValue(staffIdAtom);
  const staffs = useRecoilValue(staffListForScheduleFilter);
  const isEveryPermission = useRecoilValue(isEveryPermissionMoreDetailScheduleAtom);
  const [scheduleDeletedLecture, setScheduleDeletedLecture] = useRecoilState(deletedLectureParamsAtom);
  const { searchKeyword } = useSearchKeywords('schedule');

  const scheduleDeletedLectureParams: ScheduleManagementDeletedLectureParams = {
    staff_ids: isEveryPermission ? getStaffIds(scheduleDeletedLecture.staffs || []) : [currentStaffId],
    start_date: scheduleDeletedLecture.period?.start || filters.dateDash(),
    end_date: scheduleDeletedLecture.period?.end || filters.dateDash(),
    type: scheduleDeletedLecture.type,
    paginate_type: 'detail',
    order_by: scheduleDeletedLecture.sort ?? 'asc',
    search: searchKeyword,
  };

  const {
    data: { deletedLectures, total, groupBookingCount, privateBookingCount },
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isRefetching,
    refetch,
  } = useInfinityScheduleManagementDeletedLecture(scheduleDeletedLectureParams);

  const filteredSchedules = useMemo(() => {
    return getScheduleGroupByStartOn(deletedLectures) as ScheduleManagementDeletedLecture[][];
  }, [deletedLectures]);

  useEffect(() => {
    if (!isEveryPermission) {
      const currentStaff = staffs.filter(staff => staff.id === currentStaffId)[0];
      const staffInfo = {
        id: currentStaff?.id,
        imgUrl: getImageUrl(currentStaff?.avatar),
        name: currentStaff?.name,
      } as ScheduleStaff;

      setScheduleDeletedLecture(prev => ({ ...prev, staffs: [staffInfo] }));
    }
  }, [currentStaffId, isEveryPermission, setScheduleDeletedLecture, staffs]);

  if (total === 0 || filteredSchedules.length === 0) {
    return (
      <>
        <FilterSection>
          <ScheduleTotal total={0} groupTotal={groupBookingCount} privateTotal={privateBookingCount} />
          <Divider thin />
          <ScheduleSorting currentTab={currentTab} />
        </FilterSection>
        <NoData noDataText={SCHEDULE_NODATA_TEXT.deletedLecture} currentKeyword={searchKeyword} />
      </>
    );
  }

  return (
    <Container>
      <FilterSection>
        <ScheduleTotal total={total} groupTotal={groupBookingCount} privateTotal={privateBookingCount} />
        <Divider thin />
        <ScheduleSorting currentTab={currentTab} />
      </FilterSection>
      <PullToRefresh isRefetching={isRefetching} onRefresh={refetch}>
        <ContentSection>
          <InfiniteScroll loadMore={fetchNextPage} hasMore={hasNextPage} isLoading={isFetchingNextPage}>
            <ScheduleCard schedules={filteredSchedules} currentTab={currentTab} />
          </InfiniteScroll>
        </ContentSection>
      </PullToRefresh>
    </Container>
  );
};

export default DeletedLectureScheduleList;

const Container = styled.div`
  > .typography {
    padding-bottom: 20px;
  }
`;

const FilterSection = styled.section`
  background-color: ${theme.color.white};
`;

const ContentSection = styled.section`
  padding: 24px 20px;
  background-color: ${theme.color.gray6};
`;
