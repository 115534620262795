import styled from '@emotion/styled';
import LinkButton from 'components/Button/LinkButton';
import Typography from 'components/Typography';
import Drawer from 'designedComponents/Drawers';
import { TicketsParams } from 'hooks/service/queries/useInfinityTickets';
import usePermission from 'hooks/usePermission';
import useQueryString from 'hooks/useQueryString';
import { MEMBER_PERMISSION } from 'pages/MemberDetail/constants';
import { MemberFormType } from 'pages/MemberForm/types';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useResetRecoilState } from 'recoil';
import { customBackHandlersAtom } from 'recoil/common';
import FormFieldLinkButton from 'sharedComponents/FormField/FormFieldLinkButton';

import TicketsList from './TicketsList';

const Tickets = () => {
  const { hasPermission } = usePermission();
  const canCreateUserTicket = hasPermission(MEMBER_PERMISSION.memberTicketCreate);
  const resetCustomBackHandler = useResetRecoilState(customBackHandlersAtom);

  const [isOpen, setIsOpen] = useState(false);
  const { setSearchParams } = useQueryString();

  const { control } = useFormContext<MemberFormType>();
  const tickets = useWatch({ control, name: 'tickets' });
  const userTickets = useWatch({ control, name: 'userTickets' });

  const hasAnyTickets = tickets.length || userTickets.length;

  const goToProductForm = (productType: TicketsParams['product_type']) => {
    setIsOpen(false);
    resetCustomBackHandler();
    setSearchParams({
      type: 'product',
      productMode: 'create',
      productType: `${productType}`,
      step: '1',
    });
  };

  return (
    <>
      <StyledFormLinkButton
        disabled={!canCreateUserTicket}
        showArrow={canCreateUserTicket}
        iconName="ticket"
        hasValue={canCreateUserTicket && !!hasAnyTickets}
        onClick={() => setIsOpen(true)}>
        {`수강권/상품 ${canCreateUserTicket && hasAnyTickets ? '추가' : ''}`}
      </StyledFormLinkButton>

      <TicketsList />

      <Drawer isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <StyledButton showBorder={false} showArrow={false} onClick={() => goToProductForm('userTicket')}>
          <Typography size={17} weight={600}>
            수강권
          </Typography>
          <Typography textColor="gray2">프라이빗 수업/그룹 수업</Typography>
        </StyledButton>
        <StyledButton showBorder={false} showArrow={false} onClick={() => goToProductForm('item')}>
          <Typography size={17} weight={600}>
            상품
          </Typography>
          <Typography textColor="gray2">운동복, 락커 등 대여 또는 판매상품</Typography>
        </StyledButton>
      </Drawer>
    </>
  );
};

export default Tickets;

const StyledFormLinkButton = styled(FormFieldLinkButton)`
  border-bottom: none;
`;

const StyledButton = styled(LinkButton)`
  padding: 0;
  &:last-of-type {
    margin-top: 8px;
  }
`;
