import styled from '@emotion/styled';
import Divider from 'components/Divider';

import Card from './Card';
import Cash from './Cash';
import Discount from './Discount';
import Installment from './Installment';
import PaymentFormTitle from './PaymentFormTitle';
import Point from './Point';
import PointAccumulation from './PointAccumulation';
import RefundPenalty from './RefundPenalty';
import SalePrice from './SalePrice';
import SettlementDate from './SettlementDate';
import Unpaid from './Unpaid';
import WireTransfer from './WireTransfer';

type Props = {
  isRefund?: boolean;
};

const TicketPaymentForm = ({ isRefund }: Props) => {
  return (
    <Container>
      <PaymentFormTitle className="payment-title">결제정보</PaymentFormTitle>
      <SalePrice />
      <PointAccumulation />
      {!isRefund && <Discount />}
      <Divider />
      <PaymentFormTitle className="method-title">결제수단</PaymentFormTitle>
      <Card />
      <Installment />
      <Cash />
      <WireTransfer />
      <Point />
      <Divider />
      {isRefund ? <RefundPenalty /> : <Unpaid />}
      <SettlementDate />
    </Container>
  );
};

export default TicketPaymentForm;

const Container = styled.div`
  height: 100%;
`;
