import styled from '@emotion/styled';
import Button from 'components/Button';
import Checkmark from 'components/Checkmark';
import Divider from 'components/Divider';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import useQueryString from 'hooks/useQueryString';
import DetailResultItem from 'pages/Booking/components/AfterRequestBooking/DetailResultItem';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { customBackHandlersAtom } from 'recoil/common';
import FooterButton from 'sharedComponents/FooterButton';
import MainLayout from 'sharedComponents/MainLayout';
import filters from 'utils/filters';

import { BookingPrivateResultStateType } from '../types';
import BookedMembersDrawer from './BookedMembersDrawer';
import BookingFail from './BookingFail';

const BookingPrivateComplete = () => {
  const {
    state: { data },
  } = useLocation() as BookingPrivateResultStateType;
  const navigate = useNavigate();
  const { getSearchParams } = useQueryString();
  const from = getSearchParams('from') as string;

  const totalRequestLectureCount = data.success.lecture_count + data.fail.length;
  const failCount = data.fail.length;
  const hasFail = !!failCount;
  const isOnlyOneMember = data.success.members.length === 1;
  const [isOpen, setIsOpen] = useState(false);
  const [showAllList, setShowAllList] = useState(!hasFail || data.success.lectures.length < 3);

  const showAllButton = useMemo(() => {
    if (!hasFail || showAllList) {
      return false;
    } else if (data.success.lectures.length > 3) {
      return true;
    }
  }, [data.success.lectures.length, hasFail, showAllList]);

  const goToMemberDetail = (id: number) => {
    navigate(`/member/detail/${id}?tab=history`);
  };

  const goBack = useCallback(() => {
    const step = from === 'updateAll' ? -1 : from === 'copy' ? -3 : -2;
    navigate(step);
  }, [from, navigate]);

  const clickCheckBookingList = () => {
    if (isOnlyOneMember) {
      goToMemberDetail(data.success.members[0].id);
    } else {
      setIsOpen(true);
    }
  };

  const setCustomBackHandlers = useSetRecoilState(customBackHandlersAtom);

  useEffect(() => {
    setCustomBackHandlers(prev => [...prev, { key: 'private-create-complete', handler: goBack }]);
    return () => {
      setCustomBackHandlers([]);
    };
  }, [goBack, setCustomBackHandlers]);

  return (
    <>
      <MainLayout>
        <Container>
          <Checkmark />
          <div className="title-wrapper">
            <Typography size={21} weight={700}>
              {totalRequestLectureCount}개 수업 중,
            </Typography>
            <Typography size={21} weight={700} textColor="primary">
              {data.success.booking_count}건 예약완료
            </Typography>
            <Button variant="contained" color="gray" size="small" widthSize={87} onClick={clickCheckBookingList}>
              예약내역 보기
            </Button>
          </div>

          <BookingWrapper>
            {data.success.lectures.map((lecture, index) => {
              const show = index < 3;
              const { staff, start_on, end_on, title, room } = lecture;
              const date = `${filters.dateTimeWithWeekday(start_on)} ~ ${filters.time(end_on)}`;

              return (
                <BookingListItem
                  key={index}
                  date={date}
                  title={title}
                  staffName={staff}
                  roomName={room}
                  show={showAllList || show}
                />
              );
            })}
          </BookingWrapper>

          {showAllButton && (
            <Button
              className="show-all-button"
              fullWidth
              size="medium40"
              gap={2}
              onClick={() => setShowAllList(true)}
              textColor="gray2"
              rightIcon={<Icon name="arrowBottomBold" size={16} color="gray3" />}>
              전체보기
            </Button>
          )}

          {hasFail && (
            <>
              <Divider />
              <BookingFail fails={data.fail} />
            </>
          )}
        </Container>

        <FooterButton onClick={goBack}>확인</FooterButton>
      </MainLayout>
      <BookedMembersDrawer
        members={data.success.members}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        goToMemberDetail={goToMemberDetail}
      />
    </>
  );
};

export default BookingPrivateComplete;

const Container = styled.div`
  min-height: 100%;
  padding-top: 56px;
  padding-bottom: 86px;

  .lf-player-container {
    margin-bottom: 4px;

    #lottie {
      margin: 0px auto 0px 20px !important;
      width: 40px !important;
      height: 40px !important;
    }
  }

  .title-wrapper {
    width: 100%;
    padding: 0 20px;
    position: relative;

    button {
      position: absolute;
      top: 0;
      right: 20px;
    }
  }

  .show-all-button {
    align-items: flex-start;
  }
`;

const BookingWrapper = styled.ul`
  padding: 24px 20px 20px;
`;

const BookingListItem = styled(DetailResultItem)<{ show?: boolean }>`
  display: ${({ show }) => (show ? 'block' : 'none')};
`;
