import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';

import { CommonPropsType } from '../types';

type Props = CommonPropsType;

const PrivateTitle = ({ isCardBackground, currentContrastColor, startTime, title, sameTimeEvents, isDayView }: Props) => {
  return (
    <TitleWrapper>
      <Typography
        className="title"
        size={isDayView ? 14 : 12}
        weight={600}
        textColor={isCardBackground ? currentContrastColor : 'gray1'}>
        {title}
      </Typography>

      {isDayView && sameTimeEvents < 4 && (
        <Typography
          size={13}
          weight={500}
          textColor={isCardBackground ? currentContrastColor : 'gray3'}
          opacity={isCardBackground ? 0.88 : 1}>
          {startTime}
        </Typography>
      )}
    </TitleWrapper>
  );
};

export default PrivateTitle;

const TitleWrapper = styled.div`
  ${theme.flex('row', 'center', 'space-between', 2)};
  flex-wrap: wrap;
  width: 100%;
`;
