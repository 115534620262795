import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import { PERMISSION } from 'constants/permission';
import useGetBoardQnaDetail from 'hooks/service/queries/useGetBoardQnaDetail';
import usePermission from 'hooks/usePermission';
import { useErrorBoundary } from 'react-error-boundary';
import { Navigate } from 'react-router-dom';
import filters from 'utils/filters';
import getImageUrl from 'utils/getImageUrl';

import { QNA_DETAIL_TEXT } from '../constants';
import QnaComment from './QnaComment';
import QnaCommentForm from './QnaCommentForm';

type Props = {
  id: number;
  permissionDenied?: boolean;
};

const QnaDetail = ({ id, permissionDenied }: Props) => {
  const { hasPermission } = usePermission();
  const canViewQna = hasPermission(PERMISSION.board.qna.view);
  const canComment = hasPermission(PERMISSION.board.qna.comment);
  const canDeleteOthers = hasPermission(PERMISSION.board.qna.deleteOthers);

  // 403에러로 진입했지만 조회권한도 없으면 api 호출하지 않음
  const canCallApi = !permissionDenied || canViewQna;

  const { data: qna, isError, error } = useGetBoardQnaDetail(id, canCallApi);
  const { showBoundary } = useErrorBoundary();

  if (!canCallApi) {
    return <Navigate to="/more-details" replace />;
  }
  if (isError) {
    showBoundary(error);
  }

  if (!qna) return null;

  return (
    <Container>
      <QuestionHeader>
        <Typography size={17} weight={700} className="member">
          {qna.member.name}
        </Typography>
        <Typography size={13} weight={500} textColor="gray3" className="date">
          {filters.dateTimeWithWeekday(qna?.updated_at)}
        </Typography>
      </QuestionHeader>
      <Divider thin />
      <Title size={15} weight={600}>
        {qna.title}
      </Title>
      <Contents size={15} textColor="gray2" tag="pre">
        {qna.contents}
      </Contents>
      <AttachmentWrapper>
        {qna.attachments?.map(attachment => (
          <Image key={attachment.id} src={getImageUrl(attachment.path, '0x0')} alt={qna?.title} />
        ))}
      </AttachmentWrapper>
      <Divider />
      <Comments>
        <Typography textColor="gray3" span weight={500}>
          {QNA_DETAIL_TEXT.comments}
        </Typography>
        <Typography textColor="primary" weight={600} span>
          {qna.comments?.length || 0}
        </Typography>
        {qna.comments?.map(comment => (
          <QnaComment key={comment.id} comment={comment} canComment={canComment} canDeleteOthers={canDeleteOthers} />
        ))}
      </Comments>
      <QnaCommentForm questionId={id} canComment={canComment} />
    </Container>
  );
};

export default QnaDetail;

const Container = styled.div`
  height: 100%;
`;

const QuestionHeader = styled.div`
  padding: 16px 20px;
  ${theme.flex('row', 'center', 'space-between', 6)}
  flex-wrap: wrap;
  .member::after {
    content: '회원';
    color: ${theme.color.gray3};
    font-size: 1.4rem;
    font-weight: 500;
    margin: auto 6px;
    display: inline-block;
  }
`;

const Title = styled(Typography)`
  padding: 20px 20px 8px;
`;

const Contents = styled(Typography)`
  padding: 0 20px 40px;
`;

const Comments = styled.div`
  padding: 24px 20px 124px;
  white-space: pre-wrap;
  > span:first-of-type {
    margin-right: 2px;
  }
`;

const AttachmentWrapper = styled.div`
  padding: 0 20px 40px;
  ${theme.flex('column', '', '', 12)}
`;

const Image = styled.img`
  width: 100%;
  object-fit: cover;
  margin: 0;
`;
