import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Form from 'components/Form';
import Icon from 'components/Icon';
import UnderlinedTextareaController from 'components/Textarea/UnderlinedTextareaController';
import UnderlinedTextFieldController from 'components/TextField/UnderlinedTextFieldController';
import useGetStaffMe from 'hooks/service/queries/useGetStaffMe';
import useErrorDialog from 'hooks/useErrorDialog';
import useGetMinHeight from 'hooks/useGetMinHeight';
import useToast from 'hooks/useToast';
import { useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { isKeyboardShowAtom } from 'recoil/keyboard';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import { ValidationError } from 'yup';

import { CounselCreateUpdateFormProps, CounselingFormType } from '../type';
import Channel from './Channel';
import CounselRangetime from './CounselRangetime';
import CounselSelectDate from './CounselSelectDate';
import Funnel from './Funnel';
import IsRegistered from './IsRegistered';
import Staffs from './Staffs';

const CounselCreateUpdateForm = ({ typeText, onSubmit }: CounselCreateUpdateFormProps) => {
  const fullHeight = useGetMinHeight();
  const isKeyboardShow = useRecoilValue(isKeyboardShowAtom);
  const { data: staff } = useGetStaffMe();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isSubmitting, isDirty },
  } = useFormContext<CounselingFormType>();
  const errorDialog = useErrorDialog();
  const { setToast } = useToast();
  const isName = useWatch({ control, name: 'name' });
  const isPhone = useWatch({ control, name: 'phone' });
  const isContents = useWatch({ control, name: 'contents' });

  const clearTextField = useCallback(
    (name: keyof CounselingFormType) => setValue(name, '', { shouldValidate: true, shouldDirty: true }),
    [setValue],
  );

  return (
    <StyleForm
      onSubmit={handleSubmit(onSubmit, error => {
        if (error.name) {
          const validationError = error.name as ValidationError;
          if (validationError.type === 'max') {
            setToast({ type: 'error', message: error.name.message ?? '', bottom: 76 });
          } else {
            errorDialog.open(error.name.message);
          }
          return;
        }

        if (error.phone) {
          errorDialog.open(error.phone.message);
          return;
        }
      })}
      footerButton={{ text: `${typeText} 완료`, disabled: !isContents || isSubmitting || !isDirty }}
      isKeyboardShow={isKeyboardShow}
      minHeight={fullHeight}>
      <CounselSelectDate />
      <CounselRangetime />
      <UnderlinedTextFieldController
        controllerProps={{ control, name: 'name' }}
        placeholder="고객명"
        left={{ prefix: <Icon name="privateClass" fillColor={theme.color.gray2} /> }}
        onClear={() => clearTextField('name')}
        textColor={isName ? 'gray1' : 'gray3'}
        suffixMarginRight={20}
      />
      <UnderlinedTextFieldController
        controllerProps={{ control, name: 'phone' }}
        placeholder="휴대폰 번호"
        inputMode="numeric"
        left={{ prefix: <Icon name="phone" fillColor={theme.color.gray2} /> }}
        onClear={() => clearTextField('phone')}
        textColor={isPhone ? 'gray1' : 'gray3'}
        suffixMarginRight={20}
      />
      <IsRegistered />
      <Channel />
      <Funnel />
      <ApiBoundary>
        <Staffs staff={staff} />
      </ApiBoundary>
      <CounselContents isKeyboardShow={isKeyboardShow}>
        <UnderlinedTextareaController
          controllerProps={{ control, name: 'contents' }}
          placeholder="상담내용"
          left={<Icon name="explain" color="gray2" />}
        />
      </CounselContents>
    </StyleForm>
  );
};

export default CounselCreateUpdateForm;

const StyleForm = styled(Form)<{ isKeyboardShow: boolean; minHeight: number }>`
  ${theme.flex('column', '', ',')}
  ${({ minHeight }) => minHeight && `min-height: ${minHeight}px;`} 
  padding-bottom: ${({ isKeyboardShow }) => (isKeyboardShow ? '0px' : '82px')};

  footer {
    margin-top: auto;
    ${({ isKeyboardShow }) => isKeyboardShow && 'padding: 10px 20px;'};
  }
`;

const CounselContents = styled.div<{ isKeyboardShow: boolean }>`
  .underline-textarea {
    border-bottom: none;
  }
`;
