import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Avatar from 'components/Avatar';
import Typography from 'components/Typography';
import { useRecoilValue } from 'recoil';
import { staffIdAtom } from 'recoil/common';
import filters from 'utils/filters';
import getImageUrl from 'utils/getImageUrl';

import { QNA_DETAIL_TEXT } from '../constants';
import { QnaCommentType } from '../types';
import CommentMenu from './CommentMenu';

type Props = {
  comment: QnaCommentType;
  canComment: boolean;
  canDeleteOthers: boolean;
};

const QnaComment = ({ comment, canComment, canDeleteOthers }: Props) => {
  const commenter = comment.member || comment.staff;
  const commenterRole = comment.staff ? comment.staff?.roles?.[0]?.display_name : QNA_DETAIL_TEXT.member;

  const staffId = useRecoilValue(staffIdAtom);

  const isStaff = comment.staff !== null;
  const isMine = isStaff && comment.user_id === staffId;

  return (
    <Container isStaff={isStaff}>
      <CommentHeader>
        <Avatar size={24} imgUrl={commenter?.avatar ? getImageUrl(commenter.avatar.image) : undefined} />
        <Typography size={15} weight={600} span className="name" ellipsis={1}>
          {commenter?.name || ''}
        </Typography>
        <Typography size={13} weight={600} span textColor="gray3">
          {commenterRole}
        </Typography>
      </CommentHeader>
      <Typography size={15} className="contents" tag="pre">
        {comment.contents}
      </Typography>
      <Typography size={13} textColor="gray3">
        {filters.dateTimeWithWeekday(comment.updated_at)}
      </Typography>

      {!comment.deleted_at && (
        <CommentHeaderMenu
          comment={comment}
          canEditMine={isStaff && isMine && canComment}
          canDeleteOthers={isStaff && !isMine && canDeleteOthers}
          isMine={isMine}
        />
      )}
    </Container>
  );
};

export default QnaComment;

const staffColor = 'rgba(108, 133, 251, 0.1)'; // primary 색상 + 10% opacity

const Container = styled.div<{ isStaff: boolean }>`
  width: 100%;
  background-color: ${({ isStaff }) => (isStaff ? staffColor : theme.color.gray7)};
  border-radius: 20px 20px ${({ isStaff }) => (isStaff ? '0 20px' : '20px 0')};
  margin: 16px 0 18px;
  padding: 20px;
  .contents {
    margin: 10px 0 12px;
  }

  position: relative;
  :before {
    content: ' ';
    width: 100%;
    height: 40px;
    background-color: ${({ isStaff }) => (isStaff ? staffColor : theme.color.gray7)};
    position: absolute;
    bottom: -40px;
    left: 0;
  }
  :after {
    content: '';
    width: 100%;
    height: 40px;
    background: white;
    position: absolute;
    bottom: -40px;
    left: 0;
    border-radius: ${({ isStaff }) => (isStaff ? '0 20px' : '20px 0')} 0 0;
  }
`;

const CommentHeader = styled.div`
  ${theme.flex('', 'center', 'start', 4)}

  .name {
    max-width: 144px;
    display: inline-block;
    white-space: nowrap;
  }
  .avatar {
    margin-right: 6px;
  }
`;

const CommentHeaderMenu = styled(CommentMenu)`
  position: absolute;
  top: 16px;
  right: 18px;

  .dropdown-target {
    display: block;
  }
  .dropdown-contents-container {
    margin-top: 4px;
  }
`;
