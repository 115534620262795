import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

/**
 * 프라이빗 수업의 예약 취소
 */
const usePatchBookingPrivateCancel = (bookingId: number) => {
  const { mutationFn } = useMutationFnWithCommonParams({
    method: 'patch',
    url: `/api/booking/private/cancel/${bookingId}`,
  });

  return useCustomMutation({
    mutationFn,
  });
};

export default usePatchBookingPrivateCancel;
