import Icon from 'components/Icon';
import UnderlinedTextFieldController from 'components/TextField/UnderlinedTextFieldController';
import Typography from 'components/Typography';
import { useFormContext, useWatch } from 'react-hook-form';
import filters from 'utils/filters';

import { TICKET_PAYMENT_FORM_TEXT } from '../constants';
import { TicketPaymentFormType } from '../types';
import { AMOUNT_LIMIT, isNotValidAmount } from './PaymentTextField';
import { PaymentRow } from './StyledComponents';

/** 환불 시, 티켓 발급할 때 적립한 포인트 회수 (포인트 사용 개념)  */
const PointWithdraw = () => {
  const { control, setValue } = useFormContext<TicketPaymentFormType>();
  const pointAmount = useWatch({ control, name: 'payment.point_amount' });

  return (
    <PaymentRow>
      <UnderlinedTextFieldController
        controllerProps={{ control, name: 'payment.point_amount' }}
        inputMode="numeric"
        placeholder="0"
        textAlign="right"
        left={{ prefix: <Icon name="point" color="gray2" />, text: TICKET_PAYMENT_FORM_TEXT.point.withdrawLabel }}
        suffix={
          <Typography span size={15} weight={500}>
            P
          </Typography>
        }
        value={filters.numberComma(pointAmount)}
        onChange={e => {
          const pointAmount = filters.numberComma(e.target.value, { reverse: true });
          const isNotValid = isNotValidAmount(pointAmount);
          const value = Number(pointAmount) > AMOUNT_LIMIT ? AMOUNT_LIMIT : pointAmount;
          setValue('payment.point_amount', isNotValid ? 0 : value, {
            shouldValidate: true,
            shouldDirty: true,
          });
        }}
      />
    </PaymentRow>
  );
};

export default PointWithdraw;
