import Avatar from 'components/Avatar';
import CheckBox from 'components/CheckBox';
import Typography from 'components/Typography';

import { ScheduleStaff } from '../type';

type Props = {
  staff: ScheduleStaff;
  isSelected: boolean;
  isCurrentUser: boolean;
  onToggle: (staff: ScheduleStaff) => void;
};

const ScheduleStaffItem = ({ staff, isSelected, isCurrentUser, onToggle }: Props) => {
  return (
    <li>
      <CheckBox
        id={`${staff.id}`}
        label={
          <div className="staff-label-wrapper">
            <Avatar size={24} imgUrl={staff.imgUrl} />
            <div className="staff-label">
              <Typography size={17} weight={500}>
                {staff.name}
              </Typography>
              {isCurrentUser && (
                <Typography textColor="gray3" weight={600}>
                  나
                </Typography>
              )}
            </div>
          </div>
        }
        gap={16}
        checked={isSelected}
        onChange={() => onToggle(staff)}
      />
    </li>
  );
};

export default ScheduleStaffItem;
