import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import { UnpaidDataResponse } from 'hooks/service/queries/useInfinityReportUnpaid';
import { useState } from 'react';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import filters from 'utils/filters';
import getEllipsisByLength from 'utils/getEllipsisByLength';

import { REPORT_PRODUCT_TYPE_LABEL } from '../../constants';
import ReportUnpaidDetailInfo from './ReportUnpaidDetailInfo';
import ReportUnpaidDetailInfoSkelton from './ReportUnpaidDetailInfoSkelton';

type Props = {
  unpaids: UnpaidDataResponse[];
};

const UnpaidCard = ({ unpaids }: Props) => {
  const [isShowDrawer, setIsShowDrawer] = useState(false);
  const [detailData, setDetailData] = useState<UnpaidDataResponse | null>(null);

  const getPaymentMethodLabel = (payments: UnpaidDataResponse['payments']): string => {
    const methodSet = payments.reduce((acc, payment) => {
      if (payment['card_amount'] > 0) acc.add('카드');
      if (payment['point_amount'] > 0) acc.add('포인트');
      if (payment['cash_amount'] > 0) acc.add('현금');
      if (payment['wiretransfer_amount'] > 0) acc.add('계좌이체');

      return acc;
    }, new Set<string>());

    const method = Array.from(methodSet);

    return `${method[0] ?? ''} ${method.length - 1 > 0 ? `외 ${method.length - 1}건` : ''}`;
  };

  const showDetailInfo = (unpadidDetailData: UnpaidDataResponse) => {
    setIsShowDrawer(true);
    setDetailData(unpadidDetailData);
  };

  const closeDetailInfo = () => {
    setIsShowDrawer(false);
  };

  return (
    <>
      {unpaids.map(unpaid => {
        const productTypeLabel = REPORT_PRODUCT_TYPE_LABEL[unpaid.ticket.product_type as keyof typeof REPORT_PRODUCT_TYPE_LABEL];
        const paymentMethod = getPaymentMethodLabel(unpaid.payments);

        const memberName = getEllipsisByLength({ text: unpaid.member.name, max: 5, hasEllipsis: true });
        return (
          <Container key={`${unpaid.id}-${filters.dateMonthDayKor(unpaid.created_at)}`}>
            <CardWrapper onClick={() => showDetailInfo(unpaid)}>
              <div className="ticket-info-wrapper">
                <Typography ellipsis={1} weight={600} textColor="gray1">
                  {`${productTypeLabel} (${unpaid.ticket.title})`}
                </Typography>
                <div className="member-info-wrapper">
                  <div>
                    <Typography span ellipsis={1} weight={500} textColor="gray2">
                      {memberName}
                    </Typography>
                    <Typography weight={500} textColor="gray2">
                      회원
                    </Typography>
                  </div>
                  <Typography span size={14} weight={500} textColor="gray3">
                    {paymentMethod}
                  </Typography>
                </div>
              </div>
              <Divider thin thinColor="gray6" />
              <div className="paid-info-wrapper">
                <div>
                  <Typography span textColor="gray2">
                    잔여 미수금
                  </Typography>
                  <Typography span weight={600} textColor="secondary3">
                    {`${filters.numberComma(unpaid.unpaid_info.unpaid_amount)}원`}
                  </Typography>
                </div>
                <div>
                  <Typography span textColor="gray2">
                    총 결제 금액
                  </Typography>
                  <Typography span textColor="gray2">{`${filters.numberComma(unpaid.unpaid_info.paid_amount)}원`}</Typography>
                </div>
                <div>
                  <Typography span textColor="gray2">
                    결제일
                  </Typography>
                  <Typography span textColor="gray2">
                    {filters.dateWithWeekday(unpaid.settlement_at)}
                  </Typography>
                </div>
              </div>
            </CardWrapper>
          </Container>
        );
      })}
      {isShowDrawer && detailData && (
        <ApiBoundary fallback={<ReportUnpaidDetailInfoSkelton />}>
          <ReportUnpaidDetailInfo isOpen={isShowDrawer} detailData={detailData} onClose={closeDetailInfo} />
        </ApiBoundary>
      )}
    </>
  );
};

export default UnpaidCard;

const Container = styled.div`
  .date-text {
    padding-bottom: 20px;
  }

  :not(:first-of-type) {
    .date-text {
      padding-top: 28px;
    }
  }
`;

const CardWrapper = styled.div`
  padding: 18px;
  margin-bottom: 12px;
  border-radius: 16px;
  background: ${theme.color.white};
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08);

  .ticket-info-wrapper {
    padding-bottom: 12px;

    > p {
      padding-bottom: 2px;
    }

    .member-info-wrapper {
      ${theme.flex('row', 'center', 'space-between', 8)}

      div {
        ${theme.flex('row', 'center', '', 2)}
      }
    }
  }

  .paid-info-wrapper {
    padding-top: 12px;

    div {
      ${theme.flex('', 'center', 'space-between', 5)}
    }

    div:not(:last-of-type) {
      padding-bottom: 8px;
    }
  }
`;
