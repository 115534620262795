import { theme } from 'assets/styles';
import { UserTicketResponse } from 'hooks/service/queries/useInfinityUserTicketProduct';
import { UserTicketCardType } from 'sharedComponents/UserTicketCard/types';

import filters from './filters';
import formatCommonTicketInfo from './formatCommonTicketInfo';
import getRemainingDaysText from './getRemainingDaysText';
import getTicketInfoByType from './getTicketInfoByType';

/**
 * 회원 상세 > TicketSwiper,
 * 수강권 상세 페이지 메인 상단의 큰 수강권 정보 세팅 함수
 */
const formatUserTicketCard = (userTicket: UserTicketResponse): UserTicketCardType => {
  const {
    id,
    booking_limit_per_month,
    booking_limit_per_week,
    is_show_cancel_count,
    availability_start_at,
    expire_at,
    user_ticket_status,
    change_daily_booking_count,
    remaining_coupon,
    usable_coupon,
    remaining_cancel,
    ticket,
    is_shared,
  } = userTicket;

  const { ticketColor, classType, convertTicketType, maxTrainee } = formatCommonTicketInfo(ticket);

  const { showCoupon: showBookingCoupon, showPeriod } = getTicketInfoByType(ticket.type);
  const showRemainingCancelCoupon = (ticket.type === 'P' && is_show_cancel_count) || ticket.type === 'T';

  let limitText = null;
  if (booking_limit_per_month) {
    limitText = `월 ${booking_limit_per_month}회`;
  }
  if (booking_limit_per_week) {
    limitText = `주 ${booking_limit_per_week}회`;
  }

  let ticketStatus: UserTicketCardType['ticketStatus'] = user_ticket_status;
  if (ticket.type === 'S' && (user_ticket_status === 'using' || user_ticket_status === 'to_use')) {
    ticketStatus = 'sell';
  }

  const noShowRemainingDaysText =
    !showPeriod || ticketStatus === 'refund' || ticketStatus === 'expired' || ticketStatus === 'not_usable';

  const alreadyUsed = change_daily_booking_count; // 이미 사용한 당일예약 변경 횟수
  const totalDailyBookingChange = ticket.daily_booking_change_limit;
  const remainingCount = totalDailyBookingChange - alreadyUsed;
  const remainingDailyBookingChange = remainingCount > 0 ? remainingCount : 0;

  return {
    id,
    title: ticket.title,
    ticketStatus,
    isShared: is_shared,
    isExperience: ticket.is_ignore_new_payment,
    classType,
    convertTicketType,
    limitText,
    ticketColor:
      ticketStatus === 'using' || ticketStatus === 'to_use' || ticketStatus === 'sell'
        ? ticketColor
        : [theme.color.gray3, theme.color.gray3],
    maxTrainee,
    remainingCoupon: showBookingCoupon ? `잔여 ${remaining_coupon}` : null,
    usableCoupon: showBookingCoupon ? `예약가능 ${ticket.available_class_type !== 'I' ? usable_coupon : remaining_coupon}` : null,
    remainingCancel: showRemainingCancelCoupon ? `취소 ${remaining_cancel}` : null,
    period: showPeriod ? `${filters.date(availability_start_at)} ~ ${filters.date(expire_at)}` : null,
    remainingDaysWithText: noShowRemainingDaysText ? null : `${getRemainingDaysText(expire_at, availability_start_at)}`,
    dailyBookingChangeLimit: !ticket.daily_booking_change_limit ? null : `당일변경 ${remainingDailyBookingChange}`,
  };
};

export default formatUserTicketCard;
