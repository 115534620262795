import { useIsMutating } from '@tanstack/react-query';
import { ButtonProps } from 'components/Button';
import { AllPageModeType } from 'pages/Booking/types';
import { EtcScheduleCreateFormType } from 'pages/EtcSchedule/types';
import { useFormContext } from 'react-hook-form';
import FooterButton from 'sharedComponents/FooterButton';

type Props = ButtonProps & {
  pageMode: AllPageModeType;
};

const SubmitButton = ({ children, disabled, pageMode, ...props }: Props) => {
  const {
    watch,
    formState: { isDirty },
  } = useFormContext<EtcScheduleCreateFormType>();
  const isMutating = useIsMutating() > 0;
  const { title, startDate, endDate, startTime, endTime } = watch();
  const noRequiredValue = !title || !startDate || !endDate || !startTime || !endTime;

  return (
    <>
      <FooterButton
        type="submit"
        disabled={disabled || noRequiredValue || isMutating || (!isDirty && pageMode !== 'copy')}
        {...props}>
        {children}
      </FooterButton>
    </>
  );
};

export default SubmitButton;
