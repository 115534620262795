import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Tabs, { TabDataElementType } from 'components/Tabs';
import { PERMISSION } from 'constants/permission';
import useGetPermissionDoHavePermission from 'hooks/service/queries/useGetPermissionDoHavePermission';
import usePermission from 'hooks/usePermission';
import useQueryString from 'hooks/useQueryString';
import useScrollContentAtTop from 'hooks/useScrollContentAtTop';
import useStatusBarColor from 'hooks/useStatusBarColor';
import { useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { allMemberSelectedFiltersAtom, assignedMemberSelectedFiltersAtom, counselSelectedFiltersAtom } from 'recoil/Member';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import MainLayout from 'sharedComponents/MainLayout';

import { MAIN_TAB_DATA } from '../constants';
import AllMemberFilter from './AllMemberFilter';
import AllMemberList from './AllMemberList';
import AllMemberPermissionGuard from './AllMemberPermissionGuard';
import AssignedMemberList from './AssignedMemberList';
import Counsel from './Counsel';
import CounselCustomerFilter from './CounselCustomerFilter';
import MemberFloatingButton from './MemberFloatingButton';
import { Container, ContentsContainer, ContentsWrapper, StickyWrapper } from './MemberLayout';
import MemberSkeletonFilter from './MemberSkeletonFilter';
import MemberSkeletonList from './MemberSkeletonList';
import Search from './Search';

const MemberList = () => {
  const { getSearchParams } = useQueryString();
  const { hasPermission } = usePermission();

  const stickyRef = useRef<HTMLDivElement>(null);

  const { isAtTop } = useScrollContentAtTop({ stickyRef });
  useStatusBarColor(isAtTop ? theme.color.white : 'linear');

  const tab = getSearchParams('tab');
  const canViewMembers = hasPermission(PERMISSION.member.view);
  const memberTabs = canViewMembers ? MAIN_TAB_DATA : MAIN_TAB_DATA.filter(tabData => tabData.value !== 'all');

  const currentTab = memberTabs.find(tabData => tabData.value === tab) || memberTabs[0];
  const navigate = useNavigate();
  const { checkPermission } = useGetPermissionDoHavePermission();

  const changeTab = (tabData: TabDataElementType) => {
    if (tabData.value === 'all') {
      checkPermission(PERMISSION.member.view.id, {
        onSuccess: () => navigate('/member', { replace: true }),
      });
      return;
    }

    navigate(`/member?tab=${tabData.value}`, { replace: true });
  };

  const searchPlaceholder = useMemo(() => {
    if (currentTab.value === 'counsel') {
      return '회원명, 메모, 내용, 휴대폰 번호 검색';
    } else {
      return '회원명, 주소, 메모, 휴대폰 번호 검색';
    }
  }, [currentTab]);

  return (
    <MainLayout
      bottomNav
      scrollable
      childrenFloatingButton={
        <ApiBoundary>
          <MemberFloatingButton currentTab={currentTab.value} />
        </ApiBoundary>
      }>
      <Container>
        {currentTab.value === 'all' ? (
          <AllMemberPermissionGuard>
            <Search searchPlaceholder={searchPlaceholder} />
          </AllMemberPermissionGuard>
        ) : (
          <Search searchPlaceholder={searchPlaceholder} />
        )}
        <ContentsContainer>
          <StickyWrapper ref={stickyRef} className={isAtTop ? 'sticky--at-top' : ''}>
            <Tabs key={memberTabs.length} data={memberTabs} value={currentTab.value} onChange={changeTab} />
            <ApiBoundary fallback={<MemberSkeletonFilter isAssigned={currentTab.value === 'assigned'} />}>
              {currentTab.value === 'all' && (
                <AllMemberPermissionGuard>
                  <AllMemberFilter filterAtom={allMemberSelectedFiltersAtom} />
                </AllMemberPermissionGuard>
              )}
              {currentTab.value === 'assigned' && <AllMemberFilter filterAtom={assignedMemberSelectedFiltersAtom} assigned />}
            </ApiBoundary>
            {currentTab.value === 'counsel' && <CounselCustomerFilter filterAtom={counselSelectedFiltersAtom} />}
          </StickyWrapper>
          <StyledContentsWrapper>
            <ApiBoundary fallback={<MemberSkeletonList currentTab={currentTab.value} />}>
              {currentTab.value === 'all' && (
                <AllMemberPermissionGuard>
                  <AllMemberList />
                </AllMemberPermissionGuard>
              )}
              {currentTab.value === 'assigned' && <AssignedMemberList />}
              {currentTab.value === 'counsel' && <Counsel />}
            </ApiBoundary>
          </StyledContentsWrapper>
        </ContentsContainer>
      </Container>
    </MainLayout>
  );
};

export default MemberList;

const StyledContentsWrapper = styled(ContentsWrapper)`
  padding-bottom: 48px;
`;
