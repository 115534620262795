import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import CheckBox from 'components/CheckBox';
import Form, { BottomWrapper } from 'components/Form';
import usePostAuthCheckCurrentPassword from 'hooks/service/mutations/usePostAuthCheckCurrentPassword';
import useErrorDialog from 'hooks/useErrorDialog';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { isKeyboardShowAtom } from 'recoil/keyboard';
import { password } from 'utils/validate';
import { boolean, object } from 'yup';

import PasswordFieldController from '../../PasswordReset/components/PasswordFieldController';
import { WITHDRAW_TEXT } from '../constants';
import { WithdrawInputType } from '../type';
import WithdrawFinalDrawer from './WithdrawFinalDrawer';

const yupSchema = object().shape({ password, agreed: boolean().isTrue() });

const WithdrawForm = () => {
  const [isDrawerOpened, setDrawerOpened] = useState(false);
  const { control, formState, register, handleSubmit, getValues } = useForm<WithdrawInputType>({
    mode: 'onChange',
    resolver: yupResolver(yupSchema),
    defaultValues: {
      password: '',
      agreed: false,
    },
  });
  const navigate = useNavigate();
  const errorDialog = useErrorDialog();

  const { mutate: checkPasswordMutate } = usePostAuthCheckCurrentPassword();
  const validatePassword = ({ password }: WithdrawInputType) => {
    const params = { current_password: password };
    checkPasswordMutate(params, {
      onSuccess: () => {
        setDrawerOpened(true);
      },
      onError: error => {
        errorDialog.open(error);
      },
    });
  };

  const [paddingBottom, setPaddingBottom] = useState(0);
  const isKeyboardShow = useRecoilValue(isKeyboardShowAtom);
  const footerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!footerRef.current) return;
    setPaddingBottom(isKeyboardShow ? 0 : footerRef.current.clientHeight);
  }, [isKeyboardShow]);

  return (
    <>
      <FormContainer onSubmit={handleSubmit(validatePassword)}>
        <Contents paddingBottom={paddingBottom + 16}>
          <PasswordFieldController controllerProps={{ control, name: 'password' }} placeholder={WITHDRAW_TEXT.placeholder} />
          <MissingButton onClick={() => navigate('/find-password?step=1&from=withdraw')} textUnderline>
            {WITHDRAW_TEXT.missingPassword}
          </MissingButton>
        </Contents>
        <Bottom ref={footerRef}>
          <CheckBox id="agreed" label={WITHDRAW_TEXT.agree} {...register('agreed')} gap={10} labelFontsize={14} />
          <Button
            type="submit"
            disabled={!formState.isValid || formState.isSubmitting}
            color="point"
            fontSize={15}
            fullWidth
            size="large56"
            variant="contained">
            {WITHDRAW_TEXT.submit}
          </Button>
        </Bottom>
      </FormContainer>
      <WithdrawFinalDrawer isOpen={isDrawerOpened} onClose={() => setDrawerOpened(false)} password={getValues('password')} />
    </>
  );
};

export default WithdrawForm;

const FormContainer = styled(Form)`
  padding: 20px 0 0;
  ${theme.flex('column', '', '')}
  flex-grow: 1;
  width: 100%;
`;
const Contents = styled.div<{ paddingBottom: number }>`
  flex-grow: 1;
  padding: 0 20px;
  padding-bottom: ${({ paddingBottom }) => paddingBottom}px;
`;
const MissingButton = styled(Button)`
  margin: 16px auto 0;
`;

const Bottom = styled(BottomWrapper)`
  padding-top: 0;
  label {
    margin-bottom: 24px;
  }
`;
