import { MemberUpdateUserTicket } from 'pages/MemberForm/types';
import { FormProvider, useForm } from 'react-hook-form';
import formatSmallUserTicketCard from 'utils/formatSmallUserTicketCard';
import getDaysDiff from 'utils/getDaysDiff';
import { getDiscountPercent } from 'utils/getPaymentDetail';

import { ProductUpdateFormType } from '../types';
import CommonProductForm from './CommonProductForm';

type Props = {
  userTicket: MemberUpdateUserTicket;
  onSubmit: (values: ProductUpdateFormType) => void;
};

const ProductUpdateForm = ({ userTicket, onSubmit }: Props) => {
  const {
    ticket_id,
    is_show_cancel_count,
    isAutoWriteUnpaid,
    max_coupon,
    remaining_cancel,
    remaining_coupon,
    availability_start_at,
    expire_at,
    ticket: { type, available_class_type },
    staffs,
    is_shared,
    lower_limit_max_coupon,
    lower_limit_remaining_coupon,
    payments: { current_point, original_price },
    newPayment: {
      paymentId,
      amount,
      card_amount,
      cash_amount,
      wiretransfer_amount,
      unpaid_amount,
      point_amount,
      reward_point,
      settlement_at,
      installment_period,
    },
  } = userTicket;

  const methods = useForm<ProductUpdateFormType>({
    defaultValues: {
      userTicket,

      installment: installment_period < 2 ? 'single' : 'split',
      isCheckedCard: !!card_amount,
      isCheckedCash: !!cash_amount,
      isCheckedWireTransfer: !!wiretransfer_amount,
      isCheckedPoint: !!point_amount,
      salePrice: original_price,
      remainingPoint: current_point,
      originAvailablePeriod: getDaysDiff(expire_at, availability_start_at) + 1,
      isAutoWriteUnpaid,

      paymentId,
      paymentStatus: 'purchase',

      payment: {
        amount,
        card_amount,
        cash_amount,
        wiretransfer_amount,
        point_amount,
        reward_point,
        installment_period: installment_period < 2 ? 2 : installment_period,
        discount: getDiscountPercent(original_price, amount, Number(unpaid_amount)),
        unpaid_amount,
        settlement_at,
      },
      count: {
        max_coupon,
        remaining_cancel,
        remaining_coupon,
      },
      period: {
        availability_start_at,
        expire_at,
      },
      detail: {
        is_shared,
        is_show_cancel_count,
        staffs,
        tempStaffs: staffs,
        ticket_id,
      },
    },
  });

  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <CommonProductForm
        pageMode="update"
        ticket={formatSmallUserTicketCard(userTicket)}
        isShowCancelCount={!!is_show_cancel_count}
        type={type}
        isItem={available_class_type === 'I'}
        minMaxCoupon={lower_limit_max_coupon}
        minRemainingCoupon={lower_limit_remaining_coupon}
        onSubmit={handleSubmit(onSubmit)}
      />
    </FormProvider>
  );
};

export default ProductUpdateForm;
