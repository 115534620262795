import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Counter from 'components/Counter';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { TicketsResponse } from 'hooks/service/queries/useInfinityTickets';
import { useCallback, useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { TicketCountFormType } from './types';

type Props = {
  ticketType: TicketsResponse['type'];
  isShowCancelCount: boolean;
  minMaxCoupon?: number;
  minRemainingCoupon?: number;
};

const TicketCountForm = ({ ticketType, isShowCancelCount, minMaxCoupon, minRemainingCoupon }: Props) => {
  const { control, setValue } = useFormContext<TicketCountFormType>();
  const maxCoupon = useWatch({ control, name: 'count.max_coupon' });
  const remainingCoupon = useWatch({ control, name: 'count.remaining_coupon' });

  const showCountField = useMemo(() => {
    switch (ticketType) {
      case 'T':
      case 'RT':
      case 'RM':
        return true;
      default:
        return false;
    }
  }, [ticketType]);

  const showCancelCountField = useMemo(() => {
    switch (ticketType) {
      case 'T':
        return true;
      case 'P':
        return isShowCancelCount;
      default:
        return false;
    }
  }, [ticketType, isShowCancelCount]);

  const deductedCount = useMemo(() => {
    if (minMaxCoupon === undefined || minRemainingCoupon === undefined) return 0;
    return minMaxCoupon - minRemainingCoupon;
  }, [minMaxCoupon, minRemainingCoupon]);

  /**
   * 잔여횟수
   * - 잔여횟수가 전체횟수 보다 많아질 수 없음(전체횟수도 같이 증가)
   * - 잔여횟수를 감소시키는 것은 상관없음
   */
  const changeRemainingCoupon = useCallback(
    (value: number) => {
      setValue('count.remaining_coupon', value, { shouldDirty: true });
      if (value + deductedCount > maxCoupon) {
        const max999 = value + deductedCount > 999 ? 999 : value + deductedCount;
        setValue('count.max_coupon', max999, { shouldDirty: true });
      }
    },
    [maxCoupon, deductedCount, setValue],
  );

  /**
   * 전체횟수
   * - 전체횟수가 잔여횟수 보다 적어질 수 없음(잔여횟수도 같이 감소)
   * - 전체횟수를 증가시키는 것은 상관없음
   */
  const changeMaxCoupon = useCallback(
    (value: number) => {
      setValue('count.max_coupon', value, { shouldDirty: true });
      if (remainingCoupon + deductedCount > value) {
        const remainingCount = value - deductedCount;
        const remainingValue =
          minRemainingCoupon && remainingCount < 0 ? minRemainingCoupon : remainingCount < 0 ? 0 : remainingCount;
        setValue('count.remaining_coupon', remainingValue, { shouldDirty: true });
      }
    },
    [remainingCoupon, minRemainingCoupon, deductedCount, setValue],
  );

  return (
    <>
      {showCountField && (
        <Wrapper className="ticket-count">
          <div className="text-wrapper">
            <Icon name="number" fillColor={theme.color.gray2} />
            <Typography span size={15} textColor="gray1">
              잔여 횟수
            </Typography>
          </div>
          <div className="counter-wrapper">
            <Controller
              name="count.remaining_coupon"
              control={control}
              render={({ field: { value } }) => (
                <Counter
                  id="remaining_coupon"
                  min={minRemainingCoupon ?? 0}
                  max={999 - deductedCount}
                  value={value || 0}
                  onChange={changeRemainingCoupon}
                />
              )}
            />
          </div>
        </Wrapper>
      )}
      {showCountField && (
        <Wrapper className="ticket-count">
          <div className="text-wrapper">
            <Icon name="wholeNumber" fillColor={theme.color.gray2} />
            <Typography span size={15} textColor="gray1">
              전체 횟수
            </Typography>
          </div>
          <div className="counter-wrapper">
            <Controller
              name="count.max_coupon"
              control={control}
              render={({ field: { value } }) => (
                <Counter id="전체횟수" min={minMaxCoupon ?? 0} value={value || 0} onChange={changeMaxCoupon} />
              )}
            />
          </div>
        </Wrapper>
      )}

      {showCancelCountField && (
        <Wrapper className="ticket-count">
          <div className="text-wrapper">
            <Icon name="roomCancel" fillColor={theme.color.gray2} />
            <Typography span size={15} textColor="gray1">
              예약취소 가능 횟수
            </Typography>
          </div>
          <div className="counter-wrapper">
            <Controller
              name="count.remaining_cancel"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Counter id="remaining_cancel" value={value || 0} onChange={onChange} />
              )}
            />
          </div>
        </Wrapper>
      )}
    </>
  );
};

export default TicketCountForm;

const Wrapper = styled.div`
  ${theme.flex('', 'center', 'space-between')};
  height: 56px;
  padding: 0 20px;
  border-bottom: 1px solid ${theme.color.gray8};

  .text-wrapper {
    flex: 1;
    ${theme.flex('', 'center', '', 10)};
  }

  .counter-wrapper {
    width: 105px;
  }
`;
