import { postMessageToApp } from 'utils/communicateWithNative';
import localStorage from 'utils/localStorage';

import useGetAuthMeInstanceTokenCheck from './service/queries/useGetAuthMeInstanceTokenCheck';

const useInitializeAfterLogin = () => {
  const { data: fcmResponse } = useGetAuthMeInstanceTokenCheck();

  const initializeAfterLogin = (accessToken: string) => {
    localStorage.set('access_token', accessToken);

    postMessageToApp('REQUEST_NOTIFICATION_PERMISSION');
    if (!fcmResponse?.validate) {
      postMessageToApp('REQUEST_FCM_TOKEN');
    }
  };

  return { initializeAfterLogin };
};

export default useInitializeAfterLogin;
