import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Form from 'components/Form';
import dayjs from 'dayjs';
import usePatchStaffEventTime, { StaffEventTimeParams } from 'hooks/service/mutations/usePatchStaffEventTime';
import useErrorDialog from 'hooks/useErrorDialog';
import useParamsId from 'hooks/useParamsId';
import useToast from 'hooks/useToast';
import { isEqual, omit, orderBy, uniqBy, uniqWith } from 'lodash';
import { ScheduleFormType } from 'pages/MoreDetails/Staff/Detail/EventScheduleSingle/types';
import { getEventMultiIsPeriodCheck } from 'pages/MoreDetails/Staff/Detail/EventScheduleSingle/utils';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { eventScheduleSelectDateSelector } from 'recoil/staff';
import EventWorkTimeCardListMulti from 'sharedComponents/ProfileField/components/EventWorkTime/components/EventWorkTimeCardListMulti';
import getReverseEventTimeDataset from 'utils/getReverseEventTimeDataset';

import { STAFF_PROFILE_FORM_TEXT } from '../../constants';
import DateMultiCalendarPicker from './DateMultiCalendarPicker';

type Props = {
  eventTimesDataset: ScheduleFormType[];
};

const EventScheduleUpdateForm = ({ eventTimesDataset }: Props) => {
  const [datasetFields, setDatasetFields] = useState(eventTimesDataset);

  const selectDateTexts = useRecoilValue(eventScheduleSelectDateSelector);

  const navigate = useNavigate();
  const { setToast } = useToast();
  const errorDialog = useErrorDialog();

  const targetStaffId = useParamsId();
  const { mutate: patchStaffEventTimeMutate } = usePatchStaffEventTime({ targetStaffId });

  const selectDateFields = useMemo(() => {
    return orderBy(
      datasetFields.filter(data => selectDateTexts.includes(data.date)),
      ({ date }) => dayjs(date),
      'asc',
    );
  }, [datasetFields, selectDateTexts]);

  useEffect(() => {
    const isFirstData = datasetFields.findIndex(data => data.date === selectDateTexts[0]);
    if (selectDateTexts.length === 1 && isFirstData) {
      setDatasetFields(prev => {
        const appendedData: ScheduleFormType = {
          date: selectDateTexts[0],
          day_of_week: Number(dayjs(selectDateTexts[0]).format('d')) as ScheduleFormType['day_of_week'],
          end_time: '18:00:00',
          id: 0,
          restTimes: [],
          start_time: '09:00:00',
          type: 'eventWorkTime',
        };
        const uniqData = uniqBy([...prev, appendedData], 'date');
        return uniqData;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submit = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    const isEventPeriodCheck = getEventMultiIsPeriodCheck(selectDateFields);
    if (!isEventPeriodCheck) {
      errorDialog.open('휴식시간은 근무시간을\n초과할 수 없습니다.');
      return;
    }

    const appendedSchedules: ScheduleFormType[] = [];
    const updatedSchedules: ScheduleFormType[] = [];
    selectDateFields.forEach(data => {
      if (eventTimesDataset.findIndex(field => field.date === data.date) === -1) {
        appendedSchedules.push(data);
      } else {
        updatedSchedules.push(data);
      }
    });

    const combineSchedules = [
      ...eventTimesDataset.map(data => {
        const currentNewData = updatedSchedules.find(newData => newData.date === data.date);
        if (currentNewData) return currentNewData;
        return data;
      }),
      ...appendedSchedules,
    ];

    const reversedDataset = getReverseEventTimeDataset(combineSchedules).map(data => omit(data, 'id'));

    const params: StaffEventTimeParams = {
      schedules: uniqWith(reversedDataset, isEqual),
    };
    patchStaffEventTimeMutate(params, {
      onSuccess: () => {
        setToast({ type: 'success', message: STAFF_PROFILE_FORM_TEXT.success.eventSchedulesAdd });
        navigate(-1);
      },
    });
  };

  const footerButton = {
    text: '추가 완료',
    disabled: isEqual(eventTimesDataset, datasetFields),
  };

  return (
    <Container>
      <DateMultiCalendarPicker
        eventTimesDataset={eventTimesDataset}
        datasetFields={datasetFields}
        setDatasetFields={setDatasetFields}
      />

      <Form id="event-work-time-form" className="event-work-time-form" onSubmit={submit} footerButton={footerButton}>
        <EventWorkTimeCardListMulti selectDateFields={selectDateFields} setDatasetFields={setDatasetFields} />
      </Form>
    </Container>
  );
};

export default EventScheduleUpdateForm;

const Container = styled.div`
  ${theme.flex('column')};
  padding-bottom: 40px;
  min-height: 100%;

  .event-work-time-form {
    ${theme.flex('column', 'center', 'flex-start')};
    width: 100%;
    background-color: ${theme.color.gray6};
    flex: 1;
  }
`;
