import { useQueryClient } from '@tanstack/react-query';
import usePatchStaffProfile from 'hooks/service/mutations/usePatchStaffProfile';
import useParamsId from 'hooks/useParamsId';
import useToast from 'hooks/useToast';
import { useState } from 'react';
import { FieldErrors, SubmitHandler, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import filters from 'utils/filters';
import getColor from 'utils/getColor';
import { mobile as yupMobile } from 'utils/validate';

import StaffInfoForm from '../../components/StaffInfoForm';
import { RESPONSE_SUCCESS } from '../../constants';
import { InfoUpdateFormType } from '../../types';
import MobileConfirmDrawer from './MobileConfirmDrawer';

const StaffUpdateForm = () => {
  const navigate = useNavigate();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { setToast } = useToast();
  const targetStaffId = useParamsId();
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting, isSubmitSuccessful, dirtyFields, isDirty, defaultValues },
  } = useFormContext<InfoUpdateFormType>();

  const { mutate: patchStaffProfileMutate } = usePatchStaffProfile({ targetStaffId });

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    reset(defaultValues, { keepDirtyValues: true });
    setIsDrawerOpen(false);
  };

  const queryClient = useQueryClient();

  const submit: SubmitHandler<InfoUpdateFormType> = inputValues => {
    const { mobile, role_id, profile } = inputValues;

    if (!isDrawerOpen && dirtyFields.mobile) {
      const isMobileCheckEnabled = yupMobile.isValidSync(mobile);
      if (isMobileCheckEnabled) {
        openDrawer();
        return;
      }
    }

    const params = {
      mobile: dirtyFields.mobile ? mobile : undefined,
      role_id: dirtyFields.role_id ? Number(role_id) : undefined,
      profile: {
        name: dirtyFields.profile?.name ? profile.name : undefined,
        gender: dirtyFields.profile?.gender ? profile.gender : undefined,
        birthday: dirtyFields.profile?.birthday ? filters.dateDash(profile.birthday) : undefined,
        registered_at: dirtyFields.profile?.registered_at ? filters.dateDashTimeSecond(profile.registered_at) : undefined,
        get_noti_all: dirtyFields.profile?.get_noti_all ? (profile.get_noti_all ? 1 : 0) : undefined,
        representative_color: dirtyFields.profile?.representative_color ? getColor(profile.representative_color) : undefined,
      },
    };

    patchStaffProfileMutate(params, {
      onSuccess: () => {
        if (dirtyFields.mobile) {
          queryClient.clear();
        }

        setToast({ type: 'success', message: RESPONSE_SUCCESS.staffInfoUpdate });
        navigate(-1);
      },
      onError: () => {
        reset(defaultValues, { keepDirtyValues: true });
        closeDrawer();
      },
    });
  };

  const onError = (error: FieldErrors<InfoUpdateFormType>) => {
    if (error.profile?.name) {
      setToast({ type: 'error', message: error.profile?.name.message as string, bottom: 92 });
      return;
    }
  };

  return (
    <>
      <StaffInfoForm
        onSubmit={handleSubmit(submit, onError)}
        footerButton={{
          text: '수정 완료',
          disabled: isSubmitting || isSubmitSuccessful || !isDirty,
        }}
      />

      <MobileConfirmDrawer isOpen={isDrawerOpen} onClose={closeDrawer} onSubmit={handleSubmit(submit, onError)} />
    </>
  );
};

export default StaffUpdateForm;
