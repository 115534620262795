import { ArrowFilterValuesType } from 'components/Tabs/ArrowFilterTabs';
import { StaffForFilter } from 'hooks/service/queries/useGetFilterStaffs';
import { MemberParams } from 'hooks/service/queries/useInfinityMember';
import { FilterOptionsType, FilterType } from 'sharedComponents/Filters/types';
import filters from 'utils/filters';

import { DEFAULT_COUNSEL_FILTER_OPTIONS, DEFAULT_MEMBER_FILTER_OPTIONS, MEMBER_FILTER_TAG_LABEL } from './constants';
import {
  MemberCounselSelectedFilterType,
  MemberGradeType,
  MemberSelectedFilterType,
  MemberStaffFilterType,
  MemberTagCountType,
} from './types';

/** 회원 목록 필터로 쓸 데이터로 변환 */
export const convertMemberFilter = (
  tagCounts: MemberTagCountType,
  grades: MemberGradeType[] | undefined,
  staffs: StaffForFilter[] | undefined,
): FilterType[] => {
  const tag = {
    key: 'tag',
    type: 'button',
    title: '회원',
    options: convertMemberTagOptions(tagCounts),
  };

  const grade = grades && {
    key: 'user_grade_id',
    type: 'button',
    title: '회원등급',
    options: convertMemberGradeOptions(grades),
  };

  const staffOptions = staffs && convertMemberStaffFilterOptions(staffs);
  const staff = staffOptions && {
    key: 'staff',
    type: 'select',
    title: '담당강사',
    options: staffOptions,
    tabConverter: (value: ArrowFilterValuesType) => {
      const staffValue = value as MemberSelectedFilterType['staff'];
      const options = staffOptions as { value: MemberStaffFilterType; label: string }[];
      const selectedOption = options.find(option => option.value?.id === staffValue?.id);

      if (!value || !selectedOption) return '담당강사';
      if (staffValue?.id?.includes(',')) return '담당강사 전체';
      return selectedOption?.label;
    },
  };

  return [
    tag,
    DEFAULT_MEMBER_FILTER_OPTIONS[1],
    // 회원등급이 없는 경우는 포함시키지 않음
    ...(grade ? [grade] : []),
    // 담당회원 필터에서는 포함시키지 않음
    ...(staff ? [staff] : []),
    ...DEFAULT_MEMBER_FILTER_OPTIONS.slice(4),
  ];
};

/** api로 받은 카운트 응답값을 회원 태그 옵션으로 변환 */
const convertMemberTagOptions = (tagCount: MemberTagCountType): FilterOptionsType[] => {
  const tags = Object.keys(tagCount) as Array<keyof MemberTagCountType>;

  return tags.map(tag => ({
    value: tag === 'total' ? undefined : tag,
    label: `${MEMBER_FILTER_TAG_LABEL[tag]} ${filters.numberComma(tagCount[tag])}`, // 전체회원 nn
  }));
};

/** api로 받은 등급 응답값을 등급 옵션으로 변환 */
const convertMemberGradeOptions = (grades: MemberGradeType[]): FilterOptionsType[] => {
  const defaultOptions = { value: undefined, label: '전체' };
  const gradeOptions = grades.map(({ id, name }) => ({ value: id, label: name }));

  return [defaultOptions, ...gradeOptions];
};

const convertMemberStaffFilterOptions = (staffs: StaffForFilter[]): FilterOptionsType[] => {
  const defaultOptions = { value: undefined, label: '선택 안함' };
  const allStaff = { value: { id: staffs.map(staff => staff.id).join(',') }, label: '전체' };
  const staffOptions = staffs.map(staff => ({
    value: { id: `${staff.id}`, avatar: (staff.avatar as ArrowFilterValuesType) ?? undefined },
    label: staff.name,
  }));

  return [defaultOptions, allStaff, ...staffOptions];
};

export const convertAllMemberFilterToParams = (selectedFilter: MemberSelectedFilterType): Omit<MemberParams, 'search'> => {
  const { tag, staff, ticket_remaining, attendance_count, sort_target, sort_type, ...params } = selectedFilter;
  const unavailableSort = !attendance_count && sort_target === 'attendance_count';
  const staffIds = staff?.id.split(',').map(Number);
  return {
    ...params,
    tag: tag ?? 'all',
    assigned_id: staffIds,
    remaining_start_date: ticket_remaining?.date?.[0],
    remaining_end_date: ticket_remaining?.date?.[1],
    remaining_coupon: ticket_remaining?.count,
    attendance_count_start_date: attendance_count?.[0],
    attendance_count_end_date: attendance_count?.[1],
    sort_target: unavailableSort ? 'name' : sort_target,
    sort_type: unavailableSort ? 'asc' : sort_type,
    paginate_type: 'detail',
  };
};

/** 상담고객 필터 데이터 변환 */
export const convertCounselFilter = (
  staffs: StaffForFilter[],
  hasAllStaffOption: boolean,
  hasRegisterFilter?: boolean,
): FilterType[] => {
  const staffOptions = convertCounselStaffOptions(staffs, hasAllStaffOption);
  const staff = {
    key: 'staffs',
    type: 'select',
    title: '담당스태프',
    options: staffOptions,
    tabConverter: (value: ArrowFilterValuesType) => {
      const staffValue = value as MemberCounselSelectedFilterType['staffs'];
      const options = staffOptions as { value: MemberStaffFilterType; label: string }[];
      const selectedOption = options.find(option => option.value?.id === staffValue?.id);
      if (!selectedOption) return '담당스태프 전체';
      return `${selectedOption?.label}`;
    },
  };

  return [
    DEFAULT_COUNSEL_FILTER_OPTIONS[0],
    ...(hasRegisterFilter ? [DEFAULT_COUNSEL_FILTER_OPTIONS[1]] : []),
    ...DEFAULT_COUNSEL_FILTER_OPTIONS.slice(2, -1),
    staff,
  ];
};

/** api로 받은 강사목록을 상담스태프 옵션으로 변환 */
const convertCounselStaffOptions = (staffs: StaffForFilter[], hasAllStaffOption: boolean): FilterOptionsType[] => {
  const staffOptions = staffs.map(({ id, name, avatar }) => ({
    value: { id: `${id}`, name, avatar: avatar as ArrowFilterValuesType },
    label: name,
  }));

  const allOption = hasAllStaffOption ? [{ value: undefined, label: '전체' }] : [];
  return [...allOption, ...staffOptions];
};
