import { useIsMutating } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import FooterButton from 'sharedComponents/FooterButton';

import { BookingPrivateFormType } from '../../types';

const SubmitButton = () => {
  const {
    watch,
    formState: { dirtyFields },
  } = useFormContext<BookingPrivateFormType>();
  const watchedFields = watch(['selectedUserTickets', 'lectureSize']);
  const isValid = watchedFields.every(field => (Array.isArray(field) ? !!field.length : !!field));
  const isDirty = !!Object.keys(dirtyFields).length;
  const isMutating = useIsMutating() > 0;

  const disabled = useMemo(() => {
    if (isMutating || !isValid) {
      return true;
    } else if (isValid && !isDirty) {
      return true;
    } else {
      return false;
    }
  }, [isDirty, isMutating, isValid]);

  return (
    <FooterButton type="submit" disabled={disabled}>
      수정 완료
    </FooterButton>
  );
};

export default SubmitButton;
