import styled from '@emotion/styled';
import { useQueryClient } from '@tanstack/react-query';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import useInfinityStaffNoticeCheckIn from 'hooks/service/queries/useInfinityStaffNoticeCheckIn';
import { CHECK_IN_TEXT } from 'pages/Notification/constants';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';
import NodataTypography from 'sharedComponents/NodataTypography';
import PullToRefresh from 'sharedComponents/PullToRefresh';

import CheckInListItem from './CheckInListItem';

const CheckInList = () => {
  const {
    data: { checkIns, today },
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    isRefetching,
    refetch,
  } = useInfinityStaffNoticeCheckIn();

  const queryClient = useQueryClient();
  const refreshNotifications = () => {
    // 알림쪽은 탭 알림표시 및 하단 네비바의 알림표시도 갱신되어야 함
    queryClient.invalidateQueries({ queryKey: ['staff/notice'] });
    refetch();
  };

  return (
    <>
      <TodayCheckInWrapper>
        <Typography weight={500}>{CHECK_IN_TEXT.today}</Typography>
        <TodayCheckInNumber weight={600} suffix={CHECK_IN_TEXT.todayNumberSuffix}>
          {today}
        </TodayCheckInNumber>
      </TodayCheckInWrapper>
      <PullToRefresh isRefetching={isRefetching} onRefresh={refreshNotifications}>
        <InfiniteScroll loadMore={fetchNextPage} isLoading={isFetchingNextPage} hasMore={hasNextPage}>
          {checkIns.length === 0 && <NodataTypography>{CHECK_IN_TEXT.noData}</NodataTypography>}
          {checkIns.map(checkIn => (
            <CheckInListItem checkInItem={checkIn} key={checkIn.id} />
          ))}
        </InfiniteScroll>
      </PullToRefresh>
    </>
  );
};

export default CheckInList;

const TodayCheckInWrapper = styled.div`
  width: calc(100% - 40px);
  border: solid 1px ${theme.color.gray5};
  border-radius: 6px;
  padding: 10px 14px;
  margin: 16px 20px;

  ${theme.flex('', 'center', 'space-between')};
`;

const TodayCheckInNumber = styled(Typography)<{ suffix: string }>`
  margin-left: 8px;
  :after {
    content: '${({ suffix }) => suffix}';
  }
`;
