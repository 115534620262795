import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import useInfiniteQueryFnWithCommonParams from '../common/useInfiniteQueryFnWithCommonParams';
import { Image } from './useGetLectureDetail';
import { HoldingResponse } from './useInfinityHolding';
import { TicketsResponse } from './useInfinityTickets';

/** 회원별 보유 수강권/상품 리스트 */
const useInfinityUserTicketProduct = (params: UserTicketParams) => {
  const { queryFn } = useInfiniteQueryFnWithCommonParams<UserTicketResponse, UserTicketParams>({
    url: `/api/userTicket/product`,
    params,
  });

  return useSuspenseInfiniteQuery({
    queryKey: ['user-ticket', { ...params }],
    queryFn,
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      if (!lastPage.data.links.next) return null;
      return lastPage.data.meta.current_page + 1;
    },
    select: data => data.pages.flatMap(page => page.data.data),
  });
};

export default useInfinityUserTicketProduct;

export type UserTicketParams = {
  user_id: number;
  product_type: 'all' | 'userTicket' | 'item';
  status: 'refund' | 'not_usable' | 'expired' | 'transfer' | 'sell' | 'active' | 'in_active';
};

export type UserTicketResponse = {
  id: number;
  studio_id: number;
  user_id: number;
  active: boolean;
  ticket_id: number;
  availability_start_at: string;
  expire_at: string;
  max_coupon: number;
  remaining_coupon: number;
  usable_coupon: number;
  max_cancel: number;
  remaining_cancel: number;
  booking_limit_per_week: number;
  booking_limit_per_month: number;
  inactive_reason: string | null;
  is_shared: boolean;
  is_show_cancel_count: boolean;
  lower_limit_max_coupon: number; // 예약 + 예약확정 + 출석 + 결석 + 노쇼
  lower_limit_remaining_coupon: number; // 예약 + 예약확정
  memoCount: number;
  staffs: Staff[];
  status: 'purchase' | 'refund' | 'upgrade' | 'downgrade' | 'full_payment' | 'installment_payment' | 'pending' | 'transfer';
  user_ticket_status: 'using' | 'to_use' | 'holding' | 'not_usable' | 'expired' | 'transfer' | 'refund';
  staff_updated_at: string;
  payment_updated_at: string;
  created_at: string;
  updated_at: string;
  deleted_at: null;
  payments: {
    /** 회원이 현재 보유한 포인트 */
    current_point: number;
    /** card_amount + cash_amount +  wiretransfer_amount - 환불금액  */
    total_amount: number;
    /** 부모 수강권의 가격 */
    original_price: number;
    /** 판매가 = total_amount + unpaid_amount  */
    sale_price: number;
    reward_point_total: number;
    point_amount_total: number;
    card_amount_total: number;
    cash_amount_total: number;
    wiretransfer_amount_total: number;
    unpaid_amount_total: number;
    initial_payment: Payment;
    last_payment: Payment;
  };
  holdings: HoldingResponse | null; // 현재 정지중인 정보
  have_holdings: boolean; // 정지예정이 있는지 여부
  change_daily_booking_count: number;

  ticket: TicketCommon;
  policy: { is_auto_write_unpaid: boolean };
};

export type TicketCommon = Pick<
  TicketsResponse,
  | 'title'
  | 'available_class_type'
  | 'type'
  | 'use_weekly_auto_coupon_balance'
  | 'colors'
  | 'max_trainee'
  | 'daily_booking_change_limit'
  | 'available_period'
  | 'is_ignore_new_payment'
> & {
  /** 티켓 판매가 */
  actual_price: number;
};

export type Staff = {
  id: number;
  name: string;
  image: Image | null;
};

export type Payment = {
  id: number;
  studio_id: number;
  user_id: number;
  transfer_user_id: null;
  order_id: number;
  order_no: null;
  trno: null;
  type: string;
  goods_type: string;
  goods_id: number;
  parent_type: string;
  parent_id: number;
  status: string;
  amount: number;
  cash_amount: number;
  wiretransfer_amount: number;
  transfer_amount: number;
  card_amount: number;
  unpaid_amount: number;
  reward_point: number;
  point_amount: number;
  commission_amount: number;
  before_user_ticket_amount: number;
  method: string;
  installment_period: number;
  model_preview: null;
  settlement_at: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
};
