import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import IconButton from 'components/Button/IconButton';
import Form from 'components/Form';
import Icon from 'components/Icon';
import Textarea from 'components/Textarea';
import OutlinedTextField from 'components/TextField/components/OutlinedTextField';
import Typography from 'components/Typography';
import { isEqual } from 'lodash';
import { useMemo } from 'react';
import { Controller, UseFieldArrayPrepend, UseFieldArrayRemove, useFormContext, useWatch } from 'react-hook-form';
import DialogBeforeLeave from 'sharedComponents/DialogBeforeLeave';

import { STAFF_PROFILE_FORM_TEXT } from '../constants';
import { ProfileUpdateFormType, StaffCreateFormType } from '../types';

type Props = {
  onSubmit: () => void;
  careerFields: StaffCreateFormType['careers'];
  prepend: UseFieldArrayPrepend<StaffCreateFormType, 'careers'> | UseFieldArrayPrepend<ProfileUpdateFormType, 'careers'>;
  remove: UseFieldArrayRemove;
  isFooterButton?: boolean;
  disabled?: boolean;
};

const StaffProfileForm = ({ onSubmit, careerFields, prepend, remove, isFooterButton }: Props) => {
  const {
    control,
    formState: { isDirty, isSubmitSuccessful, defaultValues },
  } = useFormContext();

  const values = useWatch({ control });
  const currentValues = useMemo(() => {
    const { careers } = values;
    if (careers.length === 1 && careers[0].career === undefined) {
      return { ...values, careers: [] };
    }
    return values;
  }, [values]);

  return (
    <>
      <Container onSubmit={onSubmit}>
        <Controller
          name="profile.self_introduction"
          control={control}
          render={({ field }) => {
            return (
              <Textarea
                placeholder="자기소개 입력"
                label={STAFF_PROFILE_FORM_TEXT.subTitle.selfIntroduction}
                height={164}
                {...field}
              />
            );
          }}
        />

        <Typography className="sub-title" size={13} weight={500} textColor="gray2">
          {STAFF_PROFILE_FORM_TEXT.subTitle.careers}
        </Typography>

        <ul className="careers">
          {careerFields.length ? (
            careerFields.map((field, index) => {
              return (
                <li className="career" key={field.id}>
                  <Controller
                    control={control}
                    name={`careers.${index}.career`}
                    render={({ field }) => (
                      <OutlinedTextField placeholder="주요이력 입력" id={field.name} suffix={<></>} {...field} />
                    )}
                  />
                  <IconButton onClick={() => (index === 0 ? prepend({ career: '' }) : remove(index))}>
                    <Icon name={index === 0 ? 'plusFill' : 'deleteFill'} fillColor={theme.color.gray3} />
                  </IconButton>
                </li>
              );
            })
          ) : (
            <li className="career">
              <Controller
                control={control}
                name="careers.0.career"
                render={({ field }) => (
                  <OutlinedTextField
                    placeholder="주요이력 입력"
                    id={field.name}
                    suffix={<></>}
                    {...field}
                    value={field.value || ''}
                  />
                )}
              />
              <IconButton onClick={() => prepend({ career: '' })}>
                <Icon name="plusFill" fillColor={theme.color.gray3} />
              </IconButton>
            </li>
          )}
        </ul>

        {isFooterButton && (
          <ButtonWrapper>
            <Button
              type="submit"
              variant="contained"
              color="point"
              size="large56"
              fullWidth
              disabled={isEqual(defaultValues, currentValues)}>
              수정 완료
            </Button>
          </ButtonWrapper>
        )}
      </Container>
      <DialogBeforeLeave isBlocked={isDirty && !isSubmitSuccessful} />
    </>
  );
};

export default StaffProfileForm;

const Container = styled(Form)`
  padding: 16px 20px 100px;

  .sub-title {
    margin: 34px 0 14px;
  }

  .careers {
    ${theme.flex('column', 'center', 'center', 8)};

    .career {
      ${theme.flex()};
      gap: 8px;
      width: 100%;

      .outlined-text-field {
        flex: 1;
      }
    }
  }
`;

const ButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 14px 20px;
  width: 100%;
  background-color: ${theme.color.white};
`;
