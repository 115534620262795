import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Label, { ILabelProps } from 'components/Label';
import { useMemo } from 'react';
import TicketCard from 'sharedComponents/TicketCard';
import { USER_TICKET_STATUS } from 'sharedComponents/UserTicketCard/constants';

import { ExpiredTicketCardType } from '../types';

type Props = {
  ticket: ExpiredTicketCardType;
  onClick: () => void;
};

const ExpiredTicketCard = ({ ticket, onClick }: Props) => {
  const { ticketStatus } = ticket;

  const labelProps: Omit<ILabelProps, 'children' | 'size'> = useMemo(() => {
    switch (ticketStatus) {
      case 'refund':
      case 'not_usable':
      case 'sell':
      case 'transfer':
        return { borderColor: 'secondary3', textColor: 'secondary3', opacity: 0.64 };
      case 'expired':
      default:
        return { borderColor: 'gray4', textColor: 'gray3' };
    }
  }, [ticketStatus]);

  return (
    <Container className="expired-ticket-card" onClick={onClick}>
      <TicketCard ticket={ticket} />
      <Label {...labelProps} size="regular" minWidth={48}>
        {USER_TICKET_STATUS[ticketStatus]}
      </Label>
    </Container>
  );
};

export default ExpiredTicketCard;

const Container = styled.div`
  ${theme.flex('', '', '', 16)};

  .ticket-card {
    padding: 0;

    .experience-label {
      display: none;
    }

    .ticket-card-bottom-wrapper {
      .typography {
        opacity: 0.8;
      }
    }
  }

  .label {
    margin-left: auto;
    height: fit-content;
    padding: 4px 2px;
  }
`;
