import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Counter from 'components/Counter';
import { Controller, useFormContext } from 'react-hook-form';
import FormField from 'sharedComponents/FormField';
import FormFieldTypography from 'sharedComponents/FormField/FormFieldTypography';

import { BookingGroupCreateFormType } from '../../types';

const MaxTrainee = () => {
  const { control } = useFormContext<BookingGroupCreateFormType>();

  return (
    <FormField className="max-trainee" iconName="groupClass">
      <TraineeCounterWrapper>
        <FormFieldTypography textColor="gray1" fontWeight={400}>
          최대 수강인원
        </FormFieldTypography>
        <Controller
          name="maxTrainee"
          control={control}
          render={({ field: { ref, ...rest } }) => <Counter id="maxTrainee" min={1} max={255} {...rest} />}
        />
      </TraineeCounterWrapper>
    </FormField>
  );
};

export default MaxTrainee;

export const TraineeCounterWrapper = styled.div`
  ${theme.flex('', 'center', 'space-between', 10)};

  > p {
    flex: 1;
  }

  .counter {
    width: 101px;
  }
`;
