import { PointFormType } from 'pages/MemberDetail/types';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import FooterButton from 'sharedComponents/FooterButton';

type Props = {
  onClick: () => void;
};

const UpdateButton = ({ onClick }: Props) => {
  const {
    watch,
    formState: { isValid },
  } = useFormContext<PointFormType>();
  const { type, rewardAmount, deductAmount } = watch();
  const disabled = useMemo(() => {
    if (!isValid) return true;
    if (type === 'deduct' && !deductAmount) return true;
    if (type === 'reward' && !rewardAmount) return true;
    return false;
  }, [deductAmount, isValid, rewardAmount, type]);

  return (
    <FooterButton onClick={onClick} disabled={disabled}>
      수정
    </FooterButton>
  );
};

export default UpdateButton;
