import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import Icon from 'components/Icon';

type Props = {
  onClose: () => void;
};

const ReportSaleTotalDialog = ({ onClose }: Props) => {
  return (
    <Dialog onClose={onClose} showCloseButton isInfo title={'합계'}>
      <DialogContent>
        <StyledIcon name={'ellipse'} size={2} color="gray3" />
        <DialogDescription>신규결제 + 재결제 + 체험 + 업그레이드 + 미수금 결제 + 양도&환불 수수료 - 환불</DialogDescription>
      </DialogContent>
    </Dialog>
  );
};

export default ReportSaleTotalDialog;

const DialogContent = styled.div`
  ${theme.flex('', 'flex-start', '', 4)}
  align-self: stretch;
`;

const StyledIcon = styled(Icon)`
  padding: 10px 0;
`;
