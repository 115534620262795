import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import usePostBookingPrivate from 'hooks/service/mutations/usePostBookingPrivate';
import usePostBookingPrivateCopy, { BookingPrivateCopyParams } from 'hooks/service/mutations/usePostBookingPrivateCopy';
import { BookingCountResponse } from 'hooks/service/queries/useGetBookingCount';
import useErrorDialog from 'hooks/useErrorDialog';
import usePopup from 'hooks/usePopup';
import FailAllBooking from 'pages/Booking/components/AfterRequestBooking/FailAllBooking';
import { TICKET_INVALID_KEYS } from 'pages/Booking/constants';
import { BookingPrivateFormType } from 'pages/Booking/Private/types';
import { AllPageModeType } from 'pages/Booking/types';
import {
  convertRepeatWeekdayNum,
  formatBookingPrivateParams,
  formatConfirmBookingInfo,
  getTotalLectureCount,
} from 'pages/Booking/utils';
import { userTicketErrorMessage } from 'pages/Booking/utils/private/booking';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { InfoFlexRow, InfoFlexWrapper, InfoTypography } from 'sharedComponents/InfoTypography';
import LeftSmallButtonGroup from 'sharedComponents/LeftSmallButtonGroup';

import ValidErrorDrawer from '../SelectMembers/ValidErrorDrawer';

type Props = {
  pageMode: Extract<AllPageModeType, 'create' | 'copy'>;
};

const ConfirmBeforeBooking = ({ pageMode }: Props) => {
  const { handleSubmit, getValues, reset } = useFormContext<BookingPrivateFormType>();
  const navigate = useNavigate();
  const { setPopup } = usePopup();
  const errorDialog = useErrorDialog();

  const { repeat, repeatCustomWeekday, startDate, endDate } = getValues();

  const totalCount = getTotalLectureCount({
    repeat: convertRepeatWeekdayNum(repeat, repeatCustomWeekday),
    startDate,
    endDate,
  });
  const data = formatConfirmBookingInfo(getValues());

  const [bookingCountValidation, setBookingCountValidation] = useState<BookingCountResponse | null>(null);
  const { mutate: bookingPrivateMutate, isPending } = usePostBookingPrivate();
  const { mutate: bookingPrivateCopyMutate, isPending: isPendingCopy } = usePostBookingPrivateCopy();
  const mutationFunc = pageMode === 'copy' ? bookingPrivateCopyMutate : bookingPrivateMutate;
  const isLoading = isPending || isPendingCopy;

  const resetFormAfterError = () => {
    reset(getValues(), { keepDirtyValues: true });
  };

  const bookingPrivate = (params: BookingPrivateCopyParams) => {
    mutationFunc(params, {
      onSuccess: res => {
        if (!res.data.success) {
          setPopup(
            <FailAllBooking
              fails={res.data.fail}
              closePopup={() => {
                if (pageMode === 'create') {
                  navigate(-2);
                } else {
                  navigate('/schedule', { replace: true });
                }
              }}
              goBack={() => {
                resetFormAfterError();
                navigate(-1);
              }}
            />,
          );
        } else {
          navigate(`/booking/private/complete?from=${pageMode}`, {
            replace: true,
            state: { data: res.data },
          });
        }
      },
      onError: error => {
        if (!error.response?.data) return;
        const errorData = error.response.data;

        if (TICKET_INVALID_KEYS.includes(errorData.code)) {
          resetFormAfterError();
          const message = userTicketErrorMessage(errorData);
          errorDialog.open(message, () => navigate(-1), '돌아가기');
          return false;
        }
        if (errorData.limit) {
          setBookingCountValidation(errorData.limit);
          return false;
        }
      },
    });
  };

  const submit = () => {
    const params = formatBookingPrivateParams(getValues());
    bookingPrivate(params);
  };

  // 주/월간 초과해도 예약
  const clickPassLimit = () => {
    setBookingCountValidation(null);
    const params = {
      ...formatBookingPrivateParams(getValues()),
      is_pass_limit: true,
    };
    bookingPrivate(params);
  };

  return (
    <>
      <Container>
        <Typography size={21} weight={700}>
          총
          <Typography className="count-typography" size={21} weight={700} textColor="primary" span>
            {totalCount}
          </Typography>
          개의 수업을 <br />
          일괄 예약하시겠습니까?
        </Typography>
        <Typography className="info-title" size={15} weight={600}>
          수업정보
        </Typography>
        <InfoFlexWrapper className="info-flex-wrapper" gap={8}>
          {data.map(({ value, label }) => (
            <StyledFlexRow gap={20} key={label}>
              <InfoTypography className="label-text">{label}</InfoTypography>
              <InfoTypography weight={500} textColor="gray1">
                {value}
              </InfoTypography>
            </StyledFlexRow>
          ))}
        </InfoFlexWrapper>

        <StyledButtonGroup
          leftButton={{
            text: '수정',
            disabled: isLoading,
            onClick: () => navigate(-1),
          }}
          rightButton={{
            text: '예약',
            disabled: isLoading || !totalCount,
            onClick: handleSubmit(submit),
          }}
        />
      </Container>

      <ValidErrorDrawer
        isPending={isLoading}
        bookingCountValidation={bookingCountValidation ?? {}}
        isOpen={!!bookingCountValidation}
        onClose={() => {
          resetFormAfterError();
          setBookingCountValidation(null);
        }}
        onClick={clickPassLimit}
      />
    </>
  );
};

export default ConfirmBeforeBooking;

const Container = styled.div`
  height: 100%;
  padding: 16px 20px 0;
  ${theme.flex('column', 'flex-start', 'flex-start')};

  .count-typography {
    display: inline-block;
    margin-left: 3px;
  }

  .info-title {
    margin: 40px 0 16px;
  }

  .info-flex-wrapper {
    width: 100%;
    padding-bottom: 40px;
  }
`;

const StyledButtonGroup = styled(LeftSmallButtonGroup)`
  margin-top: auto;
  padding: 10px 0;
`;

const StyledFlexRow = styled(InfoFlexRow)`
  align-items: flex-start;

  .label-text {
    min-width: 56px;
  }
`;
