import styled from '@emotion/styled';
import dayjs from 'dayjs';
import usePatchLecturePrivate, { LecturePrivatePatchParams } from 'hooks/service/mutations/usePatchLecturePrivate';
import { BookingCountResponse } from 'hooks/service/queries/useGetBookingCount';
import { LectureDetailResponse } from 'hooks/service/queries/useGetLectureDetail';
import useErrorDialog from 'hooks/useErrorDialog';
import usePopup from 'hooks/usePopup';
import useToast from 'hooks/useToast';
import LectureOverlap from 'pages/Booking/components/AfterRequestBooking/LectureOverlap';
import BookingAvailableField from 'pages/Booking/components/AvailableTime/BookingAvailableField';
import CheckInAvailableField from 'pages/Booking/components/AvailableTime/CheckInAvailableField';
import BookingRangeDate from 'pages/Booking/components/BookingRangeDate';
import BookingRangeTime from 'pages/Booking/components/BookingRangeTime';
import ClassTitle from 'pages/Booking/components/ClassTitle';
import PastLectureUpdateDialog from 'pages/Booking/components/PastLectureUpdateDialog';
import Room from 'pages/Booking/components/Room';
import SelectStaff from 'pages/Booking/components/SelectStaff';
import { BOOKING_COMMON_FORM_TEXT, TICKET_INVALID_KEYS } from 'pages/Booking/constants';
import LimitValidationDialog from 'pages/Booking/SelectMember/LimitValidationDialog';
import { formatBookingPrivateUpdateParams } from 'pages/Booking/utils';
import { userTicketErrorMessage } from 'pages/Booking/utils/private/booking';
import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { BookingPrivateUpdateFormType } from '../../types';
import StyledPrivateForm from '../components/StyledPrivateForm';
import SubmitButton from './SubmitButton';

type Props = {
  currentLecture: LectureDetailResponse;
};
const PrivateUpdateForm = ({ currentLecture }: Props) => {
  const { handleSubmit, getValues, reset } = useFormContext<BookingPrivateUpdateFormType>();

  const [bookingCountValidation, setBookingCountValidation] = useState<BookingCountResponse | null>(null);
  const [isPastLecture, setIsPastLecture] = useState(false);

  const navigate = useNavigate();
  const { setToast } = useToast();
  const { setPopup } = usePopup();
  const errorDialog = useErrorDialog();
  const { mutate: updatePrivateMutate, isPending } = usePatchLecturePrivate(currentLecture.id);

  const resetFormAfterError = useCallback(() => {
    reset(getValues(), { keepDefaultValues: true });
  }, [getValues, reset]);

  const updatePrivate = useCallback(
    (params: LecturePrivatePatchParams) => {
      updatePrivateMutate(params, {
        onSuccess: () => {
          navigate(-1);
          setToast({ type: 'success', message: BOOKING_COMMON_FORM_TEXT.successMessage.update });
          setPopup(null);
        },
        onError: error => {
          if (!error.response?.data) return;
          const errorData = error.response.data;

          if (TICKET_INVALID_KEYS.includes(errorData.code)) {
            resetFormAfterError();
            errorDialog.open(userTicketErrorMessage(errorData));
            return false;
          }
          if (errorData.limit) {
            setBookingCountValidation(errorData.limit);
            return false;
          }
          if (errorData.overlap) {
            setPopup(
              <LectureOverlap
                fails={errorData.overlap}
                onSubmit={() => updatePrivate({ ...params, is_pass_limit: true, is_force: true })}
                goBack={resetFormAfterError}
              />,
            );
            return false;
          }
        },
      });
    },
    [errorDialog, navigate, resetFormAfterError, setPopup, setToast, updatePrivateMutate],
  );

  // 1. 주/월간 초과해도 수정
  const clickPassLimit = useCallback(() => {
    const params = {
      ...formatBookingPrivateUpdateParams(getValues()),
      is_pass_limit: true,
    };
    updatePrivate(params);
    setBookingCountValidation(null);
  }, [getValues, updatePrivate]);

  const submit = (values: BookingPrivateUpdateFormType) => {
    if (dayjs(currentLecture.start_on).isBefore()) {
      setIsPastLecture(true);
      return;
    }
    const params = formatBookingPrivateUpdateParams(values);
    updatePrivate(params);
  };

  return (
    <>
      <StyleForm onSubmit={handleSubmit(submit)} showEnterField={currentLecture.policy.is_enter}>
        <ClassTitle />
        <SelectStaff lectureStaff={currentLecture.staff} />
        <BookingRangeDate isRange={false} />
        <BookingRangeTime pageMode="update" />
        <Room />
        <BookingAvailableField />
        {!!currentLecture.policy.is_enter && <CheckInAvailableField />}
        <SubmitButton />
      </StyleForm>

      {bookingCountValidation && (
        <LimitValidationDialog
          isPending={isPending}
          bookingCountValidation={bookingCountValidation}
          onClickBooking={clickPassLimit}
          onClose={() => {
            resetFormAfterError();
            setBookingCountValidation(null);
          }}
        />
      )}
      {isPastLecture && <PastLectureUpdateDialog onClose={() => setIsPastLecture(false)} />}
    </>
  );
};

export default PrivateUpdateForm;

const StyleForm = styled(StyledPrivateForm)<{ showEnterField?: boolean }>`
  .booking-cancel-available-button {
    ${({ showEnterField }) => !showEnterField && `border: none;`}
  }
`;
