import { AttachmentType } from 'api/modules/attachment';

import { recordErrorWithSentry } from './sentry';
/**
 * 이미지 URL string 생성
 * @return 예) https://img.studio.kr/attachment/name.png
 */

const getImageUrl = (image: AttachmentType['path'] | null, size = '148x148') => {
  try {
    if (!image) return undefined;
    const [width, height] = size.split('x');
    return `${image.path}/${image.name}.${image.extension}?w=${width}&h=${height}&f=webp&q=50`;
  } catch (error) {
    recordErrorWithSentry(error);
    console.error(error);
  }
};

export default getImageUrl;
