import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import { BOOKING_COMMON_FORM_TEXT } from 'pages/Booking/constants';
import { BookingCommonFormType } from 'pages/Booking/types';
import { useFormContext, useWatch } from 'react-hook-form';

type Props = {
  onClick?: () => void;
  resetTarget?: 'room' | 'member' | 'both';
};

const ResetConfirmDialog = ({ onClick, resetTarget = 'room' }: Props) => {
  const { control, setValue } = useFormContext<BookingCommonFormType>();
  const resetChangeTarget = useWatch({ control, name: 'resetChangeTarget' });
  const roomId = useWatch({ control, name: 'roomId' });
  const target = resetChangeTarget ? BOOKING_COMMON_FORM_TEXT.reset.changeTarget[resetChangeTarget] : '';

  const close = () => {
    setValue('resetChangeTarget', undefined, { shouldDirty: true });
  };

  return (
    <>
      {roomId === BOOKING_COMMON_FORM_TEXT.room.notSelected.value && resetChangeTarget && (
        <Dialog isError onClose={close} positiveAction={{ text: '확인', onClick: onClick ? onClick : close }}>
          <DialogDescription>
            {target} 변경되어 {BOOKING_COMMON_FORM_TEXT.reset.resetTarget[resetTarget]}이
            <br />
            초기화 되었습니다.
          </DialogDescription>
        </Dialog>
      )}
    </>
  );
};

export default ResetConfirmDialog;
