import { useSuspenseQuery } from '@tanstack/react-query';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';
import { LectureRuleType } from './useGetLectureCourse';

const useGetLecturePolicy = () => {
  const { queryFn } = useQueryFnWithCommonParams<LecturePolicyResponse>({
    url: `/api/lecture/policy`,
  });

  return useSuspenseQuery({
    queryKey: [`lecture/policy`],
    queryFn,
    select: data => data.data,
    gcTime: 0,
  });
};

export default useGetLecturePolicy;

export type LecturePolicyResponse = {
  /** 체크인 사용여부 */
  is_enter: boolean;
  /** 자동 폐강 시간 설정값 (ex/ 60으로 반환되는 경우 수업 1시간 전 폐강) */
  autoclose_deadline: number | null;
  /** 예약대기가 자동으로 예약되는 시간 설정값 */
  booking_auto_shift_deadline: number | null;
  /** 당일 예약 변경 가능 시간 설정값 */
  daily_booking_change_deadline: number | null;
  /** 예약 대기 가능 시설 여부 (주간 예약 대기 가능 횟수 제한) */
  weekly_waiting_limit: number;
  /**
   * 체크인 가능 시작/종료 시간 설정값
   */
  enter_start_deadline: number | null;
  enter_end_deadline: number | null;
  /** 그룹 수업 운영정보 설정 */
  /**
   * rule_type이 R, RR 일 때,
   * 예약 취소 가능 시작/종료 설정값
   */
  group_booking_cancel_rule_type: LectureRuleType;
  group_booking_cancel_startline: number;
  group_booking_cancel_deadline: number;
  /**
   * rule_type이 A, AR 일 때,
   * 예약 취소 가능 시작일/종료일/시작시작/종료시간
   */
  group_booking_cancel_start_days: number;
  group_booking_cancel_end_days: number;
  group_booking_cancel_start_time: number;
  group_booking_cancel_end_time: number;
  /**
   * rule_type이 R, RR 일 때,
   * 예약 가능 시작/종료 설정값
   */
  group_booking_rule_type: LectureRuleType;
  group_booking_startline: number;
  group_booking_deadline: number;
  /**
   * rule_type이 A, AR 일 때,
   * 예약 가능 시작일/종료일/시작시작/종료시간
   */
  group_booking_start_days: number;
  group_booking_end_days: number;
  group_booking_start_time: number;
  group_booking_end_time: number;

  /** 프라이빗 수업 운영정보 설정 */
  /**
   * rule_type이 R, RR 일 때,
   * 예약 취소 가능 시작/종료 설정값
   */
  private_booking_cancel_rule_type: LectureRuleType;
  private_booking_cancel_startline: number;
  private_booking_cancel_deadline: number;
  /**
   * rule_type이 A, AR 일 때,
   * 예약 취소 가능 시작일/종료일/시작시작/종료시간
   */
  private_booking_cancel_start_days: number;
  private_booking_cancel_end_days: number;
  private_booking_cancel_start_time: number;
  private_booking_cancel_end_time: number;
  /**
   * rule_type이 R, RR 일 때,
   * 예약 가능 시작/종료 설정값
   */
  private_booking_rule_type: LectureRuleType;
  private_booking_startline: number;
  private_booking_deadline: number;
  /**
   * rule_type이 A, AR 일 때,
   * 예약 가능 시작일/종료일/시작시작/종료시간
   */
  private_booking_start_days: number;
  private_booking_end_days: number;
  private_booking_start_time: number;
  private_booking_end_time: number;
};
