import 'dayjs/locale/ko';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  Eventcalendar,
  localeKo,
  MbscCalendarEventData,
  MbscCellClickEvent,
  MbscEventcalendarView,
  MbscEventClickEvent,
  MbscPageChangeEvent,
  MbscPageLoadedEvent,
  setOptions,
} from '@mobiscroll/react';
import { useDrag } from '@use-gesture/react';
import { theme } from 'assets/styles';
import { Z_INDEX } from 'constants/zIndex';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import useGetAllScheduleAllDayEtcSchedule from 'hooks/service/queries/useGetAllScheduleAllDayEtcSchedule';
import useGetAllScheduleAllDayEtcScheduleTime from 'hooks/service/queries/useGetAllScheduleAllDayEtcScheduleTime';
import useGetAllScheduleCounsel from 'hooks/service/queries/useGetAllScheduleCounsel';
import useGetAllScheduleEtcSchedule from 'hooks/service/queries/useGetAllScheduleEtcSchedule';
import useGetAllScheduleLecture from 'hooks/service/queries/useGetAllScheduleLecture';
import useGetHolidayTarget, { HolidayTargetResponse } from 'hooks/service/queries/useGetHolidayTarget';
import useScrollContentAtTop from 'hooks/useScrollContentAtTop';
import useScrollRestoration from 'hooks/useScrollRestoration';
import useStatusBarColor from 'hooks/useStatusBarColor';
import { isEqual } from 'lodash';
import { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { calendarSettingsAtom } from 'recoil/calendarSettings';
import { studioIdAtom } from 'recoil/common';
import { isPageEndAtom, showBottomNavAtom } from 'recoil/mainLayout';
import {
  calendarViewTypeAtom,
  currentDateAtom,
  currentPeriodAtom,
  headerDatePickerOpenAtom,
  selectedFiltersAtom,
} from 'recoil/schedule';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import CenterLineLoading from 'sharedComponents/CenterLineLoading';
import filters from 'utils/filters';
import { Color } from 'utils/getColor';

import { EVENT_CARD_TYPE } from '../constants';
import { CalendarViewType, LectureParamsType } from '../types';
import { allDayEtcScheduleDataset, counselDataset, etcScheduleDataset, lectureDataset } from '../utils/calendarDataset';
import getEventUrl from '../utils/getEventUrl';
import getParams from '../utils/getParams';
import CustomCalendarHeader from './CustomCalendarHeader';
import CalendarAgendaDrawer from './drawer/CalendarAgendaDrawer';
import CreateScheduleDrawer from './drawer/CreateScheduleDrawer';
import MonthEventCard from './eventCard/MonthEventCard';
import ScheduleEventCard from './eventCard/ScheduleEventCard';
import TodayButton from './TodayButton';

dayjs.locale('ko');
dayjs.extend(customParseFormat);

setOptions({ locale: localeKo });

const ScheduleCalendar = () => {
  useScrollRestoration();

  /**
   * mobiscroll 내부 클래스를 매칭해야 하는데, 직접 삽입할 수 없어 아래 useEffect로 삽입
   * ref는 자동으로 매칭되는 readonly인데, 직접 넣으려고 해서 unknown 추가
   */
  const stickyRef = useRef<HTMLDivElement | unknown>(null);
  useEffect(() => {
    const target = document.querySelector('.mbsc-calendar-wrapper');
    if (target) {
      stickyRef.current = target;
    }
  }, []);
  const { isAtTop } = useScrollContentAtTop({ stickyRef: stickyRef as RefObject<HTMLDivElement> });
  useStatusBarColor(isAtTop ? theme.color.white : 'linear');

  const navigate = useNavigate();

  const calendarSwipeRef = useRef(null);

  const [isCreateDrawerOpen, setIsCreateDrawerOpen] = useState(false);
  const [isAgendaDrawerOpen, setIsAgendaDrawerOpen] = useState(false);

  const studioId = useRecoilValue(studioIdAtom);
  const calendarViewType = useRecoilValue(calendarViewTypeAtom);
  const calendarSettings = useRecoilValue(calendarSettingsAtom);
  const { schedules, staffs } = useRecoilValue(selectedFiltersAtom);
  /** 현재 선택된 날짜 */
  const [currentDate, setCurrentDate] = useRecoilState(currentDateAtom);
  /** 실제 요청 기간 */
  const [currentPeriod, setCurrentPeriod] = useRecoilState(currentPeriodAtom);
  const [isDatePickerOpen, setIsDatePickerOpen] = useRecoilState(headerDatePickerOpenAtom);
  const showBottomNav = useRecoilValue(showBottomNavAtom);
  const isPageEnd = useRecoilValue(isPageEndAtom);

  useEffect(() => {
    if (calendarViewType !== 'month') {
      document.getElementById('scrollableTarget')?.scrollTo({ top: 0 });
    }
  }, [calendarViewType]);

  const isDay = useMemo(() => calendarViewType === 'day', [calendarViewType]);
  const isWeek = useMemo(() => calendarViewType === 'week', [calendarViewType]);
  const isMonth = useMemo(() => calendarViewType === 'month', [calendarViewType]);

  const commonParams = useMemo(() => {
    return {
      start_date: currentPeriod && filters.dateDash(currentPeriod.startDate),
      end_date: currentPeriod && filters.dateDash(currentPeriod.endDate),
      staff_ids: staffs.includes('all') ? [] : (staffs as number[]),
      studioId,
    };
  }, [currentPeriod, staffs, studioId]);

  /** 일정 이벤트 api paramsSet */
  const lectureParams = useMemo(() => getParams('lecture', schedules, !!currentPeriod), [schedules, currentPeriod]);
  const counselParams = useMemo(() => getParams('counsel', schedules, !!currentPeriod), [schedules, currentPeriod]);
  const etcScheduleParams = useMemo(
    () => getParams('etcSchedule', schedules, !!currentPeriod, calendarSettings.etcScheduleHide),
    [schedules, currentPeriod, calendarSettings.etcScheduleHide],
  );

  const { data: lectureEvents = [], isFetching: isFetching1 } = useGetAllScheduleLecture({
    ...commonParams,
    ...(lectureParams as LectureParamsType),
  });
  const { data: counselEvents = [], isFetching: isFetching2 } = useGetAllScheduleCounsel({ ...commonParams, ...counselParams });
  const { data: etcScheduleEvents = [], isFetching: isFetching3 } = useGetAllScheduleEtcSchedule({
    ...commonParams,
    ...etcScheduleParams,
  });
  const { data: allDayEtcScheduleEvents = [], isFetching: isFetching4 } = useGetAllScheduleAllDayEtcSchedule({
    ...commonParams,
    ...etcScheduleParams,
  });
  const { data: allDayEtcScheduleTimeEvents = [], isFetching: isFetching5 } = useGetAllScheduleAllDayEtcScheduleTime({
    ...commonParams,
    ...etcScheduleParams,
  });

  const { data: holidayTargets = [] } = useGetHolidayTarget({
    start_date: currentPeriod && filters.dateDash(currentPeriod.startDate),
    end_date: currentPeriod && filters.dateDash(currentPeriod.endDate),
    enabled: !!currentPeriod,
  });

  useEffect(() => {
    /** 주간, 월간 캘린더 휴일 텍스트 색 변경, mobiscroll 내부 콘텐츠라 css에서는 불가능해서 javascript로 진행 */
    if (!isDay) {
      const currentTarget = isWeek ? 'mbsc-schedule-header-day' : 'mbsc-calendar-day-text';
      const targets = document.getElementsByClassName(currentTarget);
      if (targets?.length) {
        for (const target of targets) {
          const element = target as HTMLElement;

          const changeColor = (color: Color, important?: 'important') => {
            element.style.setProperty('color', theme.color[color], important);
          };

          if (isWeek) {
            const sliceTargets = holidayTargets.map(holiday => {
              const sliceDate = holiday.slice(-2);
              return sliceDate[0] === '0' ? sliceDate.slice(-1) : sliceDate;
            });
            if (sliceTargets.includes(target.textContent || '')) {
              /** 휴일 텍스트 색 변경 */
              changeColor('secondary3', 'important');
            } else {
              changeColor('gray2');
            }
          } else if (isMonth) {
            /** 월간의 경우, 일자만 있는 mobiscroll에서 aria-label을 가지고와 포맷 형태의 날짜로 변경 */
            const parsedDate = dayjs(target.getAttribute('aria-label'), 'dddd, MM월 D, YYYY', 'ko').format('YYYY-MM-DD');
            if (holidayTargets.includes(parsedDate || '')) {
              /** 휴일 텍스트 색 변경 */
              changeColor('secondary3', 'important');
            } else {
              changeColor('gray2');
            }
          }
        }
      }
    }
  }, [isDay, isWeek, isMonth, holidayTargets]);

  /** 캘린더 이벤트 삽입을 위한 데이터 가공(필수) */
  const lectureCalendarData = useMemo(() => lectureDataset(lectureEvents), [lectureEvents]);
  const counselCalendarData = useMemo(() => counselDataset(counselEvents), [counselEvents]);
  const etcScheduleCalendarData = useMemo(() => etcScheduleDataset(etcScheduleEvents), [etcScheduleEvents]);
  /** 종일 기타 일정 중, 반복일정 설정되지 않은 코스 일정 */
  const allDayEtcScheduleCalendarData = useMemo(
    () => allDayEtcScheduleDataset(allDayEtcScheduleEvents),
    [allDayEtcScheduleEvents],
  );
  /** 종일 기타 일정 중, 반복일정 설정된 개별 일정 */
  const allDayEtcScheduleTimeCalendarData = useMemo(
    () => allDayEtcScheduleDataset(allDayEtcScheduleTimeEvents),
    [allDayEtcScheduleTimeEvents],
  );

  const allCalendarData = useMemo(() => {
    return [
      ...lectureCalendarData,
      ...counselCalendarData,
      ...etcScheduleCalendarData,
      ...allDayEtcScheduleCalendarData,
      ...allDayEtcScheduleTimeCalendarData,
    ];
  }, [
    lectureCalendarData,
    counselCalendarData,
    etcScheduleCalendarData,
    allDayEtcScheduleCalendarData,
    allDayEtcScheduleTimeCalendarData,
  ]);

  const agendaData = useMemo(() => {
    if (isMonth) return allCalendarData;
    return [...etcScheduleCalendarData, ...allDayEtcScheduleCalendarData, ...allDayEtcScheduleTimeCalendarData].filter(
      data => data.allDay,
    );
  }, [isMonth, allCalendarData, etcScheduleCalendarData, allDayEtcScheduleCalendarData, allDayEtcScheduleTimeCalendarData]);

  const getMoveDate = (day: number): Date => {
    const date = new Date(currentDate);
    return new Date(date.setDate(date.getDate() + day));
  };

  /** 캘린더 Swipe 이동을 위한 라이브러리 적용 함수 */
  const gestureBind = useDrag(({ last: isLeave, movement: [currentX] }) => {
    /** 월간 형태는 onSelectedDateChange 이벤트에서 따로 스와이프 */
    if (isMonth || !isLeave) return;

    if (currentX < -70 || currentX > 70) {
      const moveNumber = calendarViewType === 'day' ? 1 : 7;
      const currentMove = currentX < -70 ? moveNumber : currentX > 70 ? -moveNumber : 0;

      const nextDate = getMoveDate(currentMove);
      setCurrentDate(nextDate);
    }
  });

  const closeHeaderPicker = () => setIsDatePickerOpen(false);

  const clickEvent = ({ event }: MbscEventClickEvent) => {
    if (isDatePickerOpen) {
      closeHeaderPicker();
      return;
    }

    if (isMonth) {
      setIsAgendaDrawerOpen(true);
    } else {
      switch (event.type) {
        case EVENT_CARD_TYPE.counsel:
          navigate(`/counsel/detail/${event.id}`);
          break;
        case EVENT_CARD_TYPE.etcSchedule:
          navigate(`/schedule/etc/detail/${event.id}`);
          break;
        case EVENT_CARD_TYPE.allDayEtcSchedule: {
          const baseUrl = getEventUrl(event.isBulkAllDay);
          navigate(`${baseUrl}/${event.id}`);
          break;
        }
        default:
          navigate(`/booking/detail/${event.id}`);
      }
    }
  };

  const clickEmptyEvent = (e: { domEvent: MbscCellClickEvent['domEvent']; date: Date }) => {
    if (isDatePickerOpen) {
      closeHeaderPicker();
    } else {
      if (isMonth) {
        if (e.domEvent.target.classList[0] === 'mbsc-calendar-cell-inner') {
          setCurrentDate(e.date);
          setIsCreateDrawerOpen(true);
        } else {
          setIsAgendaDrawerOpen(true);
        }
      } else {
        setCurrentDate(e.date);
        setIsCreateDrawerOpen(true);
      }
    }
  };

  const onMonthPageSlide = ({ date }: { date: Date }) => {
    setCurrentDate(date);
  };

  const changePeriod = (firstDay: Date, lastDay: Date) => {
    const endDate = dayjs(lastDay).subtract(1, 'days').toDate();
    const period = { startDate: firstDay, endDate };

    const beforeCurrentPeriod = {
      startDate: filters.dateDash(currentPeriod?.startDate),
      endDate: filters.dateDash(currentPeriod?.endDate),
    };

    const beforePeriod = {
      startDate: dayjs(period.startDate).format('YYYY-MM-DD'),
      endDate: dayjs(period.endDate).format('YYYY-MM-DD'),
    };

    const isPeriodEqual = isEqual(beforeCurrentPeriod, beforePeriod);
    if (isPeriodEqual) return;

    requestAnimationFrame(() => setCurrentPeriod(period));
  };

  const onPageChange = ({ firstDay, lastDay, month }: MbscPageChangeEvent) => {
    changePeriod(firstDay, lastDay);

    const isBetweenDate = dayjs().isBetween(firstDay, lastDay, 'day', '[]');
    const isToday = filters.dateDash(month) === filters.dateDash(new Date());
    if (isToday && isWeek && isBetweenDate) {
      requestAnimationFrame(() => {
        setCurrentDate(new Date());
      });
    }
  };

  const onPageLoaded = ({ firstDay, lastDay }: MbscPageLoadedEvent) => {
    changePeriod(firstDay, lastDay);
  };

  const customCalendarHeader = () => {
    return (
      <ApiBoundary>
        <CustomCalendarHeader key={String(studioId)} holidayTargets={holidayTargets} />
      </ApiBoundary>
    );
  };

  /**
   * data: 캘린더 data 속성에 있는 각 이벤트의 값
   * data.original: Eventcalendar의 data 속성에 넣는 값
   */
  const customRenderScheduleEvent = ({ original, ...data }: MbscCalendarEventData) => {
    if (!original) return;
    return <ScheduleEventCard eventData={data} original={original} />;
  };

  /** 월간은 라이브러리 view 타입이 calendar라 별도 커스텀 */
  const customRenderCalendarEvent = ({ original }: MbscCalendarEventData) => {
    if (!original) return;
    return <MonthEventCard original={original} />;
  };

  const viewSettings = useMemo(() => {
    if (isMonth) return { calendar: { type: calendarViewType, popover: false } };
    return {
      schedule: {
        type: calendarViewType,
        startDay: isWeek ? calendarSettings.dayRange[0] : undefined,
        endDay: isWeek ? calendarSettings.dayRange[1] : undefined,
        startTime: isMonth ? undefined : filters.time(new Date(calendarSettings.timeRange[0])),
        endTime: isMonth ? undefined : filters.time(new Date(calendarSettings.timeRange[1])),
      },
    };
  }, [isWeek, isMonth, calendarViewType, calendarSettings]);

  const allDaysLength = useMemo(() => {
    if (isDay) return [agendaData.length];

    /** 선택된 날짜가 있는 주의 날짜 목록 */
    const startOfWeek = dayjs(currentDate).startOf('isoWeek');
    const weekDays = [startOfWeek];
    for (let i = 1; i < 7; i++) {
      weekDays.push(startOfWeek.add(i, 'day'));
    }

    /** 주의 각 날짜별 개수 */
    const weekCounts = [0, 0, 0, 0, 0, 0, 0];
    agendaData.forEach(({ start, end }) => {
      weekDays.forEach((day, index) => {
        if (day.isBetween(start, end, 'day', '[]')) {
          weekCounts[index]++;
        }
      });
    });
    return weekCounts;
  }, [isDay, agendaData, currentDate]);

  const isDayWeekAllDay = useMemo(() => {
    if (calendarSettings.etcScheduleHide) return false;
    return !!allDayEtcScheduleEvents.length || !!allDayEtcScheduleTimeEvents.length;
  }, [allDayEtcScheduleEvents.length, allDayEtcScheduleTimeEvents.length, calendarSettings.etcScheduleHide]);

  const isLoading = isFetching1 || isFetching2 || isFetching3 || isFetching4 || isFetching5;

  return (
    <>
      {isLoading && <CenterLineLoading />}

      <Container
        ref={calendarSwipeRef}
        {...gestureBind()}
        viewType={calendarViewType}
        isDayWeekAllDay={isDayWeekAllDay}
        allDaysLength={allDaysLength}
        isAtTop={isAtTop}
        holidayTargets={holidayTargets}>
        <Eventcalendar
          theme="ios"
          themeVariant="light"
          view={viewSettings as MbscEventcalendarView}
          data={allCalendarData}
          renderHeader={customCalendarHeader}
          renderScheduleEvent={customRenderScheduleEvent} // 일간, 주간 이벤트 카드
          renderLabel={customRenderCalendarEvent} // 월간 이벤트 카드
          selectedDate={currentDate}
          onEventClick={clickEvent} // 일정 이벤트 클릭
          onCellClick={clickEmptyEvent} // 빈 부분 클릭
          onSelectedDateChange={onMonthPageSlide} // 월간 페이지 변경을 위한 함수. onPageChange와 받는 이벤트가 다름
          onPageChange={onPageChange} // 월간, 주간 이동 시 실제 요청기간을 위한 상태 변경
          onPageLoaded={onPageLoaded}
          firstDay={!isDay ? 1 : 0} // 요일 시작을 선택할 수 있는 옵션
        />

        {/* Calendar가 렌더되기 전 화면 자체가 깜빡여 보이는 현상 방지 */}
        <CalendarBackground />

        {!isPageEnd && showBottomNav && <TodayButton currentDate={currentDate} setCurrentDate={setCurrentDate} />}

        <CreateScheduleDrawer isOpen={isCreateDrawerOpen} onClose={() => setIsCreateDrawerOpen(false)} />
        <CalendarAgendaDrawer
          isOpen={isAgendaDrawerOpen}
          onClose={() => setIsAgendaDrawerOpen(false)}
          allCalendarData={agendaData}
          currentDate={currentDate}
        />
      </Container>
    </>
  );
};

export const Container = styled.div<{
  viewType: CalendarViewType;
  isDayWeekAllDay?: boolean;
  allDaysLength: number[];
  isAtTop?: boolean;
  holidayTargets?: HolidayTargetResponse;
}>(
  /** 기본 세팅 + 일간(day) */
  ({ viewType, isDayWeekAllDay, allDaysLength, isAtTop }) => css`
    /** .mbsc-schedule-grid-scroll 클래스와 touch-action이 중복 적용되어 에러가 발생. 이를 해결하기 위해 넣음 */
    touch-action: none;

    /** 타임라인 인디케이터 요일 포인터 미노출 */
    .mbsc-schedule-time-indicator {
      display: none;
    }

    .mbsc-schedule-grid-scroll {
      ${viewType === 'day' && 'padding-right: 20px'};
    }

    .mbsc-schedule-event-custom {
      &:not(.mbsc-schedule-event-all-day) {
        padding-bottom: 0.6px !important;
      }
    }

    /** 기타일정 종일 데이터가 없으면 해당 영역 미노출 */
    .header-wrapper {
      ${viewType === 'day' && !isDayWeekAllDay && 'margin-bottom: 7px'};
    }
    .mbsc-schedule-all-day-cont {
      ${!isDayWeekAllDay && 'display: none'};
    }

    .mbsc-schedule-all-day-item {
      padding: 0;
    }

    .mbsc-ios {
      &.mbsc-eventcalendar,
      .mbsc-calendar-wrapper {
        border-top-right-radius: 24px;
        border-top-left-radius: 24px;
      }

      /** 컨테이너 */
      &.mbsc-eventcalendar {
        overflow: visible;
      }

      .mbsc-calendar-wrapper {
        position: sticky;
        top: 0;
        z-index: ${Z_INDEX.stickyTab};
      }

      /** 컨테이너 헤더 */
      .mbsc-calendar-header {
        border-top-right-radius: 24px;
        border-top-left-radius: 24px;
        z-index: 3;
        background: ${theme.color.white};
        transition: border-radius 0.2s ease;
        ${isAtTop &&
        `
          border-top-right-radius: 0px;
          border-top-left-radius: 0px;
        `}

        .mbsc-calendar-controls {
          padding: 0;
        }

        .header-wrapper {
          padding-bottom: ${viewType === 'day' ? '14px' : '8px'};
        }
      }

      /** 캘린더 헤더 */
      .mbsc-schedule-header {
        display: none;
        border-bottom: none;
        background-color: ${theme.color.white};

        /** 요일/날짜 그룹 */
        .mbsc-schedule-header-item {
          ${theme.flex('column', 'center', 'center', 6)};
          width: 26px;
          line-height: inherit;

          .mbsc-schedule-header-dayname {
            line-height: 12px;
            font-size: 1.2rem;
            color: ${theme.color.gray3};
          }

          .mbsc-schedule-header-day {
            ${theme.flex()};
            font-size: 1.4rem;
            color: ${theme.color.gray2};

            &.mbsc-selected {
              width: 24px;
              height: 24px;
              font-weight: 700;
              color: ${theme.color.primary} !important;
              background-color: rgba(108, 133, 251, 0.2);
            }
          }
        }
      }

      .mbsc-schedule-wrapper {
        overflow: visible;
      }

      .mbsc-schedule-all-day-cont {
        position: sticky;
        top: 60px;
        background-color: ${theme.color.white};
        z-index: ${Z_INDEX.stickyTab};
      }

      /** 종일 일정 */
      .mbsc-schedule-all-day {
        ${theme.flex('row', 'flex-start', 'center')};

        .mbsc-schedule-all-day-group-wrapper {
          overflow-y: scroll;

          /** 종일 일정 개수에 따라 테이블 높이 조정 */
          .mbsc-schedule-resource-group {
            ${viewType === 'day' && `height: ${allDaysLength[0] > 2 ? 78 : allDaysLength[0] === 2 ? 63 : 35}px !important`};
          }

          /** 종일 일정이 3개 이상일 때는 2번째 요소까지만 노출  */
          .mbsc-schedule-event-all-day {
            margin-bottom: 4px;
            padding: 0 20px 0 0;
            height: 24px;

            > div {
              ${theme.flex('row', 'center', 'flex-start')};
              padding: 0 8px;
            }
          }
        }
      }

      .mbsc-schedule-event {
        &:not(.mbsc-schedule-event-all-day) {
          min-height: inherit;
        }
      }

      .mbsc-schedule-event-start {
        padding: 0;
      }

      .mbsc-flex-col.mbsc-flex-1-1.mbsc-schedule-grid-scroll.mbsc-ios {
        ${viewType === 'day' && 'padding-top: 25px !important'};
      }

      .mbsc-flex-col.mbsc-flex-1-0.mbsc-schedule-time-wrapper.mbsc-ios.mbsc-ltr,
      .mbsc-flex-1-1.mbsc-schedule-time.mbsc-ios.mbsc-ltr {
        max-height: 50px !important;
      }

      .mbsc-schedule-time-wrapper:not(.mbsc-schedule-time-wrapper-end) {
        &.mbsc-flex-none {
          ${viewType === 'day' && 'top: 12.5px'};
        }
      }

      .mbsc-schedule-time-wrapper-end {
        height: 50px !important;
      }

      .mbsc-schedule-grid-wrapper {
        margin-top: ${isDayWeekAllDay ? '19px' : 0};
      }

      /** 타임라인 시간 영역 */
      .mbsc-schedule-wrapper {
        height: 100%;

        .mbsc-schedule-time {
          ${theme.flex()};
          top: 0px;

          &.mbsc-schedule-time-end {
            display: none;
          }
        }

        .mbsc-schedule-column {
          padding: 0 !important;
        }

        .mbsc-schedule-time-cont-inner {
          padding: 0;
        }

        .mbsc-schedule-all-day-text,
        .mbsc-schedule-time {
          font-size: 1.2rem;
          text-align: center;
          color: ${theme.color.gray2};
        }

        .mbsc-schedule-time,
        .mbsc-schedule-time-col {
          width: 60px;
        }

        .mbsc-schedule-time-col {
          .mbsc-schedule-all-day-text {
            font-weight: 500;
          }
        }

        .mbsc-schedule-all-day-wrapper {
          max-height: max-content;
          overflow-y: visible;
        }

        /** 타임라인 격자 스타일 */
        .mbsc-schedule-all-day-wrapper,
        .mbsc-schedule-item {
          border: 0;
          border-bottom: 1px solid ${theme.color.gray6} !important;
        }

        .mbsc-schedule-item.mbsc-flex-1-0.mbsc-ios {
          &:nth-of-type(2) {
            border-top: 1px solid ${theme.color.gray6} !important;
          }
        }
      }

      /** 좌우 스와이프를 위해서 삽입 */
      .mbsc-flex-col.mbsc-schedule-grid-scroll {
        touch-action: pan-y;
        overflow-y: visible;
      }

      .mbsc-schedule-events.mbsc-ltr {
        right: 0;
      }
    }
  `,

  /** 월간(month) 형태 스타일 */
  ({ viewType }) =>
    viewType === 'month' &&
    css`
      .mbsc-ios {
        .mbsc-calendar-cell-text {
          width: 20px;
          height: 20px;
          border: 0;
          line-height: 1.8;
        }

        /** 월간 형태일 때, 다른 날짜 선택 시 스타일 적용 */
        &.mbsc-selected .mbsc-calendar-cell-text {
          font-weight: 700;
          color: ${theme.color.primary} !important;
          background-color: rgba(108, 133, 251, 0.2);
        }

        .mbsc-calendar-wrapper {
          border: 0;

          .mbsc-calendar-body-inner {
            height: calc(100vh - 171px); // 각 헤더 + 하단 내비게이션 높이를 계산

            /** 캘린더 테이블 컨테이너 */
            .mbsc-calendar-table {
              .mbsc-calendar-week-days {
                margin-top: 7px;
                padding: 0 10px;

                /** 요일 텍스트 */
                .mbsc-calendar-week-day {
                  font-size: 1.2rem;
                  color: ${theme.color.gray3};
                }
              }

              /** 각 cell의 날짜(숫자) 텍스트 */
              .mbsc-calendar-week-day,
              .mbsc-calendar-cell-text {
                font-size: 1.2rem;
                color: ${theme.color.gray2};

                &.mbsc-calendar-today {
                  font-weight: 700;
                  border-color: ${theme.color.white};
                  color: ${theme.color.primary} !important;
                }
              }

              .mbsc-calendar-row {
                padding: 0 10px;

                &:nth-of-type(2) {
                  .mbsc-calendar-cell {
                    border: 0;
                  }
                }

                .mbsc-calendar-cell {
                  border-color: ${theme.color.gray6};
                }

                .mbsc-calendar-cell-inner {
                  /** 화면이 커지면 노출되는 불필요한 월간 텍스트 */
                  .mbsc-calendar-month-name {
                    display: none;
                  }

                  .mbsc-calendar-text.mbsc-calendar-custom-label {
                    height: 14px;
                  }

                  /** 더보기 항목 active 색 고정 */
                  .mbsc-calendar-text-more {
                    &::before {
                      background-color: white;
                    }

                    .mbsc-calendar-label-text {
                      /** 보이지 안게 처리하고, 커스텀 before 표시 */
                      color: #00000000;
                      font-size: 0;

                      &::before {
                        content: '...';
                        font-size: 1.2rem;
                        font-weight: bold;
                        letter-spacing: 2px;
                        line-height: 1;
                        color: ${theme.color.gray3};
                      }
                    }
                  }
                }

                .mbsc-calendar-day:after {
                  border: 0;
                }
              }
            }
          }
        }

        .mbsc-calendar-cell {
          border-top-width: 1px !important;
        }
      }
    `,

  /** 주간(week) 형태 스타일 */
  ({ viewType, allDaysLength, isDayWeekAllDay }) =>
    viewType === 'week' &&
    css`
      .mbsc-ios {
        /** 헤더 sticky를 위한 속성 */
        .mbsc-schedule-all-day-cont {
          top: 113px;
        }

        .mbsc-schedule-header {
          position: sticky;
          top: 54px;
          display: inherit;
          padding-top: 6px;
          padding-bottom: 9px;
          z-index: ${Z_INDEX.stickyTab + 1}; // 종일 헤더보다 앞에 나오게 하기 위한 용도
          border-bottom: ${!isDayWeekAllDay && `1px solid ${theme.color.gray6}`};

          .mbsc-schedule-header-item {
            padding: 0 9px;
            height: 44px;
          }
        }

        .mbsc-schedule-all-day-group-wrapper {
          height: ${allDaysLength.filter(length => length > 2).length
            ? '58px'
            : allDaysLength.filter(length => length === 2).length
              ? '53px'
              : '29px'} !important;
          border-left: 1px solid ${theme.color.gray6};

          .mbsc-schedule-event-all-day {
            margin: 0 !important;
            margin-bottom: 4px !important;
            padding: 0 !important;
            height: 20px !important;
          }
        }

        /** 요일 헤더 border 제거 */
        .mbsc-schedule-all-day-item {
          padding: 0;

          &:nth-of-type(n + 2) {
            border-left: 1px solid ${theme.color.gray6} !important;
          }

          &::after {
            display: none;
          }
        }

        /** 타임라인 세로 border 제거 */
        .mbsc-schedule-col-width {
          border: 0;
          border-color: ${theme.color.gray6};
        }

        .mbsc-schedule-events {
          position: static;

          .mbsc-schedule-event {
            padding: 0;
          }
        }

        .mbsc-schedule-time {
          top: 0;
          height: 56px !important;
        }

        .mbsc-schedule-time,
        .mbsc-schedule-column,
        .mbsc-schedule-time-cont-inner {
          padding: 0;
        }

        .mbsc-schedule-column {
          border-left: 1px solid ${theme.color.gray6} !important;
        }

        .mbsc-schedule-column-inner {
          margin-top: 25px;
        }

        .mbsc-flex-1-1.mbsc-schedule-time.mbsc-ios.mbsc-ltr {
          min-height: 50px !important;
        }

        .mbsc-schedule-time-end {
          display: none;
        }

        .mbsc-schedule-wrapper {
          padding-left: 0;
        }

        .mbsc-schedule-time,
        .mbsc-schedule-time-col {
          margin-top: 0 !important;
          width: 60px;
        }

        .mbsc-flex-col.mbsc-flex-1-0.mbsc-schedule-time-wrapper.mbsc-ios.mbsc-ltr {
          max-height: 50px !important;
        }

        .mbsc-schedule-grid-wrapper {
          margin: 0;
        }

        .mbsc-schedule-all-day-wrapper {
          padding-bottom: 0 !important;
        }

        .mbsc-schedule-all-day-text {
          padding: 3px 0;
        }

        .mbsc-schedule-fake-scroll-y {
          display: none;
        }
      }
    `,
);

const CalendarBackground = styled.div`
  position: absolute;
  top: 56px;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${theme.color.white};
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
`;

export default ScheduleCalendar;
