import Typography from 'components/Typography';
import TwoLinedTitle from 'designedComponents/Drawers/DrawerTitle/TwoLinedTitle';
import DoubleButtonDrawer from 'designedComponents/Drawers/DrawerWithButton/DoubleButtonDrawer';
import useDeleteStaff from 'hooks/service/mutations/useDeleteStaff';
import useQueryString from 'hooks/useQueryString';
import useToast from 'hooks/useToast';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { targetStaffAtom } from 'recoil/staff';

type Props = {
  isDrawerOpen: boolean;
  onClose: () => void;
};

const DeleteStaffDrawer = ({ isDrawerOpen, onClose }: Props) => {
  const targetStaff = useRecoilValue(targetStaffAtom);

  const navigate = useNavigate();
  const { setSearchParams } = useQueryString();
  const { setToast } = useToast();
  const { mutate: deleteStaffMutate } = useDeleteStaff(targetStaff.id);

  const deleteStaff = () => {
    deleteStaffMutate(undefined, {
      onSettled: () => {
        onClose();
      },
      onSuccess: () => {
        navigate(-1);
        setToast({ type: 'success', message: `${targetStaff.name} 강사가 삭제되었습니다.` });
      },
      onError: error => {
        if (error.response?.data.code === 10607) {
          setSearchParams({ tab: 'assignedMember' });
        }
      },
    });
  };

  return (
    <DoubleButtonDrawer
      header={<TwoLinedTitle title={[`[${targetStaff.name}] 강사를`, '삭제하시겠습니까?']} />}
      isOpen={isDrawerOpen}
      onClose={onClose}
      leftButton={{ text: '취소', variant: 'outlined', onClick: onClose }}
      rightButton={{ text: '삭제', onClick: deleteStaff }}>
      <Typography size={15}>
        담당강사로 지정된 그룹 수업이 있는 경우,
        <br />
        강사를 삭제할 수 없습니다. (프라이빗 수업 제외)
      </Typography>
    </DoubleButtonDrawer>
  );
};

export default DeleteStaffDrawer;
