import { color, ETC_SCHEDULE_COLOR, MATCH_REPRESENTATIVE_COLOR } from 'assets/styles';

export type Color = keyof typeof color;
type MatchColor = keyof typeof MATCH_REPRESENTATIVE_COLOR;

/**
 *  대표컬러: 서버에 있는 색상 코드와 실제 디자인 색상을 매칭해서 자동 반환해주는 유틸
 *  사용법: getColor(서버컬러 or theme.color의 키값) -> 반환해주는 값을 그대로 사용하면됨.
 *  @return {ColorType | MatchColorType}
 */
const getColor = (baseColor: Color | MatchColor) => {
  const isOriginColor = Object.prototype.hasOwnProperty.call(MATCH_REPRESENTATIVE_COLOR, baseColor);
  const isEtcColor = Object.prototype.hasOwnProperty.call(ETC_SCHEDULE_COLOR, baseColor);
  const isThemeColor = Object.prototype.hasOwnProperty.call(color, baseColor);

  if (isOriginColor) {
    return MATCH_REPRESENTATIVE_COLOR[baseColor] as Color;
  }

  if (isEtcColor) {
    return ETC_SCHEDULE_COLOR[baseColor];
  }

  if (isThemeColor) {
    return Object.keys(MATCH_REPRESENTATIVE_COLOR).find(key => MATCH_REPRESENTATIVE_COLOR[key] === baseColor);
  }

  return Object.keys(color).find(key => {
    return color[String(key) as Color] === baseColor;
  });
};

export default getColor;
