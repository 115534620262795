import React from 'react';
import { useFormContext } from 'react-hook-form';
import DialogBeforeLeave from 'sharedComponents/DialogBeforeLeave';

import { MemberFormType } from '../types';
import isDirtyMemberForm from '../utils/isDirtyMemberForm';
import isEqualUserTickets from '../utils/isEqualUserTickets';

type Props = {
  hasType: boolean;
};

const LeaveDialog = ({ hasType }: Props) => {
  const {
    watch,
    formState: { dirtyFields, isSubmitSuccessful },
  } = useFormContext<MemberFormType>();
  const { tickets, userTickets, initialUserTickets } = watch();
  const isDirty = isDirtyMemberForm(dirtyFields) || !!tickets.length || !isEqualUserTickets(userTickets, initialUserTickets);

  return <DialogBeforeLeave isBlocked={!hasType && !isSubmitSuccessful && isDirty} />;
};

export default LeaveDialog;
