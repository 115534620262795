import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

/** 내 계정정보 > 비밀번호 재설정, 이미 로그인 상태기 때문에 카카오인증없이 바로 변경됨 */
const usePostAuthChangeAuthenticatedPassword = () => {
  const { mutationFn } = useMutationFnWithCommonParams<AuthChangeAuthenticatedPasswordParams>({
    method: `post`,
    url: `/api/auth/change-authenticated-password`,
    data: {
      studio_id: undefined,
      staff_id: undefined,
    },
  });

  return useCustomMutation({
    mutationFn,
  });
};

export default usePostAuthChangeAuthenticatedPassword;

type AuthChangeAuthenticatedPasswordParams = {
  password: string;
  password_confirmation: string;
};
