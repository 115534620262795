import styled from '@emotion/styled';
import Divider from 'components/Divider';
import { get } from 'lodash';
import { Fragment } from 'react';
import ButtonFilter from 'sharedComponents/Filters/ButtonFilter';
import { FilterOptionsProps } from 'sharedComponents/Filters/types';

import AttendanceCountFilter from './AttendanceCountFilter';
import LastAttendanceDayFilter from './LastAttendanceDayFilter';
import StaffFilter from './StaffFilter';
import TicketPeriodFilter from './TicketPeriodFilter';

const AllMemberWholeFilterOptions = ({ filters, filterChanged, changeOption }: FilterOptionsProps) => {
  const tagFilter = filters.find(filter => filter.key === 'tag');
  const ticketTypeFilter = filters.find(filter => filter.key === 'ticket_type');
  const staffFilter = filters.find(filter => filter.key === 'staff');
  const userGradeIdFilter = filters.find(filter => filter.key === 'user_grade_id');
  const ticketFilter = filters.find(filter => filter.key === 'ticket_remaining');
  const lastAttendanceDayFilter = filters.find(filter => filter.key === 'last_attendance_day');
  const attendanceCountFilter = filters.find(filter => filter.key === 'attendance_count');

  return (
    <Container>
      {[tagFilter, ticketTypeFilter].map(filter =>
        filter ? (
          <Fragment key={filter.key}>
            <ButtonFilter currentFilter={filter} selectedOption={get(filterChanged, filter.key)} onClick={changeOption} />
            <Divider />
          </Fragment>
        ) : null,
      )}
      {userGradeIdFilter ? (
        <>
          <ButtonFilter
            key={userGradeIdFilter.key}
            currentFilter={userGradeIdFilter}
            selectedOption={get(filterChanged, 'user_grade_id')}
            onClick={changeOption}
          />
          <Divider />
        </>
      ) : null}
      {staffFilter ? (
        <>
          <StaffFilter currentFilter={staffFilter} selectedOption={get(filterChanged, 'staff')} onClick={changeOption} />
          <Divider />
        </>
      ) : null}
      {ticketFilter ? (
        <TicketPeriodFilter
          currentFilter={ticketFilter}
          selectedOption={get(filterChanged, 'ticket_remaining')}
          onClick={changeOption}
        />
      ) : null}
      <Divider />
      {lastAttendanceDayFilter ? (
        <LastAttendanceDayFilter
          currentFilter={lastAttendanceDayFilter}
          selectedOption={get(filterChanged, 'last_attendance_day')}
          onClick={changeOption}
        />
      ) : null}
      <Divider />
      {attendanceCountFilter ? (
        <AttendanceCountFilter
          currentFilter={attendanceCountFilter}
          selectedOption={get(filterChanged, 'attendance_count')}
          onClick={changeOption}
        />
      ) : null}
    </Container>
  );
};

export default AllMemberWholeFilterOptions;

const Container = styled.div`
  > div:first-of-type {
    padding-top: 16px;
  }
`;
