import { TabDataElementType } from 'components/Tabs';
import useQueryString from 'hooks/useQueryString';
import { useRecoilState } from 'recoil';
import { reportSaleMode } from 'recoil/report/atom';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import ReportLayout from './components/ReportLayout';
import ReportLectureList from './components/ReportLecture/ReportLectureList';
import ReportPoint from './components/ReportPoint/ReportPoint';
import ReportList from './components/ReportSale/ReportSaleList';
import ReportSummaryList from './components/ReportSale/ReportSaleSummaryList';
import ReportTabs from './components/ReportTabs';
import ReportUnpaidList from './components/ReportUnpaid/ReportUnpaidList';
import { REPORT_TABS } from './constants';

const MoreDetailReport = () => {
  const { getSearchParams, setSearchParams } = useQueryString();
  const [saleMode, setSaleMode] = useRecoilState(reportSaleMode);

  const tab = getSearchParams('tab');
  const isShowSummaryList = saleMode !== 'list';
  const currentTab = REPORT_TABS.find(tabData => tabData.value === tab) || REPORT_TABS[0];

  const changeTab = (tabData: TabDataElementType) => {
    setSearchParams({ tab: tabData.value }, { replace: true });
  };

  const setIsShowSummaryList = (show: boolean) => {
    const listMode = show ? 'summary' : 'list';
    setSaleMode(listMode);
  };

  return (
    <ReportLayout
      tab={
        <ReportTabs
          isShowSummaryList={isShowSummaryList}
          currentTab={currentTab}
          changeTab={changeTab}
          setIsShowSummaryList={setIsShowSummaryList}
        />
      }>
      <ApiBoundary>
        {currentTab.value === 'sale' && <>{isShowSummaryList ? <ReportSummaryList /> : <ReportList />}</>}
        {currentTab.value === 'lecture' && <ReportLectureList />}
        {currentTab.value === 'unpaid' && <ReportUnpaidList />}
        {currentTab.value === 'point' && <ReportPoint />}
      </ApiBoundary>
    </ReportLayout>
  );
};

export default MoreDetailReport;
