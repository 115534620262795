import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import DropDown from 'components/DropDown';
import Icon from 'components/Icon';
import { PropsWithChildren, useMemo } from 'react';
import { UserTicketCardType } from 'sharedComponents/UserTicketCard/types';

import { DROPDOWN_NOT_USABLE_DATA, DROPDOWN_USABLE_DATA } from '../constants';
import { TicketDropDownValueType, UserTicketProps } from '../types';

type Props = UserTicketProps &
  Pick<UserTicketCardType, 'ticketStatus'> & {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    onClick: (value: TicketDropDownValueType['value']) => void;
  };

const TicketDropDown = ({ userTicket, ticketStatus, children, isOpen, setIsOpen, onClick }: PropsWithChildren<Props>) => {
  const data = useMemo(() => {
    const {
      payments: { unpaid_amount_total },
      ticket: { available_class_type },
    } = userTicket;
    const usable = ticketStatus === 'using' || ticketStatus === 'to_use' || ticketStatus === 'holding';

    /** 사용 가능한 수강권 중 미수금 있을 때 사용불가 처리만 노출 */
    if (usable && unpaid_amount_total) {
      return DROPDOWN_USABLE_DATA.filter(({ value }) => value === 'not_usable');
    }

    /** 대여형 상품(양도, 환불, 사용불가 처리) */
    if (usable && available_class_type === 'I') {
      return DROPDOWN_USABLE_DATA.filter(({ value }) => value !== 'upgrade');
    }

    switch (ticketStatus) {
      /** 정지중(업그레이드, 환불, 사용불가 처리) */
      case 'holding':
        return DROPDOWN_USABLE_DATA.filter(({ value }) => value !== 'transfer');
      /** 사용불가(사용재개, 삭제하기) */
      case 'not_usable':
        return DROPDOWN_NOT_USABLE_DATA;
      /** 양도, 환불(삭제하기) */
      case 'transfer':
      case 'refund':
        return DROPDOWN_NOT_USABLE_DATA.filter(({ value }) => value === 'delete');
      /** 판매 상품(환불, 삭제하기) */
      case 'sell':
        return [DROPDOWN_USABLE_DATA[2], DROPDOWN_NOT_USABLE_DATA[1]];
      /** 사용중, 사용예정 */
      default:
        return DROPDOWN_USABLE_DATA;
    }
  }, [ticketStatus, userTicket]);

  const dropdownContent = useMemo(
    () => (
      <ContentsWrapper>
        {data.map(({ value, label, icon }) => {
          const isRedColor = value === 'refund' || value === 'not_usable' || value === 'delete';
          return (
            <li key={value}>
              <Button
                fullWidth
                fontSize={15}
                fontWeight={500}
                textColor={isRedColor ? 'secondary3' : 'gray2'}
                rightIcon={<Icon size={16} {...icon} />}
                onClick={() => onClick(value)}>
                {label}
              </Button>
            </li>
          );
        })}
      </ContentsWrapper>
    ),
    [data, onClick],
  );

  return (
    <DropDown isOpen={isOpen} content={dropdownContent} position="right" bottomSpacing={8} onClose={() => setIsOpen(false)}>
      {children}
    </DropDown>
  );
};

export default TicketDropDown;

const ContentsWrapper = styled.ul`
  width: 128px;
  border-radius: 12px;
  background-color: ${theme.color.white};
  box-shadow: 1px 2px 8px 0px rgba(145, 145, 145, 0.2);

  li {
    padding: 13px 12px 13px 16px;
    :not(:last-of-type) {
      border-bottom: 1px solid ${theme.color.gray7};
    }

    button {
      height: 100%;
      justify-content: space-between;
    }
  }
`;
