import { TabDataElementType } from 'components/Tabs';
import FoldingFilterTabs from 'components/Tabs/FoldingFilterTabs';
import useGetHistoryCount from 'hooks/service/queries/useGetHistoryCount';
import { CurrentMemberIdProps, HistoryFilterFormType } from 'pages/MemberDetail/types';
import { useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { memberDetailHistoryFilterAtom } from 'recoil/MemberDetail';

const HistoryCountTab = ({ currentMemberId }: CurrentMemberIdProps) => {
  const [historyFilter, setHistoryFilter] = useRecoilState(memberDetailHistoryFilterAtom);

  const { data } = useGetHistoryCount({
    member_id: currentMemberId,
    user_ticket_id: historyFilter.ticketId === 'all' ? undefined : Number(historyFilter.ticketId),
  });

  /** 예약 상태 필터 탭 */
  const tabData: TabDataElementType[] = useMemo(() => {
    return [
      { value: 'all', label: `전체 ${data.total}` },
      { value: 'booked_list', label: `예약 ${data.booked}` },
      { value: 'attendance', label: `출석 ${data.attendance}` },
      { value: 'absence', label: `결석 ${data.absence}` },
      { value: 'noshow', label: `노쇼 ${data.noshow}` },
      { value: 'cancel', label: `취소 ${data.cancel}` },
    ];
  }, [data]);

  const changeTab = (tabData: TabDataElementType) => {
    const value = tabData.value as HistoryFilterFormType['status'];
    setHistoryFilter(prev => ({ ...prev, status: value }));
  };

  return <FoldingFilterTabs data={tabData} value={historyFilter.status} onChange={changeTab} />;
};

export default HistoryCountTab;
