import dayjs from 'dayjs';
import { EtcScheduleDetailResponse } from 'hooks/service/queries/useGetEtcScheduleEtcScheduleTime';
import { convertRepeatFormState } from 'pages/Booking/utils';
import { EtcScheduleCreateFormType } from 'pages/EtcSchedule/types';
import { StaffType } from 'types/staffTypes';
import filters from 'utils/filters';

const convertFormState = (data: EtcScheduleDetailResponse, currentStaff: StaffType): EtcScheduleCreateFormType => {
  const { title, is_all_day, is_overlap, is_private, start_on, end_on, staffs, color, etc_schedule, register } = data;

  const selectedStaffs = staffs.map(({ id, name, image }) => {
    return {
      id,
      name,
      avatar: image,
    };
  });
  const selectedStaffsWithRegister = [{ ...register, avatar: register.image }, ...selectedStaffs];

  const { repeat, repeatCustomWeekday } = convertRepeatFormState(
    etc_schedule.weekday.map(num => {
      return { weekday: num };
    }),
  );

  const repeatValue = repeat === 'custom' && !repeatCustomWeekday.length ? null : repeat;

  /**
   * 현재 종일 일정인 경우,
   * 폼 시간 defaultValues 현재 시점으로부터 가장 가까운 정각으로 startTime 세팅
   * (종일 아닌 일정으로 수정할 때를 위한 초기화)
   */
  const startTime = is_all_day ? dayjs().add(1, 'hour').startOf('hour').toDate() : dayjs(start_on).toDate();
  const endTime = is_all_day ? dayjs(startTime).add(50, 'minute').toDate() : dayjs(end_on).toDate();

  return {
    title,
    startDate: filters.dateDash(start_on),
    endDate: filters.dateDash(end_on),
    startTime,
    endTime,
    is_all_day,
    is_overlap,
    is_private: is_private ? 'close' : 'open',
    color,
    representativeColor: currentStaff.profile.representative_color,
    currentStaffId: currentStaff.id,
    selectedStaffs: selectedStaffsWithRegister,
    tempSelectedStaffs: selectedStaffsWithRegister,
    is_repeat: !!repeatValue,
    repeat: repeatValue,
    repeatCustomWeekday,
    tempRepeatCustomWeekday: repeatCustomWeekday,
  };
};

export default convertFormState;
