import styled from '@emotion/styled';
import { MATCH_REPRESENTATIVE_COLOR } from 'assets/styles';
import Divider from 'components/Divider';
import Form from 'components/Form';
import usePostStaff from 'hooks/service/mutations/usePostStaff';
import useGetRole from 'hooks/service/queries/useGetRole';
import useErrorDialog from 'hooks/useErrorDialog';
import usePopup from 'hooks/usePopup';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import CompletePopupContents from 'sharedComponents/CompletePopupContents';
import { PrivateStartInterval, WorkTime } from 'sharedComponents/ProfileField';
import getColor from 'utils/getColor';

import { StaffCreateFormType } from '../../types';
import { getIsPeriodCheck, getSchedulesParam } from '../../utils';
import SubmitButtonGroup from './SubmitButtonGroup';

const CreateStepSchedules = () => {
  const navigate = useNavigate();

  const errorDialog = useErrorDialog();
  const { setPopup } = usePopup();
  const { handleSubmit, reset } = useFormContext<StaffCreateFormType>();

  const { data: roles } = useGetRole();
  const { mutate: postStaffMutate, isPending } = usePostStaff();

  const submit: SubmitHandler<StaffCreateFormType> = inputValues => {
    const { profile, imageUrl, schedules, mobile, role_id, careers } = inputValues;

    const isPeriodCheck = getIsPeriodCheck(schedules);
    if (!isPeriodCheck) {
      errorDialog.open('휴식시간은 근무시간을\n초과할 수 없습니다.');
      return;
    }

    /**
     * hired_at, is_representative, type_id
     * 위 데이터들은 따로 설정하는 곳이 없으며, 프론트에서 기본값으로 적용해 보내기로함
     */
    const schedulesParam = getSchedulesParam([...schedules.restTimes, ...schedules.workTimes]);
    const careersParam = careers.filter(({ career }) => !!career);
    const params = {
      profile: {
        ...profile,
        get_noti_all: profile.get_noti_all ? 1 : 0,
        representative_color: getColor(profile.representative_color) || MATCH_REPRESENTATIVE_COLOR['red0'],
        hired_at: profile.registered_at,
      },
      staffAvatar: imageUrl ? { image: imageUrl } : undefined,
      schedules: schedulesParam,
      mobile,
      role_id,
      careers: careersParam,
      is_representative: 1,
      type_id: 2,
    };

    const roleName = roles.find(role => role.id === role_id)?.display_name || '';

    postStaffMutate(params, {
      onSuccess: () => {
        const mobileRegex = mobile.length === 10 ? /(\d{3})(\d{3})(\d{4})/ : /(\d{3})(\d{4})(\d{4})/;
        const mobileFormat = mobile.replace(mobileRegex, '$1-$2-$3');
        const tableData = [
          { title: '강사명', content: profile.name },
          { title: '휴대폰번호', content: mobileFormat || '-' },
          { title: '역할', content: roleName },
        ];

        reset();

        setPopup(
          <CompletePopupContents
            type="createStaff"
            tableData={tableData}
            onClose={() => {
              setPopup(null);
              navigate(-3);
            }}
          />,
        );
      },
    });
  };

  return (
    <Container onSubmit={handleSubmit(submit)}>
      <PrivateStartInterval />
      <Divider thin />

      <WorkTime />
      <Divider thin />

      <SubmitButtonGroup buttonText="등록 완료" disabled={isPending} />
    </Container>
  );
};

export default CreateStepSchedules;

const Container = styled(Form)`
  margin-bottom: 76px;
`;
