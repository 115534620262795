import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import Label from 'components/Label';
import Typography from 'components/Typography';
import { ArrangeTypographyWrapper } from 'sharedComponents/StyledComponents';

import { FormatTicketCard } from './types';

type Props = {
  className?: string;
  ticket: FormatTicketCard;
  isSelected?: boolean;
};

const TicketCard = ({ className, ticket, isSelected }: Props) => {
  return (
    <Container className={`ticket-card ${className ?? ''}`}>
      <TicketImageWrapper ticketColor={ticket.ticketColor}>
        <div className="ticket-image">
          <div className="style-gradient-wrapper">
            <StyleGradient />
            <StyleGradient />
            <StyleGradient fullWidth />
          </div>

          {ticket?.isFavorite && (
            <span className="favorite-icon">
              <Icon name="starFill" size={16} fillColor={theme.color.softYellow1} />
            </span>
          )}
        </div>
      </TicketImageWrapper>

      <TicketInfoWrapper className="ticket-card-top-wrapper">
        <TicketDetailInfoWrapper>
          {ticket.topOptions.map((ticketInfo, index) => {
            if (!ticketInfo) return null;
            return (
              <Typography key={`${ticket.id}-top-${index}`} span size={13} weight={500} textColor="gray2">
                {ticketInfo}
              </Typography>
            );
          })}
        </TicketDetailInfoWrapper>

        <Typography size={15} weight={600} ellipsis={1} textColor={isSelected ? 'primary' : 'gray1'}>
          {ticket.title}
        </Typography>

        {!!ticket.bottomOptions.length && (
          <TicketDetailInfoWrapper className="ticket-card-bottom-wrapper">
            {ticket.bottomOptions.map((ticketInfo, index) => {
              if (!ticketInfo) return null;
              return (
                <Typography key={`${ticket.id}-bottom-${index}`} span size={13} textColor="gray2" opacity={0.8}>
                  {ticketInfo}
                </Typography>
              );
            })}
          </TicketDetailInfoWrapper>
        )}
      </TicketInfoWrapper>

      {ticket.isExperience && (
        <ExperienceLabel className="experience-label" size="small" textColor="secondary2" borderColor="secondary2">
          체험
        </ExperienceLabel>
      )}
    </Container>
  );
};

export default TicketCard;

const Container = styled.div`
  ${theme.flex('', '', '', 20)};
  padding: 12px 0;
  position: relative;
`;

const TicketImageWrapper = styled.div<Pick<FormatTicketCard, 'ticketColor'>>(
  ({ ticketColor }) => css`
    margin-top: 12px;

    .ticket-image {
      position: relative;
      padding: 4px;
      width: 49px;
      height: 28px;
      background: linear-gradient(90deg, ${ticketColor[0]} 0%, ${ticketColor[1]} 100%);
      background-color: ${theme.color.blueViolet0};
      border-radius: 3px;

      .style-gradient-wrapper {
        width: 100%;
        height: 100%;
        ${theme.flex('column', '', '', 2)};

        > span:last-of-type {
          margin-top: auto;
        }
      }

      .favorite-icon {
        position: absolute;
        bottom: -4px;
        right: -4px;
        display: inline-block;
        background-color: ${theme.color.white};
        border-radius: 50%;
        border: 1px solid ${theme.color.gray7};

        svg {
          display: block;
        }
      }
    }
  `,
);

const StyleGradient = styled.span<{ fullWidth?: boolean }>`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '14px')};
  height: 4px;
  opacity: 0.24;
  background: linear-gradient(90deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
`;

const TicketInfoWrapper = styled.div`
  flex: 1;
  ${theme.flex('column', '', '', 4)};
`;

const TicketDetailInfoWrapper = styled(ArrangeTypographyWrapper)`
  &:last-of-type {
    ${theme.textEllipsis(1)};

    .typography {
      &:before {
        top: 3px;
        margin-top: 0;
      }
    }
  }
`;

export const ExperienceLabel = styled(Label)`
  min-width: 32px;
  position: absolute;
  right: 20px;
`;
