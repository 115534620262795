import styled from '@emotion/styled';
import Phone from 'assets/images/phone_full.svg';
import Button from 'components/Button';
import Drawer from 'designedComponents/Drawers';
import TitleWithSubText from 'designedComponents/Drawers/DrawerTitle/TitleWithSubText';
import { ReactNode, useState } from 'react';

import { MESSAGE_PREVIEW_TEXT } from '../constants';

type Props = {
  /** 타이틀과 휴대폰UI 사이에 요약정보를 추가할 경우 */
  summary?: ReactNode;
  /** 휴대폰 UI로 들어가는 컴포넌트 */
  children: ReactNode;
  scrollable?: boolean;
  className?: string;
};

const MessagePreview = ({ summary, children, scrollable = false, className }: Props) => {
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);

  return (
    <>
      <StyledButton variant="contained" color="gray" size="large56" onClick={() => setIsDrawerOpened(true)}>
        {MESSAGE_PREVIEW_TEXT.title}
      </StyledButton>
      <StyledDrawer
        isOpen={isDrawerOpened}
        onClose={() => setIsDrawerOpened(false)}
        header={<TitleWithSubText title={MESSAGE_PREVIEW_TEXT.title} subTitle={MESSAGE_PREVIEW_TEXT.description} />}>
        {summary}
        <MobilePreview className={className}>
          <ImageBackground src={Phone} />
          <Container scrollable={scrollable}>{children}</Container>
        </MobilePreview>
      </StyledDrawer>
    </>
  );
};

export default MessagePreview;

const StyledButton = styled(Button)`
  min-width: 115px;
`;

const StyledDrawer = styled(Drawer)`
  padding-bottom: 0;
  overflow: hidden;
  height: min(calc(100vh - 180px), 150vw);
`;

const MobilePreview = styled.div`
  position: relative;
  margin-top: 24px;
  height: calc(100% - 76px);
`;

const ImageBackground = styled.img`
  position: absolute;
  width: 100%;
  height: auto;
  z-index: -1;
`;

const Container = styled.div<{ scrollable: boolean }>`
  position: relative;
  top: 15vw;
  width: 100%;
  height: calc(100% - 20vw);
  overflow-y: ${({ scrollable }) => (scrollable ? 'scroll' : 'hidden')};
  padding: 0 8vw 40px;
`;
