import styled from '@emotion/styled';
import Button from 'components/Button';
import SubTitle from 'components/SubTitle';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { eventScheduleSelectDateAtom, listHeaderTotalAtom } from 'recoil/staff';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import ListContentHeader from 'sharedComponents/ListContentHeader';
import MainLayout, { IMainLayoutProps } from 'sharedComponents/MainLayout';

import { STAFF_PROFILE_FORM_TEXT } from '../../Form/constants';
import { DATE_SORT_FILTERS } from '../../List/constants';
import EventScheduleCardList from './components/EventScheduleCardList';

const EventScheduleSingle = () => {
  const navigate = useNavigate();

  const listHeaderTotal = useRecoilValue(listHeaderTotalAtom);

  const methods = useForm<{ sort: 'asc' | 'desc' }>({
    defaultValues: {
      sort: 'asc',
    },
  });

  const resetEventSchedule = useResetRecoilState(eventScheduleSelectDateAtom);
  const headerProps: IMainLayoutProps['header'] = {
    rightButton: (
      <Button
        fontSize={15}
        textColor="gray2"
        fontWeight={600}
        onClick={() => {
          resetEventSchedule();
          navigate('update-form');
        }}>
        추가
      </Button>
    ),
    noUnderline: true,
  };

  const changeSort = (e: { target: { value: string } }) => {
    methods.setValue('sort', e.target.value as 'asc' | 'desc');
  };

  return (
    <MainLayout header={headerProps}>
      <Container>
        <SubTitle
          title={STAFF_PROFILE_FORM_TEXT.subTitle.eventSchedule}
          message={{ position: 'bottom', text: STAFF_PROFILE_FORM_TEXT.desc.eventSchedule }}
        />
        <ApiBoundary>
          <FormProvider {...methods}>
            <ListContentHeader
              type="event"
              sortData={DATE_SORT_FILTERS}
              total={listHeaderTotal}
              onChange={changeSort}
              sort={methods.watch('sort')}
            />
            <EventScheduleCardList />
          </FormProvider>
        </ApiBoundary>
      </Container>
    </MainLayout>
  );
};

export default EventScheduleSingle;

const Container = styled.div`
  padding-bottom: 40px;

  .sub-title {
    margin: 8px 0 40px;
    padding: 0 20px;
  }
`;
