import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import SubTitle from 'components/SubTitle';
import useQueryString from 'hooks/useQueryString';
import { isEmpty } from 'lodash';
import { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import DialogBeforeLeave from 'sharedComponents/DialogBeforeLeave';
import MainLayout from 'sharedComponents/MainLayout';
import ProfileForm from 'sharedComponents/ProfileField/components/Profile/ProfileForm';

import { STAFF_CREATE_STEP, staffInfoYupSchema } from '../constants';
import { StaffCreateFormType } from '../types';
import CreateStepInfo from './components/CreateStepInfo';
import CreateStepProfile from './components/CreateStepProfile';
import CreateStepSchedules from './components/CreateStepSchedules';
import ProgressCircle from './components/ProgressCircle';
import { DEFAULT_VALUES_INIT } from './constants';

const StaffCreateForm = () => {
  const { setSearchParams, getAllSearchParams } = useQueryString();

  const navigate = useNavigate();
  const queryStringParsed = getAllSearchParams();
  const isProfilePage = queryStringParsed.type === 'profile';
  const isRepresentativeColor = queryStringParsed.type === 'representativeColor';

  const methods = useForm<StaffCreateFormType>({
    mode: 'onChange',
    shouldFocusError: false,
    resolver: yupResolver(staffInfoYupSchema),
    defaultValues: DEFAULT_VALUES_INIT,
  });

  const { errors, dirtyFields } = methods.formState;
  const { profile, role_id } = dirtyFields;

  const step = Number(queryStringParsed.step) || 1;

  const isError = useMemo(() => errors.profile?.name || errors.mobile, [errors.profile?.name, errors.mobile]);

  const nextStep = useCallback(() => {
    const next = step + 1;
    setSearchParams({ ...queryStringParsed, step: String(next) });
  }, [setSearchParams, queryStringParsed, step]);

  const clickBackFromProfilePage = useCallback(() => {
    const { setValue, getValues } = methods;
    setValue('profile.name', getValues('beforeName'), { shouldDirty: true });
    setValue('image', getValues('image'), { shouldDirty: true });
  }, [methods]);

  const clickBack = () => {
    clickBackFromProfilePage();
    navigate(-1);
  };

  const headerTitle = useMemo(() => {
    if (isProfilePage) return '프로필 설정';
    if (isRepresentativeColor) return '대표컬러 선택';

    switch (step) {
      case 1:
        return '기본정보 입력';
      case 2:
        return '프로필 입력';
      case 3:
        return '시간정보 설정';
      default:
        return undefined;
    }
  }, [isProfilePage, isRepresentativeColor, step]);

  const isShowProgress = isProfilePage || isRepresentativeColor;

  return (
    <MainLayout
      key={`${isProfilePage}-${isRepresentativeColor}`}
      header={{
        title: headerTitle,
        titleScrollThreshold: isProfilePage || isRepresentativeColor ? undefined : 60,
        noUnderline: isRepresentativeColor,
        leftButton: (
          <IconButton onClick={clickBack}>
            <Icon name="headerBack" />
          </IconButton>
        ),
      }}
      progress={isShowProgress ? undefined : { max: 3, step }}>
      <ApiBoundary>
        <FormProvider {...methods}>
          {isProfilePage ? (
            <ProfileForm label="강사명" placeholder="강사명 입력" />
          ) : (
            <>
              {!isRepresentativeColor && (
                <Container>
                  <ProgressCircle max={3} step={step} />
                  <SubTitle title={STAFF_CREATE_STEP[step - 1].label} bottom={40} />
                </Container>
              )}
              {step === 1 && (
                <CreateStepInfo
                  nextStep={nextStep}
                  disabled={!!isError || !(profile?.name && role_id) || methods.formState.isSubmitting}
                />
              )}
              {step === 2 && <CreateStepProfile nextStep={nextStep} />}
              {step === 3 && <CreateStepSchedules />}
            </>
          )}
        </FormProvider>
      </ApiBoundary>
      <DialogBeforeLeave isBlocked={!isEmpty(dirtyFields)} />
    </MainLayout>
  );
};

export default StaffCreateForm;

const Container = styled.section`
  padding: 0 20px;
`;
