import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Typography from 'components/Typography';
import usePhoto, { ImgUrlsType } from 'hooks/usePhoto';
import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import Thumbnail, { ThumbnailCommonWrap } from 'sharedComponents/Thumbnail';

import InputFile from '../InputFile';

export type PhotoFormType = {
  imgUrls: Array<ImgUrlsType>;
  files: Array<File>;
};

type Props = {
  className?: string;
  toastPosition?: number;
};

const PhotoArea = ({ className, toastPosition }: Props) => {
  const { getValues, setValue } = useFormContext<PhotoFormType>();
  const imgUrlsValue = getValues('imgUrls') ?? [];

  const fileRef = useRef<HTMLInputElement>(null);
  const { files, imgUrls, onAddPhoto, onChange, onRemove, setImgUrls } = usePhoto(fileRef, undefined, toastPosition);

  useEffect(() => {
    setValue('imgUrls', imgUrls, { shouldDirty: true });
  }, [imgUrls, setValue]);

  useEffect(() => {
    const fileArray = files.map(({ file }) => file);
    setValue('files', fileArray, { shouldDirty: true });
  }, [files, setValue]);

  useEffect(() => {
    setImgUrls(imgUrlsValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className={className}>
      <div className="button-wrapper">
        <Button
          className="add-photo-button"
          variant="contained"
          size="small"
          color="gray"
          padding={{ left: 12, right: 12 }}
          edge="circular"
          fontWeight={600}
          onClick={onAddPhoto}>
          사진추가
        </Button>
        <InputFile ref={fileRef} onChange={onChange} />
        <Typography size={13} weight={500} lineHeight={16} textColor="gray3">
          최대 3장, 2MB (jpg, jpeg, png)
        </Typography>
      </div>

      {!!imgUrls.length && (
        <ThumbnailWrapper>
          {imgUrls.map((item, index) => (
            <Thumbnail
              key={index}
              imgSrc={item.imgUrl}
              showRemoveButton
              onRemove={() => {
                onRemove(item, index);
              }}
            />
          ))}
        </ThumbnailWrapper>
      )}
    </Container>
  );
};

export default PhotoArea;

const Container = styled.div`
  margin-top: 12px;
  padding-bottom: 40px;

  .button-wrapper {
    ${theme.flex('', 'center', 'flex-start', 8)}
  }
  .add-photo-button {
    background-color: ${theme.color.gray6};
  }
`;

const ThumbnailWrapper = styled(ThumbnailCommonWrap)`
  padding-top: 28px;
`;
