import usePopup from 'hooks/usePopup';
import useToast from 'hooks/useToast';
import { isEqual } from 'lodash';
import { ReactNode, useEffect, useState } from 'react';
import { RecoilState, useRecoilState, useResetRecoilState } from 'recoil';

import { FilterOptionsProps, FilterOptionsType, FilterType, ParamsType } from './types';
import { detectFilterErrors } from './utils';
import WholeFilters from './WholeFilters';

type Props<T extends ParamsType> = {
  title?: string;
  filterAtom: RecoilState<T>;
  filters: FilterType[];
  renderWholeFilterPopupOptions: (props: FilterOptionsProps) => ReactNode;
};

const WholeFilterPopup = <T extends ParamsType>({ title, filterAtom, filters, renderWholeFilterPopupOptions }: Props<T>) => {
  const { setToast } = useToast();
  // 리스트에 반영되는 필터, 필터 적용버튼 누르면 변경됨
  const [selectedFilter, setSelectedFilter] = useRecoilState(filterAtom);
  const resetSelectedFilter = useResetRecoilState(filterAtom);

  // 필터 UI용 상태
  const [filterChanged, setFilterChanged] = useState(selectedFilter);

  useEffect(() => {
    setFilterChanged(selectedFilter);
  }, [selectedFilter]);

  const resetFilter = () => {
    resetSelectedFilter();
    setFilterChanged(selectedFilter);
  };

  const changeOption = (option: FilterOptionsType, key: string) => {
    setFilterChanged({ ...filterChanged, [key]: option.value });
  };

  const { setPopup } = usePopup();
  const closePopup = () => {
    setPopup(null);
  };

  const handleSave = () => {
    const hasError = detectFilterErrors(filters, filterChanged, setToast, 92);
    if (hasError) {
      return;
    }
    setSelectedFilter(filterChanged);
    closePopup();
    if (!isEqual(filterChanged, selectedFilter)) {
      setToast({ type: 'success', message: '필터가 적용되었습니다.', bottom: 68 });
    }
  };

  return (
    <WholeFilters title={title} onReset={resetFilter} onSave={handleSave} onClose={closePopup}>
      {renderWholeFilterPopupOptions({ filters, filterChanged, changeOption })}
    </WholeFilters>
  );
};

export default WholeFilterPopup;
