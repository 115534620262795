import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { Color } from 'utils/getColor';
import getContrastTextColor from 'utils/getContrastTextColor';

import { CardStyled } from './CardStyled';

type Props = {
  title: string;
  isPrivate: boolean;
  cardColor: string;
};

const EventEtcScheduleCard = ({ ...props }: Props) => {
  const { title, isPrivate, cardColor } = props;

  const currentContrastColor = getContrastTextColor(cardColor) as Color;

  return (
    <Container cardColor={cardColor}>
      <div className="title-wrapper">
        {isPrivate && <Icon name="lockBold" size={12} fillColor={theme.color[currentContrastColor]} />}
        <Typography size={12} weight={600} textColor={currentContrastColor} ellipsis={1}>
          {title}
        </Typography>
      </div>
    </Container>
  );
};

export default EventEtcScheduleCard;

const Container = styled(CardStyled)`
  .title-wrapper {
    ${theme.flex('row', 'center', 'flex-start')}
  }
`;
