import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Typography from 'components/Typography';
import { v4 as uuidv4 } from 'uuid';

import { EVENT_WORK_TIME_TITLE } from '../constants';

type Props = {
  title: string;
  diffText: string;
  onClick?: (customId: string) => void;
};

const SubHeader = ({ title, diffText, onClick }: Props) => {
  return (
    <Container>
      <div className="title">
        <Typography size={15} weight={500} textColor="gray2" opacity={0.88}>
          {title}
        </Typography>

        {title === EVENT_WORK_TIME_TITLE.rest && diffText === '0' && (
          <Button
            size="tiny"
            borderRadius={999}
            variant="contained"
            color="gray"
            textColor="gray2"
            padding={{ left: 8, right: 8 }}
            onClick={() => onClick?.(uuidv4())}>
            추가
          </Button>
        )}
      </div>
      <Typography weight={500} textColor="gray3">
        {diffText}시간
      </Typography>
    </Container>
  );
};

export default SubHeader;

const Container = styled.div`
  padding: 0 20px;

  .title {
    ${theme.flex('row', 'center', 'center')};
    gap: 6px;
  }
`;
