import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import CheckBox from 'components/CheckBox';
import AvatarLabel from 'designedComponents/Drawers/CheckRadioDrawer/AvatarLabel';
import useInfinityStaff from 'hooks/service/queries/useInfinityStaff';
import { Controller, useFormContext } from 'react-hook-form';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';

import { TicketDetailInfoFormType } from '../types';

const SelectStaffsDrawerContent = () => {
  const { control } = useFormContext<TicketDetailInfoFormType>();
  const {
    data: { staffs },
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfinityStaff();

  return (
    <InfiniteScroll hasMore={hasNextPage} isLoading={isFetchingNextPage} loadMore={fetchNextPage}>
      <StyledUl>
        {staffs.map(({ id, avatar, name }) => {
          return (
            <li key={id}>
              <Controller
                control={control}
                name="detail.tempStaffs"
                render={({ field: { value, onChange } }) => {
                  const checked = value.some(field => field.id === id);

                  return (
                    <CheckBox
                      id={`${id}`}
                      label={<AvatarLabel id={`${id}`} imgUrl={avatar} label={name} showLabelAll />}
                      gap={16}
                      value={id}
                      checked={!!checked}
                      onChange={e => {
                        const staffValue = {
                          id,
                          name,
                          image: avatar,
                        };
                        if (e.target.checked) {
                          onChange([...value, staffValue]);
                        } else {
                          onChange([...value.filter(staff => staff.id !== id)]);
                        }
                      }}
                    />
                  );
                }}
              />
            </li>
          );
        })}
      </StyledUl>
    </InfiniteScroll>
  );
};

export default SelectStaffsDrawerContent;

const StyledUl = styled.ul`
  ${theme.flex('column', '', '', 8)};

  li {
    padding: 8px 0;

    .avatar-label {
      gap: 8px;
      .check-radio-filter-custom-label-text {
        font-weight: 500;
      }
    }
  }
`;
