import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Switch from 'components/Switch';
import { APP_LINK_TEXT } from 'pages/MemberDetail/constants';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { isKeyboardShowAtom } from 'recoil/keyboard';
import filters from 'utils/filters';

import SelectMessageReservationDateDrawer from './SelectMessageReservationDateDrawer';
import { MessageReservationType } from './types';

type Props = {
  offAlert?: () => void;
};

/** 메세지 보낼 때 예약 토글 및 시간 설정 UI  */
const SendReservation = ({ offAlert }: Props) => {
  const { control, setValue } = useFormContext<MessageReservationType>();
  const reservationDate = useWatch({ control, name: 'reservationDate' });
  const isReserve = useWatch({ control, name: 'isReserve' });

  const [isOpen, setIsOpen] = useState(false);

  const toggleIsReserve = () => {
    if (isReserve && offAlert) {
      offAlert();
      return;
    }
    setValue('isReserve', !isReserve);
  };

  const isKeyBoardShow = useRecoilValue(isKeyboardShowAtom);
  return (
    <Container isReserve={isReserve} isKeyBoardShow={isKeyBoardShow}>
      <Switch id="isReserve" label={APP_LINK_TEXT.reservation.switchLabel} checked={isReserve} onChange={toggleIsReserve} />

      {isReserve && (
        <SelectDateButton
          fullWidth
          size="medium48"
          fontSize={15}
          variant="outlined"
          outlineColor="gray5"
          padding={{ left: 16, right: 12 }}
          rightIcon={<Icon name="arrowBottom" fillColor={theme.color.gray2} />}
          textColor={!reservationDate ? 'gray4' : 'gray1'}
          borderRadius={10}
          onClick={() => setIsOpen(true)}>
          {!reservationDate ? APP_LINK_TEXT.reservation.dateButtonLabel : filters.dateTimeWithWeekday(reservationDate)}
        </SelectDateButton>
      )}

      <SelectMessageReservationDateDrawer isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </Container>
  );
};

export default SendReservation;

const Container = styled.div<{ isReserve: boolean; isKeyBoardShow: boolean }>`
  padding-left: 20px;
  padding-right: 20px;

  --basic-padding: 40px; // 예약 토글 혹은 버튼 하단 기본 패딩
  --reserve-button: 0px; //  예약 설정되면 하단 버튼 위에 추가
  --bottom-button: 0px; // 하단 버튼 fixed / static 여부에 따라 추가
  ${({ isReserve }) => isReserve && `--reserve-button: 44px;`}
  ${({ isKeyBoardShow }) => !isKeyBoardShow && `--bottom-button: 76px;`}

  padding-bottom: calc(var(--basic-padding) + var(--reserve-button) + var(--bottom-button));

  .switch {
    padding: 16px 0;
  }
`;

const SelectDateButton = styled(Button)`
  justify-content: space-between;
`;
