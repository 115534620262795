import { useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { CustomError } from 'types/errorTypes';

import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { BookingFailResponse } from './usePostBookingPrivate';
import { EtcScheduleParams } from './usePostEtcSchedule';

/** 기타 일정 모든 일정 수정 */
const usePatchEtcScheduleAll = (etcScheduleId: number) => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<EtcScheduleParams>({
    method: `patch`,
    url: `/api/etcSchedule/all/${etcScheduleId}`,
  });

  return useCustomMutation<
    AxiosResponse,
    AxiosError<Array<BookingFailResponse> | CustomError>,
    EtcScheduleParams | undefined,
    unknown
  >({
    mutationFn,
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ['etc/schedule', 'detail'],
      });
    },
  });
};

export default usePatchEtcScheduleAll;
