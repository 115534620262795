import styled from '@emotion/styled';
import CheckRadioDrawer from 'designedComponents/Drawers/CheckRadioDrawer';
import { ETC_FORM_TEXT, ETC_PRIVATE_DATA } from 'pages/EtcSchedule/constants';
import { EtcScheduleCreateFormType } from 'pages/EtcSchedule/types';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import FormFieldLinkButton from 'sharedComponents/FormField/FormFieldLinkButton';

const PrivateSchedule = () => {
  const { control } = useFormContext<EtcScheduleCreateFormType>();
  const [isOpen, setIsOpen] = useState(false);
  const isPrivate = useWatch({ control, name: 'is_private' });
  const label = ETC_PRIVATE_DATA.filter(({ value }) => value === isPrivate)[0].label;

  return (
    <>
      <StyledButton iconName="lockRegular" hasValue={!!isPrivate} onClick={() => setIsOpen(true)}>
        {label}
      </StyledButton>

      <CheckRadioDrawer
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        header={ETC_FORM_TEXT.isPrivate.title}
        name="is_private"
        control={control}
        data={ETC_PRIVATE_DATA}
      />
    </>
  );
};

export default PrivateSchedule;

const StyledButton = styled(FormFieldLinkButton)`
  border-bottom: none;
`;
