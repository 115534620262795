import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { BookingParams } from './usePostBooking';
import { BookingPrivateParams, BookingPrivateResponse } from './usePostBookingPrivate';

/**
 * 프라이빗 수업 복사
 * (프라이빗 생성과 동일한데 주/월간 초과 검증이 추가됨)
 */
const usePostBookingPrivateCopy = () => {
  const { mutationFn } = useMutationFnWithCommonParams<BookingPrivateCopyParams, BookingPrivateResponse>({
    method: `post`,
    url: `/api/booking/private/copy`,
  });

  return useCustomMutation({
    mutationFn,
  });
};

export default usePostBookingPrivateCopy;

export type BookingPrivateCopyParams = Pick<BookingParams, 'is_pass_limit'> & BookingPrivateParams;
