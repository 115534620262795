import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { color, theme } from 'assets/styles';
import Icon from 'components/Icon';
import CheckRadio from 'components/Radio/CheckRadio';
import Typography from 'components/Typography';
import useRepresentativeColor from 'hooks/useRepresentativeColor';
import { useEffect, useRef } from 'react';
import { Color } from 'utils/getColor';

import { REPRESENTATIVE_COLOR_LIST } from '../constants';
import UsingColorDialog from './UsingColorDialog';

const PaletteTypeContent = () => {
  const checkedRef = useRef<HTMLLIElement>(null);

  const { representativeColor, changeValue, dialogAction, count, colorKeys } = useRepresentativeColor();

  useEffect(() => {
    if (checkedRef.current) {
      checkedRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, []);

  return (
    <Container>
      <UsingColorDialog dialogAction={dialogAction} count={count} />

      {REPRESENTATIVE_COLOR_LIST.map(({ id, value, label }) => {
        const isChecked = value === representativeColor;
        const isIncludeColor = colorKeys.includes(value);

        const className = isChecked ? 'checked' : '';
        const usingColorClassName = isIncludeColor ? ' using-color' : '';

        return (
          <li ref={isChecked ? checkedRef : null} key={value} className={className + usingColorClassName}>
            <CheckRadio
              id={id}
              name="ticket"
              checkSize={16}
              checkBold
              checkColor="white"
              label={
                <div className="check-label-container">
                  <div className="icon-container">
                    {isIncludeColor && (
                      <Icon
                        className={isChecked ? 'select-check' : undefined}
                        name="selectCheckBold"
                        size={16}
                        fillColor={theme.color.white}
                      />
                    )}
                    <span className="overlay-white" />
                    <RepresentativeColorCircle className="test" isChecked={isChecked} value={value as keyof typeof color} />
                    {isChecked && <span className="border-icon" />}
                  </div>
                  <Typography size={13} ellipsis={1} textColor="gray2">
                    {label}
                  </Typography>
                </div>
              }
              value={value}
              checked={isChecked}
              onChange={(e: { currentTarget: { value: string } }) => {
                changeValue(e.currentTarget.value as Color);
              }}
            />
          </li>
        );
      })}
    </Container>
  );
};

export default PaletteTypeContent;

const Container = styled.ul`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(11, 1fr);
  grid-gap: 8px 8px;
  justify-content: center;
  padding: 20px;

  li {
    margin-bottom: 16px;

    .radio-check-label {
      position: relative;
    }

    // 체크 아이콘 정렬
    .input-select-base-input-wrapper {
      position: absolute;
      top: 22px;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-left: 4px;

      .input-span-check-style {
        top: 1px;

        svg {
          fill: white !important;
        }
      }
    }
  }

  .icon-container {
    position: relative;
    height: 38px;
  }

  .using-color {
    .icon-container {
      svg:first-of-type {
        position: absolute;
        top: 48%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .overlay-white {
        position: absolute;
        background-color: ${theme.color.white};
        width: 38px;
        height: 38px;
        opacity: 0.68;
      }
    }

    p {
      color: ${theme.color.gray4};
    }
  }

  .checked {
    ${theme.flex()};

    p {
      color: ${theme.color.primary};
      font-weight: 700;
    }

    .border-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      display: inline-block;
      width: 38px;
      height: 38px;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      border: 2px solid ${theme.color.primary};
    }
  }

  .check-label-container {
    position: relative;
    ${theme.flex('column', 'center', 'center')};
    height: 60px;

    p {
      margin-top: 6px;
    }

    .icon-container > .select-check {
      opacity: 0;
    }
  }
`;

const RepresentativeColorCircle = styled.span<{ isChecked: boolean; value: keyof typeof color }>(
  ({ value }) => css`
    display: inline-block;
    width: 38px;
    height: 38px;
    background-color: ${color[value]};
    border-radius: 50%;
  `,

  ({ isChecked }) =>
    isChecked &&
    css`
      margin-top: 3px;
      width: 32px;
      height: 32px;
    `,
);
