import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import OneLinedTitle from 'designedComponents/Drawers/DrawerTitle/OneLinedTitle';
import DoubleButtonDrawer from 'designedComponents/Drawers/DrawerWithButton/DoubleButtonDrawer';
import {
  ScheduleManagementBooking,
  ScheduleManagementBookings,
} from 'hooks/service/queries/useInfinityScheduleManagementBooking';
import { useNavigate } from 'react-router-dom';
import { useResetRecoilState } from 'recoil';
import { customBackHandlersAtom } from 'recoil/common';
import filters from 'utils/filters';

import { getStatusLabel } from '../../util';
import CommonDetailInfo from './CommonDetailInfo';

type Props = {
  isOpen: boolean;
  currentTab: 'booking' | 'checkin';
  scheduleInfo: ScheduleManagementBookings;
  bookingInfo: ScheduleManagementBooking;
  onClose: () => void;
};

const DefaultDetailInfo = ({ isOpen, currentTab, scheduleInfo, bookingInfo, onClose }: Props) => {
  const navigate = useNavigate();
  const resetCustomBackHandler = useResetRecoilState(customBackHandlersAtom);

  const goToClassDetail = (classId: number) => {
    navigate(`/booking/detail/${classId}`);
    resetCustomBackHandler();
  };

  const goToMemberDetail = (memberId: number) => {
    navigate(`/member/detail/${memberId}`);
    resetCustomBackHandler();
  };

  return (
    <StyledDoubleButtonDrawer
      type={1}
      isOpen={isOpen}
      onClose={onClose}
      header={<OneLinedTitle title={scheduleInfo.title} />}
      leftButton={{ text: '회원 상세', onClick: () => goToMemberDetail(bookingInfo.member.id) }}
      rightButton={{ text: '수업 상세', onClick: () => goToClassDetail(scheduleInfo.id) }}>
      <CommonDetailInfo scheduleInfo={scheduleInfo} bookingInfo={bookingInfo} />
      <InfoWrapper>
        <Typography className="info-label" textColor="gray2" size={15} weight={400}>
          사용 수강권
        </Typography>
        <Typography textColor="gray1" ellipsis={1} size={15} weight={500}>
          {`${bookingInfo.user_ticket.ticket.title}`}
        </Typography>
      </InfoWrapper>
      <Divider thin thinColor="gray8" />
      <InfoWrapper>
        <Typography className="info-label" textColor="gray2" size={15} weight={400}>
          예약상태
        </Typography>
        <Typography textColor="gray1" size={15} weight={500}>
          {`${getStatusLabel(bookingInfo.status || '출석').name}`}
        </Typography>
      </InfoWrapper>
      <Divider thin thinColor="gray8" />
      <InfoWrapper className="last-info-item">
        <Typography textColor="gray2" size={15} weight={400}>
          {currentTab === 'booking' ? '상태변경 일시' : '체크인 일시'}
        </Typography>
        <Typography textColor="gray1" size={15} weight={500}>
          {`${filters.dateTimeWithWeekday(scheduleInfo.updated_at)}`}
        </Typography>
      </InfoWrapper>
    </StyledDoubleButtonDrawer>
  );
};

export default DefaultDetailInfo;

const StyledDoubleButtonDrawer = styled(DoubleButtonDrawer)`
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: calc(32px + 76px);
`;

const InfoWrapper = styled.div`
  ${theme.flex('row', 'center', 'space-between', 20)}
  height: 56px;
  padding: 17px 20px;

  > p:first-of-type {
    flex-shrink: 0;
  }

  .info-label {
    width: 82px;
  }
`;
