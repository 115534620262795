import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Label from 'components/Label';
import Typography from 'components/Typography';
import { MemberResponse } from 'hooks/service/queries/useInfinityMember';
import { useMemo } from 'react';
import { InfoFlexRow, InfoFlexWrapper } from 'sharedComponents/InfoTypography';
import { MemberCardProps } from 'types/memberTypes';
import filters from 'utils/filters';
import getDaysDiff from 'utils/getDaysDiff';
import getRemainingDaysText from 'utils/getRemainingDaysText';
import getTicketInfoByType from 'utils/getTicketInfoByType';

type Props = {
  userTicket: MemberResponse['userTicket'][0] | MemberCardProps['userTickets'][0];
};

const MemberTicket = ({ userTicket }: Props) => {
  const {
    availability_start_at,
    expire_at,
    remaining_coupon,
    max_coupon,
    user_ticket_status,
    holdings,
    ticket: { title, type, available_class_type },
  } = userTicket;

  const attendance_count = 'attendance_count' in userTicket ? userTicket.attendance_count : null;
  const last_attendance_at = 'last_attendance_at' in userTicket ? userTicket.last_attendance_at : null;
  const { showCoupon, showPeriod } = getTicketInfoByType(type);

  const holdingDays = useMemo(() => {
    if (!holdings) return 0;

    const { start_on, end_on } = holdings;
    return getDaysDiff(end_on, start_on) + 1;
  }, [holdings]);

  const isNotUsableTicket = useMemo(() => {
    switch (user_ticket_status) {
      case 'holding':
        return `${holdingDays}일 정지`;
      case 'expired':
        return '기간만료';
      default:
        return null;
    }
  }, [user_ticket_status, holdingDays]);

  const opacity = useMemo(() => (isNotUsableTicket ? 0.48 : 1), [isNotUsableTicket]);

  const showAttendanceDate = useMemo(() => {
    if (!last_attendance_at && !attendance_count) return false;
    if (available_class_type === 'I') return false;
    return true;
  }, [available_class_type, attendance_count, last_attendance_at]);

  return (
    <Container>
      <div className="ticket-top-wrapper">
        <div className="ticket-name-wrapper">
          <Typography weight={600} textColor="gray2" opacity={opacity}>
            {title}
          </Typography>
          {isNotUsableTicket && (
            <Label size="small" textColor="secondary3" borderColor="secondary3" opacity={0.64}>
              {isNotUsableTicket}
            </Label>
          )}
        </div>
        {showPeriod && (
          <Typography span size={13} textColor="gray3" opacity={opacity}>
            {filters.period(availability_start_at, expire_at)}
          </Typography>
        )}
      </div>

      <InfoFlexWrapper gap={4}>
        {showPeriod && (
          <InfoFlexRow>
            <Typography textColor="gray2" opacity={opacity}>
              잔여기간
            </Typography>
            <Typography textColor="gray2" opacity={opacity} weight={500}>
              {getRemainingDaysText(expire_at, availability_start_at)}
            </Typography>
          </InfoFlexRow>
        )}

        {showCoupon && (
          <InfoFlexRow>
            <Typography textColor="gray2" opacity={opacity}>
              잔여횟수
            </Typography>
            <Typography textColor="gray2" opacity={opacity} weight={500}>
              {remaining_coupon}/{max_coupon}회 남음
            </Typography>
          </InfoFlexRow>
        )}

        {showAttendanceDate && (
          <InfoFlexRow>
            <Typography textColor="gray2" opacity={opacity}>
              최근 출석일
            </Typography>
            <Typography textColor="gray2" opacity={opacity} weight={500}>
              {filters.date(last_attendance_at ?? '')}
            </Typography>
          </InfoFlexRow>
        )}
      </InfoFlexWrapper>
    </Container>
  );
};

export default MemberTicket;

const Container = styled.div`
  border-top: 1px solid ${theme.color.gray7};
  padding-top: 14px;
  margin-top: 14px;

  .ticket-top-wrapper {
    margin-bottom: 10px;

    .ticket-name-wrapper {
      ${theme.flex('', '', 'space-between', 20)};
      span {
        flex: 0 0 auto;
      }
      margin-bottom: 1px;
    }
  }

  .ticket-bottom-wrapper {
    ${theme.flex('column', 'initial', '', 4)};

    .ticket-info-row {
      ${theme.flex('', 'center', 'space-between')};
    }
  }
`;
