import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import useInfinityReportUnpaid, { ReportUnpaidParams } from 'hooks/service/queries/useInfinityReportUnpaid';
import usePopup from 'hooks/usePopup';
import useSearchKeywords from 'hooks/useSearchKeywords';
import { useRecoilValue } from 'recoil';
import { reportUnpaidParamAtom } from 'recoil/report/atom';
import WholeFilterPopup from 'sharedComponents/Filters/WholeFilterPopup';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';
import filters from 'utils/filters';

import { DEFAULT_REPORT_UNPAID_FILTER_OPTIONS, REPORT_UNPAID_SORTING_FILTERS } from '../../constants';
import { filterDefinedParams } from '../../util';
import NoData from '../NoData';
import Search from '../Search';
import Sorting from '../Sorting';
import ReportUnpaidWholeFilterOptions from './ReportUnpaidWholeFilterOptions';
import UnpaidCard from './UnpaidCard';

const ReportUnpaidList = () => {
  const { setPopup } = usePopup();
  const { searchKeyword, setSearchKeyword } = useSearchKeywords('report');
  const reportUnpaid = useRecoilValue(reportUnpaidParamAtom);

  const params: ReportUnpaidParams = {
    search: searchKeyword,
    product_type: reportUnpaid.productType,
    payment_method: reportUnpaid.paymentMethod,
    paginate_type: 'detail',
    order_by: reportUnpaid.sort,
  };

  const definedParams = filterDefinedParams(reportUnpaid, ['paymentMethod', 'productType']);
  const hasDot = Object.keys(definedParams).length > 0;

  const {
    data: { unpaids, total, total_amount: unpaidTotal },
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfinityReportUnpaid(params);

  const cancelSearchParam = () => {
    setSearchKeyword('');
  };

  const openWholeDrawer = () => {
    setPopup(
      <WholeFilterPopup
        title="상세조회"
        filterAtom={reportUnpaidParamAtom}
        filters={DEFAULT_REPORT_UNPAID_FILTER_OPTIONS}
        renderWholeFilterPopupOptions={ReportUnpaidWholeFilterOptions}
      />,
    );
  };

  return (
    <Container>
      <TotalSection>
        <div className="total-count-wrapper">
          <Typography span weight={500} textColor="gray2">
            총
          </Typography>
          <Typography span weight={600} textColor="primary">
            {total}
          </Typography>
          <Typography span weight={500} textColor="gray2">
            건
          </Typography>
        </div>
        <div className="total-amount-wrapper">
          <div>
            <Typography span size={24} weight={700} textColor="gray1">
              {filters.numberComma(unpaidTotal)}
            </Typography>
            <Typography span size={21} weight={700} textColor="gray1">
              원
            </Typography>
          </div>
          <Button
            edge="circular"
            size="small"
            variant="outlined"
            padding={{ left: 12, right: 12 }}
            heightSize={28}
            fontSize={13}
            fontWeight={500}
            textColor="gray2"
            onClick={openWholeDrawer}>
            <StyledTypography hasDot={hasDot}>상세조회</StyledTypography>
          </Button>
        </div>
        <Search initialKeyword={searchKeyword} onCancel={cancelSearchParam} onSubmit={keyword => setSearchKeyword(keyword)} />
      </TotalSection>
      <Divider thin />
      <Sorting className="right-sort" filterAtom={reportUnpaidParamAtom} sortOptions={REPORT_UNPAID_SORTING_FILTERS} />
      {total === 0 || unpaids.length === 0 ? (
        <NoData currentKeyword={searchKeyword} noDataText="매출 내역이 없습니다." />
      ) : (
        <ListSection>
          <InfiniteScroll loadMore={fetchNextPage} hasMore={hasNextPage} isLoading={isFetchingNextPage}>
            <UnpaidCard unpaids={unpaids} />
          </InfiniteScroll>
        </ListSection>
      )}
    </Container>
  );
};

export default ReportUnpaidList;

const Container = styled.div`
  .right-sort {
    ${theme.flex('', '', 'flex-end')}
  }
`;

const TotalSection = styled.section`
  padding: 28px 20px 16px 20px;
  background-color: ${theme.color.white};

  .total-count-wrapper {
    padding-bottom: 2px;

    > span:first-of-type {
      padding-right: 4px;
    }
  }

  .total-amount-wrapper {
    ${theme.flex('', 'center', 'space-between')}

    padding-bottom: 16px;
  }
`;

const ListSection = styled.section`
  padding: 20px;
  background-color: ${theme.color.gray6};

  .date-text {
    padding-bottom: 20px;
  }
`;

const StyledTypography = styled(Typography)<{ hasDot: boolean }>`
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  color: ${({ hasDot }) => (hasDot ? theme.color.primary : theme.color.gray2)};
  ${({ hasDot }) =>
    hasDot &&
    `
    position: relative;

    &::after {
      content: '';
      position: absolute;
      right: -5px;
      width: 3px;
      height: 3px;
      background-color: ${theme.color.secondary3};
      border-radius: 50%;
    }
  `}
`;
