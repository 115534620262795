const getParams = (
  type: 'lecture' | 'counsel' | 'etcSchedule',
  schedules: string[],
  beforeEnabled: boolean,
  isEtcScheduleHide?: boolean,
) => {
  const currentTypes = type === 'lecture' ? ['group', 'private'] : [type];
  const enabled = beforeEnabled && ['all', ...currentTypes].some(type => schedules.includes(type));

  if (type === 'lecture') {
    const isGroup = schedules.includes('group');
    const isPrivate = schedules.includes('private');
    const types = [];

    if (isGroup) types.push('G');
    if (isPrivate) types.push('P');

    return {
      type: types,
      enabled,
    };
  }

  return {
    enabled: !isEtcScheduleHide && enabled,
  };
};

export default getParams;
