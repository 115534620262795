export const CALENDAR_SETTING_DRAWER = [
  {
    id: 'calendar',
    title: '캘린더 설정',
    subTitle: '시간/요일, 마감수업, 기타 일정 숨기기 설정',
    link: 'calendar-setting',
  },
  {
    id: 'holiday',
    title: '휴일 설정',
    subTitle: '휴일 설정, 휴일 설정 이력 보기',
    link: 'holiday-setting',
  },
];

export const CREATE_SCHEDULE_DRAWER = [
  {
    title: '프라이빗 수업',
    subTitle: '개인 (1:1), 듀엣 (1:2), 트리플 (1:3)',
    link: '/booking/private/form/create',
  },
  {
    title: '그룹 수업',
    subTitle: '그룹 (1:N) 고정 수업',
    link: '/booking/group/form/create',
  },
  {
    title: '상담',
    subTitle: '방문, 전화, 채팅, 기타 상담',
    link: '/counsel/form',
  },
  {
    title: '기타 일정',
    subTitle: '수업 외 일정',
    link: '/schedule/etc/form/create',
  },
];

export const STUDIO_SELECT_DRAWER = {
  success: '시설이 변경되었습니다.',
};
