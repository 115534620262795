import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import CheckBox from 'components/CheckBox';
import Divider from 'components/Divider';
import OneLinedTitle from 'designedComponents/Drawers/DrawerTitle/OneLinedTitle';
import SingleButtonDrawer from 'designedComponents/Drawers/DrawerWithButton/SingleButtonDrawer';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { staffIdAtom } from 'recoil/common';
import { FilterOptionsType } from 'sharedComponents/Filters/types';

import { ScheduleBookingParamType, ScheduleStaff } from '../type';
import ScheduleStaffItem from './ScheduleStaffItem';

type StaffSelectionState = {
  selected: ScheduleStaff[];
  isAllSelected: boolean;
};

type Props = {
  selectedOption: FilterOptionsType['value'];
  isOpen: boolean;
  staffOptions: FilterOptionsType[];
  onClose: () => void;
  onSave: (option: FilterOptionsType, key: string) => void;
};

const createStaffFromOption = (option: FilterOptionsType): ScheduleStaff => {
  const value = option.value as ScheduleStaff;
  return {
    id: value.id,
    name: value.name,
    imgUrl: value.imgUrl,
  };
};

const isStaffSelected = (staff: ScheduleStaff, selectedStaffs: ScheduleStaff[]): boolean => {
  return selectedStaffs.some(selected => selected.id === staff.id);
};

const ScheduleStaffDrawer = ({ selectedOption, isOpen, staffOptions, onClose, onSave: handleSave }: Props) => {
  const currentStaffId = useRecoilValue(staffIdAtom);

  const [selectedStaffs, setSelectedStaffs] = useState<StaffSelectionState>({
    selected: [],
    isAllSelected: false,
  });

  // staff 옵션 목록 만들기
  const staffsFilterList = useMemo(() => staffOptions.map(createStaffFromOption), [staffOptions]);

  // Handle individual staff selection
  const handleStaffToggle = useCallback(
    (toggledStaff: ScheduleStaff) => {
      setSelectedStaffs(prev => {
        const isCurrentlySelected = isStaffSelected(toggledStaff, prev.selected);

        let newSelected: ScheduleStaff[];
        if (isCurrentlySelected) {
          newSelected = prev.selected.filter(staff => staff.id !== toggledStaff.id);
        } else {
          newSelected = [...prev.selected, toggledStaff];
        }

        return {
          selected: newSelected,
          isAllSelected: newSelected.length === staffsFilterList.length,
        };
      });
    },
    [staffsFilterList],
  );

  // Handle "select all" toggle
  const handleSelectAll = useCallback(
    (checked: boolean) => {
      setSelectedStaffs({
        selected: checked ? staffsFilterList : [],
        isAllSelected: checked,
      });
    },
    [staffsFilterList],
  );

  // Save selections
  const handleSaveSelections = useCallback(() => {
    const selectionValue = selectedStaffs.isAllSelected ? [...selectedStaffs.selected, { name: 'all' }] : selectedStaffs.selected;

    handleSave({ value: selectionValue, label: '강사 전체' }, 'staffs');
    onClose();
  }, [selectedStaffs, handleSave, onClose]);

  const isOnlySomeChecked = selectedStaffs.selected.length > 0 && !selectedStaffs.isAllSelected;

  useEffect(() => {
    // Drawer가 열릴 때
    if (isOpen) {
      const prevSelected = (selectedOption as ScheduleBookingParamType['staffs']) || [];

      // 이전 선택이 없고 staff 목록이 있으면 전체 선택
      if (prevSelected.length === 0 && staffsFilterList.length > 0) {
        setSelectedStaffs({
          selected: staffsFilterList,
          isAllSelected: true,
        });
      } else {
        // 이전 선택 상태 복원
        setSelectedStaffs({
          selected: prevSelected,
          isAllSelected: prevSelected.length === staffsFilterList.length,
        });
      }
    }
  }, [isOpen, selectedOption, staffsFilterList]);

  return (
    <StyledSingleButtonDrawer
      isOpen={isOpen}
      onClose={onClose}
      header={<OneLinedTitle title="강사 선택" />}
      button={{
        text: '선택 완료',
        onClick: handleSaveSelections,
        disabled: selectedStaffs.selected.length === 0,
      }}>
      <Container isOnlySomeChecked={isOnlySomeChecked}>
        <CheckBox
          variant="box"
          className="header-checkbox"
          id="all"
          label="전체"
          labelFontsize={17}
          labelFontWeight={500}
          gap={16}
          value="all"
          checked={selectedStaffs.isAllSelected}
          iconName={isOnlySomeChecked ? 'lineBold' : 'selectCheckBold'}
          checkColor={isOnlySomeChecked || selectedStaffs.isAllSelected ? 'white' : 'gray5'}
          onChange={e => handleSelectAll(e.target.checked)}
        />
        <Divider thin thinColor="gray6" />
        <StaffList>
          {staffsFilterList.map(staff => (
            <ScheduleStaffItem
              key={staff.id}
              staff={staff}
              isSelected={isStaffSelected(staff, selectedStaffs.selected)}
              isCurrentUser={currentStaffId === staff.id}
              onToggle={handleStaffToggle}
            />
          ))}
        </StaffList>
      </Container>
    </StyledSingleButtonDrawer>
  );
};

export default ScheduleStaffDrawer;

const StyledSingleButtonDrawer = styled(SingleButtonDrawer)`
  padding-left: 0;
  padding-right: 0;
`;

const Container = styled.div<{ isOnlySomeChecked: boolean }>`
  padding: 0 20px;

  hr {
    margin-top: 12px;
  }

  .header-checkbox {
    padding: 8px 0px;
    .input-span-style {
      border-color: ${({ isOnlySomeChecked }) => theme.color[isOnlySomeChecked ? 'primary' : 'gray5']};
      background-color: ${({ isOnlySomeChecked }) => theme.color[isOnlySomeChecked ? 'primary' : 'white']};
    }
  }
`;

const StaffList = styled.ul`
  ${theme.flex('column', '', '', 8)};
  padding: 12px 20px;

  li {
    padding: 8px 0;
  }

  .staff-label-wrapper {
    ${theme.flex('', 'center', '', 8)}

    .staff-label {
      ${theme.flex('', 'center', '', 4)}
    }
  }
`;
