import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import SearchBar from 'components/SearchBar';
import { TicketsParams } from 'hooks/service/queries/useInfinityTickets';
import { PRODUCT_TYPE_TEXT } from 'pages/ProductForm/constants';
import { SelectTicketFormType } from 'pages/ProductForm/types';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

type Props = {
  productType: TicketsParams['product_type'];
  onCancel: () => void;
};

const Search = ({ productType, onCancel }: Props) => {
  const { setValue, control } = useFormContext<SelectTicketFormType>();
  const tempSearchKeyword = useWatch({ control, name: 'tempSearchKeyword' });

  /** 취소 버튼 클릭 시 수강권 조회 리스트 초기화 */
  const cancel = () => {
    setValue('search', undefined, { shouldDirty: true });
    onCancel();
  };

  return (
    <Container>
      <Controller
        name="tempSearchKeyword"
        control={control}
        render={({ field }) => (
          <SearchBar
            id="parent-ticket-search-bar"
            placeholder={`${PRODUCT_TYPE_TEXT[productType]}명 검색`}
            onCancel={cancel}
            onClear={() => {
              setValue('tempSearchKeyword', '', { shouldDirty: true });
            }}
            onSubmitForm={() => setValue('search', tempSearchKeyword, { shouldDirty: true })}
            {...field}
          />
        )}
      />
    </Container>
  );
};

export default Search;

const Container = styled.div`
  padding: 20px;
  border-bottom: 1px solid ${theme.color.gray6};
`;
