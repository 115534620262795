import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Label from 'components/Label';
import Typography from 'components/Typography';
import {
  ScheduleManagementBooking,
  ScheduleManagementBookings,
} from 'hooks/service/queries/useInfinityScheduleManagementBooking';
import { ScheduleManagementDeletedLecture } from 'hooks/service/queries/useInfinityScheduleManagementDeletedLecture';
import { useState } from 'react';
import filters from 'utils/filters';

import { getStatusLabel } from '../util';
import DefaultDetailInfo from './ScheduleDetailInfo/DefaultDetailInfo';
import DeletedDetailInfo from './ScheduleDetailInfo/DeletedDetailInfo';
import DeletedNoBookingDetailInfo from './ScheduleDetailInfo/DeletedNoBookingDetailInfo';

type Props = {
  schedules: ScheduleManagementBookings[][] | ScheduleManagementDeletedLecture[][];
  currentTab: 'booking' | 'checkin' | 'deletedLecture';
};

type DetailInto = {
  schedule: ScheduleManagementBookings | ScheduleManagementDeletedLecture;
  booking: ScheduleManagementBooking;
};

const ScheduleCard = ({ schedules, currentTab }: Props) => {
  const [isShowDrawer, setIsShowDrawer] = useState(false);
  const [scheduleInfo, setScheduleInfo] = useState<ScheduleManagementBookings | ScheduleManagementDeletedLecture | null>(null);
  const [bookingInfo, setBookingInfo] = useState<ScheduleManagementBooking | null>(null);

  const showDetailInfo = (detailInfo: DetailInto) => {
    setIsShowDrawer(true);
    setScheduleInfo(detailInfo.schedule);
    setBookingInfo(detailInfo.booking);
  };

  const showNoBookingDetailInfo = (schedule: ScheduleManagementBookings | ScheduleManagementDeletedLecture) => {
    setIsShowDrawer(true);
    setScheduleInfo(schedule);
  };

  const closeDetailInfo = () => {
    setIsShowDrawer(false);
  };

  return (
    <>
      {schedules.map(scheduleGroup => {
        return (
          <ListContainer key={filters.dateMonthDay(scheduleGroup[0].start_on)}>
            <Typography className="date-text" size={13} weight={500} textColor="gray2">
              {filters.dateMonthDayKor(scheduleGroup[0].start_on)}
            </Typography>
            <CardContainer>
              {scheduleGroup.map(schedule => {
                const isNoBookingLecture = schedule.bookings.length === 0;
                return (
                  <CardWrapper
                    key={schedule.id}
                    onClick={() => (isNoBookingLecture ? showNoBookingDetailInfo(schedule) : undefined)}>
                    <Card isNoBookingLecture={isNoBookingLecture}>
                      <div className="class-info">
                        <div className="class-info-lecture">
                          <Typography size={17} weight={700} textColor="gray1">
                            {filters.timePeriod(schedule.start_on, schedule.end_on)}
                          </Typography>
                          <Typography ellipsis={1} size={15} weight={600} textColor="gray2">
                            {`(${schedule.title})`}
                          </Typography>
                        </div>
                        <div className="class-info-staff">
                          <Typography size={14} weight={400} textColor="gray2">
                            {schedule.staff.name}
                          </Typography>
                          <Typography size={14} weight={400} textColor="gray2">
                            강사
                          </Typography>
                        </div>
                      </div>
                    </Card>
                    {schedule.bookings.map(booking => {
                      const label = getStatusLabel(booking.status);
                      return (
                        <Card isNoBookingLecture={isNoBookingLecture} key={booking.id}>
                          <button onClick={() => showDetailInfo({ schedule, booking })}>
                            <div className="booked-person">
                              <div className="person-info">
                                <Typography ellipsis={1} size={15} weight={600} textColor="gray1">
                                  {booking.member.name}
                                </Typography>
                                <Typography size={14} weight={400} textColor="gray2" opacity={0.8}>
                                  {filters.mobile(booking.member.mobile) ?? '-'}
                                </Typography>
                              </div>
                              <Label
                                size="large"
                                minWidth={57}
                                textColor={label.textColor}
                                color={label.color}
                                opacity={0.12}
                                fontSize={13}
                                borderRadius={4}>
                                {label.name}
                              </Label>
                            </div>
                          </button>
                        </Card>
                      );
                    })}
                  </CardWrapper>
                );
              })}
            </CardContainer>
          </ListContainer>
        );
      })}
      {isShowDrawer &&
        (bookingInfo ? (
          currentTab === 'deletedLecture' ? (
            <DeletedDetailInfo
              isOpen={isShowDrawer}
              scheduleInfo={scheduleInfo as ScheduleManagementDeletedLecture}
              bookingInfo={bookingInfo}
              onClose={closeDetailInfo}
            />
          ) : (
            <DefaultDetailInfo
              isOpen={isShowDrawer}
              currentTab={currentTab}
              scheduleInfo={scheduleInfo as ScheduleManagementBookings}
              bookingInfo={bookingInfo}
              onClose={closeDetailInfo}
            />
          )
        ) : (
          currentTab === 'deletedLecture' && (
            <DeletedNoBookingDetailInfo
              isOpen={isShowDrawer}
              scheduleInfo={scheduleInfo as ScheduleManagementDeletedLecture}
              onClose={closeDetailInfo}
            />
          )
        ))}
    </>
  );
};

export default ScheduleCard;

const ListContainer = styled.div`
  .date-text {
    padding-bottom: 20px;
  }

  :not(:first-of-type) {
    .date-text {
      padding-top: 48px;
    }
  }
`;

const CardContainer = styled.div`
  ${theme.flex('column', '', '', 12)};
`;

const CardWrapper = styled.div`
  padding: 18px 20px;
  border-radius: 16px;
  background-color: ${theme.color.white};
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08);

  > div {
    &:last-of-type {
      button {
        padding-top: 14px;
        padding-bottom: 0px;
      }
    }
  }
`;

const Card = styled.div<{ isNoBookingLecture: boolean }>`
  button {
    width: 100%;
    padding: 14px 0px;
  }

  .class-info {
    padding-bottom: ${({ isNoBookingLecture }) => (isNoBookingLecture ? '0px' : '16px')};

    &-lecture {
      ${theme.flex('row', 'center', '', 4)}
      padding-bottom: 2px;

      p:first-of-type {
        flex-basis: 1;
        flex-shrink: 0;
      }
    }

    &-staff {
      ${theme.flex('', '', '', 2)}
    }
  }

  .booked-person {
    ${theme.flex('', 'center', 'space-between', 8)}

    .person-info {
      text-align: left;
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${theme.color.gray7};
  }
`;
