import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { calendarViewTypeAtom } from 'recoil/schedule';

type Props = {
  title: string;
  cardColor: string;
  sameTimeEvents: number;
};

const EventCounselCard = ({ ...props }: Props) => {
  const { title, cardColor, sameTimeEvents } = props;

  const calendarViewType = useRecoilValue(calendarViewTypeAtom);

  const isWeekView = useMemo(() => calendarViewType === 'week', [calendarViewType]);

  const currentTitle = useMemo(() => {
    if (!isWeekView || sameTimeEvents !== 2) return `${title} 님`;
    return `${title} 님`.replaceAll('', '\n').trim();
  }, [isWeekView, sameTimeEvents, title]);

  return (
    <Container>
      <StyledCard cardColor={cardColor} isWeekView={isWeekView} sameTimeEvents={sameTimeEvents}>
        <Typography className="title" size={isWeekView ? 12 : 14} weight={600}>
          {currentTitle}
        </Typography>

        <Typography className="info-text" size={13} weight={500} textColor="gray2" opacity={0.8}>
          상담
        </Typography>
      </StyledCard>
    </Container>
  );
};

export default EventCounselCard;

/** 카드 색상에 알파값이 들어가서, 뒤에 세팅할 배경 */
const Container = styled.div`
  height: 100%;
  background-color: ${theme.color.white};
`;

const StyledCard = styled.div<{ cardColor: string; isWeekView: boolean; sameTimeEvents: number }>(
  ({ cardColor, sameTimeEvents }) =>
    cardColor &&
    css`
      ${theme.flex(sameTimeEvents > 3 ? 'column' : 'row', 'flex-start', sameTimeEvents > 3 ? '' : 'space-between')};
      flex-wrap: wrap;
      align-content: flex-start;
      padding: 5px 6px;
      height: 100%;
      border-radius: 1px 4px 4px 1px;
      border-left: 2px solid ${cardColor};
      background-color: ${`${cardColor}1f`};
      overflow: hidden;

      .title,
      .info-text {
        min-width: max-content;
      }

      .info-text {
        margin-top: 1px;
      }
    `,

  /** 주간(week) 상담 카드 */
  ({ isWeekView }) =>
    isWeekView &&
    css`
      gap: 4px;
      margin: 0px 3px 0 0;
      padding: 4px 1px;
      border-radius: 1px 2px 2px 1px;

      .title,
      .info-text {
        min-width: unset;
        white-space: pre-wrap;
      }
    `,
);
