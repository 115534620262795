import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import CheckBox from 'components/CheckBox';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import dayjs from 'dayjs';
import { omit } from 'lodash';
import { CommonProps, ScheduleFormType } from 'pages/MoreDetails/Staff/Detail/EventScheduleSingle/types';
import { Dispatch, SetStateAction, useState } from 'react';
import SubHeader from 'sharedComponents/ProfileField/components/EventWorkTime/components/SubHeader';
import { EVENT_WORK_TIME_TITLE } from 'sharedComponents/ProfileField/components/EventWorkTime/constants';
import filters from 'utils/filters';
import staffUtils from 'utils/staffSchedules';

import { CLOSED_DAY_TIME } from '../../WorkTime/constants';
import EventRestTimeAccordion from './EventRestTimeAccordion';
import WorkTimeAccordionMulti from './WorkTimeAccordionMulti';

type Props = Pick<CommonProps, 'workTimeField'> & {
  setDatasetFields: Dispatch<SetStateAction<ScheduleFormType[]>>;
  isShowBatchApply: boolean;
};

const EventWorkTimeCardMulti = ({ workTimeField, setDatasetFields, isShowBatchApply }: Props) => {
  const isClosedDay = workTimeField.start_time === CLOSED_DAY_TIME.start && workTimeField.end_time === CLOSED_DAY_TIME.end;
  const [isFieldClosedDay, setIsFieldClosedDay] = useState(isClosedDay);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [tempTimes, setTempTimes] = useState<[string, string]>([workTimeField.start_time, workTimeField.end_time]);

  const appendRestTimeField = (customId: string) => {
    setDatasetFields(prev => {
      const appended = prev.map(data => {
        if (data.date !== workTimeField.date) return data;

        const end_time = dayjs(`${dayjs().format('YYYY-MM-DD')} ${workTimeField.start_time}`)
          .add(30, 'minute')
          .format('HH:mm:ss');

        const appendRestTime: ScheduleFormType = {
          ...omit(data, 'restTimes'),
          id: customId,
          type: 'eventRestTime',
          end_time,
        };

        return {
          ...data,
          restTimes: [...(data.restTimes as ScheduleFormType[]), appendRestTime],
        };
      });
      return appended;
    });
  };

  const removeRestTimeField = (index: number, date: string) => {
    setDatasetFields(prev => {
      const removedData = prev.map(data => {
        if (data.date !== date) return data;
        return {
          ...data,
          restTimes: data.restTimes?.filter((_, i) => i !== index),
        };
      });
      return removedData;
    });
  };

  const updateRestTimeField = (index: number, date: string, times: { start_time: string; end_time: string }) => {
    setDatasetFields(prev => {
      const updatedData = prev.map(data => {
        if (data.date !== date) return data;
        return {
          ...data,
          restTimes: data.restTimes?.map((restTime, i) => {
            if (i !== index) return restTime;
            return {
              ...restTime,
              ...times,
            };
          }),
        };
      });
      return updatedData;
    });
  };

  const batchApplyAction = () => {
    setDatasetFields(prev => {
      const batchUpdatedData = prev.map(data => {
        const isClosedDay = data.start_time === CLOSED_DAY_TIME.start && data.end_time === CLOSED_DAY_TIME.end;
        if (isClosedDay || data.day_of_week !== workTimeField.day_of_week) return data;
        return {
          ...data,
          start_time: workTimeField.start_time,
          end_time: workTimeField.end_time,
          restTimes:
            workTimeField.restTimes?.map(restTime => {
              return {
                ...restTime,
                date: data.date,
              };
            }) || [],
        };
      });
      return batchUpdatedData;
    });
  };

  return (
    <Container>
      <section className="card-header">
        <Typography size={15} weight={600} textColor="gray1">
          {filters.dateWithWeekday(workTimeField.date)}
        </Typography>

        <CheckBox
          id={`close-day-${workTimeField.date}`}
          label={EVENT_WORK_TIME_TITLE.closed}
          gap={8}
          checked={isFieldClosedDay}
          labelFontWeight={500}
          onChange={({ target: { checked } }) => {
            if (checked) {
              setTempTimes([workTimeField.start_time, workTimeField.end_time]);
              setIsAccordionOpen(false);
            }

            const defaultTimes = {
              start: tempTimes[0] === CLOSED_DAY_TIME.start ? '09:00:00' : tempTimes[0],
              end: tempTimes[1] === CLOSED_DAY_TIME.end ? '18:00:00' : tempTimes[1],
            };

            setDatasetFields(prev => {
              const updated = prev.map(data => {
                if (data.date !== workTimeField.date) return data;
                return {
                  ...data,
                  type: checked ? 'eventRestTime' : 'eventWorkTime',
                  start_time: checked ? CLOSED_DAY_TIME.start : defaultTimes.start,
                  end_time: checked ? CLOSED_DAY_TIME.end : defaultTimes.end,
                };
              });
              return updated as ScheduleFormType[];
            });
            setIsFieldClosedDay(checked);
          }}
        />
      </section>

      {!isFieldClosedDay && (
        <>
          <section className="work-time-info">
            <SubHeader
              title={EVENT_WORK_TIME_TITLE.work}
              diffText={staffUtils.workTimeDiff(workTimeField.start_time, workTimeField.end_time)}
            />
            <WorkTimeAccordionMulti
              workTimeField={workTimeField}
              setDatasetFields={setDatasetFields}
              isAccordionOpen={isAccordionOpen}
              setIsAccordionOpen={setIsAccordionOpen}
            />
          </section>

          <Divider thin />

          <section className="rest-time-info">
            <SubHeader
              title={EVENT_WORK_TIME_TITLE.rest}
              diffText={workTimeField.restTimes ? staffUtils.restTimeDiffTest(workTimeField.restTimes, workTimeField) : '0'}
              onClick={(customId: string) => appendRestTimeField(customId)}
            />
            <ul className="rest-time-list">
              {workTimeField.restTimes &&
                workTimeField.restTimes.map((restTimeField, index, list) => {
                  return (
                    <li key={`${restTimeField.day_of_week}-${restTimeField.id}`}>
                      {index > 0 && <Divider thin />}

                      <div className="rest-time-accordion-wrapper">
                        <EventRestTimeAccordion
                          restTimeField={restTimeField}
                          isLastIndex={index === list.length - 1}
                          restTimeIndex={index}
                          appendRestTimeField={(customId: string) => appendRestTimeField(customId)}
                          removeRestTimeField={removeRestTimeField}
                          updateRestTimeField={updateRestTimeField}
                        />
                      </div>
                    </li>
                  );
                })}
            </ul>
          </section>

          {isShowBatchApply && (
            <div className="batch-apply-button">
              <Button
                variant="outlined"
                size="small"
                widthSize={124}
                fontSize={13}
                fontWeight={600}
                textColor="gray3"
                onClick={batchApplyAction}>
                같은 요일 일괄 적용
              </Button>
            </div>
          )}
        </>
      )}
    </Container>
  );
};

export default EventWorkTimeCardMulti;

const Container = styled.section`
  padding: 18px 0;
  width: 100%;
  border-radius: 16px;
  background-color: ${theme.color.white};
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08);

  &:not(:last-of-type) {
    margin-bottom: 12px;
  }

  .card-header {
    ${theme.flex('row', 'center', 'space-between')};
    padding: 0 20px;

    .checkbox > .typography {
      font-size: 1.4rem;
      color: ${theme.color.gray2} !important;
    }
  }

  .work-time-info {
    margin-top: 32px;
  }

  .rest-time-info {
    margin-top: 16px;
  }

  .work-time-info,
  .rest-time-info {
    > div {
      ${theme.flex('row', 'center', 'space-between')};

      &:first-of-type {
        margin-bottom: 8px;
      }

      .accordion {
        .header-box,
        .rest-time-info {
          width: 100%;
        }

        .rest-time-info {
          ${theme.flex('row', 'center', 'space-between')};
        }

        .header-wrapper {
          margin-bottom: 16px;
        }

        .header-wrapper.accordion__large {
          padding: 0;
          height: 22px;
        }
      }
    }

    .accordion {
      width: 100%;
    }

    .add-rest-time {
      margin-top: 16px;
    }
  }

  .rest-time-info {
    .header-wrapper {
      margin-bottom: 12px;
      padding: 0;
      height: inherit;
    }

    li:not(:first-of-type) > hr {
      margin-bottom: 12px;
    }

    li:is(:last-of-type) {
      .header-wrapper {
        margin-bottom: 0;
      }
    }
  }

  .batch-apply-button {
    ${theme.flex('', '', 'flex-end')};
    margin-top: 18px;
    padding: 0 18px;
  }
`;
