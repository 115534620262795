import styled from '@emotion/styled';
import Divider from 'components/Divider';
import Form from 'components/Form';
import { TicketsResponse } from 'hooks/service/queries/useInfinityTickets';
import { ComponentProps } from 'react';
import TicketCard from 'sharedComponents/TicketCard';
import { FormatTicketCard } from 'sharedComponents/TicketCard/types';
import {
  PaymentSubmitButton,
  SelectStaffs,
  ShareTicketSwitch,
  TicketCountForm,
  TicketFormContainer,
  TicketPaymentForm,
  TicketPeriodForm,
} from 'sharedComponents/TicketForm';

import { PRODUCT_FORM_TEXT } from '../constants';

type Props = Omit<ComponentProps<typeof Form>, 'children'> &
  Pick<ComponentProps<typeof TicketCountForm>, 'minMaxCoupon' | 'minRemainingCoupon'> & {
    pageMode: 'create' | 'update';
    ticket: FormatTicketCard;
    type: TicketsResponse['type'];
    isShowCancelCount: boolean;
    isItem: boolean;
    disabled?: boolean;
  };

const CommonProductForm = ({
  pageMode,
  ticket,
  type,
  isShowCancelCount,
  isItem,
  disabled,
  minMaxCoupon,
  minRemainingCoupon,
  ...props
}: Props) => {
  return (
    <TicketFormContainer {...props}>
      <StyledTicketCard ticket={ticket} />
      <Divider />
      <TicketPeriodForm ticketType={type} />
      <TicketCountForm
        ticketType={type}
        isShowCancelCount={isShowCancelCount}
        minMaxCoupon={minMaxCoupon}
        minRemainingCoupon={minRemainingCoupon}
      />
      {!isItem && <ShareTicketSwitch />}
      <SelectStaffs />
      <Divider />
      <TicketPaymentForm />
      <PaymentSubmitButton>{PRODUCT_FORM_TEXT.footerButton[pageMode]}</PaymentSubmitButton>
    </TicketFormContainer>
  );
};

export default CommonProductForm;

const StyledTicketCard = styled(TicketCard)`
  position: relative;
`;
