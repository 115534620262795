import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import useDeleteBooking from 'hooks/service/mutations/useDeleteBooking';
import useToast from 'hooks/useToast';
import { HistoryFilterFormType } from 'pages/MemberDetail/types';
import { useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

type Props = {
  onClose: () => void;
};

const DeleteHistory = ({ onClose }: Props) => {
  const { control } = useFormContext<HistoryFilterFormType>();
  const id = useWatch({ control, name: 'bookingInfo.id' });

  const { setToast } = useToast();
  const { mutate: deleteBookingMutate, isPending } = useDeleteBooking(id);

  const clickDelete = useCallback(() => {
    deleteBookingMutate(undefined, {
      onSuccess: () => {
        setToast({ type: 'success', message: '내역이 삭제되었습니다.' });
        onClose();
      },
    });
  }, [deleteBookingMutate, onClose, setToast]);

  return (
    <Dialog
      onClose={onClose}
      title="내역을 삭제하시겠습니까?"
      negativeAction={{ text: '취소', disabled: isPending }}
      positiveAction={{
        text: '삭제',
        disabled: isPending,
        onClick: clickDelete,
      }}>
      <DialogDescription>
        삭제된 내역은 복구할 수 없으며, <br />
        예약내역에서도 확인할 수 없습니다.
      </DialogDescription>
    </Dialog>
  );
};

export default DeleteHistory;
