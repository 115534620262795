import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import dayjs from 'dayjs';
import { addNewTicket, getCurrentWaitingCount, isWaitingStatus } from 'pages/Booking/utils/bookings';
import { useFormContext } from 'react-hook-form';

import { BookingFormType, BookingUserTicket } from '../types';

type Props = {
  overBookingTicket: BookingUserTicket;
  onClose: () => void;
  openWaitingCloseDialog: () => void;
};

const ExceedWaitingDialog = ({ overBookingTicket, openWaitingCloseDialog, onClose }: Props) => {
  const { getValues, watch, setValue } = useFormContext<BookingFormType>();
  const {
    currentLecture: { start_on, waiting_trainee_limit },
  } = getValues();
  const { selectedUserTickets } = watch();
  const isPastLecture = dayjs(start_on).isBefore();
  const currentWaitingCount = getCurrentWaitingCount(selectedUserTickets);
  const negativeText = isPastLecture ? '취소' : '초과예약';
  const positiveText = isPastLecture ? '초과예약' : '예약대기';
  const josa = isPastLecture ? '을' : '를';

  const bookingExceed = () => {
    if (!overBookingTicket) return;

    // 초과예약
    const addUserTicket = addNewTicket({
      selectedUserTickets,
      userTicket: overBookingTicket,
      status: 'new_booked',
    });
    const index = selectedUserTickets.findIndex(({ status }) => isWaitingStatus(status));
    const newArray = [...selectedUserTickets];
    // 대기가 있는 상태에서 초과예약 선택하면 대기 1번 위로 추가되도록
    if (index !== -1) {
      newArray.splice(index, 0, addUserTicket);
    } else {
      // 대기 없으면 그냥 맨 마지막에 추가
      newArray.push(addUserTicket);
    }
    setValue('selectedUserTickets', newArray, { shouldDirty: true });
    onClose();
  };

  const clickLeft = () => {
    // 취소
    if (isPastLecture) {
      onClose();
    } else {
      // 초과예약
      bookingExceed();
    }
  };

  const clickRight = () => {
    if (!overBookingTicket) return;
    // 과거 수업일 때 초과예약
    if (isPastLecture) {
      bookingExceed();
    } else {
      // 예약대기 인원마감
      const closeWaiting = !!waiting_trainee_limit && currentWaitingCount >= waiting_trainee_limit;
      if (closeWaiting) {
        openWaitingCloseDialog();
      } else {
        const waitingUserTicket = addNewTicket({
          selectedUserTickets,
          userTicket: overBookingTicket,
          status: 'new_booking_waiting',
        });
        setValue('selectedUserTickets', [...selectedUserTickets, waitingUserTicket], { shouldDirty: true });
        onClose();
      }
    }
  };

  return (
    <Dialog
      showCloseButton={!isPastLecture}
      onClose={onClose}
      negativeAction={{ text: negativeText, onClick: clickLeft, style: isPastLecture ? 'outlined' : 'contained' }}
      positiveAction={{ text: positiveText, onClick: clickRight }}>
      <DialogDescription>
        예약 정원이 마감되었습니다.
        <br />
        <b>[초과예약{`${!isPastLecture ? 'or 예약대기' : ''}`}]</b>
        {josa} 하시겠습니까?
      </DialogDescription>
    </Dialog>
  );
};

export default ExceedWaitingDialog;
