import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Drawer from 'designedComponents/Drawers';
import OneLinedTitle from 'designedComponents/Drawers/DrawerTitle/OneLinedTitle';
import { LectureBookingListResponse } from 'hooks/service/queries/useInfinityBookingList';
import { BookingStatusType } from 'pages/Booking/types';
import formatChangeStatusList from 'pages/Booking/utils/detail/formatChangeStatusList';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  booking: LectureBookingListResponse;
  canCancelBooking: boolean;
  canChangeBooking: boolean;
  onClick: (value: BookingStatusType['value']) => void;
};

const ChangeBookingStatusDrawer = ({ isOpen, booking, canCancelBooking, canChangeBooking, onClick, onClose }: Props) => {
  const buttonArray = formatChangeStatusList({ booking, canCancelBooking, canChangeBooking });

  const clickStatus = (value: BookingStatusType['value']) => {
    onClick(value);
    onClose();
  };

  return (
    <StyledDrawer isOpen={isOpen} onClose={onClose} header={<OneLinedTitle title={booking?.member.name} />}>
      {buttonArray.map(({ label, value }) => {
        return (
          <Button
            key={value}
            fullWidth
            size="medium40"
            disabled={value === 'cancel' && !canCancelBooking}
            fontSize={17}
            fontWeight={400}
            onClick={() => clickStatus(value)}>
            {label}
          </Button>
        );
      })}
    </StyledDrawer>
  );
};

export default ChangeBookingStatusDrawer;

const StyledDrawer = styled(Drawer)`
  ${theme.flex('column', 'center', 'flex-start', 8)};
  border-top: none;

  .drawer-header-observer-target {
    display: none;
  }

  button {
    justify-content: flex-start;
  }
`;
