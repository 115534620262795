import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import IconButton from 'components/Button/IconButton';
import DatePicker from 'components/DatePicker';
import Icon from 'components/Icon';
import Label from 'components/Label';
import dayjs from 'dayjs';
import useGetFilterStaffs from 'hooks/service/queries/useGetFilterStaffs';
import { HolidayTargetResponse } from 'hooks/service/queries/useGetHolidayTarget';
import usePermission from 'hooks/usePermission';
import usePopup from 'hooks/usePopup';
import { isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { currentPermissionsAtom } from 'recoil/calendarSettings';
import { staffIdAtom } from 'recoil/common';
import {
  calendarViewTypeAtom,
  currentDateAtom,
  headerDatePickerOpenAtom,
  selectedFiltersAtom,
  tempApplyStaffsAtom,
  tempCheckedStaffsAtom,
} from 'recoil/schedule';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import filters from 'utils/filters';

import ViewTypeSelectDropdown from './dropdown/ViewTypeSelectDropdown';
import FilterSettings from './popupPage/FilterSettings';

type Props = {
  holidayTargets: HolidayTargetResponse;
};

const CustomCalendarHeader = ({ holidayTargets }: Props) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useRecoilState(headerDatePickerOpenAtom);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const currentPermissions = useRecoilValue(currentPermissionsAtom);

  const calendarViewType = useRecoilValue(calendarViewTypeAtom);
  const [selectedFilters, setSelectedFilters] = useRecoilState(selectedFiltersAtom);
  const setTempApplyStaffs = useSetRecoilState(tempApplyStaffsAtom);
  const setTempCheckedStaffs = useSetRecoilState(tempCheckedStaffsAtom);
  const [currentDate, setCurrentDate] = useRecoilState(currentDateAtom);
  const staffId = useRecoilValue(staffIdAtom);

  const { everyPermissions } = usePermission();

  /** 다른 스태프의 일정 조회 권한들 중 하나라도 있으면 true */
  const isEveryPermission = everyPermissions(currentPermissions);

  const { setPopup } = usePopup();

  const { data: allStaffs, isLoading } = useGetFilterStaffs();

  /** 비동기 초기값 세팅을 위한 이펙트 */
  useEffect(() => {
    if (selectedFilters.staffs.includes('all') && !isLoading) {
      setSelectedFilters({ ...selectedFilters, staffs: initialStaffIds });
      setTempApplyStaffs(initialStaffIds);
      setTempCheckedStaffs(initialStaffIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const changeDate = (e: { value: Date }) => setCurrentDate(e.value);

  const openFilterPopupPage = () => {
    setPopup(
      <ApiBoundary>
        <FilterSettings allStaffs={allStaffs} isEveryPermission={isEveryPermission} />
      </ApiBoundary>,
    );
  };

  const headerDateText = useMemo(() => {
    const format = calendarViewType === 'day' ? 'MM.DD (ddd)' : 'YYYY.MM';
    return dayjs(currentDate).format(format);
  }, [calendarViewType, currentDate]);

  /** staffs 필터 비동기 초기값 세팅 */
  const initialStaffIds = useMemo(() => {
    if (!isEveryPermission) return [staffId];
    return ['all' as const];
  }, [isEveryPermission, staffId]);

  const isApplyFilter = useMemo(() => {
    const isSchedulesDirty = !isEqual(selectedFilters.schedules, ['all']);
    const isStaffsDirty = !isEqual(selectedFilters.staffs, initialStaffIds);
    return isSchedulesDirty || isStaffsDirty;
  }, [selectedFilters, initialStaffIds]);

  const isShowHolidayLabel = useMemo(() => {
    /** 주간, 월간은 헤더가 아니라 캘린더 자체에 표기 */
    if (calendarViewType !== 'day') return false;
    return holidayTargets[0] === filters.dateDash(currentDate);
  }, [calendarViewType, currentDate, holidayTargets]);

  return (
    <Container>
      <div className="header-wrapper">
        <div className="header-days-controls">
          <Button
            fontSize={19}
            fontWeight={700}
            textColor="gray1"
            rightIcon={<Icon name={isDatePickerOpen ? 'arrowTopBold' : 'arrowBottomBold'} size={16} />}
            onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}>
            {headerDateText}
          </Button>

          {isShowHolidayLabel && (
            <Label size="small" minWidth={32} textColor="secondary3" color="secondary3" opacity={0.16}>
              휴일
            </Label>
          )}
        </div>

        <div className="icons-wrapper">
          <IconButton
            className={isApplyFilter ? 'apply-filter' : undefined}
            onClick={isLoading ? undefined : openFilterPopupPage}>
            <Icon name="headerFilter" />
          </IconButton>

          <ViewTypeSelectDropdown isOpen={isDropdownOpen} setIsOpen={setIsDropdownOpen} />
        </div>
      </div>

      {isDatePickerOpen && <DatePicker dateFormat={'YYYY/MM/DD'} value={currentDate} onChange={changeDate} />}
    </Container>
  );
};

export const Container = styled.div`
  width: 100%;

  .header-wrapper {
    ${theme.flex('row', 'center', 'space-between')}
    margin: 0 20px;
    padding: 20px 0;
    background-color: ${theme.color.white};

    .header-days-controls {
      ${theme.flex('row', 'center', '', 8)};
    }
  }

  .icons-wrapper {
    ${theme.flex('', '', '', 16)};

    .apply-filter {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 4px;
        height: 4px;
        background-color: ${theme.color.secondary3};
        border-radius: 50%;
      }
    }
  }

  .date-picker {
    position: absolute;
    width: 100%;
    left: 0;
  }

  .mbsc-datepicker-tab-wrapper .mbsc-datepicker-tab {
    padding: 16px 0;
    border-bottom: 1px solid ${theme.color.gray5};
  }
`;

export default CustomCalendarHeader;
