import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import CheckBox from 'components/CheckBox';
import Typography from 'components/Typography';
import { Dispatch, SetStateAction } from 'react';
import { SelectedFiltersAtomType } from 'recoil/schedule/types';

import { FILTER_MENUS } from '../constants';

type Props = {
  tempSchedules: SelectedFiltersAtomType['schedules'];
  setTempSchedules: Dispatch<SetStateAction<SelectedFiltersAtomType['schedules']>>;
};

const FilterScheduleItems = ({ tempSchedules, setTempSchedules }: Props) => {
  const changeFilterSchedules = (e: { target: { id: string; checked: boolean } }) => {
    const filterValue = e.target.id;
    const isChecked = e.target.checked;

    if (filterValue === 'all' && !isChecked) return;

    if (isChecked) {
      const newTempType = [...tempSchedules, filterValue];

      /** '전체'를 제외한 모든 필터 선택 시 */
      const isEverySelected = ['counsel', 'group', 'private', 'etcSchedule'].every(type => newTempType.includes(type));
      /** '전체' 필터를 선택했을 때 */
      const isAllSelected = newTempType.length > 1 && newTempType[newTempType.length - 1] === 'all';
      /** '전체'가 아닌 다른 필터를 선택했을 때 */
      const isOthersSelected = filterValue !== 'all';

      if (isEverySelected || isAllSelected) {
        setTempSchedules(['all']);
        return;
      }

      /** '전체'가 아닌 다른 필터를 선택했을 때 */
      if (isOthersSelected) {
        const newSchedules = newTempType.filter(type => type !== 'all');
        setTempSchedules(newSchedules);
      }
    } else {
      const newFilters = tempSchedules.filter(value => value !== filterValue);
      if (newFilters.length) {
        setTempSchedules(newFilters);
      } else {
        setTempSchedules(['all']);
      }
    }
  };

  return (
    <Container>
      {FILTER_MENUS.map(({ value, label }) => {
        const isChecked = tempSchedules.includes(value);
        return (
          <CheckBox
            key={value}
            id={value}
            label={
              <StyledLabel isChecked={isChecked}>
                <Typography weight={isChecked ? 600 : 400} textColor={isChecked ? 'primary' : 'gray2'}>
                  {label}
                </Typography>
              </StyledLabel>
            }
            checked={isChecked}
            onChange={changeFilterSchedules}
          />
        );
      })}
    </Container>
  );
};

export default FilterScheduleItems;

const Container = styled.ul`
  ${theme.flex('', '', '', 6)};
  flex-wrap: wrap;
`;

const StyledLabel = styled.div<{ isChecked: boolean }>`
  ${theme.flex()};
  padding: 0 16px;
  width: max-content;
  height: 32px;
  border: 1px solid ${({ isChecked }) => theme.color[isChecked ? 'primary' : 'gray5']};
  border-radius: 999px;
`;
