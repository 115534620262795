import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { messageTargetCounselSelectedFilter } from 'recoil/message';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import HeaderSearchButton from 'sharedComponents/HeaderSearchButton';
import MainLayout from 'sharedComponents/MainLayout';

import { MessageTargetFormType, MessageType } from '../../types';
import { MESSAGE_TARGET_IDENTIFIER, MESSAGE_TARGET_TEXT } from '../constants';
import TargetMemberSkeletonList from '../Member/components/TargetMemberSkeletonList';
import CompleteButton from './components/CompleteButton';
import CounselList from './components/CounselList';
import StickyHeader from './components/StickyHeader';
import { convertMessageFilterToCounselFilter } from './utils';

type Props = {
  goToForm: (updater?: (state: MessageType) => MessageType) => void;
};

const TargetCounsel = ({ goToForm }: Props) => {
  const state = useLocation().state as MessageType | undefined;

  const methods = useForm<MessageTargetFormType>({
    defaultValues: {
      selected: state?.counseling_logs || [],
      isAllSelected: false,
    },
  });

  const setSelectedFilter = useSetRecoilState(messageTargetCounselSelectedFilter);

  useEffect(() => {
    if (!state?.filter) return;

    // 발송당시 검색필터 적용, 검색어 제외
    const { keyword, ...filter } = state.filter;
    setSelectedFilter(convertMessageFilterToCounselFilter(filter));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout
      header={{
        title: '상담고객 목록',
        leftButton: (
          <IconButton onClick={() => goToForm()}>
            <Icon name="headerBack" />
          </IconButton>
        ),
        rightButton: <HeaderSearchButton identifier={MESSAGE_TARGET_IDENTIFIER} placeholder={MESSAGE_TARGET_TEXT.placeholder} />,
        bgColor: 'white',
        noUnderline: true,
      }}
      backgroundColor="gray6">
      <FormProvider {...methods}>
        <StickyHeader />
        <ApiBoundary fallback={<TargetMemberSkeletonList type="counsel" />}>
          <CounselList />
        </ApiBoundary>
        <CompleteButton goToForm={goToForm} />
      </FormProvider>
    </MainLayout>
  );
};

export default TargetCounsel;
