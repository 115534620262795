import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { NAME_SORT_FILTERS } from 'pages/MoreDetails/Staff/List/constants';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { staffListPageAtom } from 'recoil/staff';

type Props = {
  sort: ('asc' | 'desc') | ('nameAsc' | 'nameDesc' | 'dateAsc' | 'dateDesc');
};

const MoreDetailStaffNoList = ({ sort }: Props) => {
  const { search } = useRecoilValue(staffListPageAtom);

  const sortLabel = useMemo(() => {
    return NAME_SORT_FILTERS.filter(data => sort === data.value)[0].label;
  }, [sort]);

  return (
    <Container>
      <section className="list-content-header">
        <Typography size={13} weight={500} textColor="gray3">
          총<span className="total-count">0</span>명
        </Typography>

        <div>
          <Button
            fontWeight={400}
            textColor="gray2"
            rightIcon={<Icon name="arrowBottomFill" size={16} fillColor={theme.color.gray3} />}
            onClick={() => null}>
            {sortLabel}
          </Button>
        </div>
      </section>

      <div className="no-content">
        <Typography size={15} lineHeight={22} textColor="gray2">
          {search ? (
            <>
              <span>{`"${search}"`}</span>의 검색 결과가 없습니다.
            </>
          ) : (
            '담당회원 정보가 없습니다.'
          )}
        </Typography>
      </div>
    </Container>
  );
};

export default MoreDetailStaffNoList;

const Container = styled.div`
  ${theme.flex('column', 'center', 'flex-start')};
  height: 240px;

  .typography > span {
    font-weight: 600;
    color: ${theme.color.primary};
  }

  .list-content-header {
    ${theme.flex('row', 'center', 'space-between')};
    margin-bottom: 16px;
    padding: 24px 20px 0;
    width: 100%;

    .typography > .total-count {
      margin-left: 4px;
      color: ${theme.color.primary};
      font-weight: 600;
    }
  }

  .no-content {
    ${theme.flex()};
    padding: 0 20px;
    height: 240px;
  }
`;
