import { MATCH_REPRESENTATIVE_COLOR } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import { PERMISSION } from 'constants/permission';
import dayjs from 'dayjs';
import useGetLectureDetail from 'hooks/service/queries/useGetLectureDetail';
import useGetPermissionDoHavePermission from 'hooks/service/queries/useGetPermissionDoHavePermission';
import useCheckPermissionBookingDetail from 'hooks/useCheckPermissionBookingDetail';
import { DropdownType } from 'pages/Booking/types';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { customBackHandlersAtom, errorBackStepAtom, staffIdAtom } from 'recoil/common';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import MainLayout from 'sharedComponents/MainLayout';
import { Color } from 'utils/getColor';
import getContrastTextColor from 'utils/getContrastTextColor';

import { BOOKING_STATUS_CHANGE_PERMISSION } from '../constants';
import Bookings from './Bookings';
import BookingDetailDropDown from './LectureInfo/BookingDetailDropDown';
import BookingOpenCloseDialog from './LectureInfo/BookingOpenCloseDialog';
import ColoredDetailInfo from './LectureInfo/ColoredDetailInfo';
import LectureDelete from './LectureInfo/LectureDelete';
import LectureUpdateListDrawer from './LectureInfo/LectureUpdateListDrawer';

type Props = {
  currentLectureId: number;
};

const DetailMain = ({ currentLectureId }: Props) => {
  const { data } = useGetLectureDetail(currentLectureId);
  const bookingType = data.type === 'G' ? 'group' : 'private';
  const { canViewLecture, canCreateLecture, canUpdateLecture, canDeleteLecture, canCancelBooking, canChangeBooking } =
    useCheckPermissionBookingDetail(data.staff.id, bookingType);
  const { checkPermission } = useGetPermissionDoHavePermission();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const [openCloseDialog, setOpenCloseDialog] = useState<'open' | 'close' | null>(null);
  const [isOpenDrawer, setIsOpenDrawer] = useState<'update' | 'delete' | null>(null);
  const currentStaffId = useRecoilValue(staffIdAtom);
  const setErrorBackStep = useSetRecoilState(errorBackStepAtom);
  const resetCustomBackHandler = useResetRecoilState(customBackHandlersAtom);

  const bgColor = MATCH_REPRESENTATIVE_COLOR[data.staff.representative_color];
  const contrastColor = getContrastTextColor(bgColor) as Color;
  const isPastLecture = dayjs(data.start_on).isBefore();
  const isMe = data.staff.id === currentStaffId;

  const goBookingUpdateForm = (mode: string) => {
    setIsOpenDrawer(null);
    resetCustomBackHandler();
    navigate(`/booking/${bookingType}/form/${mode}/${currentLectureId}`);
  };

  const getPermissionId = (action: DropdownType['value']) => {
    const currentAction = (action === 'copy' ? 'create' : action === 'open' || action === 'close' ? 'update' : action) as
      | 'create'
      | 'update'
      | 'delete';
    const permission = BOOKING_STATUS_CHANGE_PERMISSION[isMe ? 'mine' : 'others'][bookingType][currentAction];
    return permission.id;
  };

  const onError = () => {
    if (isMe) {
      setErrorBackStep({
        to: pathname,
        options: { replace: true },
      });
    } else {
      checkPermission(PERMISSION.lecture.others.editLecture[bookingType].id, {
        onError: () => {
          setErrorBackStep({
            to: '/schedule',
            options: { replace: true },
          });
        },
      });
    }
  };

  const clickDropDownItem = (value: DropdownType['value']) => {
    switch (value) {
      case 'open':
        checkPermission(getPermissionId(value), {
          onSuccess: () => setOpenCloseDialog('open'),
          onError,
        });
        break;
      case 'close':
        checkPermission(getPermissionId(value), {
          onSuccess: () => setOpenCloseDialog('close'),
          onError,
        });
        break;
      case 'update':
        checkPermission(getPermissionId(value), {
          onSuccess: () => {
            if (isPastLecture || data.is_single_lecture) {
              goBookingUpdateForm('update');
            } else {
              setIsOpenDrawer('update');
            }
          },
          onError,
        });
        break;
      case 'copy':
        checkPermission(getPermissionId(value), {
          onSuccess: () => navigate(`/booking/${bookingType}/form/copy/${currentLectureId}`),
          onError,
        });
        break;
      case 'delete':
        checkPermission(getPermissionId(value), {
          onSuccess: () => setIsOpenDrawer('delete'),
          onError,
        });
        break;
    }
    setIsOpenDropDown(false);
  };

  /** 수업 보기 권한 없으면 에러페이지로 */
  useEffect(() => {
    if (!canViewLecture) {
      navigate('/error?type=403', { replace: true });
    }
  }, [canViewLecture, navigate]);

  return (
    <MainLayout
      header={{
        title: data.title,
        titleScrollThreshold: 40,
        noUnderline: true,
        leftButton: (
          <IconButton onClick={() => navigate(-1)}>
            <Icon name="headerClose" color={contrastColor} />
          </IconButton>
        ),
        rightButton:
          !canCreateLecture && !canUpdateLecture && !canDeleteLecture ? undefined : (
            <BookingDetailDropDown
              bookingType={bookingType}
              contrastColor={contrastColor}
              bookingClosedAt={data.booking_closed_at}
              isOpen={isOpenDropDown}
              onToggle={setIsOpenDropDown}
              onClick={clickDropDownItem}
              canCreateLecture={canCreateLecture}
              canUpdateLecture={canUpdateLecture}
              canDeleteLecture={canDeleteLecture}
            />
          ),
        bgColor,
      }}>
      <ColoredDetailInfo currentLecture={data} bgColor={bgColor} contrastColor={contrastColor} bookingType={bookingType} />

      <ApiBoundary>
        <Bookings
          isMe={isMe}
          currentLecture={data}
          bookingType={bookingType}
          canCancelBooking={canCancelBooking}
          canChangeBooking={canChangeBooking}
        />
      </ApiBoundary>

      <LectureUpdateListDrawer
        isOpen={isOpenDrawer === 'update'}
        onClose={() => setIsOpenDrawer(null)}
        goBookingUpdateForm={goBookingUpdateForm}
      />

      <LectureDelete
        currentLecture={data}
        bookingType={bookingType}
        isPastLecture={isPastLecture}
        isOpen={isOpenDrawer === 'delete'}
        onClose={() => setIsOpenDrawer(null)}
      />

      {!!openCloseDialog && (
        <BookingOpenCloseDialog
          currentLectureId={currentLectureId}
          openCloseDialog={openCloseDialog}
          onClose={() => setOpenCloseDialog(null)}
        />
      )}
    </MainLayout>
  );
};

export default DetailMain;
