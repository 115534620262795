import usePostLectureGroup from 'hooks/service/mutations/usePostLectureGroup';
import usePopup from 'hooks/usePopup';
import useToast from 'hooks/useToast';
import { isArray } from 'lodash';
import FailAllBooking from 'pages/Booking/components/AfterRequestBooking/FailAllBooking';
import BookingRangeDate from 'pages/Booking/components/BookingRangeDate';
import BookingRangeTime from 'pages/Booking/components/BookingRangeTime';
import ResetConfirmDialog from 'pages/Booking/components/ResetConfirmDialog';
import ConfirmNoRoomDialog from 'pages/Booking/components/Room/ConfirmNoRoomDialog';
import { BOOKING_COMMON_FORM_TEXT } from 'pages/Booking/constants';
import { AllPageModeType } from 'pages/Booking/types';
import { formatBookingGroupParams } from 'pages/Booking/utils';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { BookingGroupCreateFormType } from '../../types';
import CommonGroupForm from './CommonGroupForm';

type Props = {
  pageMode: Extract<AllPageModeType, 'create' | 'copy'>;
};

const GroupCommonCreateForm = ({ pageMode }: Props) => {
  const { getValues, setValue } = useFormContext<BookingGroupCreateFormType>();
  const [isOpenNoRoomDialog, setIsOpenNoRoomDialog] = useState(false);
  const { setPopup } = usePopup();
  const { setToast } = useToast();
  const navigate = useNavigate();
  const { mutate: createGroupLecture } = usePostLectureGroup();

  const createLecture = () => {
    const params = formatBookingGroupParams(getValues());
    createGroupLecture(params, {
      onSuccess: () => {
        const backStep = pageMode === 'copy' ? -2 : -1;
        navigate(backStep);
        setToast({ type: 'success', message: BOOKING_COMMON_FORM_TEXT.successMessage.create, bottom: 76 });
      },
      onError: error => {
        if (isArray(error.response?.data)) {
          setPopup(
            <FailAllBooking
              fails={error.response?.data}
              closePopup={() => {
                if (pageMode === 'create') {
                  navigate(-1);
                } else {
                  navigate('/schedule', { replace: true });
                }
              }}
            />,
          );
          return false;
        }
      },
    });
  };

  const submit = (values: BookingGroupCreateFormType) => {
    const { roomId, selectedRoom } = values;

    /** 룸 선택 안함을 수동 클릭한 경우는 다음 버튼 누를 경우 확인 팝업 노출하지 않음 */
    if (!selectedRoom && roomId === BOOKING_COMMON_FORM_TEXT.room.notSelected.value) {
      setIsOpenNoRoomDialog(true);
      return;
    } else {
      createLecture();
    }
  };

  // 룸 선택 후 날짜 변경하면 룸선택 초기화 팝업 노출
  const resetRoom = () => {
    const hasSelectedRoom = getValues('roomId') !== BOOKING_COMMON_FORM_TEXT.room.notSelected.value;
    if (hasSelectedRoom) {
      setValue('resetChangeTarget', 'date');
      setValue('roomId', BOOKING_COMMON_FORM_TEXT.room.notSelected.value);
      setValue('selectedRoom', undefined);
    }
  };

  return (
    <>
      <CommonGroupForm pageMode={pageMode} onSubmit={submit}>
        <BookingRangeDate
          isRange
          isInitialOpen={pageMode === 'copy'}
          resetRoomMember={pageMode === 'create' ? resetRoom : undefined}
        />
        <BookingRangeTime pageMode="create" />
      </CommonGroupForm>

      {isOpenNoRoomDialog && (
        <ConfirmNoRoomDialog
          onClick={() => {
            setIsOpenNoRoomDialog(false);
            createLecture();
          }}
          onClose={() => setIsOpenNoRoomDialog(false)}
        />
      )}

      {getValues('resetChangeTarget') === 'date' && <ResetConfirmDialog />}
    </>
  );
};

export default GroupCommonCreateForm;
