import styled from '@emotion/styled';
import Phone from 'assets/images/phone.svg';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Typography from 'components/Typography';
import Drawer from 'designedComponents/Drawers';
import TitleWithSubText from 'designedComponents/Drawers/DrawerTitle/TitleWithSubText';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { isKeyboardShowAtom } from 'recoil/keyboard';

import { NOTICE_FORM_TEXT } from '../constants';
import PreviewContent from './PreviewContent';

const Preview = () => {
  const [isOpen, setIsOpen] = useState(false);

  const isKeyboardShow = useRecoilValue(isKeyboardShowAtom);

  return (
    <Container isFooterFixed={!isKeyboardShow}>
      <Button fullWidth size="medium48" variant="outlined" color="gray" onClick={() => setIsOpen(true)}>
        {NOTICE_FORM_TEXT.preview}
      </Button>
      <StyledDrawer
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        header={<TitleWithSubText title={NOTICE_FORM_TEXT.preview} subTitle={NOTICE_FORM_TEXT.previewDescription} />}>
        <ImageContainer>
          <PhoneBackground src={Phone} />
          <NoticePopup>
            <PreviewContent />
          </NoticePopup>
          <BottomButtons>
            <Typography size={13} weight={500} textColor="gray3">
              {NOTICE_FORM_TEXT.previewLeft}
            </Typography>
            <Typography size={13} weight={500} textColor="gray3">
              {NOTICE_FORM_TEXT.previewRight}
            </Typography>
          </BottomButtons>
        </ImageContainer>
      </StyledDrawer>
    </Container>
  );
};

export default Preview;

const Container = styled.div<{ isFooterFixed: boolean }>`
  padding: 0 20px;

  --basic-padding: 32px;
  --footer-height: ${({ isFooterFixed }) => (isFooterFixed ? '76px' : '0px')};
  padding-bottom: calc(var(--footer-height) + var(--basic-padding));
`;

const StyledDrawer = styled(Drawer)`
  padding-top: 32px;
`;

const PhoneBackground = styled.img`
  width: 100%;
  height: auto;
`;

const ImageContainer = styled.div`
  position: relative;
`;

const NoticePopup = styled.div`
  position: absolute;
  top: 30vw;
  left: 8vw;
  width: calc(100% - 16vw);
  height: calc(100% - 45vw);
  overflow-y: scroll;
`;

const BottomButtons = styled.div`
  position: absolute;
  bottom: 2.7vw;
  left: 2.7vw;
  width: calc(100% - 5.4vw);
  height: 56px;
  border-top: 1px solid ${theme.color.gray6};
  background-color: ${theme.color.white};
  border-radius: 0 0 32px 32px;

  ${theme.flex('row', 'center', 'space-between')}
  .typography {
    flex: 1;
    text-align: center;
    :first-of-type {
      border-right: 1px solid ${theme.color.gray5};
    }
  }
`;
