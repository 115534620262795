import { BookingParams, BookingSpecificErrorType } from 'hooks/service/mutations/usePostBooking';
import { LectureBookingListResponse } from 'hooks/service/queries/useInfinityBookingList';

import { BookingFormType, BookingUserTicket } from '../BookingMember/types';

type Props = {
  userTicket: BookingUserTicket;
  currentBookingUserTickets: BookingUserTicket[];
  selectedUserTickets: BookingUserTicket[];
  overlapTickets: BookingFormType['overlapTickets'];
  canCancelBooking: boolean;
};

/**
 * 회원변경 페이지에서 현재 예약 인원으로 카운트 될 예약 상태
 * 예약, 예약확정, 출석, 새로 예약 추가한 케이스
 */
export const BOOKING_COUNT_STATUS = ['booked', 'booking_confirmed', 'attendance', 'new_booked'];

export const getErrorMembersName = (data: BookingSpecificErrorType[]) => {
  return data.map(({ name }) => name);
};

export const isBookedStatus = (status: BookingUserTicket['status']): boolean => {
  return !!status && !['booking_waiting', 'new_booking_waiting', 'cancel'].includes(status);
};

export const isWaitingStatus = (status: BookingUserTicket['status']): boolean => {
  return status === 'booking_waiting' || status === 'new_booking_waiting';
};

export const getCurrentWaitingCount = (selectedUserTickets: BookingUserTicket[]) => {
  return selectedUserTickets.filter(({ status }) => isWaitingStatus(status)).length;
};

/** 새로운 예약/대기 수강권 추가 */
export const addNewTicket = ({
  selectedUserTickets,
  userTicket,
  status,
}: Pick<Props, 'selectedUserTickets' | 'userTicket'> & { status: BookingUserTicket['status'] }) => {
  const waitingNumber = status === 'new_booking_waiting' ? getCurrentWaitingCount(selectedUserTickets) + 1 : null;
  return {
    ...userTicket,
    id: userTicket.is_shared ? `${userTicket.id}-isShared-new` : userTicket.id, // 패밀리수강권은 같은 아이디의 수강권을 여러번 선택할 수 있어서 구분용
    status,
    waitingNumber,
  };
};

/** 기존, 새로 추가한 수강권 취소 */
export const removeSelectedTicket = ({ selectedUserTickets, userTicket }: Pick<Props, 'selectedUserTickets' | 'userTicket'>) => {
  const removeIndex = selectedUserTickets.findIndex(item => item.id === userTicket.id);
  return selectedUserTickets.filter((_, index) => {
    return index !== removeIndex;
  });
};

/** 회원변경 페이지 첫 진입 시 현재 예약/대기 중인 회원 세팅 */
export const formatSelectedUserTicket = (bookings: LectureBookingListResponse[]) => {
  // 예약리스트 다음으로 예약대기 리스트 오도록 정렬
  // sort된 티켓 목록에 waitingNumber 추가
  return bookings
    .map(({ id, member, user_ticket, status }) => ({
      ...user_ticket,
      member,
      status,
      id: user_ticket.is_shared ? `${user_ticket.id}-isShared-${status}` : user_ticket.id,
      bookingId: id,
    }))
    .reduce(
      (acc, ticket) => {
        const waitingNumber = isWaitingStatus(ticket.status) ? ++acc.waitingCount : null;
        acc.tickets.push({ ...ticket, waitingNumber });
        return acc;
      },
      { waitingCount: 0, tickets: [] as BookingUserTicket[] },
    ).tickets;
};

/** 회원변경 페이지 내에서 추가, 제거 할 때 예약, 대기자 정렬 */
export const arrangeCurrentSelectedUserTickets = (selectedTickets: BookingUserTicket[], maxTrainee: number) => {
  const currentBookedCount = selectedTickets.filter(({ status }) => isBookedStatus(status)).length;
  let waitingNumber = 0;

  // 먼저 대기자들의 상태를 결정
  const updatedTickets: BookingUserTicket[] = selectedTickets.map(ticket => {
    if (ticket.status && isWaitingStatus(ticket.status)) {
      waitingNumber++;
      if (waitingNumber === 1 && currentBookedCount < maxTrainee) {
        return { ...ticket, status: 'new_booked', waitingNumber: null };
      }
      return ticket;
    }
    return ticket;
  });
  // 그 다음 대기번호 재할당
  waitingNumber = 0;
  return updatedTickets.map(ticket => {
    if (ticket.status && isWaitingStatus(ticket.status)) {
      waitingNumber++;
      return { ...ticket, waitingNumber };
    }
    return ticket;
  });
};

export const getNumberUserTicketId = (userTicketId: Props['userTicket']['id']) => {
  return typeof userTicketId === 'string' ? Number(userTicketId.split('-')[0]) : userTicketId;
};

export const getBookingCheckboxState = ({
  userTicket,
  currentBookingUserTickets,
  selectedUserTickets,
  overlapTickets,
  canCancelBooking,
}: Props) => {
  const checked = selectedUserTickets.some(field => field.id === userTicket.id);
  const alreadyBooked = currentBookingUserTickets.some(field => field.id === userTicket.id);
  const cancelDisabled = alreadyBooked && !canCancelBooking;
  const isDuplicated = !!overlapTickets && overlapTickets.includes(getNumberUserTicketId(userTicket.id));
  const currentBooking = selectedUserTickets.filter(({ status }) => status && BOOKING_COUNT_STATUS.includes(status));

  return {
    checked,
    alreadyBooked,
    cancelDisabled,
    isDuplicated,
    currentBooking,
  };
};

export const filterTicketsByStatus = (
  tickets: BookingUserTicket[],
  predicate: (status: LectureBookingListResponse['status']) => boolean,
): BookingUserTicket[] => {
  return tickets.filter(item => predicate(item.status as LectureBookingListResponse['status']));
};

export const getCancelledBookings = (currentBookings: BookingUserTicket[], selectedTickets: BookingUserTicket[]) => {
  const cancelledTickets = currentBookings
    .filter(item => item.bookingId !== null)
    .filter(item => !selectedTickets.some(ticket => ticket.bookingId === item.bookingId))
    .map(item => ({ booking_id: item.bookingId as number, status: item.status as LectureBookingListResponse['status'] }));

  return cancelledTickets;
};

export const formatBookingParams = (formValues: BookingFormType): BookingParams => {
  const { currentBookingUserTickets, selectedUserTickets, currentLecture } = formValues;
  const existingIds = new Set(currentBookingUserTickets.map(item => item.id));

  // 기존 예약(대기) 회원들
  const existingTickets = selectedUserTickets.filter(({ id }) => existingIds.has(id));
  const bookedMembers = filterTicketsByStatus(existingTickets, isBookedStatus);
  const waitingMembers = filterTicketsByStatus(existingTickets, isWaitingStatus).filter(
    item => item.status === 'booking_waiting',
  );

  const bookableCount = currentLecture.max_trainee - bookedMembers.length;
  const bookableWaitingCount = currentLecture.waiting_trainee_limit - waitingMembers.length;

  // 새로 추가한 회원들
  const newBookings = selectedUserTickets.filter(item => !existingIds.has(item.id));
  const isBookingOver = newBookings.filter(item => item.status === 'new_booked').length > bookableCount;
  const isWaitingOver =
    !!currentLecture.waiting_trainee_limit &&
    selectedUserTickets.filter(item => item.status === 'new_booking_waiting').length > bookableWaitingCount;

  // 기존 예약(대기) 회원들 중 예약 상태를 변경할 booking id
  const changes = selectedUserTickets
    .filter(item => existingIds.has(item.id) && item.status && item.status.includes('new'))
    .map(item => {
      return {
        booking_id: currentBookingUserTickets.filter(({ id }) => id === item.id)[0].bookingId as number,
        status: item.status === 'new_booked' || !item.status ? 'booked' : 'booking_waiting',
      };
    });

  const bookings = newBookings.map(({ id, status }) => ({
    user_ticket_id: getNumberUserTicketId(id),
    status: status === 'new_booked' || !status ? 'booked' : 'booking_waiting',
  }));

  const cancelBookings = getCancelledBookings(currentBookingUserTickets, selectedUserTickets);
  const cancelBookingIds = cancelBookings.map(item => item.booking_id);
  // 기존 예약 취소 후 예약 대기 등 다른 예약 상태로 처리되어 취소 아이디 보내지 않을 배열
  const changeAfterCancel = changes
    .filter(item => item.booking_id && cancelBookingIds.includes(item.booking_id))
    .map(({ booking_id }) => booking_id as number);
  // 정말 예약, 예약대기 등 취소만 한 booking
  const cancel_bookings = cancelBookings.filter(item => !changeAfterCancel.includes(item.booking_id));

  const result = {
    bookings,
    cancel_bookings,
    changes,
    lecture_id: currentLecture.id,
    is_booking_over: isBookingOver,
    is_waiting_over: isWaitingOver,
  };

  return result;
};
