import Divider from 'components/Divider';
import Typography from 'components/Typography';
import DateTimePicker from 'designedComponents/Pickers/DateTimePicker';
import useQueryString from 'hooks/useQueryString';
import RuleTypeAccordion from 'pages/Booking/components/AvailableTime/RuleType/RuleTypeAccordion';
import {
  RuleTypeContainer,
  StyledRuleTypeTimeTypography,
  UpdateDateTypography,
} from 'pages/Booking/components/AvailableTime/StyledComponents';
import { BookingGroupUpdateFormType } from 'pages/Booking/Group/types';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import FooterButton from 'sharedComponents/FooterButton';
import filters from 'utils/filters';

import { SmallTitleTypography } from '../Create/OthersAvailableSetting';

/** 그룹 1개 수정 당일예약변경, 폐강시간(최소수강인원 있는 경우에만), 예약대기 */
const UpdateOthersSetting = () => {
  const { getValues, setValue } = useFormContext<BookingGroupUpdateFormType>();
  const navigate = useNavigate();
  const { getSearchParams } = useQueryString();
  const [expanded, setExpanded] = useState<string | false>(getSearchParams('detail') as string);
  const {
    currentLecture,
    minTrainee,
    lectureDate,
    daily_change_booking_end_at: dailyChange,
    booking_auto_shift_available_minutes_from_start: autoWaiting,
    autoclose_at: autoClose,
  } = getValues();

  const [tempDailyChange, setTempDailyChange] = useState(dailyChange);
  const [tempAutoClose, setTempAutoClose] = useState(autoClose);
  const [tempWaiting, setTempWaiting] = useState(autoWaiting);

  const changeAccordionOpen = (panel: string) => {
    setExpanded(prev => (prev !== panel ? panel : false));
  };

  const changeDate = (e: { value: Date }) => {
    const date = filters.dateDashTimeSecondZero(e.value);
    switch (expanded) {
      case 'dailyChange':
        setTempDailyChange(date);
        break;
      case 'autoClose':
        setTempAutoClose(date);
        break;
      case 'waiting':
        setTempWaiting(date);
        break;
    }
  };

  const saveDate = () => {
    setValue('daily_change_booking_end_at', tempDailyChange, { shouldDirty: true });
    setValue('autoclose_at', tempAutoClose, {
      shouldDirty: currentLecture && currentLecture.min_trainee === minTrainee,
    });
    setValue('booking_auto_shift_available_minutes_from_start', tempWaiting, { shouldDirty: true });
    navigate(-1);
  };

  return (
    <>
      <RuleTypeContainer className="dailyChange update">
        <RuleTypeAccordion
          iconName="changeRegular"
          height={78}
          noBottomBorder
          isOpen={expanded === 'dailyChange'}
          onChange={() => changeAccordionOpen('dailyChange')}
          header={
            <div>
              <SmallTitleTypography>당일 예약 변경</SmallTitleTypography>
              <StyledRuleTypeTimeTypography>
                <UpdateDateTypography isOpen={expanded === 'dailyChange'} endAt={tempDailyChange} />
                <Typography span size={15}>
                  까지 변경 가능합니다.
                </Typography>
              </StyledRuleTypeTimeTypography>
            </div>
          }>
          <DateTimePicker max={lectureDate} defaultValue={dailyChange} value={tempDailyChange} onChange={changeDate} />
        </RuleTypeAccordion>
      </RuleTypeContainer>
      {!!minTrainee && (
        <>
          <Divider />
          <RuleTypeContainer className="autoClose update">
            <RuleTypeAccordion
              iconName="error"
              height={78}
              noBottomBorder
              isOpen={expanded === 'autoClose'}
              onChange={() => changeAccordionOpen('autoClose')}
              header={
                <div>
                  <SmallTitleTypography>폐강시간</SmallTitleTypography>
                  <StyledRuleTypeTimeTypography>
                    <UpdateDateTypography isOpen={expanded === 'autoClose'} endAt={tempAutoClose} />
                    <Typography span size={15}>
                      전에 폐강됩니다.
                    </Typography>
                  </StyledRuleTypeTimeTypography>
                </div>
              }>
              <DateTimePicker max={lectureDate} defaultValue={autoClose} value={tempAutoClose} onChange={changeDate} />
            </RuleTypeAccordion>
          </RuleTypeContainer>
        </>
      )}
      <Divider />
      <RuleTypeContainer className="waiting update">
        <RuleTypeAccordion
          iconName="wait"
          height={78}
          isLast
          isOpen={expanded === 'waiting'}
          onChange={() => changeAccordionOpen('waiting')}
          header={
            <div>
              <SmallTitleTypography>예약대기 자동 예약</SmallTitleTypography>
              <StyledRuleTypeTimeTypography>
                <UpdateDateTypography isOpen={expanded === 'waiting'} endAt={tempWaiting} />
                <Typography span size={15}>
                  까지 자동 예약됩니다.
                </Typography>
              </StyledRuleTypeTimeTypography>
            </div>
          }>
          <DateTimePicker max={lectureDate} defaultValue={autoWaiting} value={tempWaiting} onChange={changeDate} />
        </RuleTypeAccordion>
      </RuleTypeContainer>
      <FooterButton onClick={saveDate}>완료</FooterButton>
    </>
  );
};

export default UpdateOthersSetting;
