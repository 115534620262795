import { Payment } from 'hooks/service/queries/useInfinityUserTicketProduct';
import getIsUnpaid from 'pages/TicketDetail/components/utils/getIsUnpaid';
import { PAYMENT_STATUS } from 'pages/TicketDetail/constants';
import { InfoValueRowType } from 'sharedComponents/InfoTypography/types';

import filters from './filters';

export type PaymentMethodParamsType = {
  type: string;
  value: number;
};

/**
 * 회원 상세 > 결제정보 탭,
 * 수강권 상세 > 결제정보 페이지에서 사용되는 결제정보 카드 내용 세팅
 * */
const getPaymentDetail = (payment: Payment, actualPrice: number): { data: InfoValueRowType[]; discountPercent: number } => {
  const {
    status,
    amount,
    unpaid_amount,
    commission_amount,
    card_amount,
    cash_amount,
    wiretransfer_amount,
    installment_period,
    reward_point,
    point_amount,
  } = payment;
  const isUnpaid = getIsUnpaid(status);

  // 할인율은 (결제총액 + 미수금)으로 계산 (시설웹 동일)
  const discountPercent = getDiscountPercent(actualPrice, amount, unpaid_amount);
  const installmentStatus = card_amount ? (installment_period <= 1 ? '일시불' : `${installment_period}개월`) : undefined;

  const paymentMethod = getPaymentMethod(
    [
      { type: '카드', value: card_amount },
      { type: '현금', value: cash_amount },
      { type: '계좌이체', value: wiretransfer_amount },
      { type: '포인트', value: point_amount },
    ],
    installmentStatus,
  );

  const defaultData: InfoValueRowType[] = [
    { label: '구분', value: PAYMENT_STATUS[status] },
    { label: '판매가', value: `${filters.numberComma(actualPrice)}원` },
    { label: '포인트 적립', value: `${filters.numberComma(reward_point)}P` },
    { label: '할인율', value: `${discountPercent}%` },
    {
      label: '결제금액',
      value: `${status === 'refund' && !!amount ? '-' : ''}${filters.numberComma(amount)}원`,
      textColor: 'primary',
    },
    {
      label: `${status === 'refund' ? '위약금' : '미수금'}`,
      value: `${filters.numberComma(unpaid_amount || commission_amount)}원`,
      textColor: 'secondary3',
    },
    { label: '결제방법', value: paymentMethod },
  ];

  const data = [...defaultData];
  if (isUnpaid) {
    data.splice(2, 2); // 미수금은 포인트적립, 할인율 노출 제거
  }

  if (status === 'refund') {
    data.splice(3, 1); // 환불은 할인율 노출 제거
  }

  return { data, discountPercent };
};

export default getPaymentDetail;

export const getDiscountPercent = (actualPrice: number, amount: number, unpaid_amount: number) => {
  return actualPrice > amount + unpaid_amount ? Math.round(((actualPrice - (amount + unpaid_amount)) / actualPrice) * 100) : 0;
};

const getPaymentMethod = (methods: PaymentMethodParamsType[], installmentStatus?: string) => {
  const filteredMethods = methods.filter(item => item.value > 0);
  const methodsCount = filteredMethods.length;
  const methodType = methodsCount === 0 ? '-' : `${filteredMethods[0].type}${installmentStatus ? `(${installmentStatus})` : ''}`;

  return methodsCount > 1 ? `${methodType} 외 ${methodsCount - 1}개` : methodType;
};
