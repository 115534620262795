import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Loading from 'components/Loading';

const CenterLineLoading = () => {
  return (
    <LoadingContainer>
      <Loading type="line" />
    </LoadingContainer>
  );
};

export default CenterLineLoading;

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  ${theme.flex()};
  height: 100vh;
  z-index: 9999;
`;
