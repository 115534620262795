import { atom, selector } from 'recoil';
import { selectedTempSchedulesAtom } from 'recoil/schedule';
import localStorageEffect from 'utils/localStorageEffect';

import { DEFAULT_SETTING_TIMES, permissionsMap } from './constants';
import { CalendarSettingsAtomType, PermissionsMapType } from './types';

export const calendarSettingsAtom = atom<CalendarSettingsAtomType>({
  key: 'calendarSettingsAtom',
  default: {
    timeRange: DEFAULT_SETTING_TIMES,
    defaultViewType: 'week',
    dayRange: [1, 0], // 1~0 까지dml 요일 범위. 월요일부터 1, 2, 3, 4, 5, 6, 0
    closedClassBackground: [],
    etcScheduleHide: false,
  },
  effects: [localStorageEffect('calendar_setting')],
});

export const calendarSettingSelector = selector({
  key: 'calendarSettingSelector',
  get: ({ get }) => {
    const calendarSettings = get(calendarSettingsAtom);
    const { timeRange } = calendarSettings;

    if (typeof timeRange[0] !== 'string') {
      return { ...calendarSettings };
    }

    /** 로컬 스토리지에서 받아온 timeRange 문자열을 Date 객체로 변경 */
    const currentTimeRange: CalendarSettingsAtomType['timeRange'] = [new Date(timeRange[0]), new Date(timeRange[1])];
    return { ...calendarSettings, timeRange: currentTimeRange };
  },
});

export const currentPermissionsAtom = atom({
  key: 'currentPermissionsAtom',
  default: selector({
    key: 'currentPermissionsSelector',
    get: ({ get }) => {
      const tempSchedules = get(selectedTempSchedulesAtom);

      if (tempSchedules.length === 1 && tempSchedules[0] === 'all') {
        return [permissionsMap['group'], permissionsMap['private'], permissionsMap['counsel'], permissionsMap['etcSchedule']];
      }

      const permissions: (typeof permissionsMap)[PermissionsMapType][] = [];
      tempSchedules.forEach(item => {
        const pushItem = permissionsMap[item as PermissionsMapType];
        if (pushItem) {
          permissions.push(pushItem);
        }
      });

      return permissions;
    },
  }),
});

export const isEveryPermissionAtom = atom({
  key: 'isEveryPermissionAtom',
  default: false,
});
