import { useSuspenseQuery } from '@tanstack/react-query';

import useGetAllQueryFnWithCommonParams from '../common/useGetAllQueryFnWithCommonParams';
import { LectureBookingListResponse } from './useInfinityBookingList';

/**
 * 회원변경 페이지에서 현재 예약된 리스트 호출용 (전체를 한번에 불러와야 함)
 */
const useGetAllBookingList = (lectureId: number) => {
  const { queryFn } = useGetAllQueryFnWithCommonParams<Array<LectureBookingListResponse>>({
    url: `/api/booking/list/${lectureId}`,
  });

  return useSuspenseQuery({
    queryKey: ['booking/list', lectureId],
    queryFn,
    select: data => data.data,
    gcTime: 0,
  });
};

export default useGetAllBookingList;
