import { ArrowFilterValuesType } from 'components/Tabs/ArrowFilterTabs';
import { get, isEmpty } from 'lodash';
import { FilterType } from 'sharedComponents/Filters/types';
import filters from 'utils/filters';

import { MemberCounselSelectedFilterType, MemberTicketFilterType } from './types';

export const MAIN_TAB_DATA = [
  { value: 'all', label: '전체회원' },
  { value: 'assigned', label: '담당회원' },
  { value: 'counsel', label: '상담고객' },
];

export const DEFAULT_SORT_DATA = [
  { id: 'nameAsc', label: '이름 오름차순', value: 'name,asc' },
  { id: 'nameDesc', label: '이름 내림차순', value: 'name,desc' },
  { id: 'dateDesc', label: '등록일 최신순', value: 'registered_at,desc' },
  { id: 'dateAsc', label: '등록일 과거순', value: 'registered_at,asc' },
];

export const ATTENDANCE_SORT_DATA = [
  { id: 'attendanceDesc', label: '출석 횟수 높은순', value: 'attendance_count,desc' },
  { id: 'attendanceAsc', label: '출석 횟수 낮은순', value: 'attendance_count,asc' },
];

export const COUNSEL_MEMBER_CHECK: Array<{
  id: string;
  label: string;
  value: Exclude<MemberCounselSelectedFilterType['register_type'], 'both'>;
}> = [
  { id: 'registered', label: '회원', value: 'registered' },
  { id: 'not_registered', label: '비회원', value: 'not_registered' },
];

export const COUNSEL_CHANNEL_TEXT: { [key: string]: string } = {
  visit: '방문상담',
  phone: '전화상담',
  chat: '채팅상담',
  etc: '기타상담',
} as const;

export const COUNSEL_FUNNEL_TEXT: { [key: string]: string } = {
  search: '네이버검색',
  introduction: '지인소개',
  sign: '간판',
  flyer: '전단지',
  sns: 'SNS',
  etc: '기타',
} as const;

export const COUNSEL_DEFAULT_DATE = {
  start: '시작일',
  end: '종료일',
};

export const DEFAULT_COUNSEL_FILTER_TABS = {
  period: '기간선택',
  register: '회원등록여부 전체', // 메시지 받는 사람화면에서만 사용
  channel: '상담유형 전체',
  funnel: '유입경로 전체',
  staffs: '담당스태프 전체',
};

export const DEFAULT_COUNSEL_FILTER_OPTIONS: Array<FilterType> = [
  {
    key: 'period',
    type: 'period',
    title: '기간선택',
    options: [
      {
        value: 30,
        label: '1개월',
      },
      {
        value: 90,
        label: '3개월',
      },
      {
        value: 180,
        label: '6개월',
      },
      {
        value: 'custom',
        label: '직접선택',
      },
    ],
    tabConverter: (value: ArrowFilterValuesType) => {
      const periodValue = value as MemberCounselSelectedFilterType['period'];
      if (!periodValue || !periodValue.type) return '기간선택';
      if (periodValue.type === 30) return '최근 1개월';
      if (periodValue.type === 90) return '최근 3개월';
      if (periodValue.type === 180) return '최근 6개월';
      return filters.period(periodValue.start, periodValue.end);
    },
  },
  {
    // 메시지 받는 사람화면에서만 사용
    key: 'register',
    type: 'button',
    title: '회원등록 여부',
    options: [
      {
        value: undefined,
        label: '전체',
      },
      {
        value: 'registered',
        label: '회원',
      },
      {
        value: 'not_registered',
        label: '비회원',
      },
    ],
  },
  {
    key: 'channel',
    type: 'button',
    title: '상담유형',
    options: [
      {
        value: undefined,
        label: '전체',
      },
      {
        value: 'visit',
        label: '방문상담',
      },
      {
        value: 'phone',
        label: '전화상담',
      },
      {
        value: 'chat',
        label: '채팅상담',
      },
      {
        value: 'etc',
        label: '기타상담',
      },
    ],
  },
  {
    key: 'funnel',
    type: 'button',
    title: '유입경로',
    options: [
      {
        value: undefined,
        label: '전체',
      },
      {
        value: 'search',
        label: '네이버검색',
      },
      {
        value: 'introduction',
        label: '지인소개',
      },
      {
        value: 'sign',
        label: '간판',
      },
      {
        value: 'flyer',
        label: '전단지',
      },
      {
        value: 'sns',
        label: 'SNS',
      },
      {
        value: 'etc',
        label: '기타',
      },
    ],
  },
  {
    key: 'staffs',
    type: 'select',
    title: '담당스태프',
    options: [],
  },
];

export const MEMBER_FILTER_TAG_LABEL: { [key: string]: string } = {
  total: '전체',
  active: '이용회원',
  holding: '정지회원',
  expired: '만료회원',
  unpaid: '미결제회원',
};

export const DEFAULT_MEMBER_FILTER_OPTIONS: Array<FilterType> = [
  {
    key: 'tag',
    type: 'button',
    title: '회원',
    options: [
      { value: undefined, label: '전체 0' },
      { value: 'active', label: '이용회원' },
      { value: 'holding', label: '정지회원' },
      { value: 'expired', label: '만료회원' },
      { value: 'unpaid', label: '미결제회원' },
    ],
  },
  {
    key: 'ticket_type',
    type: 'button',
    title: '수강권 유형',
    options: [
      {
        value: undefined,
        label: '전체',
      },
      {
        value: 'G',
        label: '그룹 수강권',
      },
      {
        value: 'P',
        label: '프라이빗 수강권',
      },
    ],
  },
  {
    key: 'staff',
    type: 'select',
    title: '담당강사',
    options: [
      {
        value: undefined,
        label: '선택 안함',
      },
    ],
  },
  {
    key: 'user_grade_id',
    type: 'button',
    title: '회원등급',
    options: [
      {
        value: undefined,
        label: '전체',
      },
    ],
  },
  {
    key: 'ticket_remaining',
    type: 'radio',
    title: '기간별 조회',
    options: [
      {
        value: undefined,
        label: '적용 안함',
      },
      {
        value: 0,
        label: '기간 내 만료되는 회원 보기',
      },
      {
        value: 1, // remaining_coupon 의 값이 되어야 함
        label: '기간 내 1회 이하로 남은 회원',
      },
    ],
    tabConverter: value => {
      const count = get(value, 'count');
      return count ? `기간 내 ${filters.numberComma(count)}회 이하로 남은 회원` : '기간 내 만료되는 회원';
    },
    validator: value => {
      const ticketValue = value as MemberTicketFilterType | undefined;
      if (!!ticketValue && isEmpty(ticketValue.date)) return '날짜를 선택해주세요.';
    },
  },
  {
    key: 'last_attendance_day',
    type: 'radio',
    title: '미방문일수',
    options: [
      {
        value: undefined,
        label: '적용 안함',
      },
      {
        value: 1, // last_attendance_day 값이 되어야 함
        label: '1일 이상 미방문',
      },
    ],
    tabConverter: value => (value ? `${value}일 이상 미방문` : '미방문일수'),
  },
  {
    key: 'attendance_count',
    type: 'radio',
    title: '누적 출석 횟수',
    options: [
      {
        value: undefined,
        label: '적용 안함',
      },
      {
        value: 1,
        label: '기간 내 출석한 회원만 보기',
      },
    ],
    tabConverter: value => (value ? '기간 내 출석한 회원' : '누적 출석 횟수'),
  },
];

export const MEMBER_FILTER_DEFAULT_TAB_LABEL = {
  tag: '회원 전체 0',
  ticket_type: '수강권 전체',
  staff: '담당강사',
  user_grade_id: '회원등급 전체',
  ticket_remaining: '기간별 조회',
  last_attendance_day: '미방문일수',
  attendance_count: '누적 출석 횟수',
};
