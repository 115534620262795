import { useQuery } from '@tanstack/react-query';

import useGetAllQueryFnWithCommonParams from '../common/useGetAllQueryFnWithCommonParams';
import { PeriodDateType } from './useGetAllScheduleLecture';

type Props = {
  start_date: PeriodDateType;
  end_date: PeriodDateType;
  /**
   * 빈 배열일 경우 staff 전체 필터 적용
   */
  staff_ids: number[];
  enabled: boolean;
  studioId: number;
};

const useGetAllScheduleCounsel = ({ enabled, studioId, ...params }: Props) => {
  const { queryFn } = useGetAllQueryFnWithCommonParams<ScheduleCounselResponse, ScheduleCounselParams>({
    url: '/api/schedule/counsel',
    params: {
      ...params,
    },
  });

  return useQuery({
    queryKey: ['schedule/counsel', { ...params, enabled, studioId }],
    queryFn,
    select: data => data.data,
    enabled,
  });
};

export default useGetAllScheduleCounsel;

export type ScheduleCounselResponse = ScheduleCounselType[];

type ScheduleCounselType = {
  id: number;
  studio_id: number;
  staff: StaffType;
  name: string;
  phone: string | null;
  gender: 'F' | 'M' | null;
  channel: string;
  content: string | null;
  start_on: string;
  end_on: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  user_id: number | null;
};

type StaffType = {
  id: number;
  studio_id: number;
  user_grade_id: number | null;
  name: string;
  account_type: 'userAccount' | 'staffAccount' | null;
  account_id: number;
  mobile: string;
  vaccination_yn: 'Y' | 'N';
  registered_at: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  profile: ProfileType;
};

type ProfileType = {
  id: number;
  studio_id: number;
  name: string;
  self_introduction: string | null;
  representative_color: string;
  hired_at: string | null;
  get_noti_all: 0 | 1;
  private_start_interval_on_off: boolean;
  private_start_interval_am: number;
  private_start_interval_pm: number;
  registered_at: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  birthday: string;
  gender: 'F' | 'M';
  studio_user_id: number;
  web_token: string | null;
};

type ScheduleCounselParams = Omit<Props, 'enabled' | 'studioId'>;
