import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';

import { CommonPropsType } from '../types';

type Props = CommonPropsType & {
  traineeText?: string;
  sameTimeEvents: number;
};

const GroupTitle = ({
  isCardBackground,
  currentContrastColor,
  traineeText,
  startTime,
  title,
  sameTimeEvents,
  isDayView,
}: Props) => {
  /** 주간 동일 시간 3개 이상부터는 노출하지 않음 */
  return (
    <>
      <TitleWrapper sameTimeEvents={sameTimeEvents} isDayView={isDayView}>
        <Typography
          className="trainee-text"
          size={isDayView ? 14 : 12}
          weight={600}
          textColor={isCardBackground ? currentContrastColor : 'gray1'}
          lineHeight={18}>
          {traineeText}
        </Typography>

        {isDayView && (
          <Typography
            className="start-time"
            size={13}
            weight={500}
            textColor={isCardBackground ? currentContrastColor : 'gray3'}
            opacity={isCardBackground ? 0.88 : 1}>
            {startTime}
          </Typography>
        )}
      </TitleWrapper>

      <Typography
        className="title"
        size={12}
        weight={500}
        textColor={isCardBackground ? currentContrastColor : 'gray2'}
        opacity={isCardBackground ? 0.88 : 0.8}>
        {title}
      </Typography>
    </>
  );
};

export default GroupTitle;

const TitleWrapper = styled.div<{ sameTimeEvents: CommonPropsType['sameTimeEvents']; isDayView: CommonPropsType['isDayView'] }>(
  css`
    ${theme.flex('row', 'center', 'space-between', 2)};
    flex-wrap: wrap;
    width: 100%;

    .start-time {
      min-width: max-content;
    }
  `,

  ({ sameTimeEvents }) =>
    sameTimeEvents === 4 &&
    css`
      ${theme.flex('column', 'flex-start', 'space-between', 2)};
    `,
);
