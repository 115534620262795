import useQueryString from 'hooks/useQueryString';
import { BOOKING_COMMON_FORM_TEXT } from 'pages/Booking/constants';
import { AllPageModeType, BookingCommonFormType } from 'pages/Booking/types';
import syncRepeatSetting from 'pages/Booking/utils/syncRepeatSetting';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import CustomRepeatSetting from './CustomRepeatSetting';
import RepeatSetting from './RepeatSetting';

type Props = {
  pageMode: AllPageModeType;
};

const Repeat = ({ pageMode }: Props) => {
  const { getValues, setValue } = useFormContext<BookingCommonFormType>();
  const { getSearchParams } = useQueryString();
  const isCustomSetting = getSearchParams('setting');
  const [initCustomArray] = useState(getValues('repeatCustomWeekday'));
  const [initRepeat] = useState(getValues('repeat'));
  const { pathname } = useLocation();
  const showChangeDialog = !pathname.includes('etc') && !getValues('isChangedRepeat') && pageMode === 'updateAll';
  const noSelectedRoom = pageMode !== 'create' || getValues('roomId') === BOOKING_COMMON_FORM_TEXT.room.notSelected.value;

  // 생성 시 선택한 룸이 있는 경우에만 반복설정 변경 시 룸 설정 초기화 dialog 노출
  const resetRoom = () => {
    if (pageMode !== 'create' || pathname.includes('etc')) return;
    const hasSelectedRoom = getValues('roomId') !== BOOKING_COMMON_FORM_TEXT.room.notSelected.value;

    if (hasSelectedRoom) {
      setValue('resetChangeTarget', 'repeat', { shouldDirty: true });
      setValue('roomId', BOOKING_COMMON_FORM_TEXT.room.notSelected.value, { shouldDirty: true });
      setValue('selectedRoom', undefined, { shouldDirty: true });
    }
  };

  useEffect(() => {
    /**
     * 반복설정 페이지에서,
     * 맞춤설정 페이지에서 체크한 요일과 반복설정의 값과 맞으면 반복설정 값을 변경하고,
     * 임시로 체크한 요일들 다시 실제 체크된 요일로 세팅
     */
    if (!isCustomSetting) {
      const repeatCustomWeekday = getValues('repeatCustomWeekday');
      const value = syncRepeatSetting(repeatCustomWeekday);
      setValue('repeat', value);
      setValue('tempRepeatCustomWeekday', repeatCustomWeekday, { shouldDirty: true });
    }
  }, [getValues, isCustomSetting, setValue]);

  return (
    <>
      {isCustomSetting ? (
        <CustomRepeatSetting
          showChangeDialog={showChangeDialog}
          initCustomArray={initCustomArray}
          initRepeat={initRepeat}
          noSelectedRoom={noSelectedRoom}
          resetRoom={resetRoom}
        />
      ) : (
        <RepeatSetting
          showChangeDialog={showChangeDialog}
          initCustomArray={initCustomArray}
          initRepeat={initRepeat}
          noSelectedRoom={noSelectedRoom}
          resetRoom={resetRoom}
        />
      )}
    </>
  );
};

export default Repeat;
