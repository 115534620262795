import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

/**
 * 그룹 수업 예약대기 취소
 */
const usePatchBookingGroupCancelWaiting = (bookingId: number) => {
  const { mutationFn } = useMutationFnWithCommonParams({
    method: 'patch',
    url: `/api/booking/group/cancel/waiting/${bookingId}`,
  });

  return useCustomMutation({
    mutationFn,
  });
};

export default usePatchBookingGroupCancelWaiting;
