import styled from '@emotion/styled';
import Typography from 'components/Typography';
import { UserTicketLectureResponse } from 'hooks/service/queries/useInfinityUserTicketLecture';
import NoSearchDataTypography from 'sharedComponents/NoSearchDataTypography';
import { StyledSelectInfiniteScroll } from 'sharedComponents/StyledComponents';

import UserTicketListItem from './UserTicketListItem';

type Props = {
  search?: string;
  userTickets: Array<UserTicketLectureResponse>;
  selectedUserTickets: Array<UserTicketLectureResponse>;
  disabledSelectMembers?: boolean;
  hasMore: boolean;
  isLoading: boolean;
  loadMore: () => void;
};

const CommonList = ({ search, userTickets, selectedUserTickets, disabledSelectMembers, hasMore, isLoading, loadMore }: Props) => {
  /** 전체 회원 보유 수강권 목록 중 선택된 수강권 제외 */
  const allExceptSelectedUserTickets = userTickets.filter(
    ticket => !selectedUserTickets.some(selectedTicket => ticket.id === selectedTicket.id),
  );

  if (!allExceptSelectedUserTickets.length) {
    return (
      <NoDataWrapper>
        {search ? (
          <NoSearchDataTypography keyword={search} />
        ) : (
          <Typography textColor="gray3">선택 가능한 회원이 없습니다.</Typography>
        )}
      </NoDataWrapper>
    );
  }

  return (
    <InfiniteScrollContainer hasMore={hasMore} isLoading={isLoading} loadMore={loadMore}>
      <ul>
        {allExceptSelectedUserTickets.map(data => (
          <UserTicketListItem key={data.id} data={data} disabled={disabledSelectMembers} />
        ))}
      </ul>
    </InfiniteScrollContainer>
  );
};

export default CommonList;

const InfiniteScrollContainer = styled(StyledSelectInfiniteScroll)`
  li:not(:last-of-type) {
    border-bottom: none;
  }
`;

const NoDataWrapper = styled.div`
  margin: 80px auto;
  span {
    font-size: 1.4rem;
  }
`;
