import styled from '@emotion/styled';
import Typography from 'components/Typography';
import useInfinityUserTicketLecture from 'hooks/service/queries/useInfinityUserTicketLecture';
import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import NoSearchDataTypography from 'sharedComponents/NoSearchDataTypography';
import { StyledSelectInfiniteScroll } from 'sharedComponents/StyledComponents';
import filters from 'utils/filters';

import SelectUserTicketItem from './SelectUserTicketItem';
import { BookingFormType, BookingUserTicket } from './types';

const BookableList = () => {
  const { control, getValues } = useFormContext<BookingFormType>();
  const search = useWatch({ control, name: 'search' });
  const selectedUserTickets = useWatch({ control, name: 'selectedUserTickets' });
  const {
    currentLecture: { id, start_on, end_on, type, max_trainee },
  } = getValues();

  const {
    data: userTickets,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfinityUserTicketLecture({
    lectureId: id,
    start_date: filters.dateDash(start_on),
    end_date: filters.dateDash(end_on),
    search,
  });

  const allUserTickets: BookingUserTicket[] = useMemo(() => {
    return userTickets.map(item => {
      const {
        id,
        is_shared,
        usable_coupon,
        availability_start_at,
        expire_at,
        ticket: { title, type },
        member: { id: memberId, name, vaccination_yn, mobile, image, account_id },
      } = item;
      return {
        id,
        is_shared,
        usable_coupon,
        availability_start_at,
        expire_at,
        ticket: { title, type },
        member: { id: memberId, name, vaccination_yn, mobile, image, account_id },
        waitingNumber: null,
        status: null,
        bookingId: null,
      };
    });
  }, [userTickets]);

  /** 전체 회원 보유 수강권 목록 중 선택된 수강권 제외 */
  const allExceptSelectedUserTickets = useMemo(() => {
    return allUserTickets.filter(
      ticket => !selectedUserTickets.some(selectedTicket => !selectedTicket.is_shared && ticket.id === selectedTicket.id),
    );
  }, [allUserTickets, selectedUserTickets]);

  if (!allExceptSelectedUserTickets.length) {
    return (
      <NoDataWrapper>
        {search ? (
          <NoSearchDataTypography keyword={search} />
        ) : (
          <Typography textColor="gray3">선택 가능한 회원이 없습니다.</Typography>
        )}
      </NoDataWrapper>
    );
  }

  return (
    <InfiniteScrollContainer hasMore={hasNextPage} isLoading={isFetchingNextPage} loadMore={fetchNextPage}>
      <ul>
        {allExceptSelectedUserTickets.map(data => (
          <SelectUserTicketItem
            key={data.id}
            userTicket={data}
            disabled={type === 'P' && selectedUserTickets.length === max_trainee}
          />
        ))}
      </ul>
    </InfiniteScrollContainer>
  );
};

export default BookableList;

const InfiniteScrollContainer = styled(StyledSelectInfiniteScroll)`
  li:not(:last-of-type) {
    border-bottom: none;
  }
`;

const NoDataWrapper = styled.div`
  margin: 80px auto;
  span {
    font-size: 1.4rem;
  }
`;
