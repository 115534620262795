export const FILTER_POPUP_HEADER = {
  title: '필터 설정',
  subTitle: {
    schedule: '일정',
    staff: '강사',
  },
};

export const FILTER_MENUS = [
  { value: 'all', label: '전체' },
  { value: 'private', label: '프라이빗 수업' },
  { value: 'group', label: '그룹 수업' },
  { value: 'counsel', label: '상담' },
  { value: 'etcSchedule', label: '기타일정' },
];

export const OTHER_STAFF_VIEW_PERMISSTIONS = {
  groupLectureView: '',
  privateLEctureView: '',
  counselView: '',
  etcScheduleView: '',
};

export const TOAST_MESSAGE = {
  notPermission: '다른 강사 조회 권한이 없습니다.',
  filterSuccess: '필터가 적용되었습니다.',
};
