import { EtcScheduleDetailResponse } from 'hooks/service/queries/useGetEtcScheduleEtcScheduleTime';
import usePermission from 'hooks/usePermission';
import FallbackStaffField from 'pages/Booking/components/SelectStaff/FallbackStaffField';
import { PageModeType } from 'pages/Booking/types';
import { ETC_FORM_TEXT, ETC_SCHEDULE_PERMISSION } from 'pages/EtcSchedule/constants';
import { useMemo } from 'react';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import SelectColor from './SelectColor';
import StaffLinkButton from './StaffLinkButton';
import Staffs from './Staffs';

type Props = {
  pageMode: PageModeType;
  selectedStaffs?: EtcScheduleDetailResponse['staffs'];
};

/**
 * 본인, 다른 스태프 기타일정 등록 및 수정 가능한 지 권한 확인하여,
 * 강사 선택 가능 여부 판단
 */
const CheckPermissionSelectStaff = ({ pageMode }: Props) => {
  const { hasPermission } = usePermission();

  /** 본인 일정만 생성/수정 가능 */
  const canUpdateOnlyMine = hasPermission(ETC_SCHEDULE_PERMISSION.mine[pageMode]);
  /** 다른 스태프 일정만 생성/수정 가능 */
  const canUpdateOnlyOthers = hasPermission(ETC_SCHEDULE_PERMISSION.others[pageMode]);

  const canSelectStaff = useMemo(() => {
    if (canUpdateOnlyMine && canUpdateOnlyOthers) {
      return true;
    } else if (canUpdateOnlyOthers) {
      return true;
    } else {
      return false;
    }
  }, [canUpdateOnlyMine, canUpdateOnlyOthers]);

  return (
    <>
      <ApiBoundary fallback={<FallbackStaffField placeholder={ETC_FORM_TEXT.scheduleShare.title} />}>
        {canSelectStaff ? <Staffs /> : <StaffLinkButton disabled showArrow={false} />}
      </ApiBoundary>
      <SelectColor />
    </>
  );
};

export default CheckPermissionSelectStaff;
