import styled from '@emotion/styled';
import { LectureDetailResponse } from 'hooks/service/queries/useGetLectureDetail';
import useQueryString from 'hooks/useQueryString';
import BookingRangeDate from 'pages/Booking/components/BookingRangeDate';
import BookingRangeTime from 'pages/Booking/components/BookingRangeTime';
import ClassTitle from 'pages/Booking/components/ClassTitle';
import RepeatField from 'pages/Booking/components/Repeat/RepeatField';
import ResetConfirmDialog from 'pages/Booking/components/ResetConfirmDialog';
import Room from 'pages/Booking/components/Room';
import ConfirmNoRoomDialog from 'pages/Booking/components/Room/ConfirmNoRoomDialog';
import SelectStaff from 'pages/Booking/components/SelectStaff';
import { BOOKING_COMMON_FORM_TEXT } from 'pages/Booking/constants';
import { AllPageModeType } from 'pages/Booking/types';
import { getPrivateTitle } from 'pages/Booking/utils/private/booking';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { BOOKING_PRIVATE_FORM_TEXT } from '../../constants';
import { BookingPrivateFormType } from '../../types';
import LectureSize from '../components/LectureSize';
import SelectMembersField from '../components/SelectMembers/SelectMembersField';
import NextButton from './NextButton';
import StyledPrivateForm from './StyledPrivateForm';

type Props = {
  pageMode: Extract<AllPageModeType, 'create' | 'copy'>;
  currentLecture?: LectureDetailResponse;
};

const PrivateCommonCreateForm = ({ pageMode, currentLecture }: Props) => {
  const { getValues, setValue } = useFormContext<BookingPrivateFormType>();
  const [isOpenNoRoomDialog, setIsOpenNoRoomDialog] = useState(false);
  const [resetTarget, setResetTarget] = useState<'room' | 'member' | 'both' | undefined>(undefined);

  const { setSearchParams, getAllSearchParams } = useQueryString();

  const goNextPage = () => {
    /** 수업명 입력하지 않았을 때, 수강회원 이름으로 세팅 */
    if (!getValues('title')) {
      const title = getPrivateTitle(getValues('selectedUserTickets'));
      setValue('title', title, { shouldDirty: true });
    }

    setSearchParams({
      ...getAllSearchParams(),
      step: '2',
    });
  };

  const clickNext = () => {
    const { roomId, selectedRoom } = getValues();

    /** 룸 선택 안함을 수동 클릭한 경우는 다음 버튼 누를 경우 확인 팝업 노출하지 않음 */
    if (!selectedRoom && roomId === BOOKING_COMMON_FORM_TEXT.room.notSelected.value) {
      setIsOpenNoRoomDialog(true);
      return;
    }
    goNextPage();
  };

  /** 복사 폼에서 날짜 변경 시 수강회원만 초기화 */
  const resetMember = () => {
    setValue('selectedUserTickets', []);
    setValue('prevSelectedUserTickets', []);
    setValue('title', '');
  };

  /** 생성 폼에서 룸, 수강회원 선택 후 날짜 변경하면 초기화 팝업 노출 */
  const resetRoomMember = () => {
    const hasSelectedRoom = getValues('roomId') !== BOOKING_COMMON_FORM_TEXT.room.notSelected.value;
    const hasSelectedMember = !!getValues('selectedUserTickets').length;
    if (!hasSelectedRoom && !hasSelectedMember) return;

    if (hasSelectedRoom && hasSelectedMember) {
      setResetTarget('both');
    } else if (hasSelectedMember) {
      setResetTarget('member');
    } else {
      setResetTarget('room');
    }
    setValue('resetChangeTarget', 'date');
    setValue('roomId', BOOKING_COMMON_FORM_TEXT.room.notSelected.value);
    setValue('selectedRoom', undefined);
    resetMember();
  };

  return (
    <>
      <FormContainer>
        <SelectStaff lectureStaff={currentLecture?.staff} />
        <BookingRangeDate
          isRange
          isInitialOpen={pageMode === 'copy'}
          resetRoomMember={pageMode === 'create' ? resetRoomMember : resetMember}
        />
        <BookingRangeTime pageMode={pageMode} />
        <RepeatField />
        <Room />
        <LectureSize />
        <SelectMembersField />
        <ClassTitle description={BOOKING_PRIVATE_FORM_TEXT.title.description} />
        <NextButton onClick={clickNext} />
      </FormContainer>

      {isOpenNoRoomDialog && (
        <ConfirmNoRoomDialog
          onClick={() => {
            setIsOpenNoRoomDialog(false);
            goNextPage();
          }}
          onClose={() => setIsOpenNoRoomDialog(false)}
        />
      )}

      {getValues('resetChangeTarget') === 'date' && <ResetConfirmDialog resetTarget={resetTarget} />}
    </>
  );
};

export default PrivateCommonCreateForm;

const FormContainer = styled(StyledPrivateForm)`
  .underline-textarea {
    border-bottom: none;
  }
`;
