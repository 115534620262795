import styled from '@emotion/styled';
import { useDrag } from '@use-gesture/react';
import { theme } from 'assets/styles';
import Header, { IHeaderProps } from 'components/Header';
import Progress, { IProgressProps } from 'components/Progress';
import { Z_INDEX } from 'constants/zIndex';
import useStatusBarColor from 'hooks/useStatusBarColor';
import debounce from 'lodash/debounce';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { isKeyboardShowAtom } from 'recoil/keyboard';
import { isPageEndAtom, showBottomNavAtom } from 'recoil/mainLayout';
import { popupAtom } from 'recoil/popup';
import { ColorType } from 'types/colorTypes';

import BottomNavigation from './components/BottomNavigation';

export interface IMainLayoutProps {
  children: React.ReactNode;
  header?: IHeaderProps & {
    forceShowHeaderTitle?: boolean;
    titleScrollThreshold?: number;
    noUnderline?: boolean;
  };
  bottomNav?: boolean;
  backgroundColor?: ColorType;
  /** scroll 이벤트로 bottomNav 노출 유무 필요한 페이지일 때 */
  scrollable?: boolean;
  /** 최상단 프로그레스 바 옵션 { max(최대값), step(현재값) } */
  progress?: IProgressProps;
  /** 스크롤 최상단으로 올라와야 할 때 */
  resetScrollTrigger?: string;
  /** floating button component */
  childrenFloatingButton?: ReactNode;
  /** 검색 component */
  childrenSearch?: JSX.Element | undefined;
}

// NOTE: 플로팅 버튼 props에 대해서 고민해보기
const MainLayout = ({
  children,
  header,
  bottomNav,
  scrollable,
  progress,
  backgroundColor,
  resetScrollTrigger,
  childrenFloatingButton,
  childrenSearch,
}: IMainLayoutProps) => {
  const { pathname } = useLocation();
  const isLinearStatusBar = pathname === '/schedule' || pathname === '/member';
  const currentStatusBarColor = isLinearStatusBar ? 'linear' : theme.color[header?.bgColor || 'white'];

  /** 단순 팝업의 경우 감지가 안되서, 로직 추가 */
  const isOpenPopup = !!useRecoilValue(popupAtom);
  useStatusBarColor(isOpenPopup ? theme.color.white : currentStatusBarColor);

  const [showHeaderTitle, setShowHeaderTitle] = useState(!header?.titleScrollThreshold);
  const [showHeaderUnderline, setShowHeaderUnderline] = useState(false);
  const [showBottomNav, setShowBottomNav] = useRecoilState(showBottomNavAtom);
  const [isPageEnd, setIsPageEnd] = useRecoilState(isPageEndAtom);

  const mainScrollTargetRef = useRef<HTMLDivElement>(null);

  const handleScrollStop = useMemo(() => debounce(() => setShowBottomNav(true), 1300), [setShowBottomNav]);

  const isKeyboardShow = useRecoilValue(isKeyboardShowAtom);

  const gestureBind = useDrag(
    ({ last: isLeave, distance }) => {
      const activeElement = document.activeElement as HTMLElement;
      if (!isKeyboardShow || !isLeave || distance[1] < 10 || activeElement?.tagName === 'TEXTAREA') return;
      activeElement?.blur();
    },
    {
      enabled: isKeyboardShow,
    },
  );

  useEffect(() => {
    if (!resetScrollTrigger) return;
    if (!mainScrollTargetRef.current) return;

    mainScrollTargetRef.current.scrollTo(0, 0);
  }, [resetScrollTrigger]);

  useEffect(() => {
    /** bottomNav 스크롤 관련 이벤트 */
    if (!scrollable || !bottomNav) return;

    if (!mainScrollTargetRef.current) return;
    const mainScrollTarget = mainScrollTargetRef.current;

    let prevScrollY = 0;

    const handleScrollDirection = () => {
      const { scrollTop, clientHeight, scrollHeight } = mainScrollTarget;

      /** scrollTop이 최상단일 경우 nav 보임 */
      if (!scrollTop) {
        setShowBottomNav(true);
        setIsPageEnd(false);
        return;
      }

      /** 스크롤이 거의 페이지 최하단으로 갔을 때 nav 숨김 (iOS 대응) */
      if (scrollTop + clientHeight >= scrollHeight - 50) {
        setShowBottomNav(false);
        setIsPageEnd(true);
        return;
      }

      /** 스크롤 거의 페이지 최상단으로 갔을 때 nav 보임 (iOS 대응) */
      if (scrollTop <= 50) {
        setShowBottomNav(true);
        setIsPageEnd(false);
        return;
      }

      if (scrollTop > prevScrollY) {
        setShowBottomNav(false);
      } else {
        setShowBottomNav(true);
      }

      setIsPageEnd(false);
      prevScrollY = scrollTop;

      handleScrollStop();
    };

    mainScrollTarget.addEventListener('scroll', handleScrollDirection);
    return () => {
      mainScrollTarget.removeEventListener('scroll', handleScrollDirection);
    };
  }, [scrollable, bottomNav, handleScrollStop, header?.titleScrollThreshold, setIsPageEnd, setShowBottomNav]);

  useEffect(() => {
    if (header?.forceShowHeaderTitle) {
      setShowHeaderTitle(true);
      return;
    }

    /** header title 스크롤 관련 이벤트 */
    if (!header?.titleScrollThreshold) return;

    if (!mainScrollTargetRef.current) return;
    const mainScrollTarget = mainScrollTargetRef.current;

    /** 스크롤이 특정 위치에 도달하면 헤더타이틀 노출 */
    const headerTitleScrollEvent = () => {
      if (!mainScrollTargetRef.current || !header?.titleScrollThreshold) return;
      setShowHeaderTitle(mainScrollTarget.scrollTop >= header.titleScrollThreshold);
    };

    mainScrollTarget.addEventListener('scroll', headerTitleScrollEvent);
    return () => {
      mainScrollTarget.removeEventListener('scroll', headerTitleScrollEvent);
    };
  }, [header?.forceShowHeaderTitle, header?.titleScrollThreshold]);

  /** 페이지 스크롤 시 Header 하단 underline 생성되는 이벤트 */
  useEffect(() => {
    if (header?.noUnderline || !mainScrollTargetRef.current || !header) return;

    const mainScrollTarget = mainScrollTargetRef.current;
    const headerUnderlineScrollEvent = () => {
      setShowHeaderUnderline(mainScrollTarget.scrollTop > 0);
    };

    mainScrollTarget.addEventListener('scroll', headerUnderlineScrollEvent);
    return () => {
      mainScrollTarget.removeEventListener('scroll', headerUnderlineScrollEvent);
    };
  }, [header]);

  return (
    <Container backgroundColor={backgroundColor} showHeaderUnderline={showHeaderUnderline} {...gestureBind()}>
      {!!progress && <Progress {...progress} />}

      {!!header && <Header {...header} title={showHeaderTitle ? header?.title : undefined} />}

      {childrenSearch}

      <PageWrapper id="scrollableTarget" ref={mainScrollTargetRef} scrollable={scrollable} bottomNav={bottomNav}>
        {children}
      </PageWrapper>

      {!!childrenFloatingButton && (
        <FloatingWrapper className="floating-button-wrapper" showBottomNav={isPageEnd ? false : showBottomNav}>
          {childrenFloatingButton}
        </FloatingWrapper>
      )}

      {!!bottomNav && (
        <BottomWrapper showBottomNav={isPageEnd ? false : showBottomNav}>
          <BottomNavigation />
        </BottomWrapper>
      )}
    </Container>
  );
};

const Container = styled.div<Pick<IMainLayoutProps, 'backgroundColor'> & { showHeaderUnderline: boolean }>`
  ${theme.flex('column', '', '')};
  position: relative;
  height: 100vh;
  overflow: hidden;
  background-color: ${({ backgroundColor }) => theme.color[backgroundColor || 'white']};

  header {
    ${({ showHeaderUnderline }) => showHeaderUnderline && `box-shadow: 0 -1px 0 0 ${theme.color.gray6} inset`};
  }
`;

const PageWrapper = styled.div<Pick<IMainLayoutProps, 'scrollable' | 'bottomNav'>>`
  flex: 1;
  overflow-y: auto;
  overscroll-behavior: none;
  isolation: isolate;
  padding-bottom: ${({ scrollable, bottomNav }) => !scrollable && bottomNav && '55px'};
`;

const BottomWrapper = styled.footer<{ showBottomNav: boolean }>`
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  bottom: ${({ showBottomNav }) => (showBottomNav ? 0 : '-60px')};
  transition: all 0.2s;
  background-color: ${theme.color.white};
  z-index: 5;
`;

const FloatingWrapper = styled.div<{ showBottomNav: boolean }>`
  position: fixed;
  right: 0;
  bottom: ${({ showBottomNav }) => (showBottomNav ? '70px' : '-60px')};
  transition: all 0.3s;
  z-index: ${Z_INDEX.floatingButton - 1};

  .actions-wrapper {
    position: absolute;
    bottom: 0;
  }
`;

export default MainLayout;
