import { UnderlinedOptions } from 'components/TextField/components/UnderlinedTextField';
import { TextFieldPropTypes } from 'components/TextField/types';
import UnderlinedTextFieldController from 'components/TextField/UnderlinedTextFieldController';
import Typography from 'components/Typography';
import { Control, FieldPath, UseFormSetValue, useWatch } from 'react-hook-form';
import filters from 'utils/filters';

import { TicketPaymentFormType } from '../types';

type KeyType = Extract<
  FieldPath<TicketPaymentFormType>,
  'payment.card_amount' | 'payment.cash_amount' | 'payment.wiretransfer_amount' | 'payment.unpaid_amount' | 'payment.reward_point'
>;

export type PaymentTextFieldProps = Pick<TextFieldPropTypes, 'disabled' | 'left'> &
  Pick<UnderlinedOptions, 'left' | 'textColor'> & {
    control: Control<TicketPaymentFormType>;
    setValue: UseFormSetValue<TicketPaymentFormType>;
    keyValue: KeyType;
    suffix?: string;
  };

export const AMOUNT_LIMIT = 15000000;
export const isNotValidAmount = (value: string | number) => {
  return /e\+/i.test(String(value)) || isNaN(Number(value)) || !Number.isInteger(Number(value));
};

const PaymentTextField = ({ control, setValue, keyValue, disabled, textColor, left, suffix = '원' }: PaymentTextFieldProps) => {
  const keyAmount = useWatch({ control, name: keyValue });

  return (
    <UnderlinedTextFieldController
      controllerProps={{ control, name: keyValue }}
      inputMode="numeric"
      placeholder="0"
      textAlign="right"
      disabled={disabled}
      left={left}
      textColor={textColor}
      suffix={
        <Typography span size={15} weight={500}>
          {suffix}
        </Typography>
      }
      value={filters.numberComma(keyAmount)}
      onChange={e => {
        const amount = filters.numberComma(e.target.value, { reverse: true });
        const isNotValid = isNotValidAmount(amount);
        const value = Number(amount) > AMOUNT_LIMIT ? AMOUNT_LIMIT : amount;
        setValue(keyValue, isNotValid ? 0 : value, { shouldValidate: true, shouldDirty: true });
      }}
    />
  );
};

export default PaymentTextField;
