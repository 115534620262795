import usePatchCounsel from 'hooks/service/mutations/usePatchCounseling';
import usePostCounsel, { PostCounselingParamsType } from 'hooks/service/mutations/usePostCounseling';
import { CounselingDetail } from 'hooks/service/queries/useGetCounseling';
import useErrorDialog from 'hooks/useErrorDialog';
import useToast from 'hooks/useToast';
import { useLocation, useNavigate } from 'react-router-dom';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import { CustomError } from 'types/errorTypes';
import filters from 'utils/filters';

import { CounselingFormType } from '../type';
import CounselCreateUpdateForm from './CounselCreateUpdateForm';

type Props = {
  isUpdateMode: boolean;
  typeText: string;
  currentCounseling: CounselingDetail | null;
};

const CounselCreateForm = ({ isUpdateMode, typeText, currentCounseling }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setToast } = useToast();
  const errorDialog = useErrorDialog();

  const { mutate: postCounselMutate } = usePostCounsel();
  const { mutate: patchCounselMutate } = usePatchCounsel(currentCounseling?.id);

  const handleSubmit = async (values: CounselingFormType, isRetry?: boolean) => {
    const { id, date, time, is_registered, target_staff_id, phone, user_id, ...rest } = values;

    const combinedDateStartTime = `${filters.dateDash(date)} ${filters.timeSecondZero(time[0])}`;
    const combinedDateEndTime = `${filters.dateDash(date)} ${filters.timeSecondZero(time[1])}`;
    // 최초 시도시에만 체크하고, 재시도시에는 바로 진행
    if (!isRetry && user_id && is_registered === 'notRegistered') {
      const customClose = () => {
        // 수정된 값으로 다시 제출
        handleSubmit(
          {
            ...values,
            is_registered: 'registered',
          },
          true,
        ); // isRetry를 true로 설정
      };

      errorDialog.open(`이미 회원으로 등록된 고객입니다.\n회원등록 여부를 '회원'으로\n자동 변경하여 저장합니다.`, customClose);
      return;
    }

    const params: PostCounselingParamsType = {
      ...rest,
      phone,
      target_staff_id: Number(target_staff_id),
      start_on: combinedDateStartTime,
      end_on: combinedDateEndTime,
      is_registered: is_registered === 'registered',
    };

    if (isUpdateMode) {
      patchCounselMutate(params, {
        onSuccess: res => {
          setToast({ type: 'success', message: `상담이 ${typeText}되었습니다.` });
          navigate(-1);
        },
        onError: (error: CustomError) => {
          errorDialog.open(error);
        },
      });
    } else {
      postCounselMutate(params, {
        onSuccess: res => {
          setToast({ type: 'success', message: `상담이 ${typeText}되었습니다.` });
          navigate(`/counsel/detail/${res.data.id}${location.search}`);
        },
        onError: (error: CustomError) => {
          errorDialog.open(error);
        },
      });
    }
  };

  const handleSubmitWithRetry = (values: CounselingFormType) => {
    return handleSubmit(values, false);
  };

  return (
    <ApiBoundary>
      <CounselCreateUpdateForm typeText={typeText} onSubmit={handleSubmitWithRetry} />
    </ApiBoundary>
  );
};

export default CounselCreateForm;
