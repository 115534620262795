import { ETC_SCHEDULE_PERMISSION } from 'pages/EtcSchedule/constants';
import { useRecoilValue } from 'recoil';
import { staffIdAtom } from 'recoil/common';

import { EtcScheduleDetailResponse } from './service/queries/useGetEtcScheduleEtcScheduleTime';
import usePermission from './usePermission';

/**
 * 기타 일정 상세 페이지에서 강사별 일정 수정/복사/삭제 권한 체크
 * 수정/복사/삭제는 등록자만 가능하고 본인, 타강사 업데이트 권한 모두 있을 때만 true
 * 조회 권한은 본인은 항상 가능, 타강사 조회 권한 없을 때 false
 * @param register 기타 일정을 등록한 강사
 * @param staffs 기타 일정에 포함되어 있는 강사 리스트
 */
const useCheckPermissionEtcDetail = (register: EtcScheduleDetailResponse['register']) => {
  const { hasPermission, everyPermissions } = usePermission();
  const currentStaffId = useRecoilValue(staffIdAtom);
  const isRegister = register.id === currentStaffId;

  return {
    canViewEtcSchedule: hasPermission(ETC_SCHEDULE_PERMISSION.others.view),
    canCreateEtcSchedule: isRegister && everyPermissions(ETC_SCHEDULE_PERMISSION.all.create),
    canUpdateEtcSchedule: isRegister && everyPermissions(ETC_SCHEDULE_PERMISSION.all.update),
    canDeleteEtcSchedule: isRegister && everyPermissions(ETC_SCHEDULE_PERMISSION.all.delete),
  };
};

export default useCheckPermissionEtcDetail;
