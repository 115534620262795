import instance from 'api/axios';
import axios, { AxiosResponse } from 'axios';
import useToast from 'hooks/useToast';
import { useCallback } from 'react';
import { recordErrorWithSentry } from 'utils/sentry';

export type AttachmentType = 'boards' | 'memo' | 'studio_policies';

const usePostFileUploadAttachmentPresignedUrl = (type: AttachmentType) => {
  const mutationFn = async (data: AttachmentPresignedUrlParams): Promise<AxiosResponse<AttachmentPresignedUrlResponse>> => {
    return await instance({
      method: 'post',
      url: `/api/fileupload/attachment/presigned-url`,
      data,
    });
  };

  const { setToast } = useToast();

  const getAttachmentIds = useCallback(
    async (files?: Array<File>, toastPosition?: number) => {
      if (!files || files.length === 0) return []; // 오류로 보지 않음

      try {
        const preSignedImagePaths = await Promise.all(
          files.map(async file => {
            const fileType = file.type.split('/')[1]; // jpg, jpeg, png

            // file별로 S3 임시링크, DB id 미리 받아와서 매칭
            const { data } = await mutationFn({ type, fileType });
            return { file, url: data['presigned-url'], id: data.attachment.id };
          }),
        );

        await Promise.all(
          preSignedImagePaths.map(async ({ file, url }) => {
            // S3에 개별 이미지 업로드
            await axios.put(url, file);
          }),
        );

        // 최종적으로 id만 반환
        return preSignedImagePaths.map(({ id }) => id);
      } catch (e) {
        recordErrorWithSentry(e);
        setToast({ type: 'error', message: '사진 업로드에 실패했습니다.\n다시 시도해 주세요.', bottom: toastPosition });
        return false;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [type],
  );

  return { getAttachmentIds };
};

export default usePostFileUploadAttachmentPresignedUrl;

export type AttachmentResponse = {
  id: number;
  ref_type: string;
  ref_id: number;
  path: {
    path: string;
    name: string;
    extension: string;
  };
  deleted_at: string | null;
  created_at: string;
  updated_at: string;
};

type AttachmentPresignedUrlParams = {
  type: AttachmentType;
  fileType: string; // S3 파일 뒤에 붙는 확장자, MIME 타입 모두 가능
};

type AttachmentPresignedUrlResponse = {
  'presigned-url': string; // S3 업로드 URL
  timeout: 'string'; // 업로드 URL 만료 시간, "YYYY-MM-DD HH:MM:SS"
  attachment: AttachmentResponse;
  deleted_at: string | null;
  created_at: string;
  updated_at: string;
};
