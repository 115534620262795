import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Skeleton from 'components/Skeleton';
import Typography from 'components/Typography';
import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { NAME_SORT_FILTERS } from '../constants';
import { StaffFieldFormType } from '../types';

const SkelotonStaffList = () => {
  const length = 8;
  const dummyList = Array.from({ length }, (_, index) => index);

  const { control } = useFormContext<StaffFieldFormType>();
  const sort = useWatch({ control, name: 'sort' });

  const sortLabel = useMemo(() => {
    return NAME_SORT_FILTERS.filter(data => sort === data.value)[0].label;
  }, [sort]);

  return (
    <Container>
      <section className="list-content-header">
        <Typography size={13} weight={500} textColor="gray3">
          총<span className="total-count">0</span>명
        </Typography>

        <div>
          <Button
            fontWeight={400}
            textColor="gray2"
            rightIcon={<Icon name="arrowBottomFill" size={16} fillColor={theme.color.gray3} />}
            onClick={() => null}>
            {sortLabel}
          </Button>
        </div>
      </section>

      <section className="staff-list">
        {dummyList.map(i => (
          <Item key={i}>
            <Skeleton className="profile" height={48} width={48} type="circle" />
            <Skeleton height={22} width={60} type="round" />
            <Skeleton height={16} width={116} type="round" />
            <Skeleton className="last" height={16} width={47} type="round" />
          </Item>
        ))}
      </section>
    </Container>
  );
};

export default SkelotonStaffList;

const Container = styled.ul`
  .list-content-header {
    ${theme.flex('row', 'center', 'space-between')};
    margin-bottom: 16px;
    padding: 24px 20px 0;

    .typography > .total-count {
      margin-left: 4px;
      color: ${theme.color.primary};
      font-weight: 600;
    }
  }

  .staff-list {
    ${theme.flex('row', '', 'space-between', 12)};
    flex-wrap: wrap;
    padding: 0 20px 20px;

    [type='round'] {
      border-radius: 3px;
    }
  }
`;

const Item = styled.li`
  ${theme.flex('column', '', '', 4)};
  flex: 1;
  padding: 16px;
  height: 156px;
  border-radius: 16px;
  background-color: white;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08);

  .profile {
    margin-bottom: 6px;
  }

  .last {
    margin-top: 4px;
  }
`;
