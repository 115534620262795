import usePatchMember from 'hooks/service/mutations/usePatchMember';
import { useMemberForm } from 'hooks/useMemberForm';
import { CurrentMemberIdProps } from 'pages/MemberDetail/types';
import MobileConfirmDrawer from 'pages/MoreDetails/Staff/Form/InfoUpdateForm/components/MobileConfirmDrawer';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import CommonMemberForm from '../components/CommonMemberForm';
import { MemberFormType } from '../types';

type Props = CurrentMemberIdProps & {
  isUserGrade: boolean;
  canViewMemberMobileNumber: boolean;
};

const MemberUpdateForm = ({ currentMemberId, isUserGrade, canViewMemberMobileNumber }: Props) => {
  const {
    formState: { dirtyFields },
  } = useFormContext<MemberFormType>();
  const { mutate: updateMemberMutate } = usePatchMember(currentMemberId);
  const { onSubmit } = useMemberForm({ pageMode: 'update', mutationFunc: updateMemberMutate });
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <>
      <CommonMemberForm
        pageMode="update"
        onSubmit={!dirtyFields.mobile ? onSubmit : () => setIsDrawerOpen(true)}
        canViewMemberMobileNumber={canViewMemberMobileNumber}
        isUserGrade={isUserGrade}
      />

      <MobileConfirmDrawer
        target="회원앱"
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        onSubmit={() => {
          onSubmit();
          setIsDrawerOpen(false);
        }}
      />
    </>
  );
};

export default MemberUpdateForm;
