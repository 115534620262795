import { UseMutateFunction } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { IToastProps } from 'components/Toast';
import { MEMBER_FORM_TEXT } from 'pages/MemberForm/constants';
import { MemberFormPageModeType, MemberFormType } from 'pages/MemberForm/types';
import { getCreateTicketParams, getUpdateTicketParams } from 'pages/ProductForm/utils';
import { useCallback, useMemo } from 'react';
import { SubmitErrorHandler, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { staffIdAtom, studioIdAtom } from 'recoil/common';
import { CustomError } from 'types/errorTypes';
import filters from 'utils/filters';

import usePatchUserTicketUpdate from './service/mutations/usePatchUserTicketUpdate';
import usePostFileUploadAvatarPresignedUrl from './service/mutations/usePostFileUploadAvatarPresignedUrl';
import { MemberCreateParams, MemberCreateResponse } from './service/mutations/usePostMember';
import usePostUserTicket from './service/mutations/usePostUserTicket';
import { MemberDetailResponse } from './service/queries/useGetMemberDetail';
import useErrorDialog from './useErrorDialog';
import useToast from './useToast';

type Props = {
  pageMode: MemberFormPageModeType;
  mutationFunc: UseMutateFunction<
    AxiosResponse<MemberCreateResponse | MemberDetailResponse>,
    Error,
    MemberCreateParams | undefined,
    unknown
  >;
};

/** 회원 등록, 수정 요청 hook */
export const useMemberForm = ({ pageMode, mutationFunc }: Props) => {
  const {
    reset,
    getValues,
    handleSubmit,
    formState: { dirtyFields, defaultValues },
  } = useFormContext<MemberFormType>();

  const currentStudioId = useRecoilValue(studioIdAtom);
  const currentStaffId = useRecoilValue(staffIdAtom);

  const navigate = useNavigate();
  const { setToast } = useToast();
  const errorDialog = useErrorDialog();

  const { mutate: createTicketMutate } = usePostUserTicket();
  const { mutate: updateTicketMutate } = usePatchUserTicketUpdate();
  const { getAvatarImage } = usePostFileUploadAvatarPresignedUrl();

  const toastMessage: IToastProps = useMemo(() => {
    return { type: 'success', message: MEMBER_FORM_TEXT.successMessage[pageMode] };
  }, [pageMode]);

  const resetFormAfterError = useCallback(() => {
    reset(
      {
        ...defaultValues,
        beforeName: getValues('profile.name'),
        image: getValues('file') ? URL.createObjectURL(getValues('file') as File) : getValues('image'),
        file: getValues('file'),
        tickets: getValues('tickets'),
        userTickets: getValues('userTickets'),
        updateUserTickets: getValues('updateUserTickets'),
      },
      { keepDirtyValues: true },
    );
  }, [defaultValues, getValues, reset]);

  const createTickets = useCallback(
    (userId: number) => {
      const { tickets } = getValues();
      if (!tickets.length) return;

      const ticketsParamsArray = getCreateTicketParams({
        tickets,
        currentMemberId: userId,
        currentStaffId,
        currentStudioId,
      });

      createTicketMutate(
        { user_tickets: ticketsParamsArray },
        {
          onSuccess: () => {
            if (pageMode === 'update') {
              navigate(-1);
            } else {
              navigate(`/member/detail/${userId}`, { replace: true });
            }
            setToast(toastMessage);
          },
          onError: resetFormAfterError,
        },
      );
    },
    [
      createTicketMutate,
      currentStaffId,
      currentStudioId,
      getValues,
      navigate,
      pageMode,
      setToast,
      toastMessage,
      resetFormAfterError,
    ],
  );

  const updateUserTickets = useCallback(
    (userId: number) => {
      const { updateUserTickets, tickets } = getValues();
      if (!updateUserTickets || !updateUserTickets.length) return;

      const ticketsParamsArray = getUpdateTicketParams({
        userTickets: updateUserTickets,
        currentMemberId: userId,
        currentStaffId,
        currentStudioId,
      });

      updateTicketMutate(
        { user_tickets: ticketsParamsArray },
        {
          onSuccess: () => {
            if (tickets.length) {
              createTickets(userId);
            } else {
              navigate(-1);
              setToast(toastMessage);
            }
          },
          onError: resetFormAfterError,
        },
      );
    },
    [
      createTickets,
      currentStaffId,
      currentStudioId,
      getValues,
      navigate,
      setToast,
      toastMessage,
      updateTicketMutate,
      resetFormAfterError,
    ],
  );

  const submitMember = useCallback(
    (params: MemberCreateParams) => {
      const { updateUserTickets: updatedUserTickets, tickets } = getValues();
      const hasNewTickets = tickets.length; // 발급할 수강권 있는지
      const hasUpdateUserTickets = updatedUserTickets && updatedUserTickets.length; // 수정할 수강권 있는지

      mutationFunc(params, {
        onSuccess: res => {
          // 회원 수정
          if (pageMode === 'update') {
            if (hasUpdateUserTickets) {
              updateUserTickets(res.data.id);
            } else if (hasNewTickets) {
              createTickets(res.data.id);
            } else {
              navigate(-1);
              setToast(toastMessage);
            }
          } else {
            // 회원 등록
            if (hasNewTickets) {
              createTickets(res.data.id);
            } else {
              navigate(`/member/detail/${res.data.id}`, { replace: true });
              setToast(toastMessage);
            }
          }
        },
        onError: err => {
          const error = err as CustomError;
          const noPermission = error.response?.data?.code === 34001 || error.response?.data?.code === 34002;
          if (noPermission) {
            return false;
          }
          resetFormAfterError();
        },
      });
    },
    [createTickets, getValues, mutationFunc, navigate, pageMode, setToast, toastMessage, updateUserTickets, resetFormAfterError],
  );

  const submit = useCallback(
    async (values: MemberFormType) => {
      const {
        counseling_id,
        address,
        userGradeId,
        mobile,
        file,
        vaccination_yn,
        profile: { name, gender, birthday, registered_at },
      } = values;

      const defaultParams: MemberCreateParams = {
        profile: {
          name,
          gender,
          birthday,
          registered_at: filters.dateDash(registered_at),
        },
        vaccination_yn: vaccination_yn ? 'Y' : 'N',
        user_grade_id: userGradeId === MEMBER_FORM_TEXT.userGrade.notSelected.value ? null : Number(userGradeId),
        mobile: pageMode === 'update' && !dirtyFields.mobile ? undefined : mobile,
        address: address
          ? {
              address: address.address,
              detail_address: address.detail_address,
            }
          : undefined,
        counseling_id,
      };

      const avatarResponse = await getAvatarImage(file);
      const image = avatarResponse === false ? undefined : avatarResponse; // 실패하면 이미지 없이 그냥 진행

      submitMember({
        ...defaultParams,
        image,
      });
    },
    [pageMode, dirtyFields.mobile, getAvatarImage, submitMember],
  );

  const validationError: SubmitErrorHandler<MemberFormType> = error => {
    const message = error.profile?.name ? `회원명은\n ${error.profile?.name?.message}` : error.mobile?.message;
    errorDialog.open(message);
  };

  return {
    onSubmit: handleSubmit(submit, validationError),
  };
};
