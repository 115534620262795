import { MemberFormType } from 'pages/MemberForm/types';
import ProductUpdateForm from 'pages/ProductForm/components/ProductUpdateForm';
import { ProductUpdateFormType } from 'pages/ProductForm/types';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

const ProductUpdate = () => {
  const { control } = useFormContext<MemberFormType>();
  const selectedUpdatedTicket = useWatch({ control, name: 'selectedUpdatedTicket' });

  const navigate = useNavigate();

  const { fields: fieldsUserTickets, update: updateCurrentUserTickets } = useFieldArray({
    control,
    name: 'userTickets',
    keyName: 'key',
  });
  const {
    fields: fieldsUpdateUserTickets,
    append: addUserTicket,
    update: updateUserTicket,
  } = useFieldArray({
    control,
    name: 'updateUserTickets',
    keyName: 'key',
  });

  const save = (values: ProductUpdateFormType) => {
    if (!fieldsUserTickets) return;

    const {
      userTicket,
      count,
      period,
      payment,
      paymentId,
      installment,
      detail: { is_shared, staffs },
    } = values;
    const userTicketId = userTicket.id;

    const updateIndex = fieldsUserTickets.findIndex(fieldItem => fieldItem.id === userTicketId);
    const updatedUserTicket = {
      ...userTicket,
      is_shared,
      staffs,
      ...count,
      ...period,
      newPayment: { ...payment, installment_period: installment === 'single' ? 1 : payment.installment_period, paymentId },
    };

    updateCurrentUserTickets(updateIndex, updatedUserTicket);

    const updatedTicket = {
      ...values,
      ...updatedUserTicket.newPayment,
    };

    // 회원이 가진 여러 수강권 중 실제 수정된 것만 배열로 저장
    const updatedTicketsIndex = fieldsUpdateUserTickets.findIndex(fieldItem => fieldItem.userTicket.id === values.userTicket.id);
    if (updatedTicketsIndex < 0) {
      addUserTicket(updatedTicket);
    } else {
      updateUserTicket(updatedTicketsIndex, updatedTicket);
    }
    navigate(-1);
  };

  if (!selectedUpdatedTicket) {
    return <></>;
  }

  return <ProductUpdateForm userTicket={selectedUpdatedTicket} onSubmit={save} />;
};

export default ProductUpdate;
