import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import TextFieldBase, { ITextFieldBasePropTypes } from 'components/TextFieldBase';
import Typography from 'components/Typography';
import { useEffect, useRef, useState } from 'react';
import { hexToRgba } from 'utils/styles';

export type CounterProps = Omit<ITextFieldBasePropTypes, 'onChange' | 'onBlur' | 'value' | 'min' | 'max'> & {
  onChange: (value: number) => void;
  onBlur?: (value: number) => void;
  value: number;
  min?: number;
  max?: number;
  suffix?: string;
};
const Counter = ({ value, onChange, min = 0, max = 999, suffix, ...props }: CounterProps) => {
  const isDisabledMinus = value === min || value === 0 || props.disabled;
  const isDisabledPlus = value === max || value === 999 || props.disabled;

  // 버튼 클릭시 즉시 변경사항 반영
  const clickMinus = () => {
    if (value === min) return;
    const newValue = --value;
    onChange(newValue);
  };

  const clickPlus = () => {
    if (value === max) return;
    const newValue = ++value;
    onChange(newValue);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = e.target.value;
    const noNumberType = !!currentValue.match(/[^0-9]/g)?.length;
    const currentNum = Number(e.target.value);

    // 숫자가 아닌 입력이나 음수는 0으로 처리
    if (noNumberType || currentNum < 0) {
      onChange(0);
      return;
    }

    if (currentNum > 999) {
      onChange(999);
      return;
    }
    onChange(currentNum);
  };

  const handleInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    // blur 시에만 범위 체크하고 onChange 호출
    const currentNum = Number(e.target.value);
    let finalValue = currentNum;

    if (currentNum > max) {
      finalValue = max;
    } else if (currentNum < min) {
      finalValue = min;
    }

    onChange(finalValue);
  };

  const ref = useRef<HTMLDivElement>(null);
  const [isFocused, setIsFocused] = useState(false);

  /**
   * Counter input 입력 후 외부를 클릭했을 시,
   * 다른 클릭이벤트 실행되지 않고 blur 처리만 되도록
   */
  useEffect(() => {
    const clickOutside = (e: MouseEvent) => {
      if (isFocused && ref.current && !ref.current.contains(e.target as Node)) {
        e.stopPropagation();
        e.preventDefault();
        setIsFocused(false);
      }
    };

    // dialog나 drawer 등 다른 root에 영향을 주지 않도록 document가 아닌 root에 적용
    const root = document.getElementById('root');

    root?.addEventListener('click', clickOutside, true);
    return () => root?.removeEventListener('click', clickOutside, true);
  }, [isFocused]);

  return (
    <Container className="counter" suffix={suffix} ref={ref}>
      <StyledButton borderRadius="50%" variant="outlined" widthSize={24} onClick={clickMinus} disabled={isDisabledMinus}>
        <Icon name="minusBold" size={16} color={isDisabledMinus ? 'gray4' : 'gray1'} />
      </StyledButton>

      <div className="counter-text-wrapper">
        <TextFieldBase
          {...props}
          inputMode="numeric"
          value={value && String(value).replace(/(^0+)/, '')}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onFocus={() => setIsFocused(true)}
          min={min}
          max={max}
        />
        {suffix && (
          <Typography span size={15} weight={500} textColor={props.disabled ? 'gray4' : 'gray1'}>
            {suffix}
          </Typography>
        )}
      </div>

      <StyledButton borderRadius="50%" variant="outlined" widthSize={24} onClick={clickPlus} disabled={isDisabledPlus}>
        <Icon name="plusBold" size={16} color={isDisabledPlus ? 'gray4' : 'gray1'} />
      </StyledButton>
    </Container>
  );
};

export default Counter;

const Container = styled.div<Pick<CounterProps, 'suffix' | 'disabled'>>`
  ${theme.flex('', 'center', '', 12)};

  .icon-button {
    background-color: ${theme.color.white};
    :disabled {
      background-color: ${theme.color.gray8};
    }
  }

  .counter-text-wrapper {
    ${theme.flex('', 'center', '', 2)};
    flex: 1;

    input {
      padding: 0;
      flex: 1;
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 22px;
      text-align: ${({ suffix }) => (suffix ? 'right' : 'center')};
      background-color: transparent;

      :disabled {
        color: ${theme.color.gray4};
      }
    }
  }
`;

const StyledButton = styled(IconButton)<{ disabled?: boolean }>`
  border-color: ${({ disabled }) => (disabled ? theme.color.gray5 : hexToRgba(theme.color.gray4, 0.72))};
`;
