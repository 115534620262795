import { useIsFetching } from '@tanstack/react-query';
import { queryClient } from 'api/queryClient';
import useParamsId from 'hooks/useParamsId';
import { useLayoutEffect } from 'react';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import FullScreenLoading from 'sharedComponents/FullScreenLoading';

import Main from './Main';

const BookingMember = () => {
  const currentLectureId = useParamsId();
  const isFetching = useIsFetching() > 0;

  useLayoutEffect(() => {
    // 캐시된 강사 데이터로 권한 요청 후 재요청 하는 것 방지를 위해 현재 로그인 강사 캐시 지움
    queryClient.removeQueries({ queryKey: ['staff/me'] });
  }, []);

  return (
    <ApiBoundary fallback={<FullScreenLoading loop={isFetching} />}>
      <Main currentLectureId={currentLectureId} />
    </ApiBoundary>
  );
};

export default BookingMember;
