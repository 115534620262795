import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import SingleButtonDrawer from 'designedComponents/Drawers/DrawerWithButton/SingleButtonDrawer';
import useGetUserTicket from 'hooks/service/queries/useGetUserTicket';
import { UnpaidDataResponse } from 'hooks/service/queries/useInfinityReportUnpaid';
import { UserTicketResponse } from 'hooks/service/queries/useInfinityUserTicketProduct';
import { useNavigate } from 'react-router-dom';
import TicketCard from 'sharedComponents/TicketCard';
import { FormatTicketCard } from 'sharedComponents/TicketCard/types';
import filters from 'utils/filters';
import formatSmallUserTicketCard from 'utils/formatSmallUserTicketCard';
import getEllipsisByLength from 'utils/getEllipsisByLength';

import { REPORT_PAYMENT_LABEL } from '../../constants';

type Props = {
  isOpen: boolean;
  detailData: UnpaidDataResponse;
  onClose: () => void;
};

const initialAmounts = {
  card_amount: 0,
  cash_amount: 0,
  wiretransfer_amount: 0,
  point_amount: 0,
  unpaid_amount: 0,
};

const ReportUnpaidDetailInfo = ({ isOpen, detailData, onClose }: Props) => {
  const navigate = useNavigate();

  const { data: userTicket } = useGetUserTicket({ ticket_id: detailData.user_ticket.id, user_id: detailData.member.id });

  const totalPayments = detailData.payments.reduce(
    (acc, data) => {
      for (const key in initialAmounts) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          acc[key as keyof typeof acc] += Number(data[key as keyof typeof data]);
        }
      }
      return acc;
    },
    { ...initialAmounts },
  );

  const paymentLabel = detailData?.label;
  const paymentType = REPORT_PAYMENT_LABEL[paymentLabel as keyof typeof REPORT_PAYMENT_LABEL];
  const originalPrice = userTicket.payments.original_price;
  const paidAmount = detailData.payments.reduce((acc, data) => {
    return acc + data.card_amount + data.cash_amount + data.wiretransfer_amount + data.point_amount;
  }, 0);
  const isRefundDetail = paymentLabel === 'refund';
  const memberName = getEllipsisByLength({ text: detailData.member.name, max: 10, hasEllipsis: true });

  const formatUnpaidTicketCard = (userTicket: UserTicketResponse): FormatTicketCard => {
    const formattedCard = formatSmallUserTicketCard(userTicket);

    if (userTicket.user_ticket_status === 'expired') {
      // bottomOptions 배열에서 'n일' 형식의 문자열을 '0일'로 교체
      formattedCard.bottomOptions = formattedCard.bottomOptions.map(option => {
        if (option?.endsWith('일')) {
          return '0일';
        }
        return option;
      });
    }

    return formattedCard;
  };

  const goToTicketDetail = () => {
    navigate(`/product/detail/${detailData.user_ticket.id}?memberId=${detailData.member.id}`);
  };

  return (
    <StyledSingleButtonDrawer
      isOpen={isOpen}
      header={
        <HeaderTextWrapper>
          <div className="member-name-wrapper">
            <Typography ellipsis={1} size={19} weight={700} textColor="gray1">
              {memberName}
            </Typography>
            <Typography size={19} weight={700} textColor="gray1">
              회원
            </Typography>
          </div>
        </HeaderTextWrapper>
      }
      onClose={onClose}
      button={{ text: '수강권 상세', color: 'gray', onClick: () => goToTicketDetail() }}>
      <Container>
        <ClassInfoSection>
          <TicketCard ticket={formatUnpaidTicketCard(userTicket)} />
        </ClassInfoSection>
        <PriceSection paymentType={paymentType.name}>
          <Divider />
          <InfoWrapper>
            <Typography size={15} textColor="gray2">
              판매가
            </Typography>
            <Typography size={15} weight={500} textColor="gray1">
              {`${filters.numberComma(originalPrice)}원`}
            </Typography>
          </InfoWrapper>
        </PriceSection>
        <Divider />
        <PaymentSection>
          <InfoWrapper>
            <Typography size={15} textColor="gray2">
              {`${detailData.payments[0].installment_period === 1 ? '일시불' : `카드 (할부 ${detailData.payments[0].installment_period}개월)`}`}
            </Typography>
            <Typography size={15} weight={500} textColor="gray1">
              {`${isRefundDetail && totalPayments.card_amount > 0 ? '-' : ''}  ${filters.numberComma(totalPayments.card_amount)}원`}
            </Typography>
          </InfoWrapper>
          <Divider thin />
          <InfoWrapper>
            <Typography size={15} textColor="gray2">
              현금
            </Typography>
            <Typography size={15} weight={500} textColor="gray1">
              {`${isRefundDetail && totalPayments.cash_amount > 0 ? '-' : ''} ${filters.numberComma(totalPayments.cash_amount)}원`}
            </Typography>
          </InfoWrapper>
          <Divider thin />
          <InfoWrapper>
            <Typography size={15} textColor="gray2">
              계좌이체
            </Typography>
            <Typography size={15} weight={500} textColor="gray1">
              {`${isRefundDetail && totalPayments.wiretransfer_amount > 0 ? '-' : ''} ${filters.numberComma(totalPayments.wiretransfer_amount)}원`}
            </Typography>
          </InfoWrapper>
          <Divider thin />
          <InfoWrapper>
            <Typography size={15} textColor="gray2">
              포인트
            </Typography>
            <Typography size={15} weight={500} textColor="gray1">
              {`${isRefundDetail && totalPayments.point_amount > 0 ? '-' : ''} ${filters.numberComma(totalPayments.point_amount)}원`}
            </Typography>
          </InfoWrapper>
          <Divider thin />
          <InfoWrapper>
            <Typography size={15} textColor="gray2" weight={500}>
              {paymentLabel === 'transfer' ? '총 양도금액' : isRefundDetail ? '총 환불금액' : '총 결제금액'}
            </Typography>
            <Typography size={15} weight={600} textColor={`${isRefundDetail ? 'secondary3' : 'primary'}`}>
              {`${isRefundDetail ? '-' : ''} ${filters.numberComma(paidAmount)}원`}
            </Typography>
          </InfoWrapper>
          <Divider thin />
        </PaymentSection>
        <Divider />
        <UnPaidSection>
          <InfoWrapper>
            <Typography size={15} textColor="gray2">
              미수금
            </Typography>
            <Typography size={15} weight={500} textColor="secondary3">
              {`${filters.numberComma(detailData.unpaid_info.unpaid_amount)}원`}
            </Typography>
          </InfoWrapper>
          <Divider thin />
          <InfoWrapper>
            <Typography size={15} textColor="gray2">
              {paymentLabel === 'transfer' ? '양도일시' : isRefundDetail ? '환불일시' : '결제일시'}
            </Typography>
            <Typography size={15} weight={500} textColor="gray1">
              {`${filters.dateWithWeekday(detailData.settlement_at)} ${filters.time(detailData.settlement_at)}`}
            </Typography>
          </InfoWrapper>
        </UnPaidSection>
      </Container>
    </StyledSingleButtonDrawer>
  );
};

export default ReportUnpaidDetailInfo;

const StyledSingleButtonDrawer = styled(SingleButtonDrawer)`
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: calc(32px + 76px);
`;

const Container = styled.div``;

const HeaderTextWrapper = styled.div`
  ${theme.flex('row', 'center', 'space-between')};
  padding: 16px 20px;

  .member-name-wrapper {
    ${theme.flex('row', '', '', 4)}
  }

  .label {
    margin: 2px 0px;
  }
`;

const ClassInfoSection = styled.section`
  padding: 0 16px 20px 16px;
  .ticket-card {
    padding: 0;
    gap: 16px;

    .ticket-image {
      width: 63px;
      height: 36px;
    }

    .experience-label {
      right: 0;
    }
  }
`;

const PriceSection = styled.section<{ paymentType: string }>(
  ({ paymentType }) => css`
    display: ${paymentType === '양도' ? 'none' : 'block'};
  `,
);

const PaymentSection = styled.section``;

const UnPaidSection = styled.section``;

const InfoWrapper = styled.div`
  ${theme.flex('', '', 'space-between')}
  padding: 17px 20px;
`;
