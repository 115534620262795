import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Button from 'components/Button';
import ButtonGroup from 'components/Button/ButtonGroup';
import useGetPermissionDoHavePermission from 'hooks/service/queries/useGetPermissionDoHavePermission';
import usePermission from 'hooks/usePermission';
import useQueryString from 'hooks/useQueryString';
import { MEMBER_PERMISSION } from 'pages/MemberDetail/constants';
import { MemberFormType, MemberUpdateUserTicket } from 'pages/MemberForm/types';
import { useFormContext, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { CardWrapper } from 'sharedComponents/InfoTypography';
import TicketCard from 'sharedComponents/TicketCard';
import formatSmallUserTicketCard from 'utils/formatSmallUserTicketCard';

import { MemberFormTicketWrapper } from '../Tickets/TicketsList';

const UserTickets = () => {
  const { control, setValue } = useFormContext<MemberFormType>();
  const userTickets = useWatch({ control: control, name: 'userTickets' });

  const { hasPermission } = usePermission();
  const canUpdateUserTicket = hasPermission(MEMBER_PERMISSION.memberTicketEdit);
  const { checkPermission } = useGetPermissionDoHavePermission();
  const navigate = useNavigate();
  const { getAllSearchParams, setSearchParams } = useQueryString();

  const clickUpdate = (userTicket: MemberUpdateUserTicket) => {
    checkPermission(MEMBER_PERMISSION.memberTicketEdit.id, {
      onSuccess: () => {
        const { id, ticket } = userTicket;
        const productType = ticket.available_class_type === 'I' ? 'item' : 'userTicket';

        setValue('userTickets', userTickets);
        setValue('selectedUpdatedTicket', userTicket);

        setSearchParams({
          ...getAllSearchParams(),
          type: 'product',
          productMode: 'update',
          userTicketId: `${id}`,
          productType,
          step: '2',
        });
      },
      onError: () => {
        navigate(-1);
      },
    });
  };

  if (!userTickets.length) {
    return <></>;
  }

  return (
    <Container className="user-ticket-list" canUpdateUserTicket={canUpdateUserTicket}>
      {userTickets.map(userTicket => {
        return (
          <MemberFormTicketWrapper className="user-ticket-card" key={userTicket.id}>
            <TicketCard ticket={formatSmallUserTicketCard(userTicket)} />
            {canUpdateUserTicket && (
              <ButtonGroup gap={4}>
                <Button
                  padding={{ left: 8, right: 8 }}
                  widthSize={64}
                  size="small"
                  variant="outlined"
                  textColor="gray2"
                  onClick={() => clickUpdate(userTicket)}>
                  수정
                </Button>
              </ButtonGroup>
            )}
          </MemberFormTicketWrapper>
        );
      })}
    </Container>
  );
};

export default UserTickets;

const Container = styled(CardWrapper)<{ canUpdateUserTicket: boolean }>`
  padding: 8px 20px 20px;

  ${({ canUpdateUserTicket }) =>
    !canUpdateUserTicket &&
    css`
      .user-ticket-card {
        padding: 4px 20px;
      }
    `}
`;
