import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import useInfiniteQueryFnWithCommonParams from '../common/useInfiniteQueryFnWithCommonParams';

/** 부모 수강권/상품 리스트 */
const useInfinityTickets = (params: TicketsParams) => {
  const { queryFn } = useInfiniteQueryFnWithCommonParams<TicketsResponse, TicketsParams>({
    url: `/api/userTicket/tickets`,
    params,
  });

  return useSuspenseInfiniteQuery({
    queryKey: ['tickets', { ...params }],
    queryFn,
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      if (!lastPage.data.links.next) return null;
      return lastPage.data.meta.current_page + 1;
    },
    select: data => data.pages.flatMap(page => page.data.data),
  });
};

export default useInfinityTickets;

export type TicketsParams = {
  product_type: 'userTicket' | 'item';
  ticket_type: string | null;
  item_type: string | null;
  search?: string;
};

export type TicketsResponse = {
  id: number;
  studio_id: number;
  title: string;
  /**G: 그룹 수강권
   * P: 프라이빗 수강권
   * I: 상품  */
  available_class_type: 'G' | 'P' | 'I';
  /** 횟수제, 기간제, 대여형(판매, 혼합) 등 */
  type: 'T' | 'P' | 'S' | 'RM' | 'RP' | 'RT';
  /** 주간/월간 이용횟수 자동 차감  */
  use_weekly_auto_coupon_balance: boolean;
  /** 티켓 색상 */
  colors: string[];
  /** 최대 수강 인원 */
  max_trainee: number;
  /** 당일 변경 가능 횟수 */
  daily_booking_change_limit: number;
  /** 판매가 */
  original_price: number;
  /** 적립될 포인트
   * TODO: undefined 제거
   *
   */
  reward_point?: number;
  /** 즐겨찾기 저장된 시각 (실제로는 즐겨찾기 여부로 사용됨) */
  favorite_stamp: null | string;
  /** 최소 수강 인원 */
  min_trainee: number;
  /** 전체 횟수 */
  max_coupon: number;
  /** 잔여 횟수 */
  remaining_coupon: number;
  /** 취소 가능 횟수 */
  max_cancel: number;
  /** 취소 가능 횟수 */
  remaining_cancel: number;
  /** 이용 시작일  */
  availability_start_at: string;
  /** 이용 종료일 */
  expire_at: string;
  /** 주간 이용 제한 횟수 */
  booking_limit_per_week: number;
  /** 월간 이용 제한 횟수 */
  booking_limit_per_month: number;
  /** 사용기한 */
  available_period: number | null;
  /** 수업시간 분 단위 (프라이빗 수업) */
  class_period: number | null;
  /** 취소 가능 횟수 노출 유무 */
  is_show_cancel_count: boolean;
  /** 체험권 여부 */
  is_ignore_new_payment: boolean;
};
