import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

/**
 * 그룹 수업 예약 취소 (1개의 취소 처리 - 예약, 예약확정, 결석, 출석, 노쇼)
 */
const usePatchBookingGroupCancel = (bookingId: number) => {
  const { mutationFn } = useMutationFnWithCommonParams({
    method: 'patch',
    url: `/api/booking/group/cancel/${bookingId}`,
  });

  return useCustomMutation({
    mutationFn,
  });
};

export default usePatchBookingGroupCancel;
