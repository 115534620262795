import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import useGetReportLecturesDashboard from 'hooks/service/queries/useGetReportLecturesDashboard';
import useInfinityReportLecture, {
  LectureDataResponse,
  ReportLectureParams,
} from 'hooks/service/queries/useInfinityReportLecture';
import useSearchKeywords from 'hooks/useSearchKeywords';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { reportLectureParamAtom, reportLecturePeriodAtom } from 'recoil/report/atom';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';
import filters from 'utils/filters';

import { REPORT_LECTURER_SORTING_FILTERS } from '../../constants';
import { getGroupByDate } from '../../util';
import NoData from '../NoData';
import Sorting from '../Sorting';
import ReportLectureCard from './ReportLectureCard';
import ReportLectureSummary from './ReportLectureSummary';

const ReportLectureList = () => {
  const { searchKeyword } = useSearchKeywords('report');
  const reportLecturePeriod = useRecoilValue(reportLecturePeriodAtom);
  const reportLecture = useRecoilValue(reportLectureParamAtom);
  const [lecturesGroupByDate, setLecturesGroupByDate] = useState<LectureDataResponse[][]>([]);

  const params: ReportLectureParams = {
    start_date: reportLecturePeriod.period?.start || filters.dateDash(),
    end_date: reportLecturePeriod.period?.end || filters.dateDash(),
    search: searchKeyword,
    class_type: reportLecture.classType,
    type: reportLecture.ticketType,
    staff_ids:
      reportLecture.staffs && reportLecture.staffs.some(item => item.name === 'all')
        ? []
        : reportLecture.staffs?.map(item => Number(item.id)) ?? [],
    ticket_ids:
      reportLecture.tickets && reportLecture.tickets.some(item => item?.title === 'all')
        ? []
        : reportLecture.tickets?.map(item => Number(item.id)) ?? [],
    paginate_type: 'detail',
    order_by: reportLecture.sort,
  };

  const { data: lecturesDashboard } = useGetReportLecturesDashboard(params);
  const {
    data: { lectures, classCount, memberTotal },
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfinityReportLecture(params);

  useEffect(() => {
    const groupedLectures = getGroupByDate(lectures, 'action_at');
    setLecturesGroupByDate(groupedLectures);
  }, [lectures]);

  return (
    <Container>
      <ReportLectureSummary
        lecturesDashboard={lecturesDashboard}
        lectures={lectures}
        classCount={classCount}
        memberTotal={memberTotal}
      />
      <Divider thin />
      <Sorting className="right-sort" filterAtom={reportLectureParamAtom} sortOptions={REPORT_LECTURER_SORTING_FILTERS} />
      {lectures.length === 0 ? (
        <NoData currentKeyword={searchKeyword} noDataText={'매출 내역이 없습니다.'} />
      ) : (
        <ListSection>
          <InfiniteScroll loadMore={fetchNextPage} hasMore={hasNextPage} isLoading={isFetchingNextPage}>
            <ReportLectureCard lectures={lecturesGroupByDate} />
          </InfiniteScroll>
        </ListSection>
      )}
    </Container>
  );
};

export default ReportLectureList;

const Container = styled.div`
  .right-sort {
    ${theme.flex('', '', 'flex-end')}
  }

  background-color: ${theme.color.white};
`;

const ListSection = styled.section`
  padding: 24px 20px;
  background-color: ${theme.color.gray6};

  .date-text {
    padding-bottom: 20px;
  }
`;
