import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import CheckBox from 'components/CheckBox';
import Divider from 'components/Divider';
import OneLinedTitle from 'designedComponents/Drawers/DrawerTitle/OneLinedTitle';
import SingleButtonDrawer from 'designedComponents/Drawers/DrawerWithButton/SingleButtonDrawer';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { staffIdAtom } from 'recoil/common';
import { FilterOptionsType } from 'sharedComponents/Filters/types';

import { ReportCommonParamType, ReportStaff } from '../type';
import ReportStaffItem from './ReportStaffItem';

type Props = {
  selectedOption: FilterOptionsType['value'];
  isOpen: boolean;
  staffOptions: FilterOptionsType[];
  onClose: () => void;
  onSave: (option: FilterOptionsType, key: string) => void;
};

const ReportMultiStaffOptionDrawer = ({ selectedOption, isOpen, staffOptions, onClose, onSave: changeOptions }: Props) => {
  const currentStaffId = useRecoilValue(staffIdAtom);
  const [options, setOptions] = useState<ReportStaff[]>([]);
  const [tempSelectedOption, setTempSelectedOption] = useState<ReportStaff[]>([]);

  const checkedState = (() => {
    if (tempSelectedOption.length === 0) {
      return 'unchecked';
    }
    if (tempSelectedOption.some(s => s.name === 'all')) return 'checked';
    return tempSelectedOption.length === options.length ? 'checked' : 'someChecked';
  })();

  const changeMultiSelectedOptions = () => {
    changeOptions({ value: tempSelectedOption, label: '' }, 'staffs');
    onClose();
  };

  // 전체선택
  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      setTempSelectedOption([...options, { name: 'all' }]);
    } else {
      setTempSelectedOption([]);
    }
  };

  // 개별 선택
  const handleStaffSelect = (staff: ReportStaff, checked: boolean) => {
    setTempSelectedOption(prev => {
      const filteredOptions = prev.filter(item => item.name !== 'all');
      if (checked) {
        const newSelectedOptions = [...filteredOptions, staff];
        return newSelectedOptions.length === options.length ? [...newSelectedOptions, { name: 'all' }] : newSelectedOptions;
      } else {
        return filteredOptions.filter(item => item.id !== staff.id);
      }
    });
  };

  useEffect(() => {
    if (isOpen) {
      const prevSelectedStaffs = (selectedOption as ReportCommonParamType['staffs']) || [];
      const staffInfos = staffOptions.map(multiSelectedOption => multiSelectedOption.value as ReportStaff);
      setOptions(staffInfos);
      if (prevSelectedStaffs.length === 0 && staffInfos.length > 0) {
        setTempSelectedOption([...staffInfos, { name: 'all' }]);
      } else {
        setTempSelectedOption([...prevSelectedStaffs]);
      }
    }
  }, [isOpen, selectedOption, staffOptions]);

  return (
    <StyledSingleButtonDrawer
      isOpen={isOpen}
      onClose={onClose}
      header={<OneLinedTitle title="강사 선택" />}
      button={{ text: '선택 완료', onClick: changeMultiSelectedOptions, disabled: tempSelectedOption.length === 0 }}>
      <Container checkedState={checkedState}>
        <CheckBox
          id="all-staff"
          className="header-checkbox"
          label="전체"
          gap={16}
          labelFontsize={17}
          labelFontWeight={500}
          value="all"
          checked={checkedState === 'checked'}
          iconName={checkedState === 'someChecked' ? 'lineBold' : 'selectCheckBold'}
          checkColor={checkedState !== 'unchecked' ? 'white' : 'gray5'}
          onChange={e => handleSelectAll(e.target.checked)}
        />
        <Divider thin thinColor="gray6" />
        <StaffList>
          {options.map(staff => (
            <ReportStaffItem
              key={staff.id}
              staff={staff}
              isSelected={tempSelectedOption.some(t => t.id === staff.id)}
              isCurrentUser={currentStaffId === staff.id}
              onChange={handleStaffSelect}
            />
          ))}
        </StaffList>
      </Container>
    </StyledSingleButtonDrawer>
  );
};

export default ReportMultiStaffOptionDrawer;

const Container = styled.div<{ checkedState: 'checked' | 'unchecked' | 'someChecked' }>`
  padding: 0 20px;

  hr {
    margin-top: 24px;
  }

  .header-checkbox {
    .input-span-style {
      border-color: ${({ checkedState }) => (checkedState === 'unchecked' ? theme.color.gray5 : theme.color.primary)};
      background-color: ${({ checkedState }) => (checkedState === 'unchecked' ? theme.color.white : theme.color.primary)};
    }
  }
`;

const StyledSingleButtonDrawer = styled(SingleButtonDrawer)`
  padding-left: 0;
  padding-right: 0;
`;

const StaffList = styled.ul`
  ${theme.flex('column', '', '', 8)};
  padding: 12px 20px;

  li {
    padding: 8px 0;
  }

  .staff-label-wrapper {
    ${theme.flex('', 'center', '', 8)}

    .staff-label {
      ${theme.flex('', 'center', '', 4)}
    }
  }
`;
