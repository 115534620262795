import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Accordion from 'components/Accordion';
import IconButton from 'components/Button/IconButton';
import Divider from 'components/Divider';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import dayjs from 'dayjs';
import RangeTimePicker from 'designedComponents/Pickers/TimePicker/RangeTimePicker';
import { TimeDatasetType } from 'pages/MoreDetails/Staff/Form/types';
import { useEffect, useMemo, useRef, useState } from 'react';
import filters from 'utils/filters';
import { v4 as uuidv4 } from 'uuid';

import { DEFAULT_RANGE } from '../constants';

type Props = {
  restTimeField: TimeDatasetType;
  isLastIndex: boolean;
  restTimeIndex: number;
  appendRestTimeField: (customId: string) => void;
  removeRestTimeField: (index: number, date: string) => void;
  updateRestTimeField: (index: number, date: string, times: { start_time: string; end_time: string }) => void;
};

const EventRestTimeAccordion = ({
  restTimeField,
  isLastIndex,
  restTimeIndex,
  appendRestTimeField,
  removeRestTimeField,
  updateRestTimeField,
}: Props) => {
  const [timeRange, setTimeRange] = useState<[Date, Date]>(DEFAULT_RANGE);

  /** 마운트 후에 RangeTimePicker > onChange의 setTimeRange를 실행하기위한 트리거 */
  const isMounted = useRef(false);

  const { start_time, end_time } = restTimeField || {};

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const timeRangeDataset = useMemo(() => {
    const startHour = start_time?.slice(0, 2);
    const startMinute = start_time?.slice(3, 5);
    const endHour = end_time?.slice(0, 5);
    const endMinute = end_time?.slice(3, 5);

    return {
      start: {
        hour: parseInt(startHour || '09'),
        minute: parseInt(startMinute || '00'),
      },
      end: {
        hour: parseInt(endHour || '18'),
        minute: parseInt(endMinute || '00'),
      },
    };
  }, [start_time, end_time]);

  const timeText = useMemo(() => {
    return filters.timePeriodForOnlyTime(start_time, end_time);
  }, [start_time, end_time]);

  const endMinTime = useMemo(() => {
    const formatDate = dayjs(timeRange[0]).add(5, 'minute');
    return dayjs(formatDate).format('HH:mm:00');
  }, [timeRange]);

  return (
    <Container isLastIndex={isLastIndex}>
      <Accordion
        onChange={() => {
          const { start, end } = timeRangeDataset;
          setTimeRange([
            dayjs().set('hour', start.hour).set('minute', start.minute).toDate(),
            dayjs().set('hour', end.hour).set('minute', end.minute).toDate(),
          ]);
        }}
        header={
          <AcordionHeader>
            <Typography size={15} weight={600} textColor="gray2">
              {timeText}
            </Typography>

            <div className="icon-wrapper">
              <IconButton
                size="small"
                bgColor="gray6"
                widthSize={28}
                onClick={e => {
                  e.stopPropagation();
                  removeRestTimeField(restTimeIndex, restTimeField.date);
                }}>
                <Icon name="minusBold" size={16} fillColor={theme.color.gray1} />
              </IconButton>
              {isLastIndex && (
                <IconButton
                  size="small"
                  bgColor="gray6"
                  widthSize={28}
                  onClick={e => {
                    e.stopPropagation();
                    appendRestTimeField(uuidv4());
                  }}>
                  <Icon name="plusBold" size={16} fillColor={theme.color.gray1} />
                </IconButton>
              )}
            </div>
          </AcordionHeader>
        }
        showArrow={false}>
        <Divider thin />
        <RangeTimePicker
          stepMinute={5}
          timeRange={timeRange}
          onChange={value => {
            const start_time = dayjs(value[0]).format('HH:mm:00');
            const end_time = dayjs(value[1]).format('HH:mm:00');
            updateRestTimeField(restTimeIndex, restTimeField.date, { start_time, end_time });
            if (isMounted.current) {
              setTimeRange(value);
            }
          }}
          startMaxTime="23:50"
          endMinTime={endMinTime}
        />
        {isLastIndex && <Divider className="last-line" thin />}
      </Accordion>
    </Container>
  );
};

export default EventRestTimeAccordion;

const Container = styled.div<{ isLastIndex: boolean }>`
  hr {
    ${({ isLastIndex }) => isLastIndex && 'margin-top: 12px'};
  }

  .last-line {
    margin-top: 0;
  }
`;

const AcordionHeader = styled.div`
  ${theme.flex('row', 'center', 'space-between')};
  padding: 0 20px;
  width: 100%;

  .icon-wrapper {
    ${theme.flex('row', 'center', 'center', 8)};
  }
`;
