import Divider from 'components/Divider';
import { get } from 'lodash';
import { Fragment } from 'react/jsx-runtime';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import ButtonFilter from 'sharedComponents/Filters/ButtonFilter';
import { FilterOptionsProps } from 'sharedComponents/Filters/types';

import CounselPeriodFilter from './CounselPeriodFilter';
import StaffFilter from './StaffFilter';

const CounselWholeFilterOptions = ({ filters, filterChanged, changeOption }: FilterOptionsProps) => {
  const staffFilterIndex = filters.findIndex(filter => filter.key === 'staffs');

  return (
    <>
      <CounselPeriodFilter
        currentFilter={filters[0]}
        selectedOption={get(filterChanged, filters[0].key)}
        onClick={changeOption}
      />
      <Divider />
      {filters.slice(1, staffFilterIndex).map((filter, index) => (
        <Fragment key={filter.key}>
          <ButtonFilter currentFilter={filter} selectedOption={get(filterChanged, filter.key)} onClick={changeOption} />
          <Divider />
        </Fragment>
      ))}
      <ApiBoundary>
        <StaffFilter
          currentFilter={filters[staffFilterIndex]}
          selectedOption={get(filterChanged, filters[staffFilterIndex].key)}
          onClick={changeOption}
        />
      </ApiBoundary>
    </>
  );
};

export default CounselWholeFilterOptions;
