import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import useSearchKeywords from 'hooks/useSearchKeywords';
import { useEffect, useState } from 'react';
import SearchBar from 'sharedComponents/SearchBar';

/**
 * 헤더 우측 검색 버튼
 *
 * 클릭시 헤더를 가리는 검색바가 나타남
 * 시설 공지사항, 1:1 문의 등에서 활용됨
 */

type Props = {
  placeholder?: string;
  /** 공용 전역변수에 저장될 key, 다른페이지의 검색어와 구분하기 위함 */
  identifier: string;
};

const HeaderSearchButton = ({ placeholder = '제목, 내용 검색', identifier }: Props) => {
  const { searchKeyword, setSearchKeyword } = useSearchKeywords(identifier);

  const [isSearching, setIsSearching] = useState(!!searchKeyword);

  useEffect(() => {
    return () => {
      setSearchKeyword('');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container isSearching={isSearching}>
      <div className="search-bar-container">
        <SearchBar
          placeholder={placeholder}
          onCancel={() => {
            setIsSearching(false);
          }}
          identifier={identifier}
        />
      </div>

      <IconButton
        className="header-search"
        onClick={() => {
          setIsSearching(true);
          const target = document.getElementById('search');
          if (target) {
            target.focus();
          }
        }}>
        <Icon name="headerSearch" />
      </IconButton>
    </Container>
  );
};

export default HeaderSearchButton;

const Container = styled.div<{ isSearching: boolean }>(
  css`
    .search-bar-container {
      position: absolute;
      top: -8px;
      right: 0;
      width: 0;
      opacity: 0;
      transition:
        width 0.2s ease,
        opacity 0.2s ease;

      .search-bar {
        ${theme.flex('row', 'center', 'flex-end')};
        pointer-events: none;

        .search-bar-input-wrapper {
          width: 0;
          padding: 0;

          /** 트랜지션 작동 중 각진 형태의 input 스타일 방지용 */
          #search {
            border-top-right-radius: 99px !important;
            border-bottom-right-radius: 99px !important;
          }
        }
      }
    }
  `,

  ({ isSearching }) =>
    isSearching &&
    css`
      .search-bar-container {
        width: calc(100vw - 72px);
        opacity: 1;

        .search-bar {
          pointer-events: inherit;

          .search-bar-input-wrapper {
            padding: 0 16px;
          }
        }
      }

      .header-search {
        pointer-events: none;
        opacity: 0;
        transition: opacity 0.2s ease;
      }
    `,
);
