import { ERROR_CODE } from 'constants/text';
import usePostFileUploadAvatarPresignedUrl from 'hooks/service/mutations/usePostFileUploadAvatarPresignedUrl';
import useGetStaffMobile from 'hooks/service/queries/useGetStaffMobile';
import useErrorDialog from 'hooks/useErrorDialog';
import useQueryString from 'hooks/useQueryString';
import { useErrorBoundary } from 'react-error-boundary';
import { SubmitHandler, useFormContext, useWatch } from 'react-hook-form';
import SelectRepresentativeColor from 'sharedComponents/ProfileField/components/RepresentativeColor/SelectRepresentativeColor';
import { CustomError } from 'types/errorTypes';
import { mobile as yupMobile } from 'utils/validate';

import StaffInfoForm from '../../components/StaffInfoForm';
import { StaffCreateFormType } from '../../types';

type Props = {
  disabled: boolean;
  nextStep: () => void;
};

const CreateStepInfo = ({ disabled, nextStep }: Props) => {
  const { control, handleSubmit, setValue } = useFormContext<StaffCreateFormType>();

  const errorDialog = useErrorDialog();
  const { getAllSearchParams } = useQueryString();
  const queryStringParsed = getAllSearchParams();
  const isRepresentativeColor = queryStringParsed.type === 'representativeColor';

  const currentMobile = useWatch({ control, name: 'mobile' });
  const { showBoundary } = useErrorBoundary();
  const { getAvatarImage } = usePostFileUploadAvatarPresignedUrl();

  /** GET 요청 useQuery 사용 시 계속 감지하고 있어서 디테일한 조건을 설정함 */
  const isMobileCheckEnabled = yupMobile.isValidSync(currentMobile);
  const { refetch } = useGetStaffMobile({
    mobile: currentMobile,
    enabled: isMobileCheckEnabled && !!currentMobile.length,
  });

  const next: SubmitHandler<StaffCreateFormType> = async inputValues => {
    const { file } = inputValues;

    if (!isMobileCheckEnabled) {
      errorDialog.open('올바른 휴대폰 번호를 입력해 주세요.');
      return;
    }

    try {
      const mobileCheckRes = await refetch();
      if (mobileCheckRes.status === 'error') {
        throw mobileCheckRes.error;
      }

      const avatarResponse = await getAvatarImage(file);
      if (avatarResponse !== false) {
        setValue('imageUrl', avatarResponse);
      }
      nextStep();
    } catch (error: unknown) {
      const code = (error as CustomError).response?.status;
      if (code === ERROR_CODE.BAD_REQUEST) {
        errorDialog.open(error);
      } else {
        showBoundary(error);
      }
    }
  };

  return (
    <>
      {isRepresentativeColor ? (
        <SelectRepresentativeColor />
      ) : (
        <StaffInfoForm onSubmit={handleSubmit(next)} footerButton={{ text: '다음', disabled }} />
      )}
    </>
  );
};

export default CreateStepInfo;
