import useGetRoom from 'hooks/service/queries/useGetRoom';
import useGetStudioInfo from 'hooks/service/queries/useGetStudioInfo';
import { BOOKING_COMMON_FORM_TEXT } from 'pages/Booking/constants';
import { BookingCommonFormType } from 'pages/Booking/types';
import { convertRepeatWeekdayNum } from 'pages/Booking/utils';
import { getWeekdayNumbers } from 'pages/Booking/utils/formatLectureCommonParams';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { studioIdAtom } from 'recoil/common';
import FormFieldLinkButton from 'sharedComponents/FormField/FormFieldLinkButton';
import filters from 'utils/filters';

import RoomSetting from './RoomSetting';

/** 룸 설정 기능을 사용하는 시설인지 아닌지 판단 */
const CheckRoomUsable = () => {
  const { setValue, watch } = useFormContext<BookingCommonFormType>();
  const currentStudioId = useRecoilValue(studioIdAtom);
  const { pathname } = useLocation();

  const {
    data: { policy },
  } = useGetStudioInfo(currentStudioId);
  const { startDate, startTime, endDate, endTime, repeat, repeatCustomWeekday } = watch();

  const isEnabled = !!policy.is_use_rooms;
  const isCreate = pathname.includes('create');

  // 수업 생성 때 보내는 room 목록 파라미터
  const createRoomParams = useMemo(() => {
    if (!isCreate) return {};

    const selectedWeekday = convertRepeatWeekdayNum(repeat, repeatCustomWeekday);
    const weekday =
      startDate === endDate ? undefined : selectedWeekday.length ? selectedWeekday : getWeekdayNumbers(startDate, endDate);
    return {
      start_date: startDate,
      end_date: endDate,
      start_time: filters.timeSecondZero(startTime),
      end_time: filters.timeSecondZero(endTime),
      weekday,
    };
  }, [endDate, endTime, isCreate, repeat, repeatCustomWeekday, startDate, startTime]);

  // 모든 필수 파라미터가 있는지 확인
  const hasRequiredParams = useMemo(() => {
    if (!isCreate) return true;
    return Boolean(startDate && endDate && startTime && endTime);
  }, [isCreate, startDate, endDate, startTime, endTime]);

  const { data } = useGetRoom({
    isEnabled: isEnabled && hasRequiredParams,
    ...createRoomParams,
    is_update: !isCreate ? 1 : 0,
  });

  useEffect(() => {
    if (!isEnabled) {
      setValue('selectedRoom', { id: -1, name: BOOKING_COMMON_FORM_TEXT.room.notSelected.label });
    }
  }, [isEnabled, setValue]);

  if (!isEnabled || !data) {
    return (
      <FormFieldLinkButton iconName="room" disabled showArrow={false}>
        {BOOKING_COMMON_FORM_TEXT.room.noSetting}
      </FormFieldLinkButton>
    );
  }
  return <RoomSetting roomData={data} isUseRooms={isEnabled} />;
};

export default CheckRoomUsable;
