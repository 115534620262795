import styled from '@emotion/styled';
import React from 'react';

export type IInputFileProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type' | 'accept'> & {
  /** multiple false로 사용하기 위한 용도 */
  single?: boolean;
};

const InputFile = React.forwardRef<HTMLInputElement, IInputFileProps>(({ single, ...props }, ref) => {
  return <StyledInput type="file" ref={ref} multiple={!single} accept="image/*" {...props} />;
});

const StyledInput = styled.input`
  display: none;
`;

InputFile.displayName = 'InputFile';
export default InputFile;
