import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Icon from 'components/Icon';
import CheckRadioDrawer from 'designedComponents/Drawers/CheckRadioDrawer';
import { ChangeEvent, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { RecoilState, useRecoilState } from 'recoil';

import { SCHEDULE_SORTING_FILTERS } from '../constant';
import { ScheduleParamType } from '../type';

type Props<T extends ScheduleParamType> = {
  filterAtom: RecoilState<T>;
};

const Sorting = <T extends ScheduleParamType>({ filterAtom }: Props<T>) => {
  const [scheduleParams, setScheduleParams] = useRecoilState(filterAtom);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const { control, watch } = useForm<ScheduleParamType>({
    defaultValues: {
      sort: scheduleParams?.sort ?? 'asc',
    },
  });

  const currentSort = watch('sort');

  const sortLabel = useMemo(() => {
    return SCHEDULE_SORTING_FILTERS.find(data => data.value === currentSort)?.label;
  }, [currentSort]);

  const changeSort = (e: ChangeEvent<HTMLInputElement>) => {
    setScheduleParams(prevScheduleParams => ({
      ...prevScheduleParams,
      sort: e.target.value,
    }));
  };

  return (
    <Container>
      <Button
        fontWeight={400}
        textColor="gray2"
        rightIcon={<Icon name="arrowBottomFill" size={16} fillColor={theme.color.gray3} />}
        onClick={() => setIsFilterOpen(true)}>
        {sortLabel}
      </Button>
      <CheckRadioDrawer
        isOpen={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        control={control}
        name="sort"
        data={SCHEDULE_SORTING_FILTERS}
        onClick={changeSort}
      />
    </Container>
  );
};

export default Sorting;

const Container = styled.div`
  ${theme.flex('', '', 'flex-end')};
  padding: 12px 20px;
`;
