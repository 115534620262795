import { MemberResponse } from 'hooks/service/queries/useInfinityMember';
import { MemberCounselParams, MemberCounselResponse } from 'hooks/service/queries/useInfinityMemberCounsel';
import filters from 'utils/filters';

import { MessageCounselSelectedFilterType } from './Targets/Counsel/types';
import { MessageTargetType } from './types';

export const convertTargetsText = (targets?: Array<MessageTargetType>, placeholder = '') => {
  if (!targets || !targets.length) {
    return placeholder;
  }
  return targets.length === 1 ? targets[0].name : `${targets[0].name} 외 ${filters.numberComma(targets.length - 1)}명`;
};

export const convertMembersToTargets = (member: MemberResponse[]): MessageTargetType[] => {
  return member.map(({ id, name, mobile, avatar }) => ({ id, name, mobile: mobile ?? '', avatar: avatar?.image }));
};

export const convertCounselToTargets = (counsel: MemberCounselResponse[]): MessageTargetType[] => {
  return counsel.map(({ id, name, phone }) => ({ id, name, mobile: phone ?? '', avatar: null }));
};

export const convertCounselFilterToParams = (
  filters: MessageCounselSelectedFilterType,
  searchKeyword: string,
): MemberCounselParams => {
  const { register, staffs, period, ...selectedFilter } = filters;
  return {
    paginate_type: 'detail',
    ...selectedFilter,
    register_type: register ?? 'both',
    search: searchKeyword,
    target_staff_id: staffs?.id,
    start_on: period?.start,
    end_on: period?.end,
    has_mobile: 1,
  };
};
