import { ETC_SCHEDULE_COLOR, MATCH_REPRESENTATIVE_COLOR } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import { EtcScheduleDetailResponse } from 'hooks/service/queries/useGetEtcScheduleEtcScheduleTime';
import { DropdownType } from 'pages/Booking/types';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { staffIdAtom } from 'recoil/common';
import MainLayout from 'sharedComponents/MainLayout';
import { Color } from 'utils/getColor';
import getContrastTextColor from 'utils/getContrastTextColor';

import ColoredDetailInfo from './ColoredDetailInfo';
import EtcDeleteListDrawer from './EtcDeleteListDrawer';
import EtcDetailDropDown from './EtcDetailDropDown';
import EtcUpdateListDrawer from './EtcUpdateLIstDrawer';

type Props = {
  currentEtcScheduleId: number;
  data: EtcScheduleDetailResponse;
  canViewEtcSchedule: boolean;
  canCreateEtcSchedule: boolean;
  canUpdateEtcSchedule: boolean;
  canDeleteEtcSchedule: boolean;
};

const DetailMain = ({
  currentEtcScheduleId,
  data,
  canViewEtcSchedule,
  canCreateEtcSchedule,
  canUpdateEtcSchedule,
  canDeleteEtcSchedule,
}: Props) => {
  const bgColor = ETC_SCHEDULE_COLOR[data.color] || MATCH_REPRESENTATIVE_COLOR[data.color];
  const contrastColor = getContrastTextColor(bgColor) as Color;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isBulk = pathname.includes('bulk');
  const currentStaffId = useRecoilValue(staffIdAtom);
  const isShowHeaderMore = data.register.id === currentStaffId;

  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState<'update' | 'delete' | null>(null);

  const goEtcUpdateForm = (mode: string) => {
    const id = isBulk ? data.etc_schedule_id : data.id;
    navigate(`/schedule/etc/form/${id}?mode=${mode}${isBulk ? '&isBulk=true' : ''}`);
  };

  const clickDropDownItem = (value: DropdownType['value']) => {
    switch (value) {
      case 'update':
        if (data.is_single_schedule) {
          goEtcUpdateForm('update-all');
        } else {
          setIsOpenDrawer('update');
        }
        break;
      case 'copy':
        navigate(`/schedule/etc/form/${currentEtcScheduleId}?mode=copy${isBulk ? '&isBulk=true' : ''}`);
        break;
      case 'delete':
        setIsOpenDrawer('delete');
        break;
    }
    setIsOpenDropDown(false);
  };

  return (
    <MainLayout
      header={{
        leftButton: (
          <IconButton onClick={() => navigate(-1)}>
            <Icon name="headerClose" color={contrastColor} />
          </IconButton>
        ),
        rightButton: isShowHeaderMore ? (
          <EtcDetailDropDown
            contrastColor={contrastColor}
            isOpen={isOpenDropDown}
            onToggle={setIsOpenDropDown}
            onClick={clickDropDownItem}
            canCreateEtcSchedule={canCreateEtcSchedule}
            canUpdateEtcSchedule={canUpdateEtcSchedule}
            canDeleteEtcSchedule={canDeleteEtcSchedule}
          />
        ) : undefined,
        bgColor,
      }}>
      <ColoredDetailInfo
        currentEtcSchedule={data}
        isBulk={isBulk}
        bgColor={bgColor}
        contrastColor={contrastColor}
        canViewEtcSchedule={canViewEtcSchedule}
      />

      <EtcUpdateListDrawer
        isBulk={isBulk}
        isOpen={isOpenDrawer === 'update'}
        onClose={() => setIsOpenDrawer(null)}
        goEtcUpdateForm={goEtcUpdateForm}
      />

      <EtcDeleteListDrawer
        currentEtcSchedule={data}
        isBulk={isBulk}
        isOpen={isOpenDrawer === 'delete'}
        onClose={() => setIsOpenDrawer(null)}
      />
    </MainLayout>
  );
};

export default DetailMain;
