import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import ButtonGroup from 'components/Button/ButtonGroup';
import IconButton from 'components/Button/IconButton';
import { IDialogProps } from 'components/Dialog';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { Z_INDEX } from 'constants/zIndex';
import { StudiomateNoticePopupType } from 'hooks/service/queries/useGetStudiomateNoticePopup';

type Props = Pick<IDialogProps, 'positiveAction' | 'negativeAction'> & {
  notice: StudiomateNoticePopupType;
  closePopup: () => void;
};

const NoticePopupContents = ({ notice, closePopup, positiveAction, negativeAction }: Props) => {
  const contents = notice?.context.replace(/_500x0\.png/g, '_0x0.png');
  return (
    <DimBackground onClick={closePopup}>
      <NoticePopupWrapper onClick={e => e.stopPropagation()}>
        <PopupHeader>
          <Typography size={15} weight={600}>
            {notice.title}
          </Typography>
          <IconButton onClick={closePopup}>
            <Icon name="headerClose" color="gray3" />
          </IconButton>
        </PopupHeader>
        <PopupContents dangerouslySetInnerHTML={{ __html: contents }} />
        <PopupButtons>
          <Button fullWidth color="gray" variant="contained" size="medium48" onClick={negativeAction?.onClick} textColor="gray1">
            {negativeAction?.text}
          </Button>
          <Button fullWidth color="point" variant="contained" size="medium48" onClick={positiveAction?.onClick}>
            {positiveAction?.text}
          </Button>
        </PopupButtons>
      </NoticePopupWrapper>
    </DimBackground>
  );
};

export default NoticePopupContents;

const NoticePopupWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${theme.color.white};
  z-index: ${Z_INDEX.popup};

  ${theme.flex('column', '', '')}
  border-radius: 20px;
  width: calc(100vw - 40px);
  height: 540px;
`;

const PopupHeader = styled.div`
  padding: 16px 16px 16px 20px;
  ${theme.flex('', 'start', 'space-between', 8)}
  flex: 0 0 55px;
`;

const PopupContents = styled.div`
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: ${theme.color.gray1};
  flex: 1 1 auto;
  overflow-y: scroll;
  padding: 0 0 20px;

  p {
    // 시설웹에 적용된 줄간격 스타일 + 양옆 여백
    margin: 10px 20px;
  }
`;

const PopupButtons = styled(ButtonGroup)`
  padding: 10px 20px 16px;
  flex: 0 0 74px;
`;

const DimBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: ${Z_INDEX.popup - 1};
`;
