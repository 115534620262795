import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { useFormContext } from 'react-hook-form';

import { NOTICE_FORM_TEXT } from '../constants';
import { NoticeFormType } from '../types';

const PreviewContent = () => {
  const { getValues } = useFormContext<NoticeFormType>();

  return (
    <>
      <PreviewHeader>
        <IconWrapper>
          <Icon name="alertFill" color="gray3" size={20} />
        </IconWrapper>
        <Title>
          <Typography size={13} weight={500} textColor="gray3">
            {NOTICE_FORM_TEXT.previewTitleDescription}
          </Typography>
          <Typography size={15} weight={600} ellipsis={1}>
            {getValues('title')}
          </Typography>
        </Title>
      </PreviewHeader>
      <Typography textColor="gray2" tag="pre">
        {getValues('contents')}
      </Typography>
      <Attachments>{getValues('imgUrls')?.map(i => <Image src={i.imgUrl} key={i.id} alt={`${i.id}`} />)}</Attachments>
    </>
  );
};

export default PreviewContent;

const PreviewHeader = styled.div`
  ${theme.flex('row', 'center', 'start', 16)}
  margin-bottom: 24px;
`;

const Title = styled.div``;
const IconWrapper = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: ${theme.color.gray5};
  ${theme.flex()}
  flex-shrink: 0;
  // 아이콘 내부 알람표시 숨김
  circle {
    display: none;
  }
`;

const Attachments = styled.div`
  margin: 32px 0 80px;
`;

const Image = styled.img`
  width: 100%;
  object-fit: cover;
`;
