import { ButtonProps } from 'components/Button';
import { useFormContext } from 'react-hook-form';
import FooterButton from 'sharedComponents/FooterButton';

import { BookingPrivateFormType } from '../../types';

type Props = Pick<ButtonProps, 'onClick'>;

const NextButton = ({ onClick }: Props) => {
  const { watch } = useFormContext<BookingPrivateFormType>();
  const watchedFields = watch([
    'selectedUserTickets',
    'lectureSize',
    'startDate',
    'endDate',
    'selectedStaffId',
    'endTime',
    'startTime',
    'title',
  ]);

  const isValid = watchedFields.every(field => (Array.isArray(field) ? !!field.length : !!field));
  return (
    <FooterButton disabled={!isValid} onClick={onClick}>
      다음
    </FooterButton>
  );
};

export default NextButton;
