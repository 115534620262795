import { FormState } from 'react-hook-form';

import { MemberFormType } from '../types';

const isDirtyMemberForm = (dirtyFields: FormState<MemberFormType>['dirtyFields']) => {
  return (
    Object.values(dirtyFields.address ?? {}).some(Boolean) ||
    Object.values(dirtyFields.profile ?? {}).some(Boolean) ||
    dirtyFields.image ||
    dirtyFields.mobile ||
    dirtyFields.userGradeId ||
    dirtyFields.vaccination_yn
  );
};

export default isDirtyMemberForm;
