import Header from 'components/Header';
import { useRecoilValue } from 'recoil';
import { studioIdAtom } from 'recoil/common';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import MainLayout from 'sharedComponents/MainLayout';
import StudioException from 'sharedComponents/StudioException';

import EmptyList from './components/EmptyList';
import NotificationHeader from './components/NotificationHeader';
import NotificationList from './components/NotificationList';
import SkeletonHeader from './components/SkeletonHeader';
import SkeletonList from './components/SkeletonList';
import { NOTIFICATION_TEXT } from './constants';

const Notification = () => {
  const header = {
    title: NOTIFICATION_TEXT.title,
    leftAlign: true,
    noUnderline: true,
  };

  const isStudioSelected = useRecoilValue(studioIdAtom) !== 0;
  if (!isStudioSelected) {
    return (
      <ApiBoundary>
        <StudioException
          noConnectedBackground={
            <>
              <Header {...header} />
              <EmptyList />
            </>
          }
        />
      </ApiBoundary>
    );
  }
  return (
    <MainLayout bottomNav header={header}>
      <ApiBoundary fallback={<SkeletonHeader />}>
        <NotificationHeader />
      </ApiBoundary>
      <ApiBoundary fallback={<SkeletonList />}>
        <NotificationList />
      </ApiBoundary>
    </MainLayout>
  );
};

export default Notification;
