import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import Typography from 'components/Typography';
import CheckRadioDrawer from 'designedComponents/Drawers/CheckRadioDrawer';
import { RoomResponse } from 'hooks/service/queries/useGetRoom';
import { BOOKING_COMMON_FORM_TEXT } from 'pages/Booking/constants';
import { BookingCommonFormType } from 'pages/Booking/types';
import { ChangeEvent, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import FormFieldLinkButton from 'sharedComponents/FormField/FormFieldLinkButton';

type Props = {
  roomData: RoomResponse[];
  /**
   * 시설에서 룸 설정 기능 사용 여부
   * 설정하지 않을 때, 필드 클릭 불가 및 '룸 선택 안함' 텍스트 표시
   */
  isUseRooms: boolean;
};

const RoomSetting = ({ roomData, isUseRooms }: Props) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { control, setValue } = useFormContext<BookingCommonFormType>();
  const roomId = useWatch({ control, name: 'roomId' });
  const showRoomText = !!roomId && roomId !== BOOKING_COMMON_FORM_TEXT.room.notSelected.value;

  const roomDataArray = useMemo(() => {
    const defaultItem = {
      id: BOOKING_COMMON_FORM_TEXT.room.notSelected.value,
      label: BOOKING_COMMON_FORM_TEXT.room.notSelected.label,
      value: BOOKING_COMMON_FORM_TEXT.room.notSelected.value,
    };
    const roomDataItems = roomData.map(item => {
      return { id: `${item.id}`, label: item.name, value: `${item.id}` };
    });

    return [defaultItem, ...roomDataItems];
  }, [roomData]);

  const label = useMemo(() => {
    if (!isUseRooms || !roomData.length) {
      return BOOKING_COMMON_FORM_TEXT.room.notSelected.label;
    }
    if (!roomId) {
      return BOOKING_COMMON_FORM_TEXT.room.placeholder;
    }
    return roomDataArray.find(item => item.value === roomId)?.label;
  }, [isUseRooms, roomData.length, roomDataArray, roomId]);

  const clickField = () => {
    if (!isUseRooms) return;
    if (!roomData.length) {
      return setIsPopupOpen(true);
    }
    setIsDrawerOpen(true);
  };

  const clickRadioItem = (e: ChangeEvent<HTMLInputElement>) => {
    const isNotSelected = e.target.value === BOOKING_COMMON_FORM_TEXT.room.notSelected.value;
    const id = isNotSelected ? -1 : Number(e.target.value);
    const name = roomData.find(room => room.id === id)?.name ?? BOOKING_COMMON_FORM_TEXT.room.notSelected.label;
    setValue('selectedRoom', { id, name });
  };

  return (
    <>
      <FormFieldLinkButton iconName="room" showArrow={isUseRooms} hasValue={!!roomId} ellipsis={1} onClick={clickField}>
        <Wrapper>
          <Typography className="name" size={15} weight={500} ellipsis={1} textColor={roomId ? 'gray1' : 'gray3'}>
            {label}
          </Typography>
          {showRoomText && (
            <Typography span size={15} weight={500}>
              룸
            </Typography>
          )}
        </Wrapper>
      </FormFieldLinkButton>

      <CheckRadioDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        header={BOOKING_COMMON_FORM_TEXT.room.drawer.title}
        name="roomId"
        control={control}
        data={roomDataArray}
        onClick={clickRadioItem}
      />

      {isPopupOpen && (
        <Dialog
          isError
          positiveAction={{
            text: '확인',
            onClick: () => setIsPopupOpen(false),
          }}
          onClose={() => setIsPopupOpen(false)}>
          <DialogDescription>{BOOKING_COMMON_FORM_TEXT.room.dialog.description.notUsable}</DialogDescription>
        </Dialog>
      )}
    </>
  );
};

export default RoomSetting;

const Wrapper = styled.div`
  ${theme.flex('', 'center', 'flex-start', 3)};
`;
