import { MutateOptions, QueryClient, useMutation, UseMutationOptions } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import useErrorDialog from 'hooks/useErrorDialog';
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomError } from 'types/errorTypes';

const drawerExceptionPathname = ['/login'];

/**
 * 커스텀 useMutation
 * mutate 호출시 기본 에러 처리 추가
 * 에러 발생시 에러 페이지로 이동
 *
 * @params replaceOnError: 에러 페이지 이동시 replace 하지 않으려면 false (기본값 true)
 * @params dialogOnError: 네트워크에러, 401, 403 이 아닌 기타 에러 때 Dialog로 표시하지 않으려면 false (기본값 true)
 */
const useCustomMutation = <D = unknown, E = CustomError, V = void, C = unknown>(
  options: UseMutationOptions<D, E, V, C>,
  queryClient?: QueryClient,
) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const errorDialog = useErrorDialog();

  const { mutate, ...mutationResult } = useMutation<D, E, V, C>(options, queryClient);

  const customMutate = (
    variables: V,
    options?: Omit<MutateOptions<D, E, V, C>, 'onError'> & {
      replaceOnError?: boolean;
      onError?: (error: E, variables: V, context?: C) => void | boolean;
    },
  ) =>
    mutate(variables, {
      ...options,
      onError: (error, variables, context) => {
        const dialog = options?.onError?.(error, variables, context) ?? true;
        const replace = options?.replaceOnError ?? true;

        const isErrorDrawerCase =
          !drawerExceptionPathname.includes(pathname) &&
          isAxiosError(error) &&
          (error?.message === 'Network Error' || error?.response?.status === 401 || error?.response?.status === 403);

        if (isErrorDrawerCase) {
          const type = error.message === 'Network Error' ? 'not-connected' : error.response?.status;
          navigate(`/error?type=${type}`, { replace });
          queryClient?.clear(); // 에러 발생 후 기존 쿼리 재호출 방지
          return;
        }

        if (dialog && error instanceof Error) {
          errorDialog.open(error);
        }
      },
    });
  return { ...mutationResult, mutate: customMutate };
};

export default useCustomMutation;
