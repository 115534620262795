import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Accordion from 'components/Accordion';
import Button from 'components/Button';
import IconButton from 'components/Button/IconButton';
import Divider from 'components/Divider';
import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';
import Typography from 'components/Typography';
import useGetReportPointDashboard, { ReportPointDashboardParams } from 'hooks/service/queries/useGetReportPointDashboard';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { reportPointPeriodAtom } from 'recoil/report/atom';
import filters from 'utils/filters';

import { TooltipKey } from '../../type';

const PointSummaryCard = () => {
  const reportPointPeriod = useRecoilValue(reportPointPeriodAtom);
  const [isMorePointOpen, setIsMorePoint] = useState(false);
  const [tooltipState, setTooltipState] = useState('none');

  const params: ReportPointDashboardParams = {
    start_date: reportPointPeriod.period?.start || '',
    end_date: reportPointPeriod.period?.end || '',
  };
  const { data: pointDashboardData } = useGetReportPointDashboard(params);

  const changeIsMorePointOpen = () => {
    setIsMorePoint(!isMorePointOpen);
    setTooltipState('none');
  };

  const toggleTooltip = (key: TooltipKey) => {
    setTooltipState(key);
  };

  return (
    <Container isVisible={tooltipState === 'left'}>
      <Accordion
        isOpen={isMorePointOpen}
        showArrow={false}
        size="none"
        header={
          <SummaryCardHeader>
            <PointItemWrapper>
              <div className="item-label-wrapper">
                <Icon name="reportSavePoint" size={32} />
                <div className="item-label">
                  <div>
                    <Typography span size={15} weight={500} textColor="gray1">
                      적립포인트
                    </Typography>
                    <Tooltip
                      showCloseButton
                      onClose={() => toggleTooltip('none')}
                      message={['수강권, 상품 발급 시 적립된', '포인트 또는 관리자에 의해', '적립된 포인트의 합계']}
                      isOpen={tooltipState === 'reward'}
                      position="center"
                      maxWidth={208}>
                      <IconButton widthSize={16} onClick={() => toggleTooltip('reward')}>
                        <Icon name="information" size={14} fillColor={theme.color.gray3} />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <Typography size={13} weight={500} textColor="gray3">
                    {`${pointDashboardData?.reward.count}건`}
                  </Typography>
                </div>
              </div>
              <Typography size={15} weight={600} textColor="gray1">
                {`${filters.numberComma(pointDashboardData?.reward.point)}P`}
              </Typography>
            </PointItemWrapper>
            <PointItemWrapper>
              <div className="item-label-wrapper">
                <Icon name="reportMinusPoint" size={32} />
                <div className="item-label">
                  <div>
                    <Typography span size={15} weight={500} textColor="gray1">
                      차감포인트
                    </Typography>
                    <Tooltip
                      showCloseButton
                      onClose={() => toggleTooltip('none')}
                      message={['수강권, 상품 결제 시 사용한', '포인트 또는 관리자에 의해', '차감된 포인트의 합계']}
                      isOpen={tooltipState === 'deduct'}
                      position="center"
                      maxWidth={208}>
                      <IconButton widthSize={16} onClick={() => toggleTooltip('deduct')}>
                        <Icon name="information" size={14} fillColor={theme.color.gray3} />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <Typography size={13} weight={500} textColor="gray3">
                    {`${pointDashboardData?.deduct.count}건`}
                  </Typography>
                </div>
              </div>
              <Typography size={15} weight={600} textColor="gray1">
                {`${filters.numberComma(Math.abs(pointDashboardData?.deduct.point))}P`}
              </Typography>
            </PointItemWrapper>
            <PointItemWrapper>
              <div className="item-label-wrapper">
                <Icon name="reportRefundPoint" size={32} />
                <div className="item-label">
                  <div>
                    <Typography span size={15} weight={500} textColor="gray1">
                      환불포인트
                    </Typography>
                    <Tooltip
                      showCloseButton
                      onClose={() => toggleTooltip('none')}
                      message={['수강권, 상품 환불 시 회원에게', '환불되는 포인트의 합계']}
                      isOpen={tooltipState === 'refund'}
                      position="center"
                      maxWidth={208}>
                      <IconButton widthSize={16} onClick={() => toggleTooltip('refund')}>
                        <Icon name="information" size={14} fillColor={theme.color.gray3} />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <Typography size={13} weight={500} textColor="gray3">
                    {`${pointDashboardData?.refund.count}건`}
                  </Typography>
                </div>
              </div>
              <Typography size={15} weight={600} textColor="gray1">
                {`${filters.numberComma(Math.abs(pointDashboardData?.refund.reward_point))}P`}
              </Typography>
            </PointItemWrapper>
          </SummaryCardHeader>
        }>
        <SummaryCardBody>
          <PointItemWrapper>
            <div className="item-label-wrapper">
              <Icon name="reportCollectPoint" size={32} />
              <div className="item-label">
                <div>
                  <Typography span size={15} weight={500} textColor="gray1">
                    회수포인트
                  </Typography>
                  <Tooltip
                    showCloseButton
                    onClose={() => toggleTooltip('none')}
                    message={['수강권, 상품 환불 시 회원으로', '부터 회수되는 포인트의 합계']}
                    isOpen={tooltipState === 'collect'}
                    position="center"
                    maxWidth={208}>
                    <IconButton widthSize={16} onClick={() => toggleTooltip('collect')}>
                      <Icon name="information" size={14} fillColor={theme.color.gray3} />
                    </IconButton>
                  </Tooltip>
                </div>
                <Typography size={13} weight={500} textColor="gray3">
                  {`${pointDashboardData?.refund.count}건`}
                </Typography>
              </div>
            </div>
            <Typography size={15} weight={600} textColor="gray1">
              {`${filters.numberComma(Math.abs(pointDashboardData?.refund.paid_point))}P`}
            </Typography>
          </PointItemWrapper>
          <PointItemWrapper>
            <div className="item-label-wrapper">
              <Icon name="reportDemisePoint" size={32} />
              <div className="item-label">
                <div>
                  <Typography span size={15} weight={500} textColor="gray1">
                    소멸포인트
                  </Typography>
                  <Tooltip
                    showCloseButton
                    onClose={() => toggleTooltip('none')}
                    message={['설정한 날짜에 맞춰 자동으로', '소멸되는 포인트']}
                    isOpen={tooltipState === 'demise'}
                    position="center"
                    maxWidth={208}>
                    <IconButton widthSize={16} onClick={() => toggleTooltip('demise')}>
                      <Icon name="information" size={14} fillColor={theme.color.gray3} />
                    </IconButton>
                  </Tooltip>
                </div>
                <Typography size={13} weight={500} textColor="gray3">
                  {`${pointDashboardData?.destruction.count}건`}
                </Typography>
              </div>
            </div>
            <Typography size={15} weight={600} textColor="gray1">
              {`${filters.numberComma(pointDashboardData?.destruction.point)}P`}
            </Typography>
          </PointItemWrapper>
          <PointItemWrapper>
            <div className="item-label-wrapper">
              <Icon name="reportLeftPoint" size={32} />
              <div className="item-label">
                <div>
                  <Typography span size={15} weight={500} textColor="gray1">
                    잔여포인트
                  </Typography>
                  <Tooltip
                    showCloseButton
                    onClose={() => toggleTooltip('none')}
                    message={['전체 회원의 잔여 포인트 합계', '(오늘 기준)']}
                    isOpen={tooltipState === 'left'}
                    position="center"
                    maxWidth={208}>
                    <IconButton widthSize={16} onClick={() => toggleTooltip('left')}>
                      <Icon name="information" size={14} fillColor={theme.color.gray3} />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </div>
            <Typography size={15} weight={600} textColor="gray1">
              {`${filters.numberComma(pointDashboardData?.current.point)}P`}
            </Typography>
          </PointItemWrapper>
        </SummaryCardBody>
      </Accordion>
      <div className="divider">
        <Divider thin />
      </div>
      <Button
        className="more-point-button"
        fullWidth
        textColor="gray2"
        fontSize={13}
        fontWeight={500}
        rightIcon={<Icon name={isMorePointOpen ? 'arrowTopBold' : 'arrowBottomBold'} size={16} color="gray3" />}
        onClick={changeIsMorePointOpen}>
        {isMorePointOpen ? '접기' : '더보기'}
      </Button>
    </Container>
  );
};

export default PointSummaryCard;

const Container = styled.div<{ isVisible: boolean }>`
  position: relative;
  padding: 20px;
  background-color: ${theme.color.gray6};

  .more-point-button {
    padding: 20px 0px;
    text-align: center;
    background-color: ${theme.color.white};
    border-radius: 0px 0px 20px 20px;
  }

  .divider {
    background-color: ${theme.color.white};
    > hr {
      margin: 0px 20px;
      background-color: ${theme.color.gray6};
    }
  }

  .content-wrapper {
    overflow: ${({ isVisible }) =>
      isVisible
        ? 'visible'
        : 'hidden'}; // 아코디언의 content-wrapper에 overflow hidden이 적용 되어 있어서 잔여포인트의 툴팁이 잘림
  }
`;

const SummaryCardHeader = styled.div`
  padding: 20px 20px 0px 20px;
  width: 100%;
  background-color: ${theme.color.white};
  border-radius: 20px 20px 0px 0px;

  .item-label-wrapper {
    ${theme.flex('', '', '', 10)}

    .item-label {
      ${theme.flex('column', '', '')}

      > div {
        ${theme.flex('', 'center', '', 2)}
      }
    }
  }
`;

const SummaryCardBody = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  background-color: ${theme.color.white};

  .item-label-wrapper {
    ${theme.flex('', '', '', 10)}

    .item-label {
      ${theme.flex('column', '', '')}

      > div {
        ${theme.flex('', 'center', '', 2)}
      }
    }
  }
`;

const PointItemWrapper = styled.div`
  ${theme.flex('', 'center', 'space-between')}
  padding-bottom: 20px;

  .tooltip > .tooltip-contents-container {
    z-index: 1000;
  }
`;
