import Avatar from 'components/Avatar';
import AvatarGroup from 'components/Avatar/AvatarGroup';
import LinkButton, { LinkButtonProps } from 'components/Button/LinkButton';
import Typography from 'components/Typography';
import { EtcScheduleCreateFormType } from 'pages/EtcSchedule/types';
import { useFormContext, useWatch } from 'react-hook-form';
import convertTargetsText from 'utils/convertTargetsText';
import getImageUrl from 'utils/getImageUrl';

type Props = Omit<LinkButtonProps, 'children'>;

const StaffLinkButton = ({ ...props }: Props) => {
  const { control } = useFormContext<EtcScheduleCreateFormType>();
  const selectedStaffs = useWatch({ control, name: 'selectedStaffs' });
  const name = convertTargetsText(selectedStaffs, '', false);

  return (
    <LinkButton
      gap={10}
      arrowBold
      arrowSize={16}
      leftIcon={
        <AvatarGroup>
          {selectedStaffs.map(({ id, avatar }) => (
            <Avatar key={id} imgUrl={getImageUrl(avatar)} />
          ))}
        </AvatarGroup>
      }
      {...props}>
      <Typography size={15} weight={500} ellipsis={1} textColor={props.disabled ? 'gray3' : 'gray1'}>
        {name}
      </Typography>
    </LinkButton>
  );
};

export default StaffLinkButton;
