import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { useIsMutating } from '@tanstack/react-query';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import Form from 'components/Form';
import Loading from 'components/Loading';
import { Z_INDEX } from 'constants/zIndex';
import usePatchMessageMobile from 'hooks/service/mutations/usePatchMessageMobile';
import usePostMessageMobile from 'hooks/service/mutations/usePostMessageMobile';
import useMessage from 'hooks/useMessage';
import usePopup from 'hooks/usePopup';
import useResetAllRecoilState from 'hooks/useResetAllRecoilState';
import useToast from 'hooks/useToast';
import SendMessageLoading from 'pages/MemberDetail/components/AppLinkMessage/SendMessageLoading';
import MessageFooter from 'pages/MoreDetails/Message/components/MessageFooter';
import MessageSendReservation from 'pages/MoreDetails/Message/components/MessageSendReservation';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { messageTargetCounselSelectedFilter, messageTargetMemberSelectedFilter } from 'recoil/message';
import filters from 'utils/filters';
import { smsByteCheck } from 'utils/smsByteCheck';
import { object, string } from 'yup';

import { MOBILE_DEFAULT_VALUES, MOBILE_MAX_LENGTH } from '../constants';
import { MobileFormType } from '../types';
import { convertSuccessMessage, convertToMobilePostParams } from '../utils';
import MobileConfirmButton from './MobileConfirmButton';
import MobilePreview from './MobilePreview';
import MobileSender from './MobileSender';
import SelectedTargetList from './SelectedTargetList';
import Targets from './Targets';
import TitleAndMessage from './TitleAndMessage';

type Props = {
  defaultValues?: MobileFormType;
};

const yupSchema = object().shape({
  title: string().test(
    'title',
    `${filters.numberComma(MOBILE_MAX_LENGTH.title)}바이트 이내로 입력해 주세요.`,
    value => smsByteCheck(value || '') <= MOBILE_MAX_LENGTH.title,
  ),
  message: string().test(
    'message',
    `${filters.numberComma(MOBILE_MAX_LENGTH.message)}바이트 이내로 입력해 주세요.`,
    value => smsByteCheck(value || '') <= MOBILE_MAX_LENGTH.message,
  ),
});

const MobileSendForm = ({ defaultValues }: Props) => {
  const methods = useForm<MobileFormType>({
    mode: 'onChange',
    resolver: yupResolver(yupSchema),
    defaultValues: defaultValues ? { ...MOBILE_DEFAULT_VALUES, ...defaultValues } : MOBILE_DEFAULT_VALUES,
  });

  const { mutate: sendMobileMessage } = usePostMessageMobile();
  const { mutate: updateMobileMessage } = usePatchMessageMobile(defaultValues?.originalMessage?.id);
  const { setPopup } = usePopup();
  const { setToast } = useToast();
  const navigate = useNavigate();

  const resetFilters = useResetAllRecoilState([messageTargetMemberSelectedFilter, messageTargetCounselSelectedFilter]);

  const { checkReservationTime } = useMessage();

  const saveMessage = (values: MobileFormType) => {
    const params = convertToMobilePostParams(values);
    const mutateFunction = values.mode === 'create' ? sendMobileMessage : updateMobileMessage;

    // 발송 직전에도 예약 시간 가능여부 재확인
    if (values.isReserve && !checkReservationTime(values.reservationDate)) return;

    setPopup(<SendMessageLoading isReserve={values.isReserve} />);
    mutateFunction(params, {
      onSuccess: () => {
        const toastPosition = values.mode === 'update' ? 40 : 92;
        setToast({ message: convertSuccessMessage(values), type: 'success', bottom: toastPosition });
        if (values.mode === 'update' && defaultValues?.originalMessage) {
          navigate(-1);
        }

        methods.reset(MOBILE_DEFAULT_VALUES);
        resetFilters();
      },
      onError: () => {
        methods.reset(MOBILE_DEFAULT_VALUES, { keepDirtyValues: true });
      },
      onSettled: () => {
        setPopup(null);
      },
    });
  };

  const isMutating = useIsMutating() > 0;

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(saveMessage)} id="mobile">
        {isMutating && (
          <LoadingWrapper>
            <Loading />
          </LoadingWrapper>
        )}
        <MobileSender />
        <Container>
          <Contents>
            <Targets />
            <SelectedTargetList />
            <TitleAndMessage maxLength={MOBILE_MAX_LENGTH.message} />
          </Contents>
          <Divider />
          <MessageSendReservation />
        </Container>
        <MessageFooter>
          <MobilePreview />
          <MobileConfirmButton />
        </MessageFooter>
      </Form>
    </FormProvider>
  );
};

export default MobileSendForm;

export const Container = styled.div``;

export const Contents = styled.div`
  padding: 0 20px 56px;
`;

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  ${theme.flex('', 'center', 'center')}
  z-index: ${Z_INDEX.popup}
`;
