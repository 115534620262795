import useGetAllMemberCounsel from 'hooks/service/queries/useGetAllMemberCounsel';
import usePopup from 'hooks/usePopup';
import useSearchKeywords from 'hooks/useSearchKeywords';
import { MessageTargetFormType, MessageType } from 'pages/MoreDetails/Message/types';
import { convertCounselFilterToParams, convertCounselToTargets } from 'pages/MoreDetails/Message/utils';
import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { messageTargetCounselSelectedFilter } from 'recoil/message';
import FooterButton from 'sharedComponents/FooterButton';

import { MESSAGE_TARGET_IDENTIFIER, MESSAGE_TARGET_TEXT } from '../../constants';
import ProcessingPopupContents from '../../Member/components/ProcessingPopupContents';
import { convertCounselFilterToMessageFilter } from '../utils';

type Props = {
  goToForm: (updater?: (state: MessageType) => MessageType) => void;
};

const CompleteButton = ({ goToForm }: Props) => {
  const { control, formState } = useFormContext<MessageTargetFormType>();
  const isAllSelected = useWatch({ control, name: 'isAllSelected' });
  const selectedFilter = useRecoilValue(messageTargetCounselSelectedFilter);
  const { searchKeyword } = useSearchKeywords(MESSAGE_TARGET_IDENTIFIER);

  const [isAllFetchEnabled, setIsAllFetchEnabled] = useState(false);

  const params = convertCounselFilterToParams(selectedFilter, searchKeyword);
  const { data, isFetching } = useGetAllMemberCounsel(params, isAllFetchEnabled);
  const { setPopup } = usePopup();

  useEffect(() => {
    if (data && !isFetching) {
      setPopup(null);
      goToForm(state => ({
        ...state,
        target: 'counsel',
        counseling_logs: convertCounselToTargets(data),
        filter: convertCounselFilterToMessageFilter(selectedFilter, searchKeyword),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isFetching, selectedFilter, searchKeyword]);

  const selected = useWatch({ control, name: 'selected' });
  const confirmTargets = () => {
    if (isAllSelected) {
      setPopup(<ProcessingPopupContents title="선택 회원 추가중" />);
      setIsAllFetchEnabled(true);
      return;
    }

    goToForm(state => ({
      ...state,
      target: selected.length ? 'counsel' : undefined,
      counseling_logs: selected,
      filter: convertCounselFilterToMessageFilter(selectedFilter, searchKeyword),
    }));
  };

  const original = formState.defaultValues?.selected;
  const isSelectedNotChanged = original?.length === selected.length && original.every(o => selected.some(s => o?.id === s.id)); // 같은 길이인데 기존 아이디가 모두 포함되어 있으면 변경사항 없다고 봄
  const isAllSelectedNotChanged = formState.defaultValues?.isAllSelected === isAllSelected;

  const isNotChanged = isSelectedNotChanged && isAllSelectedNotChanged;
  const isSelectionEmpty = selected.length === 0 && !isAllSelected; // 체크된 회원이 없고 전체선택도 아닌 경우

  return (
    <FooterButton onClick={confirmTargets} disabled={isNotChanged || isSelectionEmpty}>
      {MESSAGE_TARGET_TEXT.confirm}
    </FooterButton>
  );
};

export default CompleteButton;
