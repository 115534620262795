import { FaqType } from './type';

export const FAQ_TEXT = {
  title: '자주 묻는 질문',
  placeholder: '검색어 입력',
};

export const FAQ_IDENTIFIER = 'faq';

export const FAQ_CATEGORIES = [
  { label: '전체', value: '전체' },
  { label: '로그인', value: '로그인' },
  { label: '센터연동', value: '센터연동' },
  { label: '수업', value: '수업' },
  { label: '수강권', value: '수강권' },
  { label: '알림', value: '알림' },
];

export const FAQ_DATA: FaqType = [
  {
    category: '로그인',
    title: '로그인이 되지 않아요.',
    contents: [
      {
        subTitle: '비밀번호 찾기를 통해 새로운 비밀번호로 변경 후 로그인 해주세요.',
        message: ['[비밀번호를 잊어버렸나요?] 버튼 선택'],
      },
    ],
  },
  {
    category: '로그인',
    title: '다른 휴대폰으로 동시에 로그인 할 수 있나요?',
    contents: [
      {
        subTitle: '동시에 로그인 할 수 있어요.',
        message: [
          '단, 로그인 되어 있는 모든 휴대폰으로 PUSH 메시지는 발송되지만 PUSH 알림은 최근에 로그인한 기기에서만 받을 수 있어요!',
        ],
      },
    ],
  },
  {
    category: '로그인',
    title: '인증번호가 오지 않아요.',
    contents: [
      {
        subTitle: '카카오톡을 먼저 확인해 주세요. ',
        message: [
          '스튜디오메이트에서는 인증번호 안내를 카카오톡을 우선적으로 전송하고 있어요.',
          '‘스튜디오메이트 계정관리’ 계정으로 도착한 메시지를 확인해 주세요.',
          '계정 차단, 스팸 처리 등으로 인해 메시지가 제대로 전송되지 않을 수 있어요!',
          '단, 네트워크 연결 등의 문제로 문자(SMS)로 전송될 수 있기 때문에 문자 메시지함도 함께 확인 부탁드려요. 문자 메시지함에도 없다면 스마트폰의 스팸 메시지함을 확인해 주세요.',
        ],
      },
    ],
  },
  {
    category: '센터연동',
    title: '센터하고 연동되지 않아요.',
    contents: [
      {
        subTitle: '센터에 등록한 강사님의 휴대폰 번호를 확인해 주세요.',
        message: ['휴대폰번호가 정확하게 일치해야 계정이 연동됩니다.'],
      },
    ],
  },
  {
    category: '센터연동',
    title: '센터와 연동을 해제하고 싶어요.',
    contents: [
      {
        subTitle: '‘더보기 > 시설조회’ 에서 연동을 해제하고 싶은 센터를 삭제해 주세요.',
        message: [
          '센터에 남아있는 강사님과 관련된 정보를 모두 삭제하고 싶은 경우에는 센터측으로 직접 삭제 요청을 해주셔야 해요.',
        ],
      },
    ],
  },
  {
    category: '수업',
    title: '삭제된 수업을 복구하고 싶어요.',
    contents: [
      {
        subTitle: '이미 삭제된 수업은 복구가 어려워요.',
        message: ['‘더보기 > 삭제된 수업’ 에서 삭제된 수업 정보를 확인하여 다시 수업을 등록해 주셔야 해요.'],
      },
    ],
  },
  {
    category: '수업',
    title: '그룹 수업의 수업시간을 한꺼번에 수정하고 싶어요.',
    contents: [
      {
        subTitle: '그룹 수업 수정화면에서 ‘수정 완료’ 버튼을 누른 후 ‘이후 수업 모두 수정’을 선택해 주세요.',
        message: ['해당 수업을 포함하여 그룹 수업 생성 시 선택한 요일과 시간의 모든 수업을 한번에 수정할 수 있어요.'],
      },
    ],
  },
  {
    category: '수강권',
    title: '휴일 설정 시 ‘자동차감 실행 안함’이 뭔가요?',
    contents: [
      {
        subTitle: '자동차감',
        message: [
          '수강권에 설정하는 기능으로 회원이 출석하지 않아도 설정한 주간 또는 월간 이용횟수만큼 자동으로 차감되는 기능입니다.',
        ],
      },
      {
        subTitle: '자동차감 실행 안함',
        message: [
          '‘자동차감 실행 안함’ 상태에서 휴일을 설정할 경우 휴일이 있는 주 또는 월에는 자동 차감이 실행되지 않습니다.',
          '반대로 ‘자동차감 실행함’ 상태에서는 휴일이 있는 주 또는 월에 자동으로 횟수가 차감되는 점 참고해 주세요.',
        ],
      },
      {
        subTitle: '자동차감 수강권을 사용하지 않을 경우',
        message: [
          '자동차감 수강권을 사용하지 않는 시설에서는 ‘자동차감 실행 안함’ 또는 ‘실행함’ 모두 아무런 영향을 끼치지 않습니다. 별도 설정없이 그대로 휴일을 설정해 주세요.',
        ],
      },
    ],
  },
  {
    category: '수강권',
    title: '휴일 설정 시 설정한 휴일만큼 수강권의 만료일이 자동으로 늘어 나나요?',
    contents: [
      {
        subTitle: '아니요, 만료일은 자동으로 늘어나지 않아요.',
        message: [
          '설정한 휴일만큼 수강권 만료일 연장을 따로 해주셔야 해요. 현재 수강권 만료일 연장은 웹페이지에서만 가능하므로 시설 웹페이지를 통해 연장해 주세요.',
        ],
      },
    ],
  },
  {
    category: '수강권',
    title: '수강권 잔여횟수와 예약가능한 횟수가 일치하지 않아요.',
    contents: [
      {
        subTitle: '미리 예약한 수업이 있을 경우 수강권의 잔여횟수와 예약가능한 횟수는 일치하지 않을 수 있어요.',
        message: [
          '예를 들어 잔여횟수가 10회이고 미리 예약한 수업이 3개라면 수강권에는 잔여횟수는 10회, 예약 가능한 횟수는 7회로 표기 됩니다.',
        ],
        appendix: '*예약 가능한 횟수는 예약완료 후에 차감되고 잔여횟수는 수업이 모두 종료된 후에 차감됩니다.',
      },
    ],
  },
  {
    category: '알림',
    title: 'PUSH 알림 메시지가 오지 않아요.',
    contents: [
      {
        subTitle:
          '사용중인 휴대폰의 알림 설정을 먼저 확인해 주세요. → 이후 관리자앱을 로그아웃 했다가 다시 로그인하고 이용해 주세요.',
        message: [
          '위 두가지 방법을 모두 시도했음에도 PUSH 알림 메시지가 수신되지 않을 경우 스튜디오메이트 고객센터로 연락주세요.',
        ],
      },
    ],
  },
  {
    category: '알림',
    title: '다른 강사의 수업과 관련된 알림 메시지를 제가 받을 수 있나요?',
    contents: [
      {
        subTitle: '네, 알림 설정을 해주시면 본인 외 다른 강사님의 수업알림 메시지를 받을 수 있어요.',
        message: ['알림 받기를 원하는 강사 정보 수정 화면에서 ‘모든 강사 알림 받기’ 설정을 켜주시면 됩니다.'],
      },
    ],
  },
];
