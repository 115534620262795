import useGetMemberFilters from 'hooks/useGetMemberFilters';
import { MEMBER_FILTER_DEFAULT_TAB_LABEL } from 'pages/Member/constants';
import { convertMemberFilter } from 'pages/Member/utils';
import { RecoilState } from 'recoil';
import Filter from 'sharedComponents/Filters';
import { ParamsType } from 'sharedComponents/Filters/types';
import filters from 'utils/filters';

import AllMemberDrawerFilterOptions from './AllMemberDrawerFilterOptions';
import AllMemberWholeFilterOptions from './AllMemberWholeFilterOptions';

/** 회원목록페이지와 문자 수신자선택에서 모두 쓰기 위해 제네릭으로 주입*/
type Props<T extends ParamsType> = {
  filterAtom: RecoilState<T>;
  assigned?: boolean;
  hasMobile?: boolean;
  isAppLinked?: boolean;
  drawerToastPosition?: number;
};

const AllMemberFilter = <T extends ParamsType>({
  filterAtom,
  assigned = false,
  hasMobile = false,
  isAppLinked = false,
  drawerToastPosition,
}: Props<T>) => {
  const { tagCounts, gradeResponse, staffList } = useGetMemberFilters({
    countParams: {
      is_assigned: assigned ? 1 : undefined,
      has_mobile: hasMobile ? 1 : undefined,
      is_app_linked: isAppLinked ? 1 : undefined,
    },
  });

  const grades = gradeResponse?.is_use_user_grade ? gradeResponse?.grades : undefined; // 회원등급 off 시설은 미노출
  const staffs = assigned ? undefined : staffList; // 담당회원 필터에서는 미노출

  // 회원 필터 탭 및 옵션은 데이터 가공이 필요함
  const memberFilter = convertMemberFilter(tagCounts, grades, staffs);
  const memberTabLabels = {
    ...MEMBER_FILTER_DEFAULT_TAB_LABEL,
    tag: `회원 전체 ${filters.numberComma(tagCounts.total ?? 0)}`,
  };

  return (
    <Filter
      filterAtom={filterAtom}
      filters={memberFilter}
      defaultTabLabels={memberTabLabels}
      renderDrawerOptions={AllMemberDrawerFilterOptions}
      renderWholeFilterPopupOptions={AllMemberWholeFilterOptions}
      drawerToastPosition={drawerToastPosition}
    />
  );
};

export default AllMemberFilter;
