import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { PERMISSION } from 'constants/permission';
import usePatchEtcSchedule from 'hooks/service/mutations/usePatchEtcSchedule';
import usePatchEtcScheduleAll from 'hooks/service/mutations/usePatchEtcScheduleAll';
import usePatchEtcScheduleBulk from 'hooks/service/mutations/usePatchEtcScheduleBulk';
import { BookingFailResponse } from 'hooks/service/mutations/usePostBookingPrivate';
import { EtcScheduleDetailResponse } from 'hooks/service/queries/useGetEtcScheduleEtcScheduleTime';
import useGetPermissionDoHavePermission from 'hooks/service/queries/useGetPermissionDoHavePermission';
import useCustomBookingForm from 'hooks/useCustomBookingForm';
import usePopup from 'hooks/usePopup';
import useQueryString from 'hooks/useQueryString';
import useToast from 'hooks/useToast';
import { isArray } from 'lodash';
import FailAllBooking from 'pages/Booking/components/AfterRequestBooking/FailAllBooking';
import BookingRangeDate from 'pages/Booking/components/BookingRangeDate';
import BookingEndDate from 'pages/Booking/components/BookingRangeDate/BookingEndDate';
import BookingStartDate from 'pages/Booking/components/BookingRangeDate/BookingStartDate';
import { ETC_FORM_TEXT, ETC_FORM_VALIDATE } from 'pages/EtcSchedule/constants';
import { EtcScheduleCreateFormType } from 'pages/EtcSchedule/types';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { errorBackStepAtom, staffIdAtom } from 'recoil/common';
import { CustomError } from 'types/errorTypes';
import { StaffType } from 'types/staffTypes';

import EtcFormMainLayout from '../components/EtcFormMainLayout';
import convertFormState from '../utils/convertFormState';
import formatEtcParams from '../utils/formatEtcParams';

type Props = {
  data: EtcScheduleDetailResponse;
  staff: StaffType;
};

const UpdateForm = ({ data, staff }: Props) => {
  const currentStaffId = useRecoilValue(staffIdAtom);
  const setErrorBackStep = useSetRecoilState(errorBackStepAtom);
  const { checkPermission } = useGetPermissionDoHavePermission();

  const { getSearchParams } = useQueryString();
  const mode = getSearchParams('mode') as string;

  const defaultValues = useMemo(() => {
    const formValues = convertFormState(data, staff);
    switch (mode) {
      case 'update':
        return {
          ...formValues,
          endDate: data.end_on,
        };
      case 'update-all':
        return {
          ...formValues,
          startDate: data.etc_schedule.start_date,
          endDate: data.etc_schedule.end_date,
        };
      case 'update-after-all':
        return {
          ...formValues,
          endDate: data.etc_schedule.end_date,
        };
      default:
        return formValues;
    }
  }, [staff, mode, data]);

  const methods = useCustomBookingForm<EtcScheduleCreateFormType>({
    resolver: yupResolver(ETC_FORM_VALIDATE),
    defaultValues,
  });

  const navigate = useNavigate();
  const { setToast } = useToast();
  const { setPopup } = usePopup();
  const { mutate: updateMutate } = usePatchEtcSchedule(data.id);
  const { mutate: updateBulkMutate } = usePatchEtcScheduleBulk(data.etc_schedule_id);
  const { mutate: updateAllMutate } = usePatchEtcScheduleAll(data.etc_schedule_id);

  const resetFormAfterError = () => {
    const { reset, getValues } = methods;
    reset(getValues(), { keepDefaultValues: true });
  };

  const mutateCallback = () => {
    return {
      onSuccess: () => {
        navigate(-1);
        setToast({ type: 'success', message: ETC_FORM_TEXT.successMessage.update });
      },
      onError: (error: AxiosError<Array<BookingFailResponse> | CustomError>) => {
        const isPermissionError = error.response?.status === 403;
        const staffType = data.staffs.filter(staff => staff.id === currentStaffId).length ? 'mine' : 'others';
        if (isPermissionError) {
          if (staffType === 'others') {
            checkPermission(PERMISSION.etcSchedule.others.view.id, {
              onError: () => {
                setErrorBackStep({
                  to: '/schedule',
                  options: { replace: true },
                });
              },
            });
          }
        } else {
          if (isArray(error.response?.data)) {
            setPopup(
              <FailAllBooking fails={error.response?.data} goBack={resetFormAfterError} closePopup={() => navigate(-2)} />,
            );
            return false;
          }
          return true;
        }
      },
    };
  };

  const submit = (values: EtcScheduleCreateFormType) => {
    const { start, ...rest } = formatEtcParams(values);
    const { start_date, end_date, start_time, end_time, ...updateSingle } = { ...rest };

    const updateParams = {
      ...updateSingle,
      start_on: `${start_date} ${start_time || '00:00:00'}`,
      end_on: `${start_date} ${end_time || '23:59:59'}`,
    };
    switch (mode) {
      case 'update':
        return updateMutate(updateParams, mutateCallback());
      case 'update-after-all':
        return updateBulkMutate({ ...rest, start_date: data.etc_schedule.start_date, start }, mutateCallback());
      case 'update-all':
        return updateAllMutate({ ...rest }, mutateCallback());
    }
  };

  return (
    <EtcFormMainLayout {...methods} pageMode={mode === 'update' ? 'update' : 'updateAll'} onSubmit={submit}>
      {mode === 'update' && <BookingRangeDate isRange={false} />}
      {mode === 'update-after-all' && (
        <>
          <BookingStartDate />
          <BookingEndDate />
        </>
      )}
      {mode === 'update-all' && <BookingRangeDate isRange={true} />}
    </EtcFormMainLayout>
  );
};

export default UpdateForm;
