import useGetLectureDetail from 'hooks/service/queries/useGetLectureDetail';
import useInfinityBookingList from 'hooks/service/queries/useInfinityBookingList';
import useCustomBookingForm from 'hooks/useCustomBookingForm';
import useQueryString from 'hooks/useQueryString';
import { convertBookingFormCommonState } from 'pages/Booking/utils';

import { BookingPrivateFormType, LectureSizeType } from '../../types';
import ConfirmBeforeBooking from '../components/ConfirmBeforeBooking';
import PrivateCommonCreateForm from '../components/PrivateCommonCreateForm';
import PrivateFormLayout from '../components/PrivateFormLayout';

type Props = {
  currentLectureId: number;
};

const FormMain = ({ currentLectureId }: Props) => {
  const { data: lectureData } = useGetLectureDetail(currentLectureId);
  const { data: bookings } = useInfinityBookingList(currentLectureId);

  const lectureSize = `${lectureData.max_trainee}` as LectureSizeType;
  const selectedUserTickets = bookings.map(({ member, user_ticket }) => {
    return { ...user_ticket, member };
  });
  const userTicketIds = bookings.map(booking => booking.user_ticket.id);

  const methods = useCustomBookingForm<BookingPrivateFormType>({
    defaultValues: {
      ...convertBookingFormCommonState(lectureData),
      lectureSize,
      selectedUserTickets,
      repeat: null,
      repeatCustomWeekday: [],
      tempRepeatCustomWeekday: [],
      prevSelectedUserTickets: selectedUserTickets,
      originUserTicketIds: userTicketIds,
    },
  });

  const { getAllSearchParams } = useQueryString();
  const { step } = getAllSearchParams();

  return (
    <PrivateFormLayout pageMode="copy" {...methods}>
      {step === '2' ? (
        <ConfirmBeforeBooking pageMode="copy" />
      ) : (
        <PrivateCommonCreateForm pageMode="copy" currentLecture={lectureData} />
      )}
    </PrivateFormLayout>
  );
};

export default FormMain;
