import styled from '@emotion/styled';
import dayjs from 'dayjs';
import RangeTimePicker from 'designedComponents/Pickers/TimePicker/RangeTimePicker';
import { BOOKING_COMMON_FORM_TEXT } from 'pages/Booking/constants';
import { AllPageModeType, BookingCommonFormType } from 'pages/Booking/types';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import FormFieldAccordion from 'sharedComponents/FormField/FormFieldAccordion';
import filters from 'utils/filters';

import BookingResetConfirmDialog from '../BookingResetConfirmDialog';
import ResetConfirmDialog from '../ResetConfirmDialog';

type Props = {
  /** 생성 폼 에서 룸설정 초기화 용도 (create) */
  pageMode: AllPageModeType;
  isEtcUpdateForm?: boolean;
};

const BookingRangeTime = ({ pageMode, isEtcUpdateForm }: Props) => {
  const {
    control,
    setValue,
    getValues,
    formState: { dirtyFields },
  } = useFormContext<BookingCommonFormType>();
  const startTime = useWatch({ control, name: 'startTime' });
  const endTime = useWatch({ control, name: 'endTime' });
  const endDate = useWatch({ control, name: 'endDate' });
  const isSelectedFutureTime = useWatch({ control, name: 'isSelectedFutureTime' });
  const [prevTime, setPrevTime] = useState([startTime, endTime]);

  /** 시작시간이 종료시간 보다 늦거나 시작시간과 종료시간이 같은 경우 */
  const add50min = (value: [Date, Date]) => {
    // 시작 시간과 종료시간이 같거나 시작 시간이 종료시간보다 전날이라면
    if (dayjs(value[0]).isSame(value[1]) || dayjs(value[0]).isAfter(value[1]) || dayjs(value[0]).isBefore(value[1], 'day')) {
      const add50Min = dayjs(value[0]).add(50, 'minute').toDate();
      const isBeforeDate = dayjs(value[0]).isBefore(add50Min, 'day');
      if (isBeforeDate) {
        const maxEndTime = dayjs(value[0]).hour(23).minute(55).toDate();
        setValue('endTime', maxEndTime);
      } else {
        setValue('endTime', add50Min);
      }
    } else {
      setValue('endTime', value[1], { shouldDirty: true });
    }
  };

  const cancelDialog = () => {
    if (!getValues('currentLecture')) return;
    const lectureStartTime = dayjs(getValues('currentLecture.start_on')).toDate();
    const lectureEndTime = dayjs(getValues('currentLecture.end_on')).toDate();
    const isDifferent = lectureStartTime !== prevTime[0];
    setValue('startTime', isDifferent ? prevTime[0] : lectureStartTime);
    setValue('endTime', isDifferent ? prevTime[1] : lectureEndTime);
    setValue('isSelectedFutureTime', false);
  };

  const continueDialog = () => {
    setValue('isSelectedFutureTime', false, { shouldDirty: true });
  };

  /**
   * 지난 수업 1개 수정 시 수정시점 보다 이후를 선택하는 경우,
   * 회원 예약 취소됨 다이얼로그 노출
   */
  const showResetBookingDialog = (value: [Date, Date]) => {
    setPrevTime([startTime, endTime]);
    setValue('startTime', value[0]);
    add50min(value);
    setValue('isSelectedFutureTime', true);
    return;
  };

  // 수업 생성할 때 룸 선택 후 시간 변경하면 룸선택 초기화 팝업 노출
  const resetRoom = () => {
    if (pageMode !== 'create' || isEtcUpdateForm) return;

    const hasSelectedRoom = getValues('roomId') !== BOOKING_COMMON_FORM_TEXT.room.notSelected.value;
    if (hasSelectedRoom) {
      setValue('resetChangeTarget', 'time');
      setValue('roomId', BOOKING_COMMON_FORM_TEXT.room.notSelected.value);
      setValue('selectedRoom', undefined);
    }
  };

  const changeTime = (value: [Date, Date]) => {
    const selectedDateTime = `${endDate} ${filters.timeSecondZero(value[0])}`; // 바꾸려는 시간
    const currentLectureStartOn = getValues('currentLecture.start_on');
    const currentTime = filters.dateDashTimeSecond(); // 수정시점
    const isSelectedFutureTimeFirst =
      pageMode === 'update' &&
      dayjs(currentLectureStartOn).isBefore(currentTime) &&
      dayjs(selectedDateTime).isAfter(currentTime) &&
      !dirtyFields.isSelectedFutureDate &&
      !dirtyFields.isSelectedFutureTime;

    const start = filters.time(value[0]) === '23:55' ? dayjs(value[0]).hour(23).minute(50).toDate() : value[0];
    if (isSelectedFutureTimeFirst) {
      showResetBookingDialog([start, value[1]]);
    } else {
      setValue('startTime', start, { shouldDirty: true });
      resetRoom();
      add50min(value);
    }
  };

  return (
    <>
      <StyledAccordion iconName="time" header={`${filters.time(startTime)} ~ ${filters.time(endTime)}`} hasValue={!!startTime}>
        <RangeTimePicker showAddTimeButton stepMinute={5} timeRange={[startTime, endTime]} onChange={changeTime} />
      </StyledAccordion>
      {isSelectedFutureTime && <BookingResetConfirmDialog target="시간" onCancel={cancelDialog} onContinue={continueDialog} />}
      {getValues('resetChangeTarget') === 'time' && <ResetConfirmDialog />}
    </>
  );
};

const StyledAccordion = styled(FormFieldAccordion)`
  .range-time-picker {
    padding-bottom: 24px;
  }
`;

export default BookingRangeTime;
