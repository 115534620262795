import { useQueryClient } from '@tanstack/react-query';

import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { BookingCountResponse } from '../queries/useGetBookingCount';
import { BookingFailResponse } from './usePostBookingPrivate';

/** 프라이빗 수업 1개 수정 */
const usePatchLecturePrivate = (lectureId: number) => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<LecturePrivatePatchParams, LecturePrivatePatchResponse>({
    method: `patch`,
    url: `/api/lecture/private/${lectureId}`,
  });

  return useCustomMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['lecture', 'detail', lectureId],
      });
    },
  });
};

export default usePatchLecturePrivate;

export type LecturePrivatePatchParams = {
  title: string;
  instructor_id: number;
  start_on: string;
  end_on: string;
  room_id?: number;
  /** 예약 가능 시작/종료 시간 */
  booking_start_at?: string;
  booking_end_at?: string;
  /** 예약 취소 가능 시작/종료 시간 */
  booking_cancel_start_at?: string;
  booking_cancel_end_at?: string;
  /** 체크인 가능 시작/종료 시간 */
  enter_start_at?: string;
  enter_end_at?: string;
  is_pass_limit?: boolean; // 주/월간 초과 검증 걸렸을 때 계속 요청하려면 true
  is_force?: boolean; // 중복 예약 하려면 true
};

export type LecturePrivatePatchResponse = {
  id: number;
};

/**
 * 검증 에러 순서
 * 1. 수정하려는 기간에 수강권이 만료 됐는지, 정지기간인지 등 검증 (일반 dialog 에러 노출)
 * 2. limit
 * 3. overlap
 */
export type LectureValidationError = {
  limit: BookingCountResponse; // 주/월간 초과 검증 걸리면 limit으로 반환
  overlap: Array<BookingFailResponse>; // 주/월간 검증 후 중복예약 검증에 걸리면 overlap으로 반환 (가장 나중에 반환되는 에러, 중복에도 예약하려면 is_force: true 로 요청)
};
