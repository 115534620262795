import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import Skeleton from 'components/Skeleton';
import { Fragment } from 'react/jsx-runtime';
import { CommonStyleCard } from 'sharedComponents/StyledComponents';

const HistorySkeleton = () => {
  return (
    <>
      {[0, 1, 2, 3].map(key => (
        <Fragment key={key}>
          <TitleSkeleton width={85} height={16} />
          <Card>
            <div className="first">
              <StyledSkeleton height={22} />
              <StyledSkeleton height={22} />
            </div>
            <StyledSkeleton height={20} />
            <Divider thin thinColor="gray6" />
            <StyledSkeleton height={48} />
            <div className="second">
              <StyledSkeleton height={38} />
              <StyledSkeleton height={38} />
            </div>
          </Card>
        </Fragment>
      ))}
    </>
  );
};

export default HistorySkeleton;

const StyledSkeleton = styled(Skeleton)`
  border-radius: 3px;
`;

const TitleSkeleton = styled(StyledSkeleton)`
  background-color: ${theme.color.gray5};
`;

const Card = styled(CommonStyleCard)`
  margin-top: 19px;
  .first {
    ${theme.flex('row', 'center', '', 8)};
    margin-bottom: 6px;
    div:first-of-type {
      flex: 0.7;
    }
    div:last-of-type {
      flex: 0.3;
    }
  }
  hr {
    margin: 18px 0 16px;
  }
  .second {
    ${theme.flex('row', 'center', '', 9)};
    margin-top: 18px;
  }
`;
