import { LectureGroupPatchParams } from 'hooks/service/mutations/usePatchLectureGroup';

import { BookingGroupUpdateFormType } from '../../Group/types';
import formatBookingPrivateUpdateParams from '../private/formatBookingPrivateUpdateParams';

const formatBookingGroupUpdateParams = (formValues: BookingGroupUpdateFormType): LectureGroupPatchParams => {
  const commonParams = formatBookingPrivateUpdateParams(formValues);
  const {
    daily_change_booking_end_at,
    autoclose_at,
    booking_auto_shift_available_minutes_from_start,
    maxTrainee,
    minTrainee,
    waitingTrainee,
    is_booking_only,
    description,
    divisionId,
  } = formValues;

  return {
    ...commonParams,
    daily_change_booking_end_at,
    autoclose_at: !minTrainee ? undefined : autoclose_at,
    booking_auto_shift_available_minutes_from_start,
    max_trainee: maxTrainee,
    min_trainee: minTrainee,
    waiting_trainee_limit: waitingTrainee,
    is_booking_only,
    description: description || undefined,
    division_id: divisionId ? Number(divisionId) : undefined,
  };
};

export default formatBookingGroupUpdateParams;
