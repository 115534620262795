import styled from '@emotion/styled';
import { useIsMutating } from '@tanstack/react-query';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import { Z_INDEX } from 'constants/zIndex';
import { useFormContext, useWatch } from 'react-hook-form';

type Props = {
  children: string;
};

const SubmitButton = ({ children }: Props) => {
  const isMutating = useIsMutating() > 0;

  const title = useWatch({ name: 'title' });
  const contents = useWatch({ name: 'contents' });

  const {
    formState: { isSubmitting, isDirty },
  } = useFormContext();

  return (
    <Floating>
      <Button
        type="submit"
        disabled={!title || !contents || isMutating || isSubmitting || !isDirty}
        variant="contained"
        fullWidth
        size="large56"
        color="point">
        {children}
      </Button>
    </Floating>
  );
};

export default SubmitButton;

const Floating = styled.footer`
  padding: 10px 20px;
  background-color: ${theme.color.white};
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: ${Z_INDEX.footerButton};
`;
