import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import useGetMemberDetail from 'hooks/service/queries/useGetMemberDetail';
import { useEffect, useLayoutEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { currentMemberAtom, memberDetailHistoryFilterAtom } from 'recoil/MemberDetail';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import MainLayout from 'sharedComponents/MainLayout';
import getEllipsisByLength from 'utils/getEllipsisByLength';

import { MEMBER_DETAIL_HISTORY_DEFAULT } from '../constants';
import { CurrentMemberIdProps } from '../types';
import DetailInfo from './DetailInfo';
import MemberInfo from './MemberInfo';
import MoreDetail from './MoreDetail';

const Main = ({ currentMemberId }: CurrentMemberIdProps) => {
  const { data: member } = useGetMemberDetail(currentMemberId);

  const [historyFilter, setHistoryFilter] = useRecoilState(memberDetailHistoryFilterAtom);
  const setCurrentMember = useSetRecoilState(currentMemberAtom);

  useEffect(() => {
    setCurrentMember(member);
  }, [member, setCurrentMember]);

  useLayoutEffect(() => {
    if (historyFilter.currentMemberId !== currentMemberId) {
      setHistoryFilter({ ...MEMBER_DETAIL_HISTORY_DEFAULT, currentMemberId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout
      header={{
        title: getEllipsisByLength({ text: member.name || '', max: 10 }),
        titleScrollThreshold: 144,
        rightButton: (
          <ApiBoundary>
            <MoreDetail currentMemberId={currentMemberId} />
          </ApiBoundary>
        ),
      }}>
      <Container className="member-detail">
        <MemberInfo member={member} />
        <DetailInfo currentMemberId={currentMemberId} />
      </Container>
    </MainLayout>
  );
};

export default Main;

const Container = styled.div`
  ${theme.flex('column', '', '')};
  min-height: 100%;

  hr {
    flex: 0 0 auto;
  }
`;
