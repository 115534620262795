import Typography from 'components/Typography';
import { useFormContext } from 'react-hook-form';
import DialogBeforeLeave from 'sharedComponents/DialogBeforeLeave';

import { CHANGE_PHONE_NUMBER_TEXT } from '../constants';
import { ChangePhoneNumberFormType } from '../types';

const LeaveAlertDialog = () => {
  const { watch } = useFormContext<ChangePhoneNumberFormType>();
  const step = watch('step');
  const isCompleted = watch('isCompleted');

  return (
    <DialogBeforeLeave isBlocked={step !== 'number' && !isCompleted}>
      {CHANGE_PHONE_NUMBER_TEXT.alert.map(text => (
        <Typography size={15} key={text}>
          {text}
        </Typography>
      ))}
    </DialogBeforeLeave>
  );
};

export default LeaveAlertDialog;
