import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { LectureBookingListResponse } from '../queries/useInfinityBookingList';
import { ChangePrivateBookingStatusParams } from './usePatchBookingPrivate';

/** 그룹 수업 예약 변경(예약을 노쇼, 출석, 결석 처리함) */
const usePatchBookingGroup = (booking: LectureBookingListResponse) => {
  const { id } = booking;

  const { mutationFn } = useMutationFnWithCommonParams<ChangePrivateBookingStatusParams>({
    method: `patch`,
    url: `/api/booking/group/${id}`,
  });

  return useCustomMutation({
    mutationFn,
  });
};

export default usePatchBookingGroup;
