import Avatar from 'components/Avatar';
import AvatarGroup from 'components/Avatar/AvatarGroup';
import OneLinedTitle from 'designedComponents/Drawers/DrawerTitle/OneLinedTitle';
import SingleButtonDrawer from 'designedComponents/Drawers/DrawerWithButton/SingleButtonDrawer';
import { useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import FormFieldLinkButton from 'sharedComponents/FormField/FormFieldLinkButton';
import convertTargetsText from 'utils/convertTargetsText';
import getImageUrl from 'utils/getImageUrl';

import { TicketDetailInfoFormType } from '../types';
import SelectStaffsDrawerContent from './SelectStaffsDrawerContent';

const SelectStaffs = () => {
  const { control, setValue } = useFormContext<TicketDetailInfoFormType>();
  const staffs = useWatch({ control, name: 'detail.staffs' });
  const tempStaffs = useWatch({ control, name: 'detail.tempStaffs' });

  const [isOpen, setIsOpen] = useState(false);

  const closeDrawer = () => {
    setValue('detail.tempStaffs', staffs);
    setIsOpen(false);
  };

  const save = () => {
    setValue('detail.staffs', tempStaffs);
    setIsOpen(false);
  };

  const leftIcon = useMemo(() => {
    if (staffs.length < 2) {
      return <Avatar imgUrl={staffs.length ? getImageUrl(staffs[0].image) : undefined} />;
    } else {
      return (
        <AvatarGroup>
          <Avatar imgUrl={getImageUrl(staffs[0].image)} />
          <Avatar imgUrl={getImageUrl(staffs[1].image)} />
        </AvatarGroup>
      );
    }
  }, [staffs]);

  return (
    <>
      <FormFieldLinkButton
        className="staff-link-button"
        leftIcon={leftIcon}
        hasValue={!!staffs.length}
        onClick={() => setIsOpen(true)}>
        {convertTargetsText(staffs, '담당강사')}
      </FormFieldLinkButton>

      <SingleButtonDrawer
        isOpen={isOpen}
        onClose={closeDrawer}
        header={<OneLinedTitle title="담당강사 선택" />}
        button={{ text: '선택 완료', onClick: save }}>
        <ApiBoundary>
          <SelectStaffsDrawerContent />
        </ApiBoundary>
      </SingleButtonDrawer>
    </>
  );
};

export default SelectStaffs;
