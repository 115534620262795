import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import CheckBox from 'components/CheckBox';
import AvatarLabel from 'designedComponents/Drawers/CheckRadioDrawer/AvatarLabel';
import { StaffResponse } from 'hooks/service/queries/useInfinityStaff';
import { useRecoilValue } from 'recoil';
import { staffIdAtom } from 'recoil/common';

import { CommonProps } from '../types';

type Props = {
  staffs: StaffResponse[];
  onChange: (e: { target: { id: string; checked: boolean } }) => void;
} & Pick<CommonProps, 'tempCheckedStaffs'>;

const FilterStaffItems = ({ staffs, tempCheckedStaffs, onChange }: Props) => {
  const staffId = useRecoilValue(staffIdAtom);

  return (
    <Container>
      {staffs.map(({ id, name, avatar }) => {
        const isStaffMe = staffId === id;
        const isChecked = tempCheckedStaffs.includes(id);
        return (
          <li key={id}>
            <CheckBox
              id={id.toString()}
              label={
                <AvatarLabel
                  key={id}
                  id={String(staffId)}
                  weight={500}
                  isMe={isStaffMe}
                  imgUrl={avatar}
                  label={name}
                  showLabelAll
                />
              }
              gap={16}
              checked={tempCheckedStaffs.includes('all') || isChecked}
              onChange={onChange}
            />
          </li>
        );
      })}
    </Container>
  );
};

export default FilterStaffItems;

const Container = styled.ul`
  ${theme.flex('column', 'flex-start', 'center', 8)};
  width: 100%;

  li {
    padding-left: 20px;
    width: 100%;
  }
`;
