import { BookingPrivateParams } from 'hooks/service/mutations/usePostBookingPrivate';
import { BookingPrivateFormType } from 'pages/Booking/Private/types';

import formatLectureCommonParams from '../formatLectureCommonParams';
import { getCurrentSelectedUserTicketIds } from './booking';

const formatBookingPrivateParams = (formValues: BookingPrivateFormType): BookingPrivateParams => {
  const { selectedUserTickets } = formValues;

  const commonParams = formatLectureCommonParams(formValues);
  const userTicketIds = getCurrentSelectedUserTicketIds(selectedUserTickets);

  const result: BookingPrivateParams = {
    user_ticket_ids: userTicketIds,
    ...commonParams,
  };
  return result;
};

export default formatBookingPrivateParams;
