import styled from '@emotion/styled';
import { CalendarNav, Datepicker, MbscDatepickerOptions } from '@mobiscroll/react';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import { Container as OriginPickerContainer } from 'components/DateCalendarPicker';
import DatePicker from 'components/DatePicker';
import Icon from 'components/Icon';
import dayjs from 'dayjs';
import DoubleButtonDrawer from 'designedComponents/Drawers/DrawerWithButton/DoubleButtonDrawer';
import useGetStaffWorkSchedules from 'hooks/service/queries/useGetStaffWorkSchedules';
import useParamsId from 'hooks/useParamsId';
import { ScheduleFormType } from 'pages/MoreDetails/Staff/Detail/EventScheduleSingle/types';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { drawerAtom } from 'recoil/drawer';
import { eventScheduleSelectDateAtom } from 'recoil/staff';
import filters from 'utils/filters';
import getWorkTimeDatasetNew from 'utils/getWorkTimeDatasetNew';

import { CALENDAR_DEFAULT_PROPS } from '../../constants';
import { CalendarOnCellClickParams } from '../../types';

type Props = MbscDatepickerOptions & {
  eventTimesDataset: ScheduleFormType[];
  datasetFields: ScheduleFormType[];
  setDatasetFields: Dispatch<SetStateAction<ScheduleFormType[]>>;
};

let firstRender = true;

const DateMultiCalendarPicker = ({ eventTimesDataset, datasetFields, setDatasetFields }: Props) => {
  const calendarRef = useRef<Datepicker>(null);
  const tempMonthRef = useRef<Date | null>(null);

  const setSelectDates = useSetRecoilState(eventScheduleSelectDateAtom);
  const [isDrawerOpen, setIsDrawerOpen] = useRecoilState(drawerAtom);

  const targetStaffId = useParamsId();
  const { data } = useGetStaffWorkSchedules({ targetStaffId });
  const workTimeOrigin: Omit<ScheduleFormType, 'date'>[] = useMemo(() => getWorkTimeDatasetNew(data), [data]);

  useEffect(() => {
    if (firstRender) {
      firstRender = false;
      setSelectDates([new Date()]);
    }
  }, [setSelectDates]);

  const renderCalendarHeader = useCallback(() => {
    return (
      <CalendarHeaderContainer>
        <Button
          widthSize={120}
          className="button-overlay"
          variant="contained"
          color="gray"
          onClick={e => {
            e.stopPropagation();
            setIsDrawerOpen(true);
          }}>
          Overlay
        </Button>
        <CalendarNav />
        <Icon name="arrowBottomFill" className="arrow-bottom-fill" fillColor={theme.color.gray2} size={16} />
      </CalendarHeaderContainer>
    );
  }, [setIsDrawerOpen]);

  const changeDate = useCallback((e: { value: Date }) => {
    if (!calendarRef.current) return;
    tempMonthRef.current = dayjs(e.value).set('date', 1).toDate();
  }, []);

  const marked = useMemo(() => {
    return eventTimesDataset.map(({ date }) => {
      return {
        date: dayjs(date).toDate(),
        color: theme.color.primary,
      };
    });
  }, [eventTimesDataset]);

  const onCellClick = useCallback(
    ({ date, selected }: CalendarOnCellClickParams) => {
      const isSelected = !selected;
      const currentDate = filters.dateDash(date);
      const dayOfWeek = Number(dayjs(date).format('d')) as ScheduleFormType['day_of_week'];
      const isAlready = eventTimesDataset.findIndex(field => field.date === filters.dateDash(date)) > -1;

      if (isSelected) {
        /** 특정일 근무시간 다중 입력폼에서 필드를 생성할 때 시간 및 휴식시간 기준은 근무시간 설정을 따름 */
        const currentOrigin = workTimeOrigin.filter(origin => origin.day_of_week === dayOfWeek)[0];
        if (!isAlready) {
          setDatasetFields(prev => {
            const addField: ScheduleFormType = {
              date: currentDate,
              day_of_week: dayOfWeek,
              end_time: currentOrigin?.end_time || '18:00:00',
              id: 0,
              restTimes:
                currentOrigin?.restTimes?.map(restTime => {
                  return {
                    ...restTime,
                    date: currentDate,
                    type: 'eventRestTime',
                  };
                }) || [],
              start_time: currentOrigin?.start_time || '09:00:00',
              type: 'eventWorkTime',
            };
            return [...prev, addField];
          });
        }
      } else {
        if (!isAlready) {
          setDatasetFields(prev => {
            return prev.filter(data => data.date !== currentDate);
          });
        }
      }
    },
    [setDatasetFields, workTimeOrigin, eventTimesDataset],
  );

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  return (
    <>
      <Container>
        <Datepicker
          ref={calendarRef}
          renderCalendarHeader={renderCalendarHeader}
          marked={marked}
          defaultValue={new Date()}
          onChange={({ value: dates }) => {
            setSelectDates(dates);
          }}
          onCellClick={onCellClick}
          selectMultiple
          {...CALENDAR_DEFAULT_PROPS}
        />
      </Container>

      <StyledDoubleButtonDrawer
        type={3}
        isOpen={isDrawerOpen}
        onClose={closeDrawer}
        leftButton={{
          text: '취소',
          onClick: closeDrawer,
        }}
        rightButton={{
          text: '완료',
          onClick: () => {
            if (calendarRef.current && tempMonthRef.current) {
              calendarRef.current.navigate(tempMonthRef.current);
              setIsDrawerOpen(false);
            }
          },
        }}
        hideHandle>
        {isDrawerOpen && <DatePicker dateFormat="YYYY/MM" rows={5} onChange={changeDate} />}
      </StyledDoubleButtonDrawer>
    </>
  );
};

export default DateMultiCalendarPicker;

const Container = styled(OriginPickerContainer)`
  ${theme.flex('column', 'center', 'center')};
  width: 100%;

  /** 캘린더 전체 컨테이너 */
  .mbsc-datepicker {
    margin-bottom: 6px;
    width: 100%;

    .mbsc-calendar-marks {
      position: absolute;
      top: 8px;

      .mbsc-calendar-mark {
        width: 4px;
        height: 4px;
      }
    }

    /** selected 날짜는 mark를 표시하지 않아도 됨 */
    .mbsc-selected {
      .mbsc-calendar-cell-text {
        ${theme.flex()};
        width: 30px !important;
        height: 30px !important;
      }

      .mbsc-calendar-mark {
        opacity: 0;
      }
    }

    /** outer 날짜의 mark 스타일 */
    .mbsc-calendar-day-outer .mbsc-calendar-mark {
      opacity: 0.6;
    }

    .mbsc-calendar-day-outer.mbsc-selected .mbsc-calendar-day-text {
      color: ${theme.color.white} !important;
    }
  }

  /** 헤더 버튼("YYYY년 MM월") */
  .mbsc-calendar-controls {
    padding-bottom: 18px;
    ${theme.flex('column')};
  }

  .mbsc-calendar-day,
  .mbsc-calendar-day-inner {
    ${theme.flex('row', 'center', 'center')};
    padding: 0;
  }

  .mbsc-calendar-day-inner {
    width: 44px;
    height: 40px;

    .mbsc-calendar-today {
      font-weight: 500;
    }
  }

  .mbsc-calendar-row {
    gap: 8px;
    padding: 0 9.5px;

    /** 날짜 텍스트(숫자) */
    .mbsc-calendar-day-text {
      color: ${theme.color.gray2};
    }
  }

  /** 요일 텍스트 커스텀 */
  .mbsc-calendar-week-days {
    ${theme.flex('row', 'center', 'center', 8)};
    padding: 0 10px;
  }

  /** 캘린더 테이블 스타일 */
  .mbsc-calendar-table {
    gap: 0;
  }
`;

const StyledDoubleButtonDrawer = styled(DoubleButtonDrawer)`
  padding: 16px 0 calc(16px + 76px);
`;

const CalendarHeaderContainer = styled.div`
  position: relative;
  ${theme.flex()};
  margin-top: 8px;

  /** mobiscroll 헤더 버튼 이벤트 방지용 */
  .button-overlay {
    position: absolute;
    width: 76px;
    height: 22px;
    opacity: 0;
    z-index: 2;
  }

  .mbsc-calendar-button {
    padding: 0;
    line-height: inherit;

    .mbsc-calendar-title {
      padding: 0;
      font-size: 1.5rem;
      font-weight: 600;
      color: ${theme.color.gray1};

      &.mbsc-calendar-year {
        margin-right: 6px;
      }
    }

    span {
    }
  }
`;
