import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import { useFormContext } from 'react-hook-form';
import PermissionNavigation from 'sharedComponents/PermissionNavigation';

import { CONTENT_TEXT, MENUS } from '../constants';
import ContentTitle from './ContentTitle';

type Props = {
  roles: Array<{
    id: string;
    label: string;
    value: string;
  }>;
};

const MenuNavigations = ({ roles }: Props) => {
  const { watch } = useFormContext();

  const isStudioOwner = roles.filter(role => role.id === watch('role'))[0].label === '스튜디오 오너';

  return (
    <Container>
      <ContentTitle title={CONTENT_TEXT.AccessItem.title} isStudioOwner={isStudioOwner} isPermissionTitle />
      <PermissionNavigation menus={MENUS} isStudioOwner={isStudioOwner} />
    </Container>
  );
};

export default MenuNavigations;

const Container = styled.section`
  padding: 32px 0;

  .text-button {
    ${theme.flex('row', 'center', 'space-between')};
    width: 100%;

    span {
      width: 100%;
    }
  }
`;
