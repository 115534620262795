import useGetLectureCourse from 'hooks/service/queries/useGetLectureCourse';
import useGetLectureDetail from 'hooks/service/queries/useGetLectureDetail';
import useGetLecturePolicy from 'hooks/service/queries/useGetLecturePolicy';
import useInfinityBookingList from 'hooks/service/queries/useInfinityBookingList';
import useCustomBookingForm from 'hooks/useCustomBookingForm';
import { convertBookingFormCommonState, convertRepeatFormState } from 'pages/Booking/utils';
import filters from 'utils/filters';

import { BookingPrivateUpdateAllFormType, LectureSizeType } from '../../types';
import PrivateFormLayout from '../components/PrivateFormLayout';
import PrivateUpdateAllForm from './PrivateUpdateAllForm';
type Props = {
  currentLectureId: number;
};

const FormMain = ({ currentLectureId }: Props) => {
  const { data: lectureData } = useGetLectureDetail(currentLectureId);
  const { data: courseData } = useGetLectureCourse(lectureData.course_id);
  const { data: bookings } = useInfinityBookingList(currentLectureId);
  const { data: lecturePolicy } = useGetLecturePolicy();

  const lectureSize = `${lectureData.max_trainee}` as LectureSizeType;
  const selectedUserTickets = bookings.map(({ member, user_ticket }) => {
    return { ...user_ticket, member };
  });
  const userTicketIds = bookings.map(booking => booking.user_ticket.id);

  const { repeat, repeatCustomWeekday } = convertRepeatFormState(courseData.schedules);

  const { endDate, ...rest } = convertBookingFormCommonState(lectureData);
  const methods = useCustomBookingForm<BookingPrivateUpdateAllFormType>({
    defaultValues: {
      ...rest,
      endDate: filters.dateDash(courseData.end_date),
      lectureSize,
      repeat,
      repeatCustomWeekday,
      tempRepeatCustomWeekday: repeatCustomWeekday,
      selectedUserTickets,
      prevSelectedUserTickets: selectedUserTickets,
      originUserTicketIds: userTicketIds,
      policy: {
        booking: {
          ruleType: lecturePolicy.private_booking_rule_type,
          startline: lecturePolicy.private_booking_startline,
          deadline: lecturePolicy.private_booking_deadline,
          startDays: lecturePolicy.private_booking_start_days,
          endDays: lecturePolicy.private_booking_end_days,
          startTime: lecturePolicy.private_booking_start_time,
          endTime: lecturePolicy.private_booking_end_time,
        },
        bookingCancel: {
          ruleType: lecturePolicy.private_booking_cancel_rule_type,
          startline: lecturePolicy.private_booking_cancel_startline,
          deadline: lecturePolicy.private_booking_cancel_deadline,
          startDays: lecturePolicy.private_booking_cancel_start_days,
          endDays: lecturePolicy.private_booking_cancel_end_days,
          startTime: lecturePolicy.private_booking_cancel_start_time,
          endTime: lecturePolicy.private_booking_cancel_end_time,
        },
        enterStartline: lecturePolicy.enter_start_deadline ?? 0,
        enterDeadline: lecturePolicy.enter_end_deadline ?? 0,
      },
    },
  });

  return (
    <PrivateFormLayout pageMode="updateAll" {...methods}>
      <PrivateUpdateAllForm currentLecture={lectureData} course={courseData} />
    </PrivateFormLayout>
  );
};

export default FormMain;
