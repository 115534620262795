import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import Skeleton from 'components/Skeleton';
import Typography from 'components/Typography';
import SingleButtonDrawer from 'designedComponents/Drawers/DrawerWithButton/SingleButtonDrawer';

const ReportUnpaidDetailInfoSkelton = () => {
  return (
    <>
      <ClassInfoSection>
        <Skeleton height={36} width={63} />
        <Skeleton height={62} />
      </ClassInfoSection>
      <Divider />
      <InfoWrapper>
        <Typography size={15} textColor="gray2">
          판매가
        </Typography>
        <Skeleton height={22} width={80} />
      </InfoWrapper>
      <Divider />
      <InfoWrapper>
        <Typography size={15} textColor="gray2">
          카드
        </Typography>
        <Skeleton height={22} width={80} />
      </InfoWrapper>
      <Divider thin />
      <InfoWrapper>
        <Typography size={15} textColor="gray2">
          현금
        </Typography>
        <Skeleton height={22} width={80} />
      </InfoWrapper>
      <Divider thin />
      <InfoWrapper>
        <Typography size={15} textColor="gray2">
          계좌이체
        </Typography>
        <Skeleton height={22} width={80} />
      </InfoWrapper>
      <Divider thin />
      <InfoWrapper>
        <Typography size={15} textColor="gray2">
          포인트
        </Typography>
        <Skeleton height={22} width={80} />
      </InfoWrapper>
    </>
  );
};

export default ReportUnpaidDetailInfoSkelton;

const StyledSingleButtonDrawer = styled(SingleButtonDrawer)`
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: calc(32px + 76px);
`;

const HeaderTextWrapper = styled.div`
  ${theme.flex('row', 'center', 'space-between')};
  padding: 16px 20px;

  .member-name-wrapper {
    ${theme.flex('row', '', '', 4)}
    align-items: center;
  }

  .label {
    margin: 2px 0px;
  }
`;

const ClassInfoSection = styled.section`
  padding: 0 16px 20px 16px;
  display: flex;
  gap: 16px;
  align-items: center;
`;

const InfoWrapper = styled.div`
  ${theme.flex('', '', 'space-between')}
  padding: 17px 20px;
`;
