import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import { useFormContext, useWatch } from 'react-hook-form';

import { BOOKING_COUNT_STATUS } from '../utils/bookings';
import SelectUserTicketItem from './SelectUserTicketItem';
import { BookingFormType } from './types';

const SelectedUserTicketList = () => {
  const { control, getValues } = useFormContext<BookingFormType>();
  const selectedUserTickets = useWatch({ control, name: 'selectedUserTickets' });
  const selectedCount = selectedUserTickets.length;
  const bookingCount = selectedUserTickets.filter(({ status }) => status && BOOKING_COUNT_STATUS.includes(status)).length;

  return (
    <>
      <Divider thin />
      <Container>
        <CountWrapper>
          <Typography span size={13} weight={500} textColor="gray2">
            예약인원
          </Typography>
          <Typography span size={13} weight={500} textColor="gray3">
            <Typography span size={13} weight={600} textColor="primary">
              {bookingCount}
            </Typography>
            <>/{getValues('currentLecture.max_trainee')}</>
          </Typography>
        </CountWrapper>
        {!selectedCount ? (
          <NoMemberTypography textColor="gray3">선택된 예약인원이 없습니다.</NoMemberTypography>
        ) : (
          <ul>
            {selectedUserTickets.map((userTicket, index) => {
              return <SelectUserTicketItem key={`${userTicket.id}-${index}`} userTicket={userTicket} />;
            })}
          </ul>
        )}
      </Container>
      <Divider thin />
    </>
  );
};

export default SelectedUserTicketList;

const Container = styled.div`
  width: 100%;
  flex: 0 0 auto;
  padding-top: 12px;

  .label-text {
    padding-left: 20px;
  }

  ul {
    padding: 0 20px;
    max-height: 220px;
    overflow-y: auto;
  }
`;

const CountWrapper = styled.div`
  padding: 0 20px;
  margin-bottom: 4px;
  ${theme.flex('', 'center', 'flex-start', 4)};
`;

const NoMemberTypography = styled(Typography)`
  ${theme.flex('column', 'center', 'center')};
  height: 124px;
`;
