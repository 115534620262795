import { useQueryClient } from '@tanstack/react-query';
import axios, { isAxiosError } from 'axios';
import useErrorDialog from 'hooks/useErrorDialog';
import { useEffect } from 'react';
import { type FallbackProps } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import { recordErrorWithSentry } from 'utils/sentry';

const ApiErrorFallback = ({ error }: FallbackProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const errorDialog = useErrorDialog();

  if (!axios.isAxiosError(error)) {
    throw error;
  }

  useEffect(() => {
    if (error) {
      recordErrorWithSentry(error);

      const isErrorDrawerCase =
        isAxiosError(error) &&
        (error?.message === 'Network Error' || error?.response?.status === 401 || error?.response?.status === 403);

      if (isErrorDrawerCase) {
        const type = error.message === 'Network Error' ? 'not-connected' : error.response?.status;
        navigate(`/error?type=${type}`, { replace: true });
        queryClient.clear(); // 에러 발생 후 기존 쿼리 재호출 방지
      } else if (error?.response?.status === 400 || error?.response?.status === 422) {
        errorDialog.open(error);
      } else {
        // NOTE: close 함수를 어떻게 전달하면 좋을지 고민됩니다.
        const customClose = () => {
          navigate(-1);
        };
        errorDialog.open(error, customClose, '이전 화면으로');
      }
    }
  }, [error, errorDialog, navigate, queryClient]);

  return null;
};

export default ApiErrorFallback;
