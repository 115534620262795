import { BOOKING_COMMON_FORM_TEXT } from 'pages/Booking/constants';
import { BookingCommonFormType } from 'pages/Booking/types';
import { useFormContext } from 'react-hook-form';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import FormFieldLinkButton from 'sharedComponents/FormField/FormFieldLinkButton';

import CheckRoomUsable from './CheckRoomUsable';

const Room = () => {
  const { getValues } = useFormContext<BookingCommonFormType>();
  const hasValue = !!getValues('selectedRoom') && getValues('selectedRoom.id') > 0;

  return (
    <ApiBoundary
      fallback={
        <FormFieldLinkButton
          iconName="room"
          hasValue={hasValue}
          disabled={!getValues('selectedRoom')}
          showArrow={hasValue}
          ellipsis={1}>
          {getValues('selectedRoom') ? `${getValues('selectedRoom.name')} 룸` : BOOKING_COMMON_FORM_TEXT.room.noSetting}
        </FormFieldLinkButton>
      }>
      <CheckRoomUsable />
    </ApiBoundary>
  );
};

export default Room;
