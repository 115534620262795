export const USER_TICKET_STATUS: { [key: string]: string } = {
  refund: '환불',
  not_usable: '사용불가',
  expired: '이용만료',
  holding: '정지중',
  to_use: '사용예정',
  using: '사용중',
  sell: '판매',
  transfer: '양도',
} as const;

export const SHOW_LABEL_LIST = ['not_usable', 'expired', 'refund', 'transfer'];
