import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import NumberPicker from 'components/NumberPicker';
import Radio from 'components/Radio';
import Typography from 'components/Typography';
import { MemberTicketFilterType } from 'pages/Member/types';
import FilterTitle from 'sharedComponents/Filters/FilterTitle';
import { FilterWrap } from 'sharedComponents/Filters/StyledComponents';
import { FilterProps } from 'sharedComponents/Filters/types';
import PeriodPicker from 'sharedComponents/PeriodPicker';
import filters from 'utils/filters';

const TicketPeriodFilter = ({ currentFilter, selectedOption, onClick: changeOption }: FilterProps) => {
  const previousOption = selectedOption as MemberTicketFilterType | undefined;

  const changePeriod = (period: string[]) => {
    changeOption({ value: { date: period.map(filters.dateDash), count: previousOption?.count }, label: '' }, 'ticket_remaining');
    return false;
  };

  const changeCount = (count?: number) => {
    changeOption({ value: { date: previousOption?.date ?? [], count }, label: '' }, 'ticket_remaining');
  };

  const unset = () => {
    changeOption({ value: undefined, label: '' }, 'ticket_remaining');
  };

  const isNotApplied = !previousOption;
  const isCountSelected = !!previousOption && !!previousOption.count;
  const isNoCountSelected = !!previousOption && !previousOption.count;

  return (
    <>
      <Container>
        <FilterTitle title={currentFilter.title} />
        <PeriodPicker
          initialDate={previousOption?.date ?? []}
          onSave={changePeriod}
          startDateDisabled={isNotApplied}
          endDateDisabled={isNotApplied}
        />
        <Radio
          id="ticket-notApplied"
          name="ticket-notApplied"
          label={<Typography size={14}>{currentFilter.options[0].label}</Typography>}
          onChange={unset}
          checked={isNotApplied}
        />
        <Radio
          id="ticket-noCount"
          name="ticket-noCount"
          label={<Typography size={14}>{currentFilter.options[1].label}</Typography>}
          onChange={() => changeCount(undefined)}
          checked={isNoCountSelected}
        />
        <Radio
          id="ticket-count"
          name="ticket-count"
          label={
            <LabelWrapper>
              <Typography size={14}>기간 내</Typography>
              <Count size={14} weight={500} isOpen={isCountSelected} disabled={!isCountSelected}>
                {previousOption?.count ?? 1}
              </Count>
              <Typography size={14}>회 이하로 남은 회원만 보기</Typography>
            </LabelWrapper>
          }
          onChange={() => changeCount(1)}
          checked={isCountSelected}
        />
      </Container>
      {isCountSelected && (
        <>
          <Divider thin thinColor="gray6" />
          <NumberPicker value={previousOption.count} onChange={(e: { value: number }) => changeCount(e.value)} />
        </>
      )}
    </>
  );
};

export default TicketPeriodFilter;

const Container = styled(FilterWrap)`
  label {
    :not(:last-of-type) {
      margin-bottom: 8px;
    }
    :first-of-type {
      margin-bottom: 12px;
    }
  }

  .period-picker {
    margin-bottom: 16px;
  }

  .radio {
    gap: 8px;
  }

  :disabled {
    color: ${theme.color.gray4};
  }
`;

const LabelWrapper = styled.div`
  ${theme.flex('', 'center', '', 6)};
`;

const Count = styled(Typography)<{ isOpen: boolean; disabled: boolean }>(
  css`
    ${theme.flex('', 'center', 'flex-start')}
    width: 43px;
    height: 32px;
    padding: 0 8px;
    border-radius: 6px;
    box-shadow: 0 0 0 1px ${theme.color.gray5};
  `,
  ({ isOpen }) =>
    isOpen &&
    css`
      box-shadow: 0 0 0 1px ${theme.color.primary};
    `,
  ({ disabled }) =>
    disabled &&
    css`
      background-color: ${theme.color.gray6};
      border-color: ${theme.color.gray6};
      color: ${theme.color.gray3};
    `,
);
