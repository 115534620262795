import { theme } from 'assets/styles';
import { ILabelProps } from 'components/Label';
import { PERMISSION } from 'constants/permission';
import dayjs from 'dayjs';
import { MemberPointHistoryResponse, PaymentStatusType } from 'hooks/service/queries/useInfinityPointHistory';
import { FilterType } from 'sharedComponents/Filters/types';
import convertTargetsText from 'utils/convertTargetsText';
import filters from 'utils/filters';

import { HistoryFilterFormType, MemberDropDownValueType, PointSelectedFilterType } from './types';
import { extractMultipleLabels } from './utils/memberDetailFilters';

export const MEMBER_PERMISSION = {
  memberEdit: PERMISSION.member.edit,
  memberDelete: PERMISSION.member.delete,
  memberTicketCreate: PERMISSION.member.registerTicket,
  memberTicketEdit: PERMISSION.member.updateTicket,
};

export const APP_LINK_TEXT = {
  form: {
    title: '회원앱 다운로드 링크입니다.',
    message: `안녕하세요 회원님. 어플 링크 보내드립니다.\n회원님의 기간 조회, 수업 예약 등을 간편하게 이용하실 수 있습니다. \n\n안드로이드 - https://play.google.com/store/apps/details?id=kr.studiomate.user \n아이폰 - https://apps.apple.com/kr/app/스튜디오메이트-회원용/id1400126585`,
    label: {
      member: '회원명',
      title: '제목',
      message: '내용',
    },
  },
  title: '앱 링크 문자보내기',
  reservation: {
    switchLabel: '보내기 예약',
    dateButtonLabel: '예약일시 선택',
  },
  mobileMessage: {
    type: {
      LMS: 'LMS',
      SMS: 'SMS',
    },
    deductPoint: {
      LMS: 37,
      SMS: 12,
    },
    noPoint: {
      title: '포인트가 부족합니다.',
    },
    confirm: {
      tooltipMessage: ['예약 발송 시, 포인트는 선차감 됩니다.'],
      title: {
        send: '메시지를 즉시 발송합니다.',
        reserved: '메시지를 발송 예약합니다.',
      },
      footerButton: {
        send: '발송 완료',
        reserved: '예약 완료',
      },
    },
  },
};

export const DROPDOWN_DATA: MemberDropDownValueType[] = [
  { value: 'update', label: '수정하기', icon: { name: 'writing2', fillColor: theme.color.gray2 } },
  { value: 'delete', label: '삭제하기', icon: { name: 'delete', fillColor: theme.color.secondary3 } },
];

export const DETAIL_INFO_TAB_DATA = [
  { value: 'basic', label: '기본정보' },
  { value: 'history', label: '이용내역' },
  { value: 'point', label: '포인트내역' },
  { value: 'payment', label: '결제내역' },
  { value: 'memo', label: '메모' },
];

export const BASIC_TAB_DATA = [
  { value: 'userTicket', label: '수강권' },
  { value: 'item', label: '상품' },
];

export const FILTER_SORT = [
  { id: 'desc', label: '수업일 내림차순', value: 'desc' },
  { id: 'asc', label: '수업일 오름차순', value: 'asc' },
];

export const BOOKING_TYPE: { [key: string]: string } = {
  booked: '예약',
  booking_confirmed: '예약확정',
  booking_waiting: '예약대기',
  cancel: '취소',
  noshow: '노쇼',
  absence: '결석',
  attendance: '출석',
} as const;

export const BOOKING_LABEL_PROPS: { [key: string]: Pick<ILabelProps, 'color' | 'textColor' | 'opacity'> } = {
  booked: { textColor: 'primary', color: 'primary', opacity: 0.12 },
  booking_confirmed: { textColor: 'primary', color: 'primary', opacity: 0.12 },
  booking_waiting: { textColor: 'gray2', color: 'gray6' },
  cancel: { textColor: 'secondary3', color: 'secondary3', opacity: 0.12 },
  noshow: { textColor: 'secondary3', color: 'secondary3', opacity: 0.12 },
  absence: { textColor: 'secondary3', color: 'secondary3', opacity: 0.12 },
  attendance: { textColor: 'blueViolet1', color: 'blueViolet1', opacity: 0.12 },
} as const;

export const HISTORY_SORTING_DATA = [
  { id: 'asc', label: '수업일 오름차순', value: 'asc' },
  { id: 'desc', label: '수업일 내림차순', value: 'desc' },
];

export const MEMBER_DETAIL_HISTORY_DEFAULT: HistoryFilterFormType = {
  status: 'all',
  ticketId: 'all',
  sort: 'desc',
  bookingInfo: {
    id: 0,
    status: '',
    classType: '',
  },
};

export const POINT_TYPE_DATA: { [key in MemberPointHistoryResponse['point_type']]: string } = {
  reward: '적립',
  deduct: '차감',
  mix: '혼합',
  destruction: '소멸',
  batch_reward: '적립 (일괄적립)',
  batch_deduct: '차감 (일괄차감)',
};

export const POINT_PAYMENT_STATUS_DATA: { [key in PaymentStatusType]: string } = {
  purchase: '발급',
  change: '변경',
  upgrade: '업그레이드',
  extension: '업그레이드',
  transfer: '양도',
  refund: '환불',
  installment_payment: '미수금',
  full_payment: '미수금',
  // 아래는 현재 들어오지 않는 데이터
  pending: '결제중',
  downgrade: '다운그레이드',
} as const;

export const POINT_POPUP_TEXT = {
  subtitle: ['회원의 잔여 포인트를', '수정합니다.'],
  reward: {
    label: '적립',
    value: 'reward',
  },
  deduct: {
    label: '차감',
    value: 'deduct',
  },
  reason: {
    placeholder: '최대 20자까지 입력',
  },
  drawer: {
    headerText: '포인트 수정',
  },
  toastMessage: '잔여 포인트 수정이 완료되었습니다.',
};

export const POINT_FILTER_TABS = [
  { value: 'period', label: `${filters.dateYearMonthKor()}` },
  { value: 'selectedStaffs', label: '강사 전체' },
  { value: 'type', label: '구분 전체' },
  { value: 'status', label: '분류 전체' },
];

export const POINT_FILTER_DEFAULT_TAB_LABEL = {
  period: filters.dateYearMonthKor(),
  selectedStaffs: '강사 전체',
  type: '구분 전체',
  status: '분류 전체',
};

export const POINT_FILTERS: Array<FilterType> = [
  {
    key: 'period',
    type: 'period',
    title: '기간선택',
    options: [
      { value: 'month', label: '월간' },
      { value: 'custom', label: '직접선택' },
    ],
    tabConverter: value => {
      const periodValue = value as PointSelectedFilterType['period'];
      if (!periodValue || periodValue.type === 'month') {
        return filters.dateYearMonthKor(periodValue?.start_date);
      }
      return filters.period(periodValue.start_date, periodValue.end_date);
    },
  },
  {
    key: 'selectedStaffs',
    type: 'select',
    title: '강사',
    options: [],
    tabConverter: value => {
      const staffsValue = value as PointSelectedFilterType['selectedStaffs'];
      const staff = staffsValue?.map(({ label }) => ({ name: label }));
      return convertTargetsText(staff, '강사 전체');
    },
  },
  {
    key: 'type',
    type: 'button',
    title: '구분',
    options: [
      { value: undefined, label: '전체' },
      { value: 'save', label: '적립' },
      { value: 'remove', label: '차감' },
      { value: 'destruction', label: '소멸' },
    ],
    tabConverter: value => {
      return !value ? '전체' : extractMultipleLabels(POINT_FILTERS[2].options, value as string[]);
    },
  },
  {
    key: 'status',
    type: 'button',
    title: '분류',
    options: [
      { value: undefined, label: '전체' },
      { value: 'purchase', label: '발급' },
      { value: 'change', label: '변경' },
      { value: 'upgrade', label: '업그레이드' },
      { value: 'installment_payment', label: '미수금' },
      { value: 'refund', label: '환불' },
      { value: 'transfer', label: '양도' },
    ],
    tabConverter: value => {
      return !value ? '전체' : extractMultipleLabels(POINT_FILTERS[3].options, value as string[]);
    },
  },
];

export const POINT_FILTER_DEFAULT = {
  period: {
    type: 'month',
    start_date: filters.dateDash(dayjs().startOf('month')),
    end_date: filters.dateDash(),
  },
};

export const POINT_FILTER_TEXT = {
  periodDescription: '최대 5개월까지 조회할 수 있습니다.',
};

export const MEMBER_DETAIL_MAIN_TEXT = {
  deleteDialog: {
    title: '회원을 삭제하시겠습니까?',
    successMessage: '회원이 삭제되었습니다.',
  },
};
